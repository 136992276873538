import { FILTERS_MENU, TABLE } from '../actions/actionTypes';
import { TableMenu } from '../Interfaces/TableMenu';

const INITIAL_STATE: TableMenu = {
  selectedTable: undefined,
};

const tableMenu = (state = INITIAL_STATE, action): TableMenu => {
  switch (action.type) {
    case TABLE.SELECT_TABLE:
      if (action.formId) {
        return { ...state, selectedTable: action.formId };
      }
      return state;
    case FILTERS_MENU.SELECT_FORM:
      if (!state.selectedTable) {
        return { ...state, selectedTable: action.payload.ref };
      }
      return state;
    case FILTERS_MENU.UNSELECT_FORM: {
      const form = action.payload.ref;
      // Unselecting the current table shown.
      if (form === state.selectedTable) {
        return { ...state, selectedTable: undefined };
      }
      return state;
    }
    case FILTERS_MENU.UNSELECT_ALL_FORMS:
      return { ...state, selectedTable: undefined };
    case FILTERS_MENU.SELECT_ALL_FORMS:
      if (!state.selectedTable) {
        if (action.payload.length > 0) {
          return { ...state, selectedTable: action.payload[0].ref };
        }
      }
      return state;
    default:
      return state;
  }
};

export default tableMenu;
