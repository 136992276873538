import '../UserDateFilter.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { DateFilters } from 'Interfaces/FiltersMenuInterface';
import { getLocalization } from '../../../global/global';
import { DateFilterProps } from './DatesContainer';

export default class DateComponent extends React.Component<DateFilterProps> {

  @bind
  private handleDateValueChange(date, name) {
    const { selectedDates } = this.props;
    const newDates: Partial<DateFilters> = {};
    newDates[name] = date;
    this.props.setSelectedDates({...selectedDates, ...newDates});
  }

  @bind
  private clearFilters() {
    this.props.setSelectedDates({
      from: '', to: '', filterBy: 'modified'
    });
  }

  public render(): JSX.Element {
    const { filterBy } = this.props.selectedDates;
    return (
      <div className="FilterBoxContainer col">
        <div className={`FilterBoxContainer__header`}>
          <label>{getLocalization('filterdate')}</label>
          <Button variant="link" onClick={this.clearFilters}>{getLocalization('clear')}</Button>
        </div>
        <div className="FilterBoxContainer__body">
          <Form.Group>
            <Form.Label column sm={3} className="date-filter-form-label">
              {getLocalization('filterBy')}
            </Form.Label>
            <Form.Check
              inline
              label={getLocalization('modifiedDate')}
              type={'radio'}
              checked={filterBy === 'modified'}
              onChange={() => this.handleDateValueChange('modified', 'filterBy')}
            />
            <Form.Check
              inline
              label={getLocalization('createdDate')}
              type={'radio'}
              checked={filterBy === 'created'}
              onChange={() => this.handleDateValueChange('created', 'filterBy')}
            />
          </Form.Group>
          <Form.Group as={Row} className="DateFilterFormGroup">
            <Form.Label column sm={2} className="date-filter-form-label">{getLocalization('from')}</Form.Label>
            <Col sm={10}>
              <Form.Control
                as="input"
                type="date"
                size="sm"
                onChange={(e) => this.handleDateValueChange((e.target as HTMLInputElement).value, 'from')}
                value={this.props.selectedDates['from'] || ''}
                max={this.props.selectedDates['to'] || undefined}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="DateFilterFormGroup">
            <Form.Label column sm={2} className="date-filter-form-label">{getLocalization('to')}</Form.Label>
            <Col sm={10}>
              <Form.Control
                as="input"
                type="date"
                size="sm"
                onChange={(e) => this.handleDateValueChange((e.target as HTMLInputElement).value, 'to')}
                value={this.props.selectedDates['to'] || ''}
                min={this.props.selectedDates['from'] || undefined}
              />
            </Col>
          </Form.Group>
        </div>
      </div>
    );
  }
}
