import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { ListItem } from 'Interfaces/Forms/QuestionInterface';
import { LooseObject } from 'Interfaces/LooseObject';
import * as React from 'react';
import FormUtils from '../utils/FormUtils';
import QuestionLabel from './QuestionLabel';

interface Props {
  dataPoint: DataPoint;
  question: LooseObject;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isTable: boolean;
  forms: LooseObject[];
  newAnswer: boolean;
  clientPersist: ClientPersistInterface;
  edit: boolean;
  parentModel?: LooseObject;
  parentDataPoint?: LooseObject;
  isSubQuestion?: boolean;
}

const RankOrderQuestion = (props: Props) => {
  const { isSubQuestion, formUtils, question, dataPoint } = props;
  const li = question.listItems.listItem;
  const getValue = (v) => {
    if (!v) {
      return li.map(() => '');
    }
    return v.split(',');
  };

  const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);

  const [value, setValue] = React.useState<string[]>(getValue(dataPoint[question.id]));

  const hasError = required && dataPoint.validate && value.length === 0 ? 'has-error' : '';

  const onChange = (v: string, i: number) => {
    const newValue = [...value];
    const index = newValue.indexOf(v);
    if (index > -1) {
      newValue[index] = '';
    }
    if (i !== 0) {
      newValue[i - 1] = v;
    }
    setValue(newValue);
    const { updateAnswer } = props;
    const newAnswer = {};
    newAnswer[question.id] = newValue.join(',');
    updateAnswer(newAnswer);
  };

  return (
    <div className={`${'form-group'} ${hasError} ${!isSubQuestion && formUtils.getResponsiveView(question)}`}>
      <QuestionLabel question={question} dataPoint={dataPoint} formUtils={formUtils}>
        {required}
      </QuestionLabel>
      {li.map((l) => {
        return (
          <RankOrderItem
            key={`rank-order-${l.id}`}
            value={value}
            listItem={l}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
};

interface RankOrderItemProps {
  value: (string | undefined)[];
  listItem: ListItem;
  onChange: (value: string, index: number) => void;
}

const RankOrderItem = (props: RankOrderItemProps) => {
  const { value, listItem } = props;
  const [options, setOptions] = React.useState<JSX.Element[]>([]);
  React.useEffect(() => {
    const opts: JSX.Element[] = [(
      <option value={''} key={`RankOrderItem-initial-${listItem.id}`}>{''}</option>
    )];
    for (let i = 0; i < value.length; i++) {
      if (!value[i] || value[i] === listItem.id) {
        opts.push(<option value={i + 1} key={`RankOrderItem-${i}-${listItem.id}`}>{i + 1}</option>);
      }
    }
    setOptions(opts);
  }, [props]);

  return (
    <div className="form-group row">
      <div className="col">
        <select
          className="form-control"
          name={`rank-${listItem.id}`}
          value={value.indexOf(listItem.id) > -1 ? (value.indexOf(listItem.id) + 1) : ''}
          onChange={(e) => props.onChange(listItem.id || '', Number(e.target.value))}
        >
          {options}
        </select>
      </div>
      <div className="col-10">
        <label >{listItem.value}</label>
      </div>
    </div>
  );
};

export default RankOrderQuestion;
