import { DataPoint } from 'Interfaces/DataPoint';
import { SHARE } from '../actions/actionTypes';

const sharedData = (state: DataPoint = [], action) => {
  switch (action.type) {
    case SHARE.LOAD_DATA:
      if (action.data) {
        return Object.assign([], action.data);
      }
      return state;
    case SHARE.UNSHARE:
      if (action.sharedData) {
        const ids = action.sharedData.map((sd) => sd.id);
        return state.filter((sd) => ids.indexOf(sd.id) === -1);
      }
      return state;
    default:
      return state; //
  }
};

export default sharedData;
