import { ThunkDispatchAction } from 'actions';
import { ActionTransmitInterface } from 'Interfaces/ActionTransmitInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { StateInterface } from 'Interfaces/StateInterface';
import { ShareUser } from 'Interfaces/User';
import * as ShareApi from '../api/shareApi';
import { SHARE } from './actionTypes';

export const checkUser = (email: string, id: string, signal: AbortSignal) => {
  return (dispatch: ThunkDispatchAction, getState: () => StateInterface): Promise<Response> => {
    return ShareApi.doCheckUser(email, id, signal, dispatch, getState);
  };
};

export const shareDataPoint = (user: ShareUser, dataPoint: DataPoint, signal: AbortSignal) => {
  return (dispatch: ThunkDispatchAction, getState: () => StateInterface): Promise<Response> => {
    return ShareApi.doShareDataPoint({ user, dataPoint }, signal, dispatch, getState);
  };
};

export const getSharerEmail = (id: string, signal: AbortSignal) => {
  return (dispatch: ThunkDispatchAction, getState: () => StateInterface): Promise<Response> => {
    return ShareApi.doGetSharerUser(id, signal, dispatch, getState);
  };
};

export const loadSharedData = (signal: AbortSignal) => {
  return (dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
    ShareApi.doLoadSharedData(signal, dispatch, getState)
      .then((response) => response.json())
      .then((json) => {
        dispatch(loadedSharedData(json));
      })
      .catch((err) => console.log(err));
  };
};

export const loadedSharedData = (data: DataPoint[]) => ({ type: SHARE.LOAD_DATA, data });

export const unshare = (sharedData: DataPoint[], signal: AbortSignal) => {
  return (dispatch: ThunkDispatchAction, getState: () => StateInterface): Promise<Response> => {
    return new Promise((resolve) => {
      ShareApi.doUnSharedData(sharedData, signal, dispatch, getState)
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'OK') {
            dispatch(unShareData(sharedData));
          }
          resolve(new Response(JSON.stringify(json)));
        })
        .catch((err) => console.log(err));
    });
  };
};

export const unShareData = (sharedData: DataPoint[]) => ({ type: SHARE.UNSHARE, sharedData });

export const sendActionEmail =
    (actionTransmit: ActionTransmitInterface, signal: AbortSignal) =>
      (dispatch: ThunkDispatchAction, getState: () => StateInterface): Promise<Response> => {
        const headers = {
          body: JSON.stringify(actionTransmit),
          method: 'POST',
        };
        return ShareApi.doSendActionEmail(signal, headers, dispatch, getState);
      };
