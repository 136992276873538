import '../styles/UserQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getLocalization } from '../../../global/global';
import { ConfirmationModal } from '../../Modals/ConfirmationModal';

export interface IExternalProps {
  newAnswer: boolean;
  saveType?: IOwnState['saveType'];
  savingInBackground: boolean;
  validateForm: () => boolean;
  savePOI: (saveType: IOwnState['saveType'], exportOnSave?: boolean) => void;
}

export interface IOwnState {
  showConfirm: boolean;
  newAnswer: boolean;
  saveType: 'CLOSE' | 'DRAFT' | 'NEW' | 'VIEW';
}

export default class SaveButton extends React.Component <IExternalProps, IOwnState> {
  private BACKGROUND_SAVING_ID;
  constructor(props) {
    super(props);
    this.state = {
      showConfirm: false,
      newAnswer: props.newAnswer,
      saveType: 'DRAFT'
    };
  }

  @bind
  private onSave(type: 'CLOSE' | 'NEW' | 'VIEW') {
    if (this.props.saveType) {
      toast(getLocalization('saveInProgress'), {
        toastId: this.BACKGROUND_SAVING_ID,
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: false
      });
    } else if (this.props.savingInBackground) {
      this.BACKGROUND_SAVING_ID = Date.now() + Math.floor(Math.random() * 100);
      toast(getLocalization('backgroundSavingInProgress'), {
        toastId: this.BACKGROUND_SAVING_ID,
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        closeButton: true,
        hideProgressBar: true,
        closeOnClick: false
      });
    } else {
      const valid =  this.props.validateForm();
      if (valid) {
        this.setState({ showConfirm: true, saveType: type  });
      }
    }
  }

  @bind
  private onSaveDraft() {
    this.setState({ showConfirm: true, saveType: 'DRAFT'  });
  }

  @bind
  private onSaveConfirmed() {
    this.closeModal();
    this.props.savePOI(this.state.saveType);
  }

  @bind
  private closeModal() {
    this.setState({ showConfirm: false });
  }

  public componentDidUpdate(prevProps: IExternalProps) {
    if (prevProps.savingInBackground && !this.props.savingInBackground && this.BACKGROUND_SAVING_ID) {
      toast.update(this.BACKGROUND_SAVING_ID, {
        type: toast.TYPE.SUCCESS,
        render: getLocalization('backgroundSavingDone')
      });
      setTimeout(() => toast.dismiss(this.BACKGROUND_SAVING_ID), 3000);
    }
  }

  public render(): JSX.Element {
    const confirmModal = this.state.showConfirm ? (
      <ConfirmationModal
        onConfirm={this.onSaveConfirmed}
        onClose={this.closeModal}
        localizations={
          {
            cancel: getLocalization('cancel'),
            confirm:getLocalization('save'),
            confirmStyle: 'primary',
            header: (
              <label>{getLocalization('confirm')}</label>
            ),
            body: (
              <div>{getLocalization('saveData')}</div>
            )
          }
        }
      />
    )
      : null;
    return (
      <React.Fragment>
        {confirmModal}
        <SplitButton
          variant={'primary'}
          title={getLocalization('save')}
          id={'save-btn'}
          size="sm"
          alignRight
          onClick={() => this.onSave('CLOSE')}
        >
          {this.state.newAnswer && (
            <>
              <Dropdown.Item as="li" eventKey="1" onClick={() => this.onSave('NEW')} id={'save-new'}>
                {getLocalization('saveandnew')}
              </Dropdown.Item>
              <Dropdown.Item as="a" eventKey="2" onClick={() => this.onSave('VIEW')} id="save-view">
                {getLocalization('saveandview')}
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Item as="a" eventKey="3" onClick={this.onSaveDraft} id="save-draft">
            {getLocalization('savedraft')}
          </Dropdown.Item>
        </SplitButton>
      </React.Fragment>
    );
  }
}
