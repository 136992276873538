import './Style.scss';
import { DocumentFileInterface, DocumentFilesInterface } from 'Interfaces/DocumentInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { ConfirmationModal } from 'views/Modals/ConfirmationModal';
import { deleteDocumentFile, getDocumentFiles } from 'actions/documentActions';
import * as React from 'react';
import { Button, Container, Figure, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getLocalization } from 'global/global';
import PMCheckbox from '../../components/Checkbox';
import AddFile from './AddFile';

interface ActionProps {
  getDocumentFiles: () => void;
  deleteDocumentFile: (id: number) => void;
}

interface StateProps {
  documentFiles: DocumentFilesInterface;
}

interface Props {
  createNewDocument: (files: DocumentFileInterface[]) => void;
}

function DocumentFiles(props: StateProps & ActionProps & Props) {

  const [loading, setLoading] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState<number[]>([]);
  const [files, setFiles] = React.useState<DocumentFileInterface[]>([]);
  const [tempFile, setTempFile] = React.useState<DocumentFileInterface | undefined>(undefined);

  React.useEffect(() => {
    if (!loading && !props.documentFiles.loaded) {
      setLoading(true);
      props.getDocumentFiles();
    } else if (props.documentFiles.loaded) {
      setLoading(false);
      setFiles(props.documentFiles.collection.filter(f => !f.documentId));
      const ids = props.documentFiles.collection.map(f => f.id);
      setSelectedFiles(selectedFiles.filter(id => ids.indexOf(id) > -1));
    }
  }, [props.documentFiles]);

  const confirmDelete = tempFile ? (
    <ConfirmationModal
      localizations={{
        cancel: getLocalization('cancel'),
        confirm: getLocalization('deleteData'),
        confirmStyle: 'danger',
        header: (<label>{getLocalization('confirm')}</label>),
        body: (<p>{getLocalization('confirmFileDelete')}</p>)
      }}
      onConfirm={() => {
        if (tempFile) {
          props.deleteDocumentFile(Number(tempFile.id));
          setTempFile(undefined);
        }
      }}
      onClose={() => setTempFile(undefined)}
    />
  ) : null;

  return (
    <div className="doc-sub-view">
      {confirmDelete}
      <Row>
        {files.map((file) => {
          return (
            <Figure
              key={`document-files-${file.id}`}
              className="document-file"
            >
              <Figure.Image
                width={200}
                height={200}
              />
              <Container className="document-file-action">
                <PMCheckbox
                  checked={selectedFiles.indexOf(Number(file.id)) > -1}
                  value={`${file.id}`}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    const value = Number(file.id);
                    setSelectedFiles(
                      checked ? selectedFiles.concat(value) : selectedFiles.filter(i => i !== value)
                    );
                  }}
                />
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => setTempFile(file)}
                >
                  <i className="fa fa-trash" aria-hidden="true" />
                </Button>
              </Container>
              <Figure.Caption>{file.name}</Figure.Caption>
            </Figure>
          );
        })}
      </Row>
      <AddFile />
      <Button
        className="ml-3"
        size="sm"
        disabled={selectedFiles.length === 0}
        onClick={() => {
          props.createNewDocument(files.filter((f) => selectedFiles.indexOf(Number(f.id)) > -1));
        }}
      >
        {getLocalization('createNewDocument')}
      </Button>
    </div>
  );
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    documentFiles: state.documentFiles,
  };
};

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    getDocumentFiles: () => dispatch(getDocumentFiles()),
    deleteDocumentFile: (id: number) => dispatch(deleteDocumentFile(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentFiles);
