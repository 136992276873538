import { Permission, RolePermissions } from 'Interfaces/Documents/PermissionInterface';
import * as React from 'react';
import { Form, Table } from 'react-bootstrap';
import { roles } from 'views/Document/DocumentPermissions';

interface Props {
  id: number;
  rolePermissions: RolePermissions;
  updatePermission: (permission: Permission, role: string) => void;
}

export default function PermissionView(props: Props) {
  const { rolePermissions, updatePermission, id } = props;
  return (
    <Table className="mt-5" hover={true} size="sm">
      {rolePermissions && roles.map((role) => {
        const permission = rolePermissions[role];
        return (
          <tr key={`role-${id}-${permission.documentFolderId}-${role}`}>
            <td>{permission.role}</td>
            <td>
              <Form.Check
                label={'Read'}
                type="checkbox"
                id={`${role}-${id}-read`}
                checked={permission.read}
                onChange={(e) => updatePermission({
                  ...permission, read: e.target.checked,
                  write: !e.target.checked ? false : permission.write
                }, role)}
              />
            </td>
            <td>
              <Form.Check
                label={'Write'}
                id={`${role}-${id}-write`}
                checked={permission.write}
                onChange={(e) => updatePermission({
                  ...permission, write: e.target.checked,
                  read: e.target.checked ? true : permission.read
                }, role)}
              />
            </td>
            <td>
              <Form.Check
                label={'Delete'}
                id={`${role}-${id}-delete`}
                checked={permission.delete}
                onChange={(e) => updatePermission({...permission, delete: e.target.checked}, role)}
              />
            </td>
          </tr>
        );
      })}
    </Table>
  );
}
