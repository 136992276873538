import { JSONInterface } from 'Interfaces/JsonInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { ShareUser } from 'Interfaces/User';
import { ThunkDispatchAction } from 'actions';
import { StateInterface } from 'Interfaces/StateInterface';
import { request } from './request';
import { getRequestConfig } from './index';

export const doSendActionEmail = (
  signal: AbortSignal,
  config: JSONInterface,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  return request('/json/app/action/send', { ...headers, ...config, signal: signal }, dispatch, getState);
};

export const doCheckUser = (
  email: string,
  id: string,
  signal: AbortSignal,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const config = getRequestConfig();
  return request(
    '/json/app/share/check/user',
    Object.assign(
      {},
      {
        method: 'POST',
        body: JSON.stringify({ email, id }),
      },
      config,
      signal,
    ),
    dispatch,
    getState,
  );
};

export const doShareDataPoint = (
  shareDetails: { user: ShareUser; dataPoint: DataPoint },
  signal: AbortSignal,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const config = getRequestConfig();
  return request(
    '/json/app/share/user',
    Object.assign(
      {},
      {
        method: 'POST',
        body: JSON.stringify(shareDetails),
      },
      config,
      signal,
    ),
    dispatch,
    getState,
  );
};

export const doGetSharerUser = (
  id: string,
  signal: AbortSignal,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const config = getRequestConfig();
  return request(`/json/app/share/sharer/user/${id}`, Object.assign({}, config, signal), dispatch, getState);
};

export const doLoadSharedData = (
  signal: AbortSignal,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const config = getRequestConfig();
  return request('/json/app/share/list/datapoints', Object.assign({}, config, signal), dispatch, getState);
};

export const doUnSharedData = (
  ids: DataPoint[],
  signal: AbortSignal,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const config = getRequestConfig();
  return request(
    '/json/app/share/remove',
    Object.assign(
      {},
      {
        method: 'POST',
        body: JSON.stringify(ids),
      },
      config,
      signal,
    ),
    dispatch,
    getState,
  );
};
