import { FEED } from '../actions/actionTypes';
import { FeedItem } from '../Interfaces/FeedItem';

const autoUpdatedFeed = (state: FeedItem[] = [], action) => {
  switch (action.type) {
    case FEED.CLEAR:
    case FEED.CLEAR_AUTO_UPDATE:
      return [];
      // case FEED.UPDATE:
    case FEED.ADD:
      if (action.feedItem) {
        const filteredState = state.filter((feedItem) => {
          if (feedItem.id === action.feedItem.id) {
            return false;
          }
          return true;
        });
        return [action.feedItem].concat(filteredState);
      }
      return state;
    default:
      return state;
  }
};

export default autoUpdatedFeed;
