import {
  DocumentFileInterface,
  DocumentInterface, DocumentVersionInterface, INITIAL_DOCUMENT, INITIAL_VERSION
} from 'Interfaces/DocumentInterface';
import './Style.scss';
import * as React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { getLocalization } from 'global/global';
import { connect } from 'react-redux';
import { saveDocument } from 'actions/documentActions';
import NewDocument from '../NewDocument';
import DocumentDetails from '../DocumentDetails';

interface Props {
  onClose: () => void;
  document?: DocumentInterface;
  selectedFolder: number;
  documentVersions?: DocumentVersionInterface[];
  files?: DocumentFileInterface[];
}

interface ActionProps {
  saveDocument: (
    document: DocumentInterface, files: DocumentFileInterface[], signal: AbortSignal, version?: DocumentVersionInterface
  ) => Promise<boolean>;
}

function DocumentEditor(props: Props & ActionProps) {
  const { documentVersions } = props;
  const [document, setDocument] = React.useState<DocumentInterface | undefined>(props.document);
  const [version, setVersion] = React.useState<DocumentVersionInterface | undefined>(undefined);
  const [files, setFiles] = React.useState<DocumentFileInterface[] | undefined>(props.files ? props.files : undefined);
  const [validate, setValidate] = React.useState(false);

  const abortController = React.useRef(new AbortController());

  React.useEffect(() => {
    if (!document && !props.document) {
      setDocument({...INITIAL_DOCUMENT, folderId: props.selectedFolder});
      setVersion({...INITIAL_VERSION});
    }
  }, [props]);

  const updateDocument =  (document: DocumentInterface) => setDocument({...document});

  const updateVersion = (version: DocumentVersionInterface) => setVersion({...version});

  const onSave = async () => {
    if (document && (
      document.name && document.createdBy && document.editedBy
    ) && ((version && version.changeLog && version.version) || !version) && files && files.length > 0) {
      const saved = await props.saveDocument(document, files, abortController.current.signal, version);
      if (saved) {
        props.onClose();
      }
    } else {
      setValidate(true);
    }
  };

  return document ? (
    <>
      {validate && (
        <Alert variant="danger">
          {getLocalization('mandatoryFieldsWarning')}
        </Alert>
      )}
      <NewDocument
        document={document}
        version={version}
        files={files}
        updateDocument={updateDocument}
        updateVersion={updateVersion}
        setFile={(f) => {
          setFiles([f]);
          if (document.id && !version) {
            setVersion({...INITIAL_VERSION});
          }
        }}
        validate={validate}
      />
      {documentVersions ? (
        <DocumentDetails
          versions={documentVersions}
          version={version}
          updateVersion={updateVersion}
          newVersion={() => setVersion({...INITIAL_VERSION})}
          reloadVersions={() => console.log('reload')}
          validate={validate}
        />
      ) : null}
      <div className="footer-buttons mt-3">
        <Button onClick={() => props.onClose()} size="sm">
          <span>{getLocalization('cancel')}</span>
        </Button>
        <Button onClick={() => void onSave()} size="sm">
          <i className="fa fa-save" />
          <span>{getLocalization('save')}</span>
        </Button>
      </div>
    </>
  ) : null;
}

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    saveDocument: (document, files, signal: AbortSignal, version) =>
      dispatch(saveDocument(document, files, signal, version)),
  };
};

export default connect(null, mapDispatchToProps)(DocumentEditor);
