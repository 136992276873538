import { CHARTS, MODULE_SELECTION } from '../actions/actionTypes';
import { ChartsMenu } from '../Interfaces/ChartsMenu';

const INITIAL_STATE: ChartsMenu = {
  selectedCharts: [],
  charts: [],
  selectedSingleInstanceCharts: [],
};

const deletedChartReport = (state: ChartsMenu, { payload }): ChartsMenu => {
  const selectedCharts = state.selectedCharts.filter((c) => c !== payload);
  const charts = state.charts.filter((c) => c.id !== payload);
  return { selectedCharts, charts, selectedSingleInstanceCharts: state.selectedSingleInstanceCharts };
};

const chartsMenu = (state = INITIAL_STATE, action): ChartsMenu => {
  switch (action.type) {
    case CHARTS.LOADED:
      if (action.charts) {
        return { ...state, charts: state.charts.concat(action.charts) };
      }
      return state;
    case CHARTS.SELECT:
      if (action.chartId) {
        const selectedCharts = [...state.selectedCharts];
        selectedCharts.splice(0, 0, action.chartId);
        return { ...state, selectedCharts: selectedCharts };
      }
      return state;
    case CHARTS.UNSELECT:
      if (action.chartId) {
        return {
          ...state,
          selectedCharts: state.selectedCharts.filter((chartId) => chartId !== action.chartId),
        };
      }
      return state;
    case CHARTS.NEW:
      if (action.chart) {
        const newState = { ...state };
        newState.selectedCharts.splice(0, 0, action.chart.id);
        newState.charts.splice(0, 0, action.chart);
        return newState;
      }
      return state;
    case CHARTS.SAVED: {
      const charts = [...state.charts].map((chart) => {
        if (chart.id && chart.id === action.reportId) {
          return action.chart;
        } else if (chart.id === action.chart.id) {
          return action.chart;
        }
        return chart;
      });
      const selectedCharts = [...state.selectedCharts];
      if (action.reportId) {
        const index = selectedCharts.indexOf(action.reportId);
        if (index !== -1) {
          selectedCharts[index] = action.chart.report_id;
        }
      }
      return { selectedCharts, charts, selectedSingleInstanceCharts: state.selectedSingleInstanceCharts };
    }
    case CHARTS.DELETE:
      return deletedChartReport(state, action);
    case CHARTS.SELECT_SINGLE_INSTANCE_CHART:
      if (action.chartId) {
        const selectedCharts = [...state.selectedSingleInstanceCharts];
        selectedCharts.splice(0, 0, action.chartId);
        return { ...state, selectedSingleInstanceCharts: selectedCharts };
      }
      return state;
    case CHARTS.UNSELECT_SINGLE_INSTANCE_CHART:
      if (action.chartId) {
        return {
          ...state,
          selectedSingleInstanceCharts: state.selectedSingleInstanceCharts.filter(
            (chartId) => chartId !== action.chartId,
          ),
        };
      }
      return state;
    case MODULE_SELECTION.UNSET_SINGLE_INSTANCE:
      return {
        ...state,
        selectedSingleInstanceCharts: [],
      };
    default:
      return state;
  }
};

export default chartsMenu;
