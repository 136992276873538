import './ScheduleDayInfoModal.scss';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DataPoint } from 'Interfaces/DataPoint';
import { BaseModalInterface, ModalComponentNames, ModalInterface } from '../../Interfaces/ModalInterface';
import { getLocalization } from '../../global/global';
import { ScheduleInterface } from '../../Interfaces/ScheduleInterface';
import { getUsersSelector } from '../../reducers/users';
import { scheduleFormAnswersSelector } from '../../reducers/scheduleAnswersReducer';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import {
  setSingleInstance,
  setSingleInstanceWithHistory
} from '../../actions/moduleSelectionActions';
import { User } from '../../Interfaces/User';
import { navigateAddModal, navigateRemoveModal } from '../../actions/navigationAction';
import { IScheduleUserSelectionModalProps } from './ScheduleUserSelectionModal';

export interface DayScheduleInterface extends Omit<ScheduleInterface, 'formId' | 'initialized'> {
  title: string;
}

export interface IScheduleDayInfoModalProps extends BaseModalInterface {
  onCancel?: () => void;
  deleteSchedule: (id: ScheduleInterface['id']) => Promise<void>;
  daySchedules: DayScheduleInterface[];
  selectedForm: FormInterface;
  selectedDate: number;
  addNewSchedule: (answerId: string, user: User, scheduleDate?: string, scheduleStatus?: string) => void;
}

const className = 'ScheduleDayInfoModal';
export const ScheduleDayInfoModal: FunctionComponent<IScheduleDayInfoModalProps> = (props) => {
  const dispatch = useDispatch();
  const users = useSelector(getUsersSelector);
  const [daySchedules, setDaySchedules] = useState<DayScheduleInterface[]>([]);
  const formAnswersSelector = scheduleFormAnswersSelector(props.selectedForm.ref);
  const answers = useSelector(formAnswersSelector);
  const history = useHistory();

  useEffect(() => {
    setDaySchedules(props.daySchedules);
  }, []);

  const openSingleInstance = (schedule: DayScheduleInterface) => {
    const dataPoint = answers.find((answer) => answer.id === schedule.answerId);
    if (dataPoint) {
      const dp: DataPoint = {
        Name: dataPoint.Name,
        user_id: dataPoint.user_id,
        questionnaire_id: dataPoint.questionnaire_id,
      };
      if (dataPoint.id) {
        dp.id = dataPoint.id;
      } else if (dataPoint.rowId) {
        dp.row_id = dataPoint.row_id;
      }
      dispatch(setSingleInstanceWithHistory({
        model: props.selectedForm,
        dataPoint: dp,
        newAnswer: false
      }, history));
      props.onClose();
    }
  };

  const deleteSchedule = async (schedule: DayScheduleInterface) => {
    await props.deleteSchedule(schedule.id);
    setDaySchedules(daySchedules.filter((daySchedule) => daySchedule.id !== schedule.id));
  };

  const addNewDataPoint = () => {
    const addSchedule = (user: User) => {
      const dataPoint = {
        formId: props.selectedForm.id,
        questionnaire_id: props.selectedForm.ref
      };
      if (props.selectedForm.hasScheduledStatus) {
        dataPoint['scheduleStatus'] = 'Scheduled';
        dataPoint['scheduleDate'] = moment(props.selectedDate).format('YYYY-MM-DD');
      }
      dispatch(setSingleInstance({
        model: props.selectedForm,
        dataPoint: dataPoint,
        newAnswer: true,
        onSave: (answerId: string, scheduleDate?: string, scheduleStatus?: string) =>
          props.addNewSchedule(answerId, user, scheduleDate, scheduleStatus)
      }));
    };
    const modalProps: ModalInterface<IScheduleUserSelectionModalProps> = {
      component: ModalComponentNames.ScheduleUserSelectionModal,
      props: {
        onClose: () => {
          dispatch(navigateRemoveModal(ModalComponentNames.ScheduleUserSelectionModal));
        },
        addNewSchedule: addSchedule
      }
    };
    dispatch(navigateAddModal(modalProps));
    props.onClose();
  };

  const getTableRow = (daySchedule: DayScheduleInterface) => {
    const user = users.find((user) => user.id === daySchedule.userId);
    return (
      <tr key={daySchedule.answerId}>
        <td
          className={`${className}__table__link`}
          onClick={() => openSingleInstance(daySchedule)}
        >
          {daySchedule.title}
        </td>
        <td>{moment(daySchedule.date).format('DD-MM-YYYY')}</td>
        <td>{daySchedule.status}</td>
        <td>{user?.name || 'User not found'}</td>
        <td>
          <Button
            variant={'danger'}
            size={'sm'}
            onClick={() => void deleteSchedule(daySchedule)}
          >
            <i className="fa fa-trash" />
          </Button>
        </td>
      </tr>
    );
  };
  return (
    <Modal
      show
      onHide={props.onClose}
      backdrop
      className={className}
      size={'lg'}
    >
      <Modal.Header closeButton>
        {'Schedules day info '}
      </Modal.Header>
      <Modal.Body className={`${className}-body`}>
        <Table striped className={`${className}__table`}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Status</th>
              <th>Assigned to</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {daySchedules.map(getTableRow)}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="primary"
          onClick={addNewDataPoint}
        >
          {getLocalization('adddatapoint')}
        </Button>
        <Button size="sm" variant="secondary" onClick={props.onClose}>
          {getLocalization('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
