import * as React from 'react';
import { StateInterface } from 'Interfaces/StateInterface';
import { MittariRegistrationDetails } from 'Interfaces/User';
import { getLocalization, globalWindow } from 'global/global';
import { ConfirmationModalProps } from 'views/Modals/ConfirmationModal';
import { ModalComponentNames, ModalInterface } from 'Interfaces/ModalInterface';
import { doCheckLicenceValidity, doGetTokenDetails, doSetupAccount } from '../api/systemApi';
import { SystemInterface } from '../Interfaces/SystemInterface';
import { navigateAddModal, navigateRemoveModal } from './navigationAction';
import { showAlert } from './index';
import { actionCreator } from './index';
import { SYSTEM } from './actionTypes';
import { logout } from './userActions';
import { updateClientPersist } from './clientPersistActions';

export const loadProperties = actionCreator<Partial<SystemInterface>>(SYSTEM.LOAD_PROPERTIES);

export const checkLicenceValidity = () => {
  return (dispatch, getState: () => StateInterface) => {
    const { clientPersist } = getState();
    const response = doCheckLicenceValidity(Number(clientPersist.user_id));
    response.then(response => response.json()).then((json) => {
      let statusMessage: string = '';
      if (!clientPersist.licenceAlertShown) {
        if (json.status === 'WARNING') {
          const days = json.days;
          const msg = getLocalization('expiryWarning').replace('{days}', days);
          dispatch(showAlert({ visible: true, message: (
            <div className="alert alert-warning">
              {msg}
            </div>
          )}));
          statusMessage = msg;
        } else if (json.status === 'EXPIRED') {
          dispatch(showAlert({ visible: true, message: (
            <div className="alert alert-danger">
              {getLocalization('expiredLicence')}
            </div>
          )}));
          statusMessage = getLocalization('expiredLicence');
        }
        dispatch(updateClientPersist({...clientPersist, licenceAlertShown: true }));
      }
      dispatch(loadProperties({ status: json.status, statusMessage }));
    }).catch((error) => {
      console.log(error);
    });
  };
};

export const setupAccount = (registrationDetails: MittariRegistrationDetails, token: string) => {
  return (dispatch) => {
    const response = doSetupAccount(registrationDetails, token);
    response.then(response => response.json()).then((json) => {
      console.log(json);
      if (json.errorCode) {
        dispatch(showAlert({ visible: true, message: json.errorMessage }));
      } else {
        const modalProps: ModalInterface<ConfirmationModalProps> = {
          component: ModalComponentNames.ConfirmationModal,
          props: {
            onClose: () => { dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal)); },
            onConfirm: () => { globalWindow.open(
              `/login.jsp?c=${registrationDetails.accountName}&lang=fi&userName=${registrationDetails.userName}`,
              '_parent'
            ); },
            localizations: {
              cancel: '',
              confirm: getLocalization('proceedToLogin'),
              confirmStyle: 'primary',
              body: (
                <p>{(
                  <div dangerouslySetInnerHTML={{__html: getLocalization('accountSetupOk') }}/>
                )}</p>
              ),
              header: (<h5>{getLocalization('info')}</h5>)
            }
          }
        };
        dispatch(navigateAddModal(modalProps));
        // dispatch(showAlert({ visible: true, message: (<div dangerouslySetInnerHTML={{__html: json.message }}/>) }))
      }
    }).catch((error) => {
      console.log(error);
    });
  };
};

export const checkToken = (token: string) => {
  return (): Promise<Response> => {
    return new Promise((resolve, reject) => {
      const request = doGetTokenDetails(token);
      request.then(response => resolve(response)).catch((error) => {
        reject(error); // console.log(error);
      });
    });
  };
};

export const autoLogout = () => {
  return (dispatch) => {
    const modalProps: ModalInterface<ConfirmationModalProps> = {
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => { console.log('closed'); },
        onConfirm: () => {
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
          dispatch(logout());
        },
        localizations: {
          cancel: '',
          confirm: getLocalization('ok'),
          confirmStyle: 'primary',
          body: (
            <p>{(
              <div dangerouslySetInnerHTML={{__html: getLocalization('autoLogout') }}/>
            )}</p>
          ),
          header: (<h5>{getLocalization('info')}</h5>)
        }
      }
    };
    dispatch(navigateAddModal(modalProps));
  };
};
