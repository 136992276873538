import { toast } from 'react-toastify';
import { ChartModel } from 'Interfaces/ChartInterface';
import { ThunkDispatchAction } from 'actions';
import { StateInterface } from 'Interfaces/StateInterface';
import { request } from './request';
import { getRequestConfig } from './index';

export const doGetCharts = (dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
  const headers = getRequestConfig();
  return request('/json/chart/list', headers, dispatch, getState);
};

export const doLoadChartData = (url, dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
  const headers = getRequestConfig();
  return request(`${url}&key=${headers.headers.key}`, headers, dispatch, getState);
};

export const doSaveChart = (chartModel: ChartModel, dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
  const headers = getRequestConfig();
  const url = chartModel.id === '' ? '' : '/update';
  return request(
    `/json/chart${url}`,
    Object.assign({}, headers, { method: 'POST', body: JSON.stringify(chartModel) }),
    dispatch,
    getState,
  );
};

export const deleteChartReport = async (
  reportId: string,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
): Promise<boolean> => {
  try {
    const url = `/json/app/deletereport/${reportId}`;
    const headers = getRequestConfig();

    const response = await request(url, { ...headers, method: 'GET' }, dispatch, getState);
    return response.status === 'OK';
  } catch (err) {
    toast.error('An error occurred while deleting chart report.');
    throw err;
  }
};

export const doLoadBarChart = (
  chartModel: ChartModel,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  return request(
    `/json/chart?chartModel=${encodeURIComponent(JSON.stringify(chartModel))}`,
    headers,
    dispatch,
    getState,
  );
};
