import './Style.scss';
import { getPaymentPlanInfo, getPaymenyTransactions, setAdditionalUsers } from 'actions/paymentActions';
import { PaymentPlanInfo } from 'Interfaces/PaymentPlanInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import * as React from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { ToastContainer } from 'react-toastify';
import { ReactModals } from 'views/Modals/ReactModals';
import { persistedStore } from 'index';
import { globalWindow } from 'global/global';
import { Persistor } from 'redux-persist';
import ProgressContainer from 'views/Progress/ProgressContainer';
import { Header } from '../Header/HeaderContainer';
import Transactions from './Transactions';
import PaymentForm from './PaymentForm';
import DetailsForm from './DetailsForm';
import Plans from './Plans';

interface StateProps {
  paymentPlanInfo: PaymentPlanInfo | null;
  clientPersist: ClientPersistInterface;
  card: JSONInterface;
  persistedStore: Persistor;
}

interface ActionProps {
  getPaymentPlanInfo: () => void;
  getPaymenyTransactions: () => void;
  setAdditionalUsers: (additionalUsers: JSONInterface) => Promise<boolean>;
}

const  Payments = (props: StateProps & ActionProps) => {

  const [loading, setLoading] = React.useState(false);
  const { paymentPlanInfo } = props;
  const [activeTab, setActiveTab] = React.useState('plan');

  const [subscription, setSubscription] = React.useState({});

  React.useEffect(() => {
    if (!props.paymentPlanInfo && !loading) {
      setLoading(true);
      initPayments();
    } else if (props.paymentPlanInfo && loading) {
      setLoading(false);
    }
  }, [props]);

  React.useEffect(() => {
    const {instance, userName, user_id, lang} = props.clientPersist;
    if (!instance || !userName || !user_id) {
      // Force redux state to update persistance local storage
      const flush = async () => {
        await props.persistedStore.flush();
      };
      void flush();
      globalWindow.open(`/login.jsp?lang=${lang}`, '_parent');
    }
  }, [props]);

  const updateSubscription = (newValues) => {
    setSubscription({...subscription, ...newValues });
  };

  const resetSubscription = () => {
    setSubscription({});
  };

  const openPaymentsTab = () => {
    setActiveTab('payment');
  };

  const initPayments = () => {
    props.getPaymentPlanInfo();
    props.getPaymenyTransactions();
  };

  const view = () => {
    const validTo = paymentPlanInfo ? paymentPlanInfo.validTo : null;
    if (paymentPlanInfo && validTo && moment(validTo).isAfter(moment(new Date()))) {
      return (
        <Plans
          paymentPlanInfo={paymentPlanInfo}
          updateSubscription={updateSubscription}
          openPaymentsTab={openPaymentsTab}
          initPayments={initPayments}
          setAdditionalUsers={props.setAdditionalUsers}
        />
      );
    } else if (paymentPlanInfo) {
      return (
        <DetailsForm
          paymentPlanInfo={paymentPlanInfo}
          updateSubscription={updateSubscription}
          openPaymentsTab={openPaymentsTab}
        />
      );
    }
    return null;
  };

  return (
    <div className={'ReactApp'}>
      <Header />
      <ReactModals />
      <ProgressContainer />
      <Container fluid className="tabs-container">
        <Tabs
          defaultActiveKey="plan"
          id="payments-tab"
          onSelect={(eventKey) => setActiveTab(eventKey)}
          activeKey={activeTab}
        >
          <Tab eventKey="plan" title="Home" className={'payments-tab'}>
            {view()}
          </Tab>
          <Tab eventKey="payment" title="Payment" className={'payments-tab'}>
            {paymentPlanInfo && (
              <PaymentForm
                paymentPlanInfo={paymentPlanInfo}
                subscription={subscription}
                resetSubscription={resetSubscription}
              />
            )}
          </Tab>
          <Tab eventKey="transactions" title="Transactions" className={'payments-tab'}>
            <Transactions />
          </Tab>
        </Tabs>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
    </div>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    paymentPlanInfo: state.paymentPlanInfo.paymentPlanInfo,
    card: state.paymentPlanInfo.card,
    persistedStore: persistedStore,
    clientPersist: state.clientPersist
  };
};

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    getPaymentPlanInfo: () => dispatch(getPaymentPlanInfo()),
    getPaymenyTransactions: () => dispatch(getPaymenyTransactions()),
    setAdditionalUsers: (additionalUsers: JSONInterface) => dispatch(setAdditionalUsers(additionalUsers))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
