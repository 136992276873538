import * as React from 'react';
import bind from 'bind-decorator';
import { map } from 'lodash-es';
import { Dropdown } from 'react-bootstrap';
import { getLocalization } from '../../../global/global';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { getFileAnswer, updateAnswerDiagrams } from '../utils/DiagramUtils';

interface Props {
  groupDiagrams: LooseObject[];
  updateAnswer: (answer: LooseObject) => void;
  dataPoint: DataPoint;
}
export default class MapSelectButton extends React.Component <Props> {

  constructor(props) {
    super(props);
  }

  @bind
  private onDiagramSelected(selectedDiagram: any) {
    const { groupDiagrams, dataPoint, updateAnswer } = this.props;
    if (selectedDiagram === -1) {
      const newAnswer = updateAnswerDiagrams(dataPoint, []);
      if (newAnswer) {
        updateAnswer({ files: newAnswer, UseDiagram: true });
      }
      return;
    }
    const files = getFileAnswer(groupDiagrams, selectedDiagram);
    if (files) {
      const newAnswer = updateAnswerDiagrams(dataPoint, files);
      if (newAnswer) {
        updateAnswer({ files: newAnswer, UseDiagram: false });
      }
    }
  }

  public render(): JSX.Element {
    const { groupDiagrams } = this.props;
    return (
      <div className="form-group">
        <Dropdown id="dropdown-custom-1">
          <Dropdown.Toggle id="select-mep-menu-dropdown">
            <i className="fa fa-map-marker" />
            {` ${getLocalization('addToMap')}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as="a" onSelect={this.onDiagramSelected} eventKey={'-1'}>{'World map'}</Dropdown.Item>
            {
              map(groupDiagrams, (diagram) => {
                return (
                  <Dropdown.Item
                    key={diagram.id}
                    as="a"
                    onSelect={this.onDiagramSelected}
                    eventKey={diagram.imageFileId}
                  >
                    {diagram.name || diagram.filename}
                  </Dropdown.Item>
                );
              })
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
