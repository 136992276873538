export interface Permission {
  id?: number;
  role: 'admin' | 'modeler' | 'ordinary' | 'enumerator' | 'viewer';
  read?: boolean;
  write?: boolean;
  delete?: boolean;
}

export interface RolePermissions {
  admin: Permission;
  modeler: Permission;
  ordinary: Permission;
  enumerator: Permission;
  viewer: Permission;
}

export const DEFAULT_PERMISSION: Partial<Permission> = {
  read: undefined,
  write: undefined,
  delete: undefined
};
