import { AnyAction } from 'redux';
import { DocumentFilesInterface } from 'Interfaces/DocumentInterface';
import { DOCUMENTS } from '../actions/actionTypes';

const INITIAL_STATE: DocumentFilesInterface = {
  loaded: false,
  collection: []
};

const documentFiles = (state = INITIAL_STATE, action: AnyAction): DocumentFilesInterface => {
  switch (action.type) {
    case DOCUMENTS.FILES_LOADED:
      if (action.documentFiles) {
        return {
          loaded: true,
          collection: action.documentFiles
        };
      }
      return state;
    case DOCUMENTS.ADD_FILE:
      if (action.file) {
        if (state.loaded) {
          return {...state, collection: state.collection.concat([action.file])};
        }
      }
      return state;
    case DOCUMENTS.DELETE_FILE:
      if (action.id) {
        return {...state, collection: state.collection.filter(f => f.id !== action.id)};
      }
      return state;
    default:
      return state;
  }
};

export default documentFiles;
