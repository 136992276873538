import { LocationHierarchyStateInterface } from 'Interfaces/HierarchyInterface';
import { HIERARCHY } from '../actions/actionTypes';

const initialState: LocationHierarchyStateInterface = { loaded: false, locationHierarchies: [] };

const locationHierarchies = (state = initialState, action) => {
  switch (action.type) {
    case HIERARCHY.LOADED:
      if (action.payload) {
        return {
          loaded: true,
          locationHierarchies: action.payload,
        };
      }
      return state;
    case HIERARCHY.UPDATE:
      if (action.payload) {
        const keys = Object.keys(action.payload);
        const newHierarchies = [...state.locationHierarchies];
        for (const key of keys) {
          const index = newHierarchies.findIndex((l) => `${l.id}` === `${action.payload[key].id}`);
          if (index > -1) {
            newHierarchies[index].xml = action.payload[key].xml;
          } else {
            newHierarchies.push(action.payload[key]);
          }
        }
        return {
          ...state,
          locationHierarchies: newHierarchies,
        };
      }
      return state;
    case HIERARCHY.UPDATE_ARCHIVE_STATUS:
      if (action.payload) {
        return {
          ...state,
          locationHierarchies: state.locationHierarchies.map((lh) => {
            if (lh.id === action.payload.id) {
              return {
                ...lh,
                active: action.payload.active,
              };
            }
            return lh;
          }),
        };
      }
      return state;
    default:
      return state; //
  }
};

export default locationHierarchies;
