import * as React from 'react';
import bind from 'bind-decorator';
import '../UserDateFilter.scss';
// import { getLocalization } from '../../../global/global';
import { Form } from 'react-bootstrap';
import { User } from 'Interfaces/User';
import TextInputComponent from '../../SingleInstance/elements/TextInputComponent';
import { getLocalization } from '../../../global/global';
import { UserProps } from './UsersContainer';

interface State {
  filter: string;
}
export default class UsersComponent extends React.Component<UserProps, State> {

  constructor(props) {
    super(props);
    this.state = {
      filter: ''
    };
  }

  /**
   * Event handler for un/selecting individual user checkbox.
   * @param user - un/selected user
   * @param checked - whether user has been checked or not
   */
  @bind
  private onUserSelect(user: User, checked: boolean) {
    const { selectedUsers } = this.props;
    const newSelectedUsers = checked ? [...selectedUsers, {
      id: user.id, name: user.name
    }] : selectedUsers.filter(u => u.id !== user.id);
    this.props.selectUnSelectUsers(newSelectedUsers);
  }

  /**
   * Event handler for un/selecting all users.
   * @param e
   */
  @bind
  private selectUnselectAllUsers(e) {
    const selectedUsers = e.target.checked ? this.props.users.map(u => ({ id: u.id, name: u.name })) : [];
    this.props.selectUnSelectUsers(selectedUsers);
  }

  /**
   * Updates the filter value when user stops typing in the input field.
   * @param filter - new filter value.
   */
  @bind
  private onFilterChange(filter) {
    this.setState({ filter });
  }

  public render(): JSX.Element {
    const { filter } = this.state;
    return (
      <div className="FilterBoxContainer col">
        <div className={`FilterBoxContainer__header UserContainer__header`}>
          <Form.Check
            id={'user-filters-select-all-users'}
            onChange={this.selectUnselectAllUsers}
          />
          <TextInputComponent
            onChange={this.onFilterChange}
            name="user-filters-input-filter"
            value={this.state.filter}
            extraClass="form-control-sm"
            placeholder={getLocalization('filterUsers')}
          />
        </div>
        <div className="FilterBoxContainer__body">
          {this.props.users.map((user) => {
            if (filter !== '' && user.name.toLowerCase().search(filter.toLowerCase()) === -1) {
              return null;
            }
            return (
              <Form.Check
                key={`user-filter-item-${user.id}`}
                id={`user-filter-item-${user.id}`}
                label={user.name}
                value={user.id}
                onChange={(e) => this.onUserSelect(user, e.target.checked)}
                checked={Boolean(this.props.selectedUsers.find( u => u.id === user.id))}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
