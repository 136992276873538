import { setSingleInstance, unsetSingleInstanceState } from 'actions/moduleSelectionActions';
import { savePOI } from 'actions/pois';
import { getLocalization } from 'global/global';
import { DataPoint } from 'Interfaces/DataPoint';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { SingleInstance } from 'Interfaces/ModuleSelectionInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import GenericModal from 'views/Modals/GenericModal';

interface Props {
  task: DataPoint;
}

interface StateProps {
  parentModel?: FormInterface;
}

interface ActionProps {
  savePOI: (dataPoint: DataPoint, parameters?: JSONInterface, showProgress?: boolean) => Promise<Response>;
  setSingleInstance: (singleInstance: SingleInstance) => void;
  unsetSingleInstance: () => void;
}

function TaskParent(props: Props & ActionProps & StateProps)  {
  const { task } = props;
  const [showConfirm, setShowConfirm] = React.useState(false);

  const openParent = () => {
    console.log('open parent');
    if (props.parentModel) {
      const singleInstance = {
        model: props.parentModel,
        newAnswer: false,
        dataPoint: {
          questionnaire_id: task['parentId'],
          row_id: task['parentRowId'],
          formId: props.parentModel.id
        }
      };
      openSingleInstance(singleInstance);
    }
  };

  const openSingleInstance = (singleInstance: SingleInstance) => {
    props.unsetSingleInstance();
    setTimeout(() => props.setSingleInstance(singleInstance), 100);
  };

  const saveTask = () => {
    setShowConfirm(false);
    const savePromise = props.savePOI(props.task, undefined, true);
    savePromise.then(response => response.json()).then(json => {
      if (props.parentModel) {
        const si = {
          model: props.parentModel,
          newAnswer: false,
          dataPoint: {
            questionnaire_id: props.parentModel.ref,
            row_id: json.row_id || json.rowId,
            formId: props.parentModel.id
          }
        };
        openSingleInstance(si);
      }
      console.log(json);
    }).catch(e => {
      console.log(e);
    });
  };

  const confirm = !showConfirm ? null : (
    <GenericModal
      visible
      body={(<p>{getLocalization('confirmCloseTask')}</p>)}
      title={getLocalization('confirm')}
      onConfirm={saveTask}
      cancel={() => setShowConfirm(false)}
      extraButtons={[(
        <Button key={'task-no-save'} size="sm" onClick={() => openParent()}>
          {getLocalization('no')}
        </Button>
      )]}
      cancelText={getLocalization('cancel')}
      confirmText={getLocalization('yes')}
    />
  );

  if (task['parentId'] && task['parentRowId']) {
    return (
      <div className="row col-md-12">
        {confirm}
        <Button size="sm" onClick={() => setShowConfirm(true)} id="task-btn">
          {` ${getLocalization('parent')}`}
        </Button>
      </div>
    );
  }
  return null;
}

const mapStateToProps = (state: StateInterface, props: Props): StateProps => {
  const form = state.forms.collection.find(f => f.ref === props.task['parentId']);
  return {
    parentModel: form
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    savePOI: (dataPoint: DataPoint, parameters?: JSONInterface, showProgress?: boolean) => {
      return dispatch(savePOI(dataPoint, parameters, showProgress));
    },
    setSingleInstance: (singleInstance) => dispatch(setSingleInstance(singleInstance)),
    unsetSingleInstance: () => dispatch(unsetSingleInstanceState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskParent);
