import { toast } from 'react-toastify';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { DataPoint } from '../Interfaces/DataPoint';
import { LooseObject } from '../Interfaces/LooseObject';
import * as POIApi from '../api/pois';
import { getLocalization } from '../global/global';
import { StateInterface } from '../Interfaces/StateInterface';
import { getLocationHierarchyQuerySelector, QueryFiltersInterface } from '../reducers/filtersMenuReducer';
import { galleryMenuSelector } from '../reducers/galleryMenuReducer';
import { FormInterface } from '../Interfaces/Forms/FormsInterface';
import { deleteFeedItems } from './feed';
import { DATAPOINTS } from './actionTypes';
import { dataURItoBlob, extractFiles, getMapFetchParams } from './utils/pois';
import { refreshToken } from './userActions';
import { doneTranscribing, startTranscribing } from './transcribingActions';
import { setSingleInstance } from './moduleSelectionActions';

export const fetchPOI =
    (
      formRef: FormInterface['ref'],
      signal?: AbortSignal,
      rowId?: number | string,
      filter?: QueryFiltersInterface[],
      query?: LooseObject,
      fields?: string,
      sort?: LooseObject,
      requestParams?: string[],
    ) =>
      (dispatch, getState): Promise<Response> => {
        const params: any = requestParams ? requestParams : [];
        const queryParams = query || {};
        if (rowId) {
          queryParams['row_id'] = rowId;
        }
        if (fields) {
          params.push(`fields=${fields}`);
        }
        if (queryParams) {
          params.push(`query=${encodeURIComponent(JSON.stringify(queryParams))}`);
        }
        if (filter) {
          params.push(`filter=${encodeURIComponent(JSON.stringify(filter))}`);
        }
        if (sort) {
          const sortParam = {
            sortdatafield: sort.dataField,
            sortorder: sort.order,
          };
          params.push(`sort=${encodeURIComponent(JSON.stringify(sortParam))}`);
        }
        const url = `json/app/answer/${formRef}?version=6&from=portal&${params.join('&')}`;
        return POIApi.getPOI(url, dispatch, getState, signal);
      };

export const fetchGalleryPOIs = (subFormIds: string[], requestParams?: string[]) => {
  return (dispatch, getState: () => StateInterface): Promise<Response> => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const locationHierarchyQuery = getLocationHierarchyQuerySelector(state);
      const selectedForm = galleryMenuSelector(state).selectedForm;
      const fields = [
        'Name',
        'id',
        'user_id',
        'location1',
        'location2',
        'location3',
        'location4',
        'questionnaire_id',
        'file',
      ];
      if (selectedForm) {
        const response = dispatch(
          fetchPOI(
            selectedForm,
            undefined,
            undefined,
            undefined,
            locationHierarchyQuery,
            [...fields, ...subFormIds].join(','),
            undefined,
            requestParams,
          ),
        );
        response.json().then((json) => {
          dispatch(dataPointsLoaded(json, selectedForm));
          resolve(new Response(JSON.stringify({ loaded: true, count: json.length })));
        });
      } else {
        reject(new Response(JSON.stringify({ loaded: false })));
      }
    });
  };
};

export const dataPointsLoaded = (dataPoints: DataPoint[], formRef: FormInterface['ref']) => ({
  type: DATAPOINTS.LOADED,
  dataPoints,
  formRef,
});

export const dataPointUpdated = (dataPoint, formId) => ({ type: DATAPOINTS.UPDATED, dataPoint, formId });

export const deletePOI = (formId: string, callBack: () => void, id: string) => {
  return (dispatch, getState) => {
    const deletePromise = POIApi.doDeletePOI(dispatch, getState, formId, id);
    deletePromise
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'OK') {
          dispatch(deleteFeedItems([id]));
          callBack();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const sharePOI = (id: string) => {
  return (dispatch, getState) => {
    const sharePromise = POIApi.doSharePOI(id, dispatch, getState);
    sharePromise
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

/* export const saveComment = (id: string, userName: string, comment: LooseObject) => {
  return (dispatch, getState) => {
    const savePromise = POIApi.doSaveComment(id, userName, comment);
    savePromise.then(response => response.json()).then((json) => {
      console.log(json);
    }).catch((error) => {
      console.log(error);
    });
  };
};*/

export const saveComment =
    (id: string, userName: string, comment: LooseObject) =>
      (dispatch, getState): Promise<Response> => {
        return new Promise((resolve, reject) => {
          const request = POIApi.doSaveComment(id, userName, comment, dispatch, getState);
          request
            .then((response) => resolve(response))
            .catch((error) => {
              reject(error); // console.log(error);
            });
        });
      };

export const deleteComment = (id: string) => {
  return (dispatch, getState) => {
    const deletePromise = POIApi.doDeleteComment(id, dispatch, getState);
    deletePromise
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const deletePOIs = (ids: string[], formId: string, callBack?: (ids: string[]) => void) => {
  return (dispatch, getState) => {
    const deleteToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(`Deleting ${ids.length} POIs`, {
      toastId: deleteToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    const deletePromise = POIApi.doDeletePOIs(ids, formId, dispatch, getState);
    deletePromise
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'OK') {
          toast.update(deleteToastId, {
            type: toast.TYPE.SUCCESS,
            render: 'POIs deleted.',
          });
          setTimeout(() => toast.dismiss(deleteToastId), 3000);
          dispatch(deleteFeedItems(ids));
          if (callBack) {
            callBack(ids);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const fetchHistory = (
  answerTable: string,
  formId: string,
  poiId: string,
  callBack: (dataPoint: DataPoint[]) => void,
) => {
  return (dispatch, getState) => {
    const savePromise = POIApi.doFetchHistory(answerTable, formId, poiId, dispatch, getState);
    savePromise
      .then((response) => response.json())
      .then((json) => {
        if (json.length > 0) {
          callBack(json);
        } else {
          toast('No history available', {
            type: toast.TYPE.INFO,
            autoClose: 3000,
            closeButton: false,
            hideProgressBar: true,
            closeOnClick: false,
          });
          callBack([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const sendReminders = (pois) => {
  return (dispatch, getState) => {
    const deleteToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('sendingReminders'), {
      toastId: deleteToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    const remindersPromise = POIApi.doSendReminders(pois, dispatch, getState);
    remindersPromise
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'OK') {
          toast.update(deleteToastId, {
            type: toast.TYPE.SUCCESS,
            render: getLocalization('remindersSent'),
          });
        } else {
          toast.update(deleteToastId, {
            type: toast.TYPE.ERROR,
            render: getLocalization('errorSendingReminders'),
          });
        }
        setTimeout(() => toast.dismiss(deleteToastId), 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

/*
 * This function is used to load server side map data.
 */
export const fetchMapData = (signal: AbortSignal) => {
  return (dispatch, getState): Promise<Response> => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const request = POIApi.doFetchMapData(
        getMapFetchParams(state.filtersMenu, state.forms.collection),
        signal,
        dispatch,
        getState,
      );
      request
        .then((response) => resolve(response))
        .catch((error) => {
          reject(error); // console.log(error);
        });
    });
  };
};

export const savePOI =
    (dataPoint: DataPoint, parameters?: JSONInterface, showProgress?: boolean) =>
      (dispatch, getState: () => StateInterface): Promise<Response> => {
        return new Promise((resolve, reject) => {
          delete dataPoint['galleryId'];
          delete dataPoint['instance'];
          delete dataPoint['action'];
          delete dataPoint['message'];
          delete dataPoint['mergeDate'];
          const groupedData = extractFiles(Object.assign({}, dataPoint));
          let url = 'json/app/answer/';
          const config = {};
          // we are updating an existing POI
          if (dataPoint.id) {
            config['method'] = 'POST';
            url = url + `update/${dataPoint.questionnaire_id}/${dataPoint.id}`;
          } else {
            config['method'] = 'POST';
            url = url + `${dataPoint.questionnaire_id}`;
          }
          const files = groupedData.files.map((f) => {
            const nf = { ...f };
            delete nf.file;
            if (nf.url && nf.url.startsWith('data:')) {
              delete nf.url;
            }
            return nf;
          });
          config['credentials'] = 'same-origin';
          config['body'] = JSON.stringify({ dataPoint: groupedData.dataPoint, files });

          const poiFiles = groupedData.files;
          const saveToastId = Date.now() + Math.floor(Math.random() * 100);
          if (showProgress !== false) {
            toast('Saving data', {
              toastId: saveToastId,
              type: toast.TYPE.INFO,
              autoClose: false,
              closeButton: false,
              hideProgressBar: true,
              closeOnClick: false,
            });
          }
          if (parameters) {
            const keys = Object.keys(parameters);
            url =
                    url +
                    '?' +
                    keys
                      .map((key) => {
                        return `${key}=${parameters[key]}`;
                      })
                      .join('&');
          }
          const promise = POIApi.doSavePOI(url, config, dispatch, getState);
          promise
            .then((response) => response.json())
            .then((json) => {
              if (json.status === 'OK') {
                toast.update(saveToastId, {
                  type: toast.TYPE.SUCCESS,
                  render: 'Data saved.',
                });
                const form = getState().forms.collection.find(f => f.ref === dataPoint.questionnaire_id);
                const getRowIds = () => {
                  const newRowId = json.rowId !== '' ? json.dataPoint._id : json.row_id;
                  const newId = dataPoint.id || json.dataPoint ? json.dataPoint.id : json.id;
                  return { newId, newRowId };
                };
                const { newRowId, newId } = getRowIds();
                const newIds = json.newIds;
                const newRowIds = json.newRowIds;
                setTimeout(() => toast.dismiss(saveToastId), 3000);
                let hasAudio = false;
                const finalizeSave = () => {
                  if (!json.dataPoint.draft_poi) {
                    if (hasAudio) {
                      dispatch(startTranscribing({
                        formId: json.dataPoint.questionnaire_id,
                        dataPointId: newId
                      }));
                      POIApi.sendTranscribeRequest(json.dataPoint.questionnaire_id, newId).then(res => res.text())
                        .then(() => dispatch(doneTranscribing())).catch(() => dispatch(doneTranscribing()));
                      const { singleInstance } = getState().moduleSelection;
                      if (singleInstance) {
                        const newSingleInstance = {
                          ...singleInstance, dataPoint: {...singleInstance.dataPoint, id: newId }
                        };
                        dispatch(setSingleInstance(newSingleInstance));
                      }
                    }
                  }
                  if (form && form.isChild) {
                    resolve(new Response(JSON.stringify({ rowId: '_', id: dataPoint.id })));
                  } else {
                    resolve(new Response(JSON.stringify({ rowId: newRowId, id: newId, newIds, newRowIds })));
                  }
                };
                if (json.files && json.files.length > 0) {
                  for (const f of json.files) {
                    const file = poiFiles.find((ff) => `${ff.tid}` === `${f.tid}`);
                    if (file) {
                      if (file.thumnailsrc) {
                        f.thumbnailsrc = file.thumnailsrc;
                      } else {
                        f.file = file.file;
                      }
                      if (file.mimeType?.indexOf('audio') > -1) {
                        hasAudio = true;
                      }
                    }
                  }
                  let uploaded = 0;
                  for (const f of json.files) {
                    let fileObj;
                    if (f.thumbnail) {
                      fileObj = dataURItoBlob(f.thumnailsrc);
                    } else {
                      fileObj = f.file;
                    }
                    if (!fileObj) {
                      uploaded++;
                      if (uploaded === json.files.length) {
                        finalizeSave();
                      }
                      continue;
                    }
                    if (f.questionId === 'BackgroundDiagram') {
                      const fileConfig = {};
                      fileConfig['method'] = 'POST';
                      fileConfig['credentials'] = 'same-origin';
                      const diagramPromise = POIApi.doUploadFile(
                        '/json/app/uploadDiagram',
                        fileConfig,
                        fileObj,
                        dispatch,
                        getState,
                        {
                          rowId: newRowId,
                          questionId: f.questionId,
                          // groupId: globalWindow.groupID
                        },
                      );
                      diagramPromise
                        .then((response) => response.json())
                        .then(() => {
                          uploaded++;
                          if (uploaded === json.files.length) {
                            finalizeSave();
                          }
                        })
                        .catch((error) => {
                          uploaded++;
                          console.error(error);
                          if (uploaded === json.files.length) {
                            finalizeSave();
                          }
                        });
                    } else {
                      const fileUploadId = Date.now() + Math.floor(Math.random() * 100);
                      if (showProgress !== false) {
                        toast('Uploading file...', {
                          toastId: fileUploadId,
                          type: toast.TYPE.INFO,
                        });
                      }
                      const uploadPromise = POIApi.uploadToS3(fileObj, `${f.url}`);
                      uploadPromise
                        .then(() => {
                          toast.update(fileUploadId, {
                            type: toast.TYPE.SUCCESS,
                            render: 'File uploaded.',
                          });
                          uploaded++;
                          if (uploaded === json.files.length) {
                            finalizeSave();
                          }
                          setTimeout(() => toast.dismiss(fileUploadId), 3000);
                        })
                        .catch((error) => {
                          toast.update(fileUploadId, {
                            type: toast.TYPE.ERROR,
                            render: getLocalization('uploadErrorMsg').replace('{{fileName}}', f.fileName),
                            autoClose: false,
                            closeButton: true
                          });
                          console.log(error);
                          uploaded++;
                          if (uploaded === json.files.length) {
                            finalizeSave();
                          }
                        });
                    }
                  }
                } else {
                  finalizeSave();
                  /* if (form && form.isChild) {
                    resolve(new Response(JSON.stringify({ rowId: '_', id: dataPoint.id })));
                  } else {
                    resolve(new Response(JSON.stringify({ rowId: newRowId, id: newId, newIds, newRowIds })));
                  }*/
                  // setTimeout(() => callBack(newRowId), 2000);
                }
              } else {
                if (json.errorCode && json.errorCode === '1009') {
                  const { clientPersist } = getState();
                  if (clientPersist.refreshToken && clientPersist.userName && clientPersist.instance) {
                    toast.update(saveToastId, {
                      type: toast.TYPE.ERROR,
                      render: getLocalization('refreshingSession'),
                    });
                    dispatch(
                      refreshToken(
                        clientPersist.userName,
                        clientPersist.instance,
                        clientPersist.refreshToken,
                      ),
                    );
                    resolve(new Response(JSON.stringify({ rowId: -1, error: json.errorMessage })));
                  }
                } else if (json.errorCode === '1096') {
                  toast.update(saveToastId, {
                    type: toast.TYPE.ERROR,
                    render: getLocalization('dbError'),
                  });
                  resolve(new Response(JSON.stringify({ rowId: -1, error: json.error })));
                  setTimeout(() => toast.dismiss(saveToastId), 3000);
                } else {
                  resolve(new Response(JSON.stringify({ rowId: -1, error: json.error })));
                  toast.update(saveToastId, {
                    type: toast.TYPE.ERROR,
                    render: 'Error saving data.',
                  });
                }
                setTimeout(() => toast.dismiss(saveToastId), 3000);
              }
            })
            .catch((error) => {
              toast.update(saveToastId, {
                type: toast.TYPE.ERROR,
                render: error.message || getLocalization('saveErrorAlert'),
              });
              setTimeout(() => toast.dismiss(saveToastId), 3000);
              reject(error);
            });
        });
      };

export const closeSingleInstance = (id: string) => {
  return () => {
    void POIApi.doCloseSingleInstance(id);
  };
};
