import { find, uniq } from 'lodash-es';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { QuestionInterface, questionTypes } from '../../../Interfaces/Forms/QuestionInterface';
import { runScript, updateScriptObject } from './ScriptUtils';
import { getOptionTexts } from './utils';
import FormUtils from './FormUtils';

export const getReferencedQuestions = (tableForm, subFormUtils) => {
  const questions: string[] = [];
  for (const qn of tableForm.question) {
    if (
      !qn.inVisible && qn.type === questionTypes.CALCULATED_VALUE_QUESTION &&
      qn.script.indexOf(subFormUtils.getModel().referenceName) !== -1
    ) {
      const refName = qn.script.substring(qn.script.indexOf('.') + 1);
      const qnId = subFormUtils.getQuestionId(`_${refName}`);
      if (qnId && questions.indexOf(qnId) === -1) {
        questions.push(qnId);
      }
    }
  }
  return questions;
};

/**
 * Sets the values for reference table.
 * @param question - reference table question.
 * @param subFormUtils - FormUtils object for the form referred by the reference table question.
 * @param subFormDataPoints - The datapoints for the referred form.
 * @param oldValue - the old value for the reference table question.
 * @param tableForm - the table form Object for the reference table question.
 * @param formUtils
 * @param dataPoint
 */
export const getReferenceTableValue = (
  question: QuestionInterface,
  subFormUtils: FormUtils | undefined,
  subFormDataPoints,
  oldValue,
  tableForm,
  formUtils?,
  dataPoint?
) => {
  let validSubForms: DataPoint[] = [];
  if (question.script && question.script !== '' && subFormUtils) {
    if (subFormUtils.getModel().isChild) {
      const allVariables: string[] = uniq(question.script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
      const obj: LooseObject = {}; // mapping for _spss -> value
      const localSPSSToId: LooseObject = {}; // mapping for _spss -> id
      for (const v of allVariables) {
        const id = subFormUtils.getQuestionId(v);
        obj[v] = null;
        localSPSSToId[v] = id;
      }
      for (const subFormDataPoint of subFormDataPoints) {
        const response =
        updateScriptObject(localSPSSToId, obj, subFormUtils, subFormDataPoint, [], [], [], undefined, undefined);
        const valid = runScript(question.script, response.vars);
        if (valid) {
          validSubForms.push(subFormDataPoint);
        }
      }
    } else {
      validSubForms = filterMainForm(question.script, subFormUtils, subFormDataPoints, formUtils, dataPoint);
    }
  } else {
    validSubForms = validSubForms.concat(subFormDataPoints);
  }
  // const oldValue: DataPoint[] = dataPoint[question.id] ? dataPoint[question.id] : [];
  const value: DataPoint[] = [];
  for (const subFormDataPoint of validSubForms) {
    let tableData = find(oldValue, { subformID: subFormDataPoint.id });
    if (!tableData) {
      // @ts-ignore
      tableData = { subformID: subFormDataPoint.id, questionnaire_id: tableForm.ref } as DataPoint;
    }
    // @ts-ignore
    value.push(tableData);
    if (subFormUtils !== undefined) {
      for (const qn of tableForm.question) {
        if (
          qn.type === questionTypes.CALCULATED_VALUE_QUESTION &&
          qn.script.indexOf(subFormUtils.getModel().referenceName) !== -1
        ) {
          const refName = qn.script.substring(qn.script.indexOf('.') + 1);
          const qnId = subFormUtils.getQuestionId(`_${refName}`);
          if (tableData) {
            const sQuestion = subFormUtils.getQuestion(qnId);
            if (sQuestion) {
              const { type } = sQuestion;
              if (
                subFormDataPoint[qnId] && (
                  type === questionTypes.SELECT_MULTIPLE_QUESTION ||
                  type === questionTypes.SELECT_ONE_QUESTION ||
                  type === questionTypes.LIKERT_SCALE_QUESTION
                )
              ) {
                const optionText = getOptionTexts(sQuestion, subFormDataPoint[qnId].split(','));
                tableData[qn.id] = optionText;
                continue;
              }
            }
            tableData[qn.id] = subFormDataPoint[qnId];
          }
        }
      }
    }
  }
  return value;
};

export const filterMainForm = (
  script: string,
  subFormUtils,
  subFormDataPoints: DataPoint[],
  formUtils,
  dataPoint
) => {
  const allVariables: string[] = uniq(script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
  let skipFilter = false;

  const mainFormObj: LooseObject = {}; // mapping for _spss -> value
  const mainFormLocalSPSSToId: LooseObject = {}; // mapping for _spss -> id

  const obj: LooseObject = {}; // mapping for _spss -> value
  const localSPSSToId: LooseObject = {}; // mapping for _spss -> id
  for (const variable of allVariables) {
    if (variable.startsWith(`_${subFormUtils.getModel().referenceName}`)) {
      const refName = variable.substring(variable.indexOf('.') + 1);
      const questionId = subFormUtils.getQuestionId(`_${refName}`);
      if (questionId) {
        const qn = subFormUtils.getQuestion(questionId);
        if (
          qn && (qn.type === questionTypes.SELECT_MULTIPLE_QUESTION ||
          qn.type === questionTypes.SELECT_ONE_QUESTION ||
          qn.type === questionTypes.LIKERT_SCALE_QUESTION)
        ) {
          mainFormObj[`_${refName}`] = null;
          mainFormLocalSPSSToId[`_${refName}`] = questionId;
        } else {
          skipFilter = true;
        }
      }
    } else {
      const questionId = formUtils.getQuestionId(variable);
      if (questionId) {
        obj[variable] = null;
        localSPSSToId[variable] = questionId;
      }
    }
  }
  if (!skipFilter) {
    const validSubForms: DataPoint[] = [];
    const response =
      updateScriptObject(localSPSSToId, obj, formUtils, dataPoint, [], [], [], undefined, undefined);
    const newScript = script.replace(`_${subFormUtils.getModel().referenceName}.`, '_');
    for (const subFormDataPoint of subFormDataPoints) {
      const mainFormResponse =
      updateScriptObject(
        mainFormLocalSPSSToId, mainFormObj, subFormUtils, subFormDataPoint, [], [], [], undefined, undefined
      );
      const valid = runScript(newScript, mainFormResponse.vars.concat(response.vars));
      if (valid) {
        validSubForms.push(subFormDataPoint);
      }
    }
    return validSubForms;
  }
  return subFormDataPoints;
};
