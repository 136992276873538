import { AnyAction } from 'redux';
import { DocumentsInterface } from 'Interfaces/DocumentInterface';
import { DOCUMENTS } from '../actions/actionTypes';

const INITIAL_STATE: DocumentsInterface = {
  loaded: false,
  collection: []
};

const documents = (state = INITIAL_STATE, action: AnyAction): DocumentsInterface => {
  switch (action.type) {
    case DOCUMENTS.LOADED:
      if (action.documents) {
        return {
          loaded: true,
          collection: action.documents
        };
      }
      return state;
    case DOCUMENTS.ADD:
      if (action.document) {
        return {
          ...state,
          collection: [action.document].concat(state.collection.filter(d => d.id !== action.document.id))
        };
      }
      return state;
    case DOCUMENTS.DELETED:
      if (action.id) {
        return {...state, collection: state.collection.filter(d => d.id !== action.id)};
      }
      return state;
    default:
      return state;
  }
};

export default documents;
