import '../styles/DateQuestion.scss';
import 'react-datepicker/dist/react-datepicker.css';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import bind from 'bind-decorator';
import { Button, Col, Form, Row } from 'react-bootstrap';
import FormUtils from '../utils/FormUtils';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { checkValidationRuleError } from '../utils/utils';
import QuestionLabel from './QuestionLabel';

interface Props {
  question: LooseObject;
  value: null | number;
  dataPoint: DataPoint;
  edit: boolean;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isSubQuestion?: boolean;
}

interface State {
  value: string;
  edit: boolean;
  isSafari: boolean;
  hasError: boolean;
  errorText?: string;
}

export default class TimeQuestion extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    const { value, question } = this.props;
    this.state = {
      value: value ? value : question.default ? question.default : '',
      edit: props.edit, // props.edit ? props.edit : false
      hasError: false,
      isSafari: navigator.userAgent.indexOf('Mac') !== -1 &&
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    };
  }

  @bind
  private handleChange(e) {
    const { question, updateAnswer } = this.props;
    const value = e.target.value;
    this.setState({ value });
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value;
      updateAnswer(newAns);
    }
  }

  @bind
  private clearDate() {
    const { question, updateAnswer } = this.props;
    this.setState({ value: '' });
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = '';
      updateAnswer(newAns);
    }
  }

  @bind
  private handleSelectedTime(date) {
    const pad = (v: number) => {
      return v < 10 ? `0${v}` : v;
    };
    const { question, updateAnswer } = this.props;
    const value = `${date.getHours()}:${pad(date.getMinutes())}`;
    this.setState({ value });
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value;
      updateAnswer(newAns);
    }
  }
  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value || this.state.edit !== nextState.edit ||
      nextProps.dataPoint.validate !== this.props.dataPoint.validate;
  }

  public getDerivedStateFromProps(props, state) {
    const { value } = state;
    const { dataPoint, question, formUtils } = props;
    if (dataPoint['validate']) {
      if (!question.optional && value === '') {
        return { hasError: true };
      }
      let hasError = false;
      let errorText: string | undefined;
      if (dataPoint['invalidValidationRules']) {
        const ivr = dataPoint['invalidValidationRules'];
        const errors = checkValidationRuleError(question.id, ivr, formUtils);
        hasError = errors.hasError;
        errorText = errors.errorText;
      }
      return { hasError, errorText };
    }
    return null;
  }

  public render(): JSX.Element {
    const { question, formUtils, dataPoint } = this.props;
    const { value, edit, isSafari, hasError, errorText } = this.state;
    const horizontal = question.showValueInParallel &&
      !formUtils.getModel().responsiveLayout &&
      !`${question.text}`.endsWith('---') ? true : false;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const hasErrorClass = (required && dataPoint.validate && this.state.value === '' ) || hasError ? 'is-invalid' : '';
    const className =
      ` numeric-question ${hasErrorClass}` +
      `${!horizontal ? formUtils.getResponsiveView(question) : 'container-fluid'}`;
    const getDate = () => {
      const d = new Date();
      const times = value.split(':');
      d.setHours(Number(times[0]));
      d.setMinutes(Number(times[1]));
      return d;
    };

    const input = isSafari ? (
      <DatePicker
        className={`form-control date-question ${hasErrorClass}`}
        wrapperClassName="date-picker"
        onChange={this.handleSelectedTime}
        selected={value !== '' ? getDate() : undefined}
        disabled={!edit}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={1}
        dateFormat="h:mm aa"
      />
    ) : (
      <input
        type="time"
        name={question.id}
        className={`form-control date-question ${hasErrorClass}`}
        value={this.state.value}
        disabled={!edit}
        onChange={this.handleChange}
      />
    );
    const control = (
      <>
        {input}
        {this.props.edit && (
          <Button
            variant="link"
            onClick={() => this.clearDate()}
          >
            <i className="fa fa-close" />
          </Button>
        )}
      </>
    );
    return (
      <Form.Group
        as={horizontal ? Row : undefined}
        className={className}
      >
        <QuestionLabel
          question={question}
          dataPoint={dataPoint}
          formUtils={formUtils}
          horizontal={horizontal}
        >
          {required}
        </QuestionLabel>
        {horizontal ? (
          <Col sm={horizontal ? 10 : undefined}>
            {control}
          </Col>
        ) : control}
        {hasError && errorText && (
          <div className="invalid-feedback">{errorText}</div>
        )}
      </Form.Group>
    );
  }
}
