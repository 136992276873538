import * as React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { StateInterface } from '../../Interfaces/StateInterface';
import { MODULES } from '../../Interfaces/ModuleSelectionInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { selectTable } from '../../actions/tableActions';
import { selectedFormsSelector } from '../../reducers/formsReducer';

interface StateProps {
  forms: FormInterface[];
  selectedTable: string | undefined;
}

interface ActionProps {
  selectTable: (formId: string) => void;
}

interface Props {
  selectedModule: string;
  buttonInnerJSX: JSX.Element;
  classNames: string;
  onClick: () => void;
}

export type TableButtonProps = StateProps & ActionProps & Props;

const TableButtonContainer = (props: TableButtonProps) => {
  const onClick = (formId) => {
    if (formId !== props.selectedTable) {
      props.selectTable(formId);
    }
  };
  return (
    <Dropdown
      id="table-btn"
      onToggle={(isOpen, event, metadata) => {
        if (metadata.source === 'click' && props.selectedModule !== `${MODULES.TABLE}`) {
          props.onClick();
        }
      }}
    >
      <Dropdown.Toggle id="chart-btn-toggle" variant="primary" size={'sm'} className={props.classNames}>
        {props.buttonInnerJSX}
      </Dropdown.Toggle>
      <Dropdown.Menu rootCloseEvent="click" onClick={(e) => e.stopPropagation()}>
        {props.forms.map((form, index) => {
          return (
            <Dropdown.Item
              as="div"
              eventKey={`${index}`}
              key={`table-dropdown-item-${form.ref}`}
              onClick={() => onClick(form.ref)}
              active={props.selectedTable === form.ref}
              className={props.selectedTable === form.ref ? 'table-active' : ''}
            >
              {form.name}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    forms: selectedFormsSelector(state),
    selectedTable: state.tableMenu.selectedTable
  };
};

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    selectTable: (formId: string) => dispatch(selectTable(formId))
  };
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(TableButtonContainer);
