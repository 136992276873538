import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';

export const SIApp = (store) => ({
  render(model, forms, dataPoint, newAnswer, exportOnOpen) {
    ReactDOM.render(
      (
        <Provider store={store}>
          <App model={model} forms={forms} dataPoint={dataPoint} newAnswer={newAnswer} export={exportOnOpen} />
        </Provider>
      ),
      document.getElementById('tabView')
    );
  }
});
