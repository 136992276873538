import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { DataPoint, FileInterface, MergedPage } from 'Interfaces/DataPoint';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { QuestionInterface, questionTypes } from 'Interfaces/Forms/QuestionInterface';
import { uniq } from 'lodash';
import FormUtils from 'views/SingleInstance/utils/FormUtils';
import { getQuestions } from 'views/SingleInstance/utils/qnrenderer';
import { canHaveSubQuestions } from 'views/SingleInstance/utils/utils';

export const mergeDataPoint = (
  currentDataPoint: DataPoint,
  newDataPoint: DataPoint,
  formUtils: FormUtils,
  clientPersist: ClientPersistInterface,
): DataPoint => {
  const mergeResult = { ...newDataPoint };
  const questions = getQuestions(
    Object.values(formUtils.getQuestions()),
    currentDataPoint,
    formUtils,
    false,
    clientPersist,
  );
  mergeResult.mergedFields = currentDataPoint.mergedFields || [];
  mergeResult.mergedSubformsTables = currentDataPoint.mergedSubformsTables || {};
  mergeResult.merged = currentDataPoint.merged;
  if (mergeResult.files?.length || currentDataPoint.files?.length) {
    const currentFiles = currentDataPoint.files || [];
    const newFiles = mergeResult.files || [];
    const newValueIds: any = {};
    newFiles.forEach((_val: FileInterface) => {
      if (_val.id) {
        newValueIds[_val.id] = true;
      }
    });
    mergeResult.files = [
      ...currentFiles.filter((dp: FileInterface) => !dp.id || (dp.id && !newValueIds[dp.id])),
      ...newFiles,
    ];
    mergeResult.merged = true;
  }

  const mergeSingleValueQuestions = (newValue, currentValue, questionId: string) => {
    if (!newValue && currentValue) {
      mergeResult[questionId] = currentValue;
    }
    if (newValue && currentValue && newValue !== currentValue) {
      mergeResult[questionId] = newValue;
      mergeResult.merged = true;
      mergeResult.mergedFields?.push(questionId);
    }
  };

  const mergeMultipleValueQuestions = (newValue: any, currentValue: any, questionId: string) => {
    if (currentValue && newValue && currentValue !== newValue) {
      mergeResult[questionId] = uniq([...currentValue.split(','), ...newValue.split(',')]).join(',');
      mergeResult.mergedFields?.push(questionId);
      mergeResult.merged = true;
    } else if (currentValue && !newValue) {
      mergeResult[questionId] = currentValue;
    }
  };

  const mergeTableSubformQuestions = (newValue: any, currentValue: any, questionId: string) => {
    const newVal = newValue || [];
    if (currentValue?.some((dp: DataPoint) => dp.id?.indexOf('DV-') === -1)) {
      const newValueTime: any = {};
      newVal.forEach((_val: DataPoint) => {
        if (_val.id) {
          newValueTime[_val.id] = _val.modified;
        }
      });

      const updatedIds = currentValue
        .map((dp: DataPoint) => {
          return dp.id && dp.id?.indexOf('DV-') === -1 && (!dp.modified || dp.modified < newValueTime[dp.id])
            ? dp.id
            : '';
        })
        .filter(String);
      const newSubformsIds = newVal
        .map((dp: DataPoint) => {
          return dp.id || '';
        })
        .filter(String);
      const subformsRemovedByOtherUser = currentValue.reduce((acc: string[], dp: DataPoint) => {
        if (newSubformsIds.includes(dp.id)) {
          return acc;
        }
        return [...acc, dp.id];
      }, []);
      const currentSubforms = currentValue.reduce((acc: DataPoint[], dp: DataPoint) => {
        if (!updatedIds.includes(dp.id)) {
          if (subformsRemovedByOtherUser.includes(dp.id) && dp.id?.indexOf('DV-') === -1) {
            return [
              ...acc,
              {
                ...dp,
                deleted: true,
              },
            ];
          }
          return [...acc, dp];
        }
        return acc;
      }, []);
      const currentSubformsIds = currentSubforms
        .map((dp: DataPoint) => {
          return dp.id?.indexOf('DV-') === -1 ? dp.id : '';
        })
        .filter(String);
      mergeResult[questionId] = [
        ...currentSubforms,
        ...newVal.reduce((acc: DataPoint[], dp: DataPoint) => {
          if (!currentSubformsIds.includes(dp.id)) {
            return [...acc, dp];
          }
          return acc;
        }, []),
      ];
      if (mergeResult.mergedSubformsTables && (updatedIds.length || subformsRemovedByOtherUser.length)) {
        mergeResult.mergedSubformsTables[questionId] = [...updatedIds, ...subformsRemovedByOtherUser];
        mergeResult.merged = true;
      }
    } else if (currentValue && !newValue) {
      mergeResult[questionId] = currentValue;
    }
  };

  const defaultMerge = (newValue: any, currentValue: any, question: QuestionInterface) => {
    if (currentValue && newValue !== currentValue && !question.script) {
      mergeResult.mergedFields?.push(question.id);
      mergeResult.merged = true;
    }
    mergeResult[question.id] = newValue;
  };

  const mergeStringQuestions = (newValue, currentValue, question: QuestionInterface) => {
    if (question.type === questionTypes.NAME_QUESTION && question.script) {
      mergeResult[question.id] = newValue;
      return;
    }
    if (currentValue && newValue && currentValue !== newValue) {
      if (mergeResult.mergedFields?.includes(question.id)) {
        const splittedValue = currentValue.split(' | ');
        if (splittedValue[splittedValue.length - 1] === newValue) {
          mergeResult[question.id] = currentValue;
          return;
        }
      }
      if (currentValue.indexOf(' | ') > -1) {
        const splittedValue = currentValue.split(' | ');
        if (splittedValue.indexOf(newValue) > -1) {
          return;
        }
      }
      if (newValue.indexOf(' | ') > -1) {
        const splittedValue = newValue.split(' | ');
        if (splittedValue.indexOf(currentValue) > -1) {
          mergeResult[question.id] = `${newValue}`;
        }
      } else {
        mergeResult[question.id] = `${currentValue} | ${newValue}`;
      }
      mergeResult.mergedFields?.push(question.id);
      mergeResult.merged = true;
    } else if (currentValue && !newValue) {
      mergeResult[question.id] = currentValue;
    }
  };

  questions.forEach((question: QuestionInterface) => {
    const questionId = question.id;
    const currentValue = currentDataPoint[questionId];
    const newValue = newDataPoint[questionId];

    switch (question.type) {
      case questionTypes.STRING_QUESTION:
      case questionTypes.NAME_QUESTION:
        mergeStringQuestions(newValue, currentValue, question);
        break;
      case questionTypes.TASK_QUESTION:
      case questionTypes.PART_OF_QUESTION:
      case questionTypes.TABLE_QUESTION:
        mergeTableSubformQuestions(newValue, currentValue, questionId);
        break;
      case questionTypes.SELECT_ONE_QUESTION:
      case questionTypes.LIKERT_SCALE_QUESTION:
      case questionTypes.DATE_QUESTION:
      case questionTypes.TIME_QUESTION:
        mergeSingleValueQuestions(newValue, currentValue, questionId);
        break;
      case questionTypes.SELECT_MULTIPLE_QUESTION:
      case questionTypes.SELECT_USER_QUESTION:
        mergeMultipleValueQuestions(newValue, currentValue, questionId);
        break;
      default:
        if (newValue) {
          defaultMerge(newValue, currentValue, question);
        } else if (currentValue) {
          mergeResult[questionId] = currentValue;
        }
        break;
    }
  });

  if (!mergeResult.merged) {
    delete mergeResult.mergedFields;
  } else {
    const form: FormInterface = formUtils.getModel() as FormInterface;
    mergeResult.mergedPages = getPagesWithMergedFields(form, [
      ...mergeResult.mergedFields,
      ...Object.keys(mergeResult.mergedSubformsTables || {}),
    ]);
    mergeResult.mergeDate = new Date().getTime();
  }

  return mergeResult;
};

export const getPagesWithMergedFields = (
  form: FormInterface,
  mergedFields: string[],
  res?: MergedPage[],
): MergedPage[] => {
  const result: MergedPage[] = res || [];
  if (form.questionPage) {
    for (const page of form.questionPage) {
      if (page.question) {
        for (const question of page.question) {
          if (
            question.triggerValues &&
                        question.triggerValues.triggerValue &&
                        canHaveSubQuestions(question)
          ) {
            for (const triggerValue of question.triggerValues.triggerValue) {
              for (const q of triggerValue.action.subQuestions.question) {
                if (checkMergeQuestion(q, mergedFields)) {
                  result.push({
                    name: page.name || `${page.pageNumber + 1}`,
                    pageNumber: page.pageNumber,
                  });
                  break;
                }
              }
              if (result.some((item) => item.pageNumber === page.pageNumber)) {
                break;
              }
            }
          }
          if (result.some((item) => item.pageNumber === page.pageNumber)) {
            break;
          }
          if (checkMergeQuestion(question, mergedFields)) {
            result.push({
              name: page.name || `${page.pageNumber + 1}`,
              pageNumber: page.pageNumber,
            });
          }
        }
      }
    }
  }
  if (form.question) {
    for (const question of form.question) {
      if (checkMergeQuestion(question, mergedFields)) {
        result.push({ name: form.name, pageNumber: 0 });
        break;
      }
    }
  }
  return result;
};

const checkMergeQuestion = (question: QuestionInterface, mergedFields: string[]) => {
  if (mergedFields.includes(question.id)) {
    return true;
  }
  return false;
};
