import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { DataPoint } from '../../Interfaces/DataPoint';
import { LooseObject } from '../../Interfaces/LooseObject';
import { StateInterface } from '../../Interfaces/StateInterface';
import { fetchHistory } from '../../actions/pois';
import HistoryTableComponent from './HistoryTableComponent';

interface ActionProps {
  fetchHistory: (
    answerTable: string, formId: string, poiId: string, callBack: (dataPoint: DataPoint[]) => void
  ) => void;
}

interface Props {
  model: LooseObject;
  poiId: string;
  poiName: string;
  columns: LooseObject[];
  closeHistory: (value: boolean) => void;
}

export type HistoryTableProps =  ActionProps & Props;

class HistoryTableContainer extends React.Component<HistoryTableProps> {
  constructor(props) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <HistoryTableComponent {...this.props} />
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    fetchHistory: (
      answerTable: string, formId: string, poiId: string, callBack: (dataPoint: DataPoint[]) => void
    ) => {
      dispatch(fetchHistory(answerTable, formId, poiId, callBack));
    }
  };
};

export default connect(null, mapDispatchToProps)(HistoryTableContainer);
