import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { FeedItem } from '../../Interfaces/FeedItem';
import { uniqueFormsSelector } from '../../reducers/formsReducer';
import { clearAutoUpdatedFeed, getFeed } from '../../actions/feed';
import { setSingleInstance } from '../../actions/moduleSelectionActions';
import { SingleInstance } from '../../Interfaces/ModuleSelectionInterface';
import { FormsInterface } from '../../Interfaces/Forms/FormsInterface';
import { FeedInterface } from '../../Interfaces/FeedInterface';
import FeedComponent from './FeedComponent';

interface StateProps {
  clientPersist: ClientPersistInterface;
  feed: FeedInterface;
  autoUpdateFeed: FeedItem[];
  filtersMenu: FiltersMenuInterface;
  forms: FormsInterface;
}

interface ActionProps {
  getFeed: (rowId: number, offSet: number, signal: AbortSignal, filter?: string) => void;
  clearAutoUpdatedFeed: (feed) => void;
  setSingleInstance: (singleInstance: SingleInstance) => void;
}

export type FeedProps = StateProps & ActionProps;

class FeedContainer extends React.Component<FeedProps> {
  public render(): JSX.Element {
    return (
      <FeedComponent {...this.props} />
    );
  }
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    clientPersist: state.clientPersist,
    feed: state.feed,
    autoUpdateFeed: state.autoUpdateFeed,
    filtersMenu: state.filtersMenu,
    forms: uniqueFormsSelector(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    getFeed: (rowId: number, offSet: number, signal: AbortSignal, filter?: string) => {
      dispatch(getFeed(rowId, offSet, signal, filter));
    },
    clearAutoUpdatedFeed: (feed) => dispatch(clearAutoUpdatedFeed(feed)),
    setSingleInstance: (singleInstance) => dispatch(setSingleInstance(singleInstance))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedContainer);
