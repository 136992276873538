import './style.scss';
import * as React from 'react';
import { getLocalization } from 'global/global';
import { Alert, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useAppDispatch } from 'index';
import { saveSetting, setupAuthenticator2FA, setupEmail2FA } from 'actions/userActions';
import { updateClientPersist } from 'actions/clientPersistActions';
import { doActivateAuthenticator, doVerifyTwoFACode } from 'api/userApi';
import { useSelector } from 'react-redux';
import { StateInterface } from 'Interfaces/StateInterface';
import GenericModal from '../GenericModal';

export interface Setup2FAModalProps {
  onClose: () => void;
  email?: string;
}

interface AuthenticatorModelInterface {
  secret: string;
  qrCode: string;
}

export default function Setup2FAModal(props: Setup2FAModalProps) {
  const dispatch = useAppDispatch();
  const clientPersist = useSelector((state: StateInterface) => state.clientPersist);
  const { email } = props;
  const [inputEmail, setInputEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const [validate, setValidate] = React.useState(false);
  const [twoFAMethod, setTwoFAMethod] = React.useState<'EMAIL' | 'AUTHENTICATOR' | ''>('');
  const [view, setView] = React.useState<'EMAIL' | 'CODE' | undefined>();
  const [msg, setMessage] = React.useState('');

  const [authModel, setAuthModel] = React.useState<AuthenticatorModelInterface | null>(null);

  React.useEffect(() => {
    if (twoFAMethod === 'EMAIL') {
      if (!email) {
        setView('EMAIL');
      } else {
        void requestCode();
      }
    } else if (twoFAMethod === 'AUTHENTICATOR') {
      setView('CODE');
      void requestSecret();
    }
  }, [twoFAMethod]);


  const requestCode = async () => {
    const req = await dispatch(setupEmail2FA(email || inputEmail));
    if (req.status === 'OK') {
      setView('CODE');
    }
  };

  const requestSecret = async () => {
    const req = await dispatch(setupAuthenticator2FA());
    if (!req.errorCode) {
      setAuthModel({...req});
    }
  };

  const validateCode = async () => {
    const req = await (twoFAMethod === 'EMAIL' ?
      doVerifyTwoFACode(Number(clientPersist.user_id), code) : doActivateAuthenticator(code)
    );
    const json = await req.json();
    if (json.status === 'OK') {
      dispatch(saveSetting('hasTwoFactorAuth', twoFAMethod));
      if (twoFAMethod !== '') {
        dispatch(updateClientPersist({...clientPersist, twoFactorAuthEnabled: twoFAMethod }));
      }
      props.onClose();
    } else if (json.errorCode) {
      setMessage(json.errorMessage);
    }
  };

  const onSubmit = () => {
    if (view === 'EMAIL') {
      if (inputEmail === '') {
        setValidate(true);
      } else {
        void requestCode();
      }
    } else if (view === 'CODE') {
      if (!code) {
        setValidate(true);
      } else {
        void validateCode();
      }
    }
  };

  const getBody = () => {
    const mode = (
      <>
        <Form.Label>{getLocalization('authenticationMethod')}</Form.Label>
        <Form.Check
          id={`EMAIL-radio-check`}
          type={'radio'}
          label={getLocalization('email')}
          checked={twoFAMethod === 'EMAIL'}
          onChange={(e) => e.target.checked ? setTwoFAMethod('EMAIL') : null}
        />
        <Form.Check
          id={`AUTHENTICATOR-radio-check`}
          type={'radio'}
          label={getLocalization('authenticatorApp')}
          checked={twoFAMethod === 'AUTHENTICATOR'}
          onChange={(e) => e.target.checked ? setTwoFAMethod('AUTHENTICATOR') : null}
        />
      </>
    );
    return (
      <>
        {mode}
        {view === 'EMAIL' ? (
          <FormGroup>
            <FormLabel>{getLocalization('enterEmailTwoFACode')}</FormLabel>
            <FormControl
              type='text'
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              size='sm'
              isInvalid={validate && inputEmail === ''}
            />
          </FormGroup>
        ) : (view === 'CODE' ? (
          <>
            {authModel ? (
              <div className="code-view">
                {authModel.qrCode ? (
                  <Form.Group>
                    <Form.Label>{getLocalization('scanCode')}</Form.Label>
                    <img src={authModel.qrCode} />
                  </Form.Group>
                ) : null}
                <Form.Label>
                  {getLocalization('key')}
                </Form.Label>
                <pre className="secret-holder">
                  {authModel.secret}
                </pre>
              </div>
            ) : null}
            {((twoFAMethod === 'AUTHENTICATOR' && authModel) || twoFAMethod === 'EMAIL') ? (
              <FormGroup>
                <FormLabel>
                  {getLocalization(
                    twoFAMethod === 'AUTHENTICATOR' ? 'enterAuthenticatorCode' : 'enterTwoFACode'
                  )}
                </FormLabel>
                <FormControl
                  type='text'
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  size='sm'
                  isInvalid={validate && code === ''}
                />
              </FormGroup>
            ) : null}
            {msg && (
              <Alert variant={'danger'}>{msg}</Alert>
            )}
          </>
        ) : null)}
      </>
    );
  };

  return (
    <GenericModal
      visible={true}
      title={getLocalization('setupTwoFA')}
      cancel={props.onClose}
      cancelText={getLocalization('cancel')}
      confirmText={getLocalization(view === 'CODE' ? 'verify' : 'getCode')}
      onConfirm={onSubmit}
      body={getBody()}
    />
  );
}
