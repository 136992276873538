import { createSelector } from 'reselect';
import { FiltersMenuInterface } from 'Interfaces/FiltersMenuInterface';
import { USERS } from '../actions/actionTypes';
import { Users } from '../Interfaces/User';
import { StateInterface } from '../Interfaces/StateInterface';
import { filtersMenuSelectedUsersSelector } from './filtersMenuReducer';

const users = (state: Users = [], action) => {
  switch (action.type) {
    case USERS.SET_USERS:
      if (action.payload) {
        return Object.assign([], action.payload);
      }
      return state;
    default:
      return state; //
  }
};

export default users;

// Selectors
export const getUsersSelector = (state: StateInterface): Users => state.users;

export const selectedUsersSelector = createSelector<
StateInterface,
Users,
FiltersMenuInterface['selectedUsers'],
Users
>(getUsersSelector, filtersMenuSelectedUsersSelector, (allUsers, selectedUsers) => {
  const userReferences = selectedUsers.map(({ id }) => id);
  return allUsers.filter((user) => userReferences.includes(user.id));
});

export const activeUsersSelector = createSelector<StateInterface, Users, Users>(getUsersSelector, (users) => {
  return users.filter((u) => u.active && u.name.indexOf('-deleted') === -1);
});
