import { AnyAction } from 'redux';
import { TranscribingInterface } from 'Interfaces/TranscribingInterface';
import { TRANSCRIBING } from '../actions/actionTypes';

const INITIAL_STATE: TranscribingInterface = {
  formId: '', dataPointId: ''
};

const transcribing = (state = INITIAL_STATE, action: AnyAction): TranscribingInterface => {
  switch (action.type) {
    case TRANSCRIBING.START:
      if (action.transcribing) {
        return action.transcribing;
      }
      return state;
    case TRANSCRIBING.DONE:
      return {
        formId: '', dataPointId: ''
      };
    default:
      return state;
  }
};

export default transcribing;
