import { find, keys, map } from 'lodash-es';
import { LooseObject } from '../../../Interfaces/LooseObject';

export const getReports = (form: LooseObject, forms: LooseObject[], reports: LooseObject) => {
  return findTables(form.questionPage || [], forms, reports);
};

export const findTables = (pages: LooseObject[], forms: LooseObject[], reports: LooseObject) => {
  let poiReports: any = [];
  for (const page of pages) {
    if (page.question) {
      for (const q of page.question) {
        if (q.type === 'TableQuestion' && !q.inVisible) {
          const reportKeys = keys(reports);
          map(reportKeys, (key) => {
            if (reports[key].form === q.text) {
              poiReports.push(reports[key]);
            }
          });
        } else if (q.type === 'Part_of_Question' && !q.inVisible) {
          const subForm = find(forms, { ref: q.listItems.relation[0].ref });
          if (subForm) {
            poiReports = reports.concat(findTables(subForm['questionPage'], forms, reports));
          }
        }
      }
    }
  }
  return poiReports;
};
