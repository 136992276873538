import './Style.scss';
import { DocumentFolder } from 'Interfaces/DocumentInterface';
import { DEFAULT_PERMISSION, Permission, RolePermissions } from 'Interfaces/Documents/PermissionInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { getRequestConfig } from 'api';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Row, Table } from 'react-bootstrap';
import { DocumentFolderPermissionInterface } from 'Interfaces/Documents/DocumentFolderPermissionInterface';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { Groups } from 'Interfaces/GroupInterface';
import { getLocalization, globalWindow } from 'global/global';
import GenericModal from 'views/Modals/GenericModal';
import { roles } from '../DocumentPermissions';
import PermissionView from './PermissionView';
import GroupPermission from './GroupPermission';

interface Props {
  folderId: number;
}

interface StateProps {
  documentFolder?: DocumentFolder;
  groups: Groups;
  clientPersist: ClientPersistInterface;
}

interface GroupPermissions {
  id: number;
  name: string;
  permissions: RolePermissions;
}

function FolderView(props: StateProps & Props) {
  const { documentFolder } = props;

  const [rolePermissions, setRolePermissions] = React.useState<RolePermissions | undefined>(undefined);
  const [groupPermissions, setGroupPermissions] = React.useState<GroupPermissions[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [showGroupEditor, setShowGroupEditor] = React.useState(false);
  const [gpRolePermissions, setGpRolePermissions] = React.useState<RolePermissions | undefined>(undefined);
  const [groupId, setGroupId] = React.useState('');

  const getGroup = React.useCallback((id: number) => {
    return props.groups.find(g => g.id === id);
  }, [props.groups]);

  React.useEffect(() => {
    if (showGroupEditor && !gpRolePermissions) {
      setGpRolePermissions(initGroupPermissions(groupId));
    }
  }, [showGroupEditor]);

  React.useEffect(() => {
    if (groupId && gpRolePermissions) {
      const newGP = {...gpRolePermissions};
      const keys = Object.keys(newGP);
      keys.forEach(key => {
        newGP[key].groupId = Number(groupId);
      });
      setGpRolePermissions(newGP);
    }
  }, [groupId]);

  React.useEffect(() => {
    if (!loading) {
      setLoading(true);
      const getPermissions = async () => {
        const config = getRequestConfig();
        const permissions = await fetch(`/json/app/document/folder/permission/${documentFolder?.id}`, {
          ...config
        }).then(res => res.json());
        const rolePermission: RolePermissions & DocumentFolderPermissionInterface =
          initGroupPermissions(globalWindow.groupID);
        if (permissions.length > 0) {
          const grouped = processPermissions(permissions);
          if (grouped[`${globalWindow.groupID}`]) {
            grouped[`${globalWindow.groupID}`].forEach((perm) => {
              rolePermission[perm.role] = perm;
            });
            setRolePermissions(rolePermission);
            delete grouped[`${globalWindow.groupID}`];
          }
          const keys = Object.keys(grouped);
          const gp: GroupPermissions[] = [];
          keys.forEach(key => {
            const rp: RolePermissions & DocumentFolderPermissionInterface = initGroupPermissions(key);
            grouped[key].forEach((perm) => {
              rp[perm.role] = perm;
            });
            const group = getGroup(Number(key));
            gp.push({
              name: `${group?.name}`,
              id: Number(key),
              permissions: rp
            });
          });
          setGroupPermissions(gp);
        } else {
          setRolePermissions(rolePermission);
          setGroupPermissions([]);
        }
        setLoading(false);
      };
      void getPermissions();
    }
  }, [documentFolder?.id]);

  const initGroupPermissions = (id) => {
    const permission: RolePermissions & DocumentFolderPermissionInterface = {};
    roles.forEach(role => {
      permission[role] = {
        ...DEFAULT_PERMISSION,
        documentFolderId: Number(documentFolder?.id),
        groupId: Number(id),
        role
      };
    });
    return permission;
  };

  const processPermissions = (perms) => {
    const grouped = {};
    perms.forEach((perm) => {
      grouped[`${perm.groupId}`] = grouped[`${perm.groupId}`] || [];
      grouped[`${perm.groupId}`].push(perm);
    });
    return grouped;
  };

  const updatePermission = (permission, role) => {
    if (rolePermissions) {
      setRolePermissions({...rolePermissions, [role]: permission });
    }
  };

  const savePermissions = async () => {
    console.log('save');
    if (rolePermissions) {
      const saved = await doSavePermissions(rolePermissions);
      setRolePermissions(saved);
    }
  };

  const doSavePermissions = async (permission) => {
    const data = Object.values(permission);
    const config = getRequestConfig();
    const permissions = await fetch(`/json/app/document/folder/permission`, {
      ...config,
      method: 'POST',
      body: JSON.stringify(data)
    }).then(res => res.json());
    if (Array.isArray(permissions)) {
      const docPerm = {...permission};
      permissions.forEach((perm) => {
        docPerm[perm.role] = perm;
      });
      return docPerm;
    }
  };

  const updateGpPermission = (permission: boolean, role: string) => {
    if (gpRolePermissions) {
      setGpRolePermissions({...gpRolePermissions, [role]: permission });
    }
  };

  const saveGroupRolePermissions = async () => {
    if (gpRolePermissions) {
      setShowGroupEditor(false);
      setGroupId('');
      const saved = await doSavePermissions(gpRolePermissions);
      setGpRolePermissions(undefined);
      let found = false;
      const newGpPermissions = groupPermissions.map(gp => {
        if (gp.id === saved['admin'].groupId) {
          found = true;
          return {...gp, permissions: saved };
        }
        return gp;
      });
      if (!found) {
        const group = getGroup(Number(saved['admin'].groupId));
        newGpPermissions.push({
          name: `${group?.name}`,
          id: saved['admin'].groupId,
          permissions: saved
        });
      }
      setGroupPermissions(newGpPermissions);
    }
  };

  const resetGpPermissions = () => {
    setGpRolePermissions(undefined);
    setShowGroupEditor(false);
    setGroupId('');
  };

  const editor = showGroupEditor ? (
    <GenericModal
      visible={true}
      title='Group permission'
      dialogClassName={'lg'}
      body={(
        <>
          {props.documentFolder && gpRolePermissions ? (
            <GroupPermission
              groups={props.groups}
              groupId={groupId}
              permissions={gpRolePermissions}
              documentFolder={props.documentFolder}
              updateRolePermission={updateGpPermission}
              setGroupId={setGroupId}
            />
          ) : null}
        </>
      )}
      onConfirm={() => void saveGroupRolePermissions()}
      cancel={() => resetGpPermissions()}
      confirmText={getLocalization('save')}
      cancelText={getLocalization('cancel')}
    />
  ) : null;

  const renderPermission = (permission: Permission) => {
    return (
      <>
        {permission.read ? <span className="permission-pill mr-2">read</span> : null}
        {permission.write ? <span className="permission-pill mr-2">write</span> : null}
        {permission.delete ? <span className="permission-pill mr-2">delete</span> : null}
      </>
    );
  };

  return (
    <div className="doc-sub-view folder-view">
      {editor}
      <h4>{documentFolder?.name}</h4>
      {props.clientPersist.roles === 'admin,modeler' && (
        <>
          <label>{getLocalization('folderAccessForOwnGroup')}</label>
          {documentFolder && rolePermissions && (
            <PermissionView
              id={Number(documentFolder.id)}
              rolePermissions={rolePermissions}
              updatePermission={updatePermission}
            />
          )}
          <Row className="container-fluid">
            <Button
              size="sm"
              onClick={() => void savePermissions()}
            >
              <i className="fa fa-save" aria-hidden="true" />
              <span>{getLocalization('save')}</span>
            </Button>
          </Row>
        </>
      )}
      {props.clientPersist.roles === 'admin,modeler' && (
        <>
          <hr />
          <label>{getLocalization('folderAccessForOtherGroups')}</label>
          <Row className="container-fluid">
            <Button
              size="sm"
              onClick={() => setShowGroupEditor(true)}
            >
              <i className="fa fa-plus" aria-hidden="true" />
              <span>{getLocalization('addGroupPermission')}</span>
            </Button>
          </Row>
          <Table hover={true} className="mt-5 text-center">
            <thead>
              <tr>
                <th>#</th>
                <th>{getLocalization('group')}</th>
                <th>{getLocalization('admin')}</th>
                <th>{getLocalization('modeler')}</th>
                <th>{getLocalization('ordinary')}</th>
                <th>{getLocalization('enumerator')}</th>
              </tr>
            </thead>
            <tbody>
              {groupPermissions.length > 0 ? groupPermissions.map((gp) => {
                return (
                  <tr
                    key={`group-permission-${gp.id}`}
                    onClick={() => {
                      setGroupId(`${gp.id}`);
                      setGpRolePermissions(gp.permissions);
                      setShowGroupEditor(true);
                    }}
                  >
                    <td className="count" />
                    <td>{gp.name}</td>
                    <td>{renderPermission(gp.permissions.admin)}</td>
                    <td>{renderPermission(gp.permissions.modeler)}</td>
                    <td>{renderPermission(gp.permissions.ordinary)}</td>
                    <td>{renderPermission(gp.permissions.enumerator)}</td>
                  </tr>
                );
              }) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    {getLocalization('noFolderAccessForOtherGroupsDefined')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state: StateInterface, props: Props): StateProps => {
  return {
    documentFolder: state.documentFolders.collection.find(f => f.id === props.folderId),
    groups: state.groups.collection,
    clientPersist: state.clientPersist
  };
};


export default connect(mapStateToProps, null)(FolderView);
