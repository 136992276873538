import * as React from 'react';
import GenericModal from '../GenericModal';

export interface AppModalProps {
  title?: string;
  body: JSX.Element;
  confirmText: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

export default function AppModal(props: AppModalProps) {

  return (
    <GenericModal
      visible={true}
      title={props.title}
      body={props.body}
      onConfirm={props.onConfirm}
      confirmText={props.confirmText}
      cancel={props.onCancel}
    />
  );
}
