import { doGeoCodeAddress, doReverseGeoCode } from '../api/geocode';
import { LooseObject } from '../Interfaces/LooseObject';
import { processReverseGeoCode } from './utils/reverseGeoCode';

export const reverseGeoCode = (lat: number, lng: number, callBack?: (geoCodedData: LooseObject) => void) => {
  return (dispatch, getState) => {
    const promise = doReverseGeoCode(lat, lng, getState().clientPersist.user_id, dispatch, getState);

    promise
      .then((response) => response.json())
      .then((json) => {
        if (json.length > 0) {
          const geoCodedData = processReverseGeoCode(json[0]);
          if (callBack) {
            callBack(geoCodedData);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const geocodeAddress =
    (address: string, signal: AbortSignal) =>
      (dispatch, getState): Promise<Response> => {
        return new Promise((resolve, reject) => {
          const request = doGeoCodeAddress(address, getState().clientPersist.user_id, signal, dispatch, getState);
          request
            .then((response) => resolve(response))
            .catch((error) => {
              reject(error); // console.log(error);
            });
        });
      };
