import * as React from 'react';
import bind from 'bind-decorator';
import { Button, Dropdown } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { SystemStatus } from 'Interfaces/SystemInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { SingleInstance } from '../../Interfaces/ModuleSelectionInterface';
import { getLocalization } from '../../global/global';


interface Props {
  enumerator: boolean;
  forms: FormInterface[];
  instance: string;
  status: SystemStatus;
  setSingleInstance: (singleInstance: SingleInstance) => void;
}

export default class AddPoiComponent extends React.Component<Props> {

  constructor(props) {
    super(props);
  }

  @bind
  private onClick(form: FormInterface) {
    const singleInstance: SingleInstance = {
      newAnswer: true,
      model: form,
      dataPoint: { formId: form.id, questionnaire_id: form.ref, created: Date.now() }
    };
    ReactGA.event({
      category: 'module-selection',
      action: `ADD_DATA`,
      label: `${this.props.instance}`
    });
    this.props.setSingleInstance(singleInstance);
  }

  public render() {
    const { forms, enumerator, status } = this.props;
    if (forms.length === 1) {
      return (
        <Button
          size={'sm'}
          onClick={() => this.onClick(forms[0])}
          id={'add-poi-dropdown-btn'}
        >
          <i className="fa fa-map-marker" />
          {` ${getLocalization('adddatapoint')}`}
        </Button>
      );
    }
    return (
      <Dropdown
        id={'add-poi-dropdown-btn'}
      >
        <Dropdown.Toggle size={'sm'} id="add-poi-dropdown-toggle" disabled={status === 'EXPIRED'}>
          <i className="fa fa-map-marker" />
          {` ${getLocalization('adddatapoint')}`}
        </Dropdown.Toggle>
        <Dropdown.Menu
          alignRight={enumerator}
        >
          {forms.map((form) => {
            return !form.isChild && (
              <Dropdown.Item
                key={form.ref}
                eventKey="1"
                onClick={() => this.onClick(form)}
                id={`add-poi-${form.ref}-btn`}
              >
                {form.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
