export interface DocumentComment {
  id?: number;
  documentId: number;
  comment: string;
  createdOn: number;
  createdBy: number | '';
}

export interface DocumentComments {
  loaded: boolean;
  loading: boolean;
  comments: DocumentComment[];
}

export const INITIAL_COMMENTS: DocumentComments = {
  loaded: false,
  loading: false,
  comments: []
};

export const INITIAL_COMMENT: Partial<DocumentComment> = {
  comment: '',
  createdBy: ''
};
