import * as React from 'react';
import { connect } from 'react-redux';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { AlertInterface } from 'Interfaces/AlertInterface';
import { ShareUser } from 'Interfaces/User';
import { DataPoint } from '../../../../Interfaces/DataPoint';
import ShareButton from '../ShareButton';
import { sharePOI } from '../../../../actions/pois';
import { showAlert } from '../../../../actions';
import { checkUser, getSharerEmail, shareDataPoint } from '../../../../actions/shareActions';

interface Props {
  dataPoint: DataPoint;
}

interface ActionProps {
  sharePOI: (id: string) => void;
  showAlert: (alert: AlertInterface) => void;
  checkUser: (email: string, id: string, signal: AbortSignal) => Promise<Response>;
  shareDataPoint: (user: ShareUser, dataPoint: DataPoint, signal: AbortSignal) => Promise<Response>;
  getSharerEmail: (id: string, signal: AbortSignal) => Promise<Response>;
}

interface StateProps {
  clientPersist: ClientPersistInterface;
}

export type ShareButtonProps = Props & ActionProps & StateProps;

/* export class ShareButtonContainer extends React.Component <ShareButtonProps> {

  constructor(props) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <ShareButton {...this.props} />
    );
  }
}*/

const ShareButtonContainer = (props: ShareButtonProps) => {
  return (
    <ShareButton {...props} />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    clientPersist: state.clientPersist
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sharePOI: (id: string) => {
      dispatch(sharePOI(id));
    },
    showAlert: (alert: AlertInterface) => {
      dispatch(showAlert(alert));
    },
    checkUser: (email: string, id: string, signal: AbortSignal): Promise<Response> => {
      return dispatch(checkUser(email, id, signal));
    },
    shareDataPoint: (user: ShareUser, dataPoint: DataPoint, signal: AbortSignal) => {
      return dispatch(shareDataPoint(user, dataPoint, signal));
    },
    getSharerEmail: (id: string, signal: AbortSignal) => dispatch(getSharerEmail(id, signal))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareButtonContainer);
