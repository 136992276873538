import { CollectionsInterface } from '../CollectionsInterface';
import { FormAttributesInterface } from './FormAttributesInterface';
import { QuestionInterface } from './QuestionInterface';
import { QuestionPageInterface } from './QuestionPageInterface';

export interface FormInterface extends FormAttributesInterface {
  id: string;
  ref: string;
  name: string;
  answerTable: string;
  count: number;
  static: boolean;
  questionPage?: QuestionPageInterface[]; // TODO
  question?: QuestionInterface[];
  relation: QuestionPageInterface['relation'];
  type: 'POI' | 'TABLE' | 'TASKFORM';
  // Whats the difference between schemaVersion and version?
  version: string | null;
  schemaVersion: number;
  locationsMandatory: string;
  statusInfo: null;
  referenceName: string | null;
  includeName: boolean;
  hasImageAttachment: number;
  hideInTable: boolean | null;
  recordGPSTrace: boolean | null;
  tracingFrequency: boolean | null;
  lang: string | null;
  showOriginalLanguage: boolean | null;
}

export interface OriginalFormText {
  [key: string]: string;
}

export interface FormOriginalText {
  [key: string]: OriginalFormText;
}

export interface FormColectionInterface extends CollectionsInterface<FormInterface> {
  originalFormText: FormOriginalText;
}

export type FormsInterface = FormColectionInterface; // CollectionsInterface<FormInterface>;

export const sortFormsByName = (formA: FormInterface, formB: FormInterface): number => {
  const nameA = formA.name ? formA.name.toUpperCase() : '';
  const nameB = formB.name ? formB.name.toUpperCase() : '';
  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  } else {
    return 0;
  }
};
