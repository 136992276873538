import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { SingleInstance } from 'Interfaces/ModuleSelectionInterface';
import ReactGA from 'react-ga';
import { uniqueFormsSelector } from 'reducers/formsReducer';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { DataPoint } from '../../Interfaces/DataPoint';
import { setSingleInstance } from '../../actions/moduleSelectionActions';
import { QuestionInterface } from '../../Interfaces/Forms/QuestionInterface';

interface Props {
  row: DataPoint;
  cell: string;
  tableQuestion?: QuestionInterface;
}

interface StateProps {
  forms: FormInterface[];
  clientPersist: ClientPersistInterface;
}

interface ActionProps {
  setSingleInstance: (singleInstance: SingleInstance) => void;
}

export type NameCellRendererProps = Props & StateProps & ActionProps;

/**
 * Renders the name cell.
 * Because for tasks we need to check the form is it should open the parent poi when clicked,
 * we have this component which receives the forms from the global state instead of passing the Forms
 * directly from the table (not possible unless the code is so coupled together which is not good also)
 */
const NameCellRenderer = (props: NameCellRendererProps) => {
  const { row, cell, forms, tableQuestion } = props;
  const value = cell ? cell : 'Name not provided';
  const form = forms && forms.find(f => f.ref === row.questionnaire_id);
  let onClick;
  const setSingleInstance = (singleInstance) => {
    const { clientPersist } = props;
    if (clientPersist.payingCustomer === 'yes') {
      ReactGA.event({
        category: 'single-instance',
        action: `open-table-view`,
        label: `${clientPersist.instance}`
      });
    }
    props.setSingleInstance(singleInstance);
  };

  if (form) {
    if (form.type === 'TABLE' && tableQuestion) {
      let v = value;
      if (tableQuestion.table?.rows?.listItem) {
        const li = tableQuestion.table?.rows?.listItem.find(l => l.id === value);
        if (li) {
          v = li.value;
        }
      }
      return (<a>{v}</a>);
    }
    onClick = () => {
      const singleInstance: SingleInstance = {
        model: form,
        dataPoint: row,
        newAnswer: false,
        history: row['isHistory']
      };
      setSingleInstance(singleInstance);
    };
  }
  let hrefValue = `#${row.questionnaire_id}/${row.id || '#'}/${row.row_id}/false`;
  if (form && form.type === 'TASKFORM' && form.openParent && row.parentId && row.parentRowId) {
    const parentForm = forms && forms.find(f => f.ref === row.parentId);
    if (parentForm) {
      const dp: DataPoint = {
        row_id: Number(row.parentRowId),
        questionnaire_id: parentForm.ref,
        formId: parentForm.id
      };
      const singleInstance: SingleInstance = {
        model: parentForm,
        dataPoint: dp,
        newAnswer: false
      };
      onClick = () => setSingleInstance(singleInstance);
    }
    hrefValue = `#${encodeURIComponent(row.parentId)}/#/${row.parentRowId}/false`;
  }
  return (<a onClick={onClick} href={hrefValue}>{value}</a>);
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    forms: uniqueFormsSelector(state),
    clientPersist: state.clientPersist
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    setSingleInstance: (singleInstance) => dispatch(setSingleInstance(singleInstance))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NameCellRenderer);
