import './AddData.scss';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import * as React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { getFormUtils } from 'views/SingleInstance/utils/FormUtilsHolder';
import { renderQuestions } from 'views/SingleInstance/utils/qnrenderer';
import { getLocalization } from 'global/global';
import { validateQuestion } from 'views/SingleInstance/utils/Validator';
import GenericModal from 'views/Modals/GenericModal';
import { ConfirmationModal } from 'views/Modals/ConfirmationModal';
import { generateName } from 'views/SingleInstance/utils/utils';

interface Props {
  form: FormInterface;
  forms: FormInterface[];
  clientPersist: ClientPersistInterface;
  saveQuestionnaire: (dataPoint: DataPoint) => void;
}

function AddData(props: Props) {

  const [page, setPage] = React.useState(0);
  const [questions, setQuestions] = React.useState<JSX.Element[]>([]);
  const [dataPoint, setDataPoint] = React.useState<DataPoint>({
    questionnaire_id: props.form.id,
    formId: props.form.id,
    user_id: Number(props.clientPersist.user_id),
    portalEditEntry: Date.now(),
    modified: Date.now(),
    created: Date.now(),
    editor: Number(props.clientPersist.user_id),
    countQuestion: 1
  });
  const [formUtils] = React.useState(getFormUtils(props.form));
  const [pages] = React.useState(props.form.questionPage ? props.form.questionPage.length : 0);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [hideSubmit, setHideSubmit] = React.useState(false);

  const updateAnswer = (dp: DataPoint) => {
    setDataPoint((prevState) => {
      return {...prevState, ...dp};
    });
  };

  React.useEffect(() => {
    const { form } = props;
    const p = form.questionPage ? form.questionPage[page] : undefined;
    if (p) {
      const qns = renderQuestions(
        p.question || [], dataPoint, false, props.forms, updateAnswer, formUtils, true, props.clientPersist
      );
      setQuestions(qns);
    }
  }, [page, dataPoint]);

  const changePage = (change: number) => {
    const newPage = page + change;
    if (change > 0) {
      const isInValid = validatePage();
      if (isInValid) {
        setAlertMsg(isInValid.text);
        return;
      }
    }
    setPage(newPage < 0 || newPage > (pages - 1) ? 0 : newPage);
  };

  const validatePage = () => {
    if (props.form.questionPage) {
      const qnPage = props.form.questionPage[page];
      if (qnPage.question) {
        for (const question of qnPage.question) {
          const isInvalid = validateQuestion(question, dataPoint, props.forms, props.clientPersist);
          if (isInvalid) {
            return question;
          }
        }
      }
    }
    return undefined;
  };

  const onSave = () => {
    const isInValid = validatePage();
    if (isInValid) {
      setAlertMsg(isInValid.text);
      return;
    }
    setShowConfirm(true);
  };

  const saveConfirmed = () => {
    setShowConfirm(false);
    const portalEditTime = Date.now() - dataPoint.portalEditEntry;
    dataPoint.Name = dataPoint.Name || generateName();
    props.saveQuestionnaire({...dataPoint, portalEditTime });
    setHideSubmit(true);
  };

  return (
    <Card className="add-data">
      <GenericModal
        visible={alertMsg !== ''}
        body={(
          <>
            <p><strong>{getLocalization('mandatoryDataMissing')}</strong></p>
            <p>{alertMsg}</p>
          </>
        )}
        onConfirm={() => {
          setAlertMsg('');
        }}
        confirmText={getLocalization('ok')}
        title={getLocalization('error')}
      />
      {showConfirm && (
        <ConfirmationModal
          onConfirm={saveConfirmed}
          onCancel={() => setShowConfirm(false)}
          localizations={{
            cancel: getLocalization('cancel'),
            confirm: getLocalization('submit'),
            confirmStyle: 'success',
            header: (<label>{getLocalization('confirm')}</label>),
            body: (<p>{getLocalization('savePOICampaign')}</p>)
          }}
          onClose={() => setShowConfirm(false)}
        />
      )}
      <Card.Header>
        <h4>
          {props.form.questionPage ? props.form.questionPage[page].name : ''}
        </h4>
      </Card.Header>
      <Card.Body>
        {questions}
      </Card.Body>
      <Card.Footer className="bg-light">
        <Row>
          <Col md={1}>
            {page > 0 && (
              <Button onClick={() => changePage(-1)} disabled={hideSubmit}>
                {getLocalization('previous')}
              </Button>
            )}
          </Col>
          <Col md={{ span: 1, offset: 10}}>
            <Button
              onClick={() => {
                if (page === pages - 1) {
                  onSave();
                } else {
                  changePage(1);
                }
              }}
              disabled={hideSubmit}
            >
              {page === pages - 1 ? getLocalization('submit') : getLocalization('next')}
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

export default AddData;
