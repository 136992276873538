import { Action } from 'typescript-fsa';
import { SYSTEM } from '../actions/actionTypes';
import { SystemInterface } from '../Interfaces/SystemInterface';
import ReducerBuilder from './ReducerBuilder';

export const INITIAL_STATE: SystemInterface = {
  version: '1.0.0',
  debug: false,
  status: 'OK',
  statusMessage: ''
};

type PayloadType = SystemInterface;

function loadProperties(state: SystemInterface, { payload }): SystemInterface {
  payload.debug =  payload.debug ? payload.debug === 'true' || payload.debug === true : payload.debug;
  return { ...state, ...payload };
}

export default new ReducerBuilder<SystemInterface, Action<PayloadType>>()
  .setInitialState(INITIAL_STATE)
  .addReducer(SYSTEM.LOAD_PROPERTIES, loadProperties)
  .build();
