import { useAppSelector } from 'index';
import * as React from 'react';

interface Props {
  formId: string;
  viewId: string;
}

export default function OriginalFormText(props: Props) {
  const forms = useAppSelector(state => state.forms);
  const formView = forms.originalFormText[props.formId];
  if (!formView || !forms.originalFormText[props.formId]) {
    return null;
  }
  return (
    <div style={{ fontStyle: 'italic', display: 'inline' }}>
      ({`${formView[props.viewId]}`})
    </div>
  );
}
