import './Style.scss';
import { DocumentInterface } from 'Interfaces/DocumentInterface';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatDisplayDate } from 'utils/utils';

interface Props {
  document: DocumentInterface;
  filesCount: number;
  active?: boolean;
  onClick: () => void;
}

export default function DocumentItem(props: Props) {
  const { document } = props;
  return (
    <Row
      className={`doc-item ${props.active ? 'active' : ''}`}
      onClick={() => props.onClick()}
    >
      <Col className="document-list">
        {`${document.name} (${props.filesCount})`}
      </Col>
      <Col className="document-list doc-creation-date text-muted">
        {formatDisplayDate(document.creationDate)}
      </Col>
    </Row>
  );
}
