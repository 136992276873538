import '../styles/UserQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { Col, Form, Row } from 'react-bootstrap';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { UserQuestionProps } from '../Containers/UserQuestionContainer';
import { pad } from '../../../utils/utils';
import { getLocalization } from '../../../global/global';
import { User, Users } from '../../../Interfaces/User';
import { checkValidationRuleError } from '../utils/utils';
import QuestionLabel from './QuestionLabel';
import TextInputComponent from './TextInputComponent';

interface State {
  value: string[];
  edit: boolean;
  filteredList: User[];
  userList: User[];
  hasError: boolean;
  errorText?: string;
}

export default class UserQuestion extends React.Component <UserQuestionProps, State> {

  constructor(props) {
    super(props);
    const { dataPoint, question } = this.props;
    const userList = this.getUserList();
    this.state = {
      value: dataPoint[question.id] ? this.getValue(dataPoint[question.id]) :
        question.default ? this.getValue(question.default) : [],
      edit: props.edit,
      userList: userList,
      filteredList: userList,
      hasError: false
    };
  }

  @bind
  private getUserList() {
    const { question, userList, clientPersist } = this.props;
    if (['assignto', 'doneby', 'toapprove'].indexOf(question.id) !== -1) {
      let users;
      let selectRoles;
      if (clientPersist.roles === 'enumerator') {
        selectRoles = question.enumRoles;
        users = question.enumUsers;
      } else {
        selectRoles = question.officeRoles;
        users = question.officeUsers;
      }
      return this.getTaskUsers(selectRoles, users);
    }

    let users: Users = [];
    if (question.roles && question.roles !== '') {
      const splitRoles = question.roles.split(',');
      const selectedUsers = question.users && question.users !== 'false'
        ? question.users.split(',').map( id => Number(id)) : [];
      splitRoles.forEach(role => {
        users = users.concat(userList.filter(u => {
          const toAdd = u.roles.includes(role) && selectedUsers.indexOf(u.id) === -1;
          if (toAdd) {
            selectedUsers.push(u.id);
          }
          return toAdd;
        }));
      });
    }

    if (question.users && question.users.trim().length > 0 && question.users !== 'false') {
      const selectedUsers = question.users.split(',').map( id => Number(id));
      users =  users.concat(userList.filter(u => selectedUsers.indexOf(u.id) !== -1));
    }
    if (!question.subgroup) {
      return users.filter(u => Number(u.groupId) === Number(clientPersist.groupId));
    }
    return users;
  }

  /**
   * For task questions, assignement can be done based on roles or to specific users.
   * If no roles/users are selected then it can be assigned to anyone in the group, parent or even subgroups.
   * @param roles - roles to which a question can only be assigned to.
   * @param users - users who can only be assigned in the specific question.
   */
  @bind
  private getTaskUsers(roles, users) {
    const { taskUsers } = this.props;

    let visibleUsers: any[] = [];
    if (users && users.trim().length > 0) {
      visibleUsers = users.split(',').map(i => Number(i));
    }
    if (roles && roles.trim().length > 0) {
      visibleUsers = visibleUsers.concat(taskUsers.map((user) => {
        if (roles.includes(user.roles)) {
          return user.id;
        }
        if (user.roles === 'admin,modeler' && roles.includes('admin')) {
          return user.id;
        }
        return -1;
      }));
    }
    if (visibleUsers.length === 0) {
      return taskUsers;
    }
    const userList: any[] = taskUsers.map((user) => {
      if (visibleUsers.indexOf(user.id) !== -1) {
        return user;
      }
      return undefined;
    }).filter(u => u !== undefined);
    return userList;
  }

  @bind
  private getValue(value: string): string[] {
    return value.split(',').filter( v => v.trim() !== '' );
  }

  @bind
  private handleChange(e) {
    let value: string[] = Object.assign([], this.state.value);

    if (e.target.checked) {
      value.push(e.target.value);
    } else {
      value = value.filter(v => v !== e.target.value);
    }
    const { updateAnswer, question } = this.props;
    this.setState({ value });
    if (updateAnswer) {
      let newAns = {};
      newAns[question.id] = value.join(',');
      if (question.id === 'assignto' && value.length > 0) {
        newAns = Object.assign({}, newAns, this.getTaskValues());
      }
      updateAnswer(newAns);
    }
  }

  @bind
  private getTaskValues(): LooseObject {
    const { question, dataPoint } = this.props;
    const today = new Date();
    const value = {};
    if (question.id === 'assignto') {
      if (!dataPoint['taskstatus']) {
        value['taskstatus'] = 'assigned';
        value['assigndate'] = `${today.getFullYear()}${'-'}${pad(today.getMonth() + 1)}${'-'}${pad(today.getDate())}`;
        value['donedate'] = '';
        value['doneby'] = '';
      }
    }
    return value;
  }

  @bind
  private filterChanged(value: any) {
    const { userList } = this.state;
    const inputLength = value.length;
    const newList = inputLength === 0 ? userList :  userList.filter(((val) =>
      val.name.toLowerCase().search(value.toLowerCase()) !== -1
    ));
    this.setState({ filteredList : newList });
  }

  public componentDidMount() {
    const { question, updateAnswer } = this.props;
    const { value, userList } = this.state;
    if (question.id === 'toapprove' && value.length === 0 && userList.length === 1) {
      const v = [`${userList[0].id}`];
      const newAns = {};
      newAns[question.id] = v.join(',');
      this.setState({ value: v }, () => updateAnswer(newAns));
    }
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value || this.state.edit !== nextState.edit
      || nextProps.dataPoint.validate !== this.props.dataPoint.validate
      || this.state.filteredList !== nextState.filteredList;
  }

  public static getDerivedStateFromProps(props: UserQuestionProps, state: State) {
    const { question, dataPoint, formUtils } = props;
    if (dataPoint['validate']) {
      if ((!question.optional && state.value.length === 0)) {
        return { hasError: true };
      }
      let hasError = false;
      let errorText: string | undefined;
      if (dataPoint['invalidValidationRules']) {
        const ivr = dataPoint['invalidValidationRules'];
        const errors = checkValidationRuleError(question.id, ivr, formUtils);
        hasError = errors.hasError;
        errorText = errors.errorText;
      }
      return { hasError, errorText };
    }
    return null;
  }

  public render(): JSX.Element {
    const { filteredList, value, userList, errorText, hasError } = this.state;
    const { question, formUtils, dataPoint } = this.props;
    const horizontal = question.showValueInParallel &&
      !formUtils.getModel().responsiveLayout &&
      !`${question.text}`.endsWith('---') ? true : false;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const hasErrorClass = hasError ? 'is-invalid' : '';
    const options = filteredList.map( (u, index) => {
      return (
        <div className="form-check" key={`${question.id}-user-${u.id}-${index}`}>
          <label>
            <input
              id="task_user"
              name={question.id}
              value={u.id}
              type="checkbox"
              checked={value.indexOf(`${u.id}`) !== -1}
              onChange={this.handleChange}
              disabled={!this.props.edit}
              className={`form-check-input ${hasErrorClass}`}
            /> {u.name}
          </label>
        </div>
      );
    });
    const input = (
      <>
        { userList.length > 10 && (
          <TextInputComponent
            name={question.id}
            onChange={this.filterChanged}
            extraAttrs={{ placeholder: getLocalization('filterUsers') }}
          />
        )}
        <div className="user-select">
          {options}
        </div>
      </>
    );
    return (
      <Form.Group
        as={horizontal ? Row : undefined}
        className={`${!horizontal ? formUtils.getResponsiveView(question) : 'container-fluid'}`}
      >
        <QuestionLabel
          question={question}
          dataPoint={dataPoint}
          formUtils={formUtils}
          horizontal={horizontal}
        >
          {required}
        </QuestionLabel>
        {horizontal ? (
          <Col sm={horizontal ? 10 : undefined}>
            {input}
          </Col>
        ) : input}
        {hasError && errorText && (
          <div className="invalid-feedback">{errorText}</div>
        )}
      </Form.Group>
    );
  }
}
