import * as React from 'react';
import { DataPoint } from 'Interfaces/DataPoint';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { getLocalization } from 'global/global';
import { QueryFiltersInterface } from 'reducers/filtersMenuReducer';
import { LooseObject } from 'Interfaces/LooseObject';
import FormUtils from '../SingleInstance/utils/FormUtils';
import TableContainer from './TableContainer';

interface Props {
  formUtils: FormUtils;
  forms: FormInterface[];
  setTableQuestionValues: (dp: DataPoint) => void;
  fetchPOI: (
    formId: string, signal: AbortSignal, rowId?: number,
    filter?: QueryFiltersInterface[], query?: LooseObject, fields?: string
  ) => Promise<Response>;
}

export const ExpandRowComponent = (props: Props) => {
  const { formUtils, forms, fetchPOI, setTableQuestionValues } = props;

  const getTables = (row: DataPoint) => {
    return (
      <TableQuestionTables
        formUtils={formUtils}
        forms={forms}
        fetchPOI={fetchPOI}
        dataPoint={row}
        setTableQuestionValues={setTableQuestionValues}
      />
    );
  };

  return {
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    renderer: (row) => {
      return getTables(row);
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return (<b>{`${getLocalization('tables')}`} -</b>);
      }
      return (<b>{`${getLocalization('tables')}`} +</b>);
    },
  };
};

interface TableQuestionTablesProps extends Props {
  dataPoint: DataPoint;
}

export const TableQuestionTables = (props: TableQuestionTablesProps) => {
  const { formUtils, forms } = props;
  const [tableData, setData] = React.useState<DataPoint | null>(null);
  const [dataPoint] = React.useState<DataPoint>(props.dataPoint);

  React.useEffect(() => {
    const abortController = new AbortController();
    if (!tableData && dataPoint) {
      const loadData = async () => {
        const tables =  formUtils.getTableQuestionIds();
        const fields = ['id'].concat(tables);
        const rowId = dataPoint['row_id'] || dataPoint['_id'];
        const response = await props.fetchPOI(
          formUtils.getModel().ref, abortController.signal, rowId, undefined, undefined, fields.join(',')
        );
        response.json().then(json => {
          console.log(json);
          if (json.length > 0) {
            setData(json[0]);
            props.setTableQuestionValues(json[0]);
          }
        }).catch((e) => {
          console.error(e);
        });
      };
      const data = {};
      tables.forEach( t => {
        if (dataPoint[t]) {
          data[t] = dataPoint[t];
        }
      });
      if (Object.keys(data).length > 0) {
        setData(data);
      } else {
        void loadData();
      }
    }
    return () => {
      abortController.abort();
    };
  }, [dataPoint]);

  const tables =  formUtils.getTableQuestionIds();

  if (!tableData) {
    return (
      <div>
        <label>
          {getLocalization('loading')}
        </label>
      </div>
    );
  }

  return (
    <>
      {
        tables.map((tableQuestionId: string) => {
          const data = tableData[tableQuestionId];
          if (data && data.length > 0) {
            const qn = formUtils.getQuestion(tableQuestionId);
            const tableForm = forms.find(f => f.ref === qn.table?.columns.relation[0].ref);
            if (tableForm) {
              return (
                <TableContainer
                  model={tableForm}
                  data={data}
                  tableQuestion={qn}
                  key={`table-expand-row-${tableQuestionId}`}
                />
              );
            }
          }
          return null;
        })
      }
    </>
  );
};
