import "./Style.scss";
import * as React from 'react';
import { StateInterface } from "Interfaces/StateInterface";
import { getMySchedules, getSchedules, updateSchedule } from "actions/scheduleActions";
import { connect } from "react-redux";
import { MyScheduleInterface, ScheduleInterface } from 'Interfaces/ScheduleInterface';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { formsForFiltersSelector } from 'reducers/formsReducer';
import { getLocalization } from 'global/global';
import { DataPoint } from 'Interfaces/DataPoint';
import { SingleInstance } from 'Interfaces/ModuleSelectionInterface';
import { setSingleInstance } from 'actions/moduleSelectionActions';
import ReactGA from 'react-ga';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { Col, Container, FormCheck, Row } from "react-bootstrap";
import { StatusEditor } from "./StatusEditor";

interface StateProps {
  mySchedule: MyScheduleInterface;
  forms: FormInterface[];
  clientPersist: ClientPersistInterface;
}

interface ActionProps {
  getMySchedules: (signal: AbortSignal) => void;
  setSingleInstance: (singleInstance: SingleInstance) => void;
  getSchedules: () => void;
  updateSchedule: (schedule: ScheduleInterface) => void;
}

let abortController: AbortController | null = new AbortController();

const MyScheduleView = (props: StateProps & ActionProps) => {
  const [init, setInit] = React.useState(false);
  const [formIcons, setFormIcons] = React.useState<JSONInterface>({});
  const [edit, setEdit] = React.useState<number | null>(null);
  const [showDone, setShowDone] = React.useState(false);

  React.useEffect(() => {
    if (!init) {
      props.getSchedules();
      setInit(true);
    }
  });

  React.useEffect(() => {
    if (abortController === null) {
      abortController = new AbortController();
    }
    props.getMySchedules(abortController.signal);
    /* return () => {
      abortController?.abort();
    };*/
  }, [props.mySchedule.schedules]);

  React.useEffect(() => {
    const icons = {};
    props.forms.forEach(f => {
      icons[f.ref] = f.smallIconUrl;
    });
    setFormIcons(icons);
  }, [props.forms]);

  React.useEffect(() => {
    const icons = {};
    props.forms.forEach(f => {
      icons[f.ref] = f.smallIconUrl;
    });
    setFormIcons(icons);
  }, [props.forms]);

  const data = React.useMemo(() => {
    // const result = props.mySchedule.schedules; //
    let result: ScheduleInterface[] = props.mySchedule.schedules;

    const  d =  Object.values(props.mySchedule.data);
    let scheduledData: DataPoint[] = [];
    for (const temp of d) {
      scheduledData = scheduledData.concat(temp);
    }

    result = result.map((sc) => {
      const dp = scheduledData.find(dp => dp.id === sc.answerId);
      if (dp) {
        return {
          ...sc,
          Name: dp.Name,
        };
      }
      return sc;
    });
    result.sort((a, b) => {
      const scheduleDate1 = a.date;
      const scheduleDate2 = b.date;
      if (scheduleDate1 > scheduleDate2) {
        return 1;
      } else if (scheduleDate1 < scheduleDate2) {
        return -1;
      }
      return 0;
    });
    /* result.sort((a, b) => {
      const aStatus = a.status;
      const bStatus = b.status;
      if (aStatus !== bStatus && (aStatus === 'Done' || bStatus === 'Done')) {
        if (aStatus === 'Done') {
          return 1;
        } else if (bStatus === 'Done') {
          return -1;
        }
      }
      return 0;
    });*/
    return result;
  }, [props.mySchedule.data]);

  const showSingleInstance = (schedule: ScheduleInterface) => {
    const form = props.forms.find(f => f.ref === schedule.formId);
    if (form) {
      ReactGA.event({
        category: 'single-instance',
        action: `open-my-schedule`,
        label: `${props.clientPersist.instance}`
      });
      const dataPoint = props.mySchedule.data[schedule.formId].find(d => d.id === schedule.answerId);
      props.setSingleInstance({
        model: form,
        dataPoint: {...dataPoint, formId: form.ref },
        newAnswer: false
      });
    }
  };

  const getDateStyle = (schedule: ScheduleInterface) => {
    if (schedule.status === 'Ongoing') {
      return 'ongoing-date';
    } else if (schedule.status === 'Done') {
      return 'done-date';
    } else if (schedule['invalidDate']) {
      return 'invalid-date';
    }
    return '';
  };

  return (
    <>
      <Row>
        <Container fluid>
          <Col>
            <FormCheck
              id={`show-done-schedules`}
              type={"checkbox"}
              checked={showDone}
              label={getLocalization('showDone')}
              onChange={(e) => setShowDone(e.target.checked)}
            />
          </Col>
        </Container>
      </Row>
      <table className="table table-fixed">
        <thead>
          <tr>
            <th scope="col-4">
              {getLocalization('name')}
              <span className="badge badge-light">({
                data.length
              })</span>
            </th>
            <th scope="col">{getLocalization('scheduleDate')} </th>
            <th scope="col">{getLocalization('scheduleStatus')} </th>
          </tr>
        </thead>
        <tbody>
          {data.map(dp => {
            if (!showDone && dp.status === 'Done') {
              return null;
            }
            return (
              <tr key={`my-schedule-${dp.id}`}>
                <td scope="col-4">
                  <span className="feed-icon" title={''}>
                    <img src={formIcons[dp['formId'] || '']} alt="icon" />
                  </span>
                  <a onClick={() => showSingleInstance(dp)} href={`#`}>
                    {dp['Name']}
                  </a>
                </td>
                <td scope="col" className={getDateStyle(dp)}>{dp['date']}</td>
                <td scope="col">
                  {edit === dp.id ? (
                    <StatusEditor
                      scheduleItem={dp}
                      handleChange={(si) => {
                        props.updateSchedule(si);
                        setEdit(null);
                      }}
                      onCancel={() => setEdit(null)}
                    />
                  ) : (
                    <>
                      {dp['status']}
                      <span onClick={() => setEdit(dp.id)} className="edit-status">
                        <i className="fa fa-pencil" aria-hidden="true" />
                      </span>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state: StateInterface) => {
  return {
    mySchedule: state.mySchedule,
    forms: formsForFiltersSelector(state),
    clientPersist: state.clientPersist
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMySchedules: (signal: AbortSignal) => {
      return dispatch(getMySchedules(signal));
    },
    setSingleInstance: (singleInstance: SingleInstance) => dispatch(setSingleInstance(singleInstance)),
    getSchedules: () => dispatch(getSchedules()),
    updateSchedule: (schedule: ScheduleInterface) => dispatch(updateSchedule(schedule))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyScheduleView);
