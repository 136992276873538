import { Cookies } from 'react-cookie';
import { StateInterface } from 'Interfaces/StateInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { sortObjects } from 'utils/utils';
import { ChangePasswordInterface } from '../Interfaces/ChangePasswordInterface';
import * as userApi from '../api/userApi';
import { getLocalization, globalWindow } from '../global/global';
import { UpgradeAccountInterface } from '../Interfaces/UpgradeAccountInterface';
import { getExpiryDate } from '../utils/cookies';
import { LoginRequestInterface } from '../Interfaces/LoginInterface';
import { ClientPersistInterface, languagesEnum } from '../Interfaces/ClientPersistInterface';
import { User } from '../Interfaces/User';
import { ajaxCallError, beginAjaxCall, endAjaxCall } from './';
import { actionCreator, showAlert, showConfirm, updateProgress } from './';
import { getErrorMessage, setCookieDataForLoginSuccess } from './loginUtils';
import { USER, USERS } from './actionTypes';
import { resetClientPersist, updateClientPersist } from './clientPersistActions';
import { getReports } from './reports';
import { getCharts } from './chartsActions';
import { getGroupDiagrams } from './diagrams';
import { loadForms } from './formsActions';
import { loadLocations } from './locationsActions';

export const logoutSuccess = actionCreator(USER.LOGOUT);

export const logout = (cookies?: Cookies) => {
  return async (dispatch, getState) => {
    const { clientPersist } = getState();
    dispatch(updateProgress({ visible: true, message: getLocalization('loggingout') }));
    const response = await userApi.logout(clientPersist);
    dispatch(updateProgress({ visible: false }));
    if (response) {
      if (cookies) {
        cookies.set('manualLogout', true, { expires: getExpiryDate(7) });
      }
      if (globalWindow.gapi && globalWindow.gapi.auth2) {
        const auth2 = globalWindow.gapi.auth2.getAuthInstance();
        if (auth2) {
          if (auth2.isSignedIn.get()) {
            auth2.signOut().then(() => {
              // console.log('User signed out.');
            });
          }
        }
      }
      // globalWindow.googleSignOut();
    }
    dispatch(logoutSuccess());
  };
};

export const updatePasswordOrEmailSuccess = actionCreator<{ success: boolean }>(USER.UPDATE_PASSWORD_OR_EMAIL);

export const changePasswordOrEmail = (payload: ChangePasswordInterface) => {
  return async (dispatch) => {
    dispatch(beginAjaxCall());
    try {
      const response = await userApi.changePasswordOrEmail(payload);
      if (response) {
        dispatch(updatePasswordOrEmailSuccess({ success: response }));
        return response;
      }
    } catch (err) {
      dispatch(ajaxCallError());
      throw err;
    } finally {
      dispatch(endAjaxCall());
    }
    return false;
  };
};

export const upgradeAccountSuccess = actionCreator<{ success: boolean }>(USER.UPGRADE_ACCOUNT);

export const upgradeAccount = (payload: UpgradeAccountInterface) => {
  return async (dispatch) => {
    dispatch(beginAjaxCall());
    try {
      const response = await userApi.upgradeAccount(payload);
      if (response) {
        dispatch(upgradeAccountSuccess({ success: response }));
        dispatch(logout());
      }
    } catch (err) {
      dispatch(ajaxCallError());
      throw err;
    } finally {
      dispatch(endAjaxCall());
    }
  };
};

export const loginUserSuccess = actionCreator<ClientPersistInterface>(USER.LOGIN_SUCCESS);

export const doLogin = (data: LoginRequestInterface, rememberme: boolean, cookies?: Cookies) => {
  return async (dispatch, getState) => {
    dispatch(updateProgress({ visible: true, message: getLocalization('loggingin') }));
    // set case to lowercase.
    // data['userName'] = data['userName'].toLowerCase();
    data['instance'] = data['instance'].toLowerCase();

    const response = await userApi.doLogin(data, getState().clientPersist);

    dispatch(updateProgress({ visible: false }));
    if (response.status === 200) {
      let responseData = response.data as ClientPersistInterface;
      responseData = setCookieDataForLoginSuccess(responseData, rememberme, false, cookies);
      if (rememberme && cookies) {
        cookies.set('loga', data.userName, { expires: getExpiryDate(7) });
        cookies.set('loga1', data.password, { expires: getExpiryDate(7) });
      }
      globalWindow.userName = responseData.userName || globalWindow.userName;
      globalWindow.pwrd = responseData.key;
      dispatch(loginUserSuccess(responseData));
      if (response.questionnaireList) {
        dispatch(
          loadForms(
            response.questionnaireList.map((f) => {
              f.ref = f.id;
              return f;
            }),
          ),
        );
      }
      if (response.locationsList) {
        dispatch(loadLocations(response.locationsList.sort((a, b) => sortObjects(a, b, 'title'))));
      }
    } else {
      const responseData = response.error as any;
      if (responseData.errorCode) {
        if (responseData.errorCode === '1038') {
          globalWindow.open(
            `https://${responseData.account}.poimapper.com/json/auth/samlrequest/x/portal`,
            '_parent',
          );
        } else {
          dispatch(
            showAlert({
              visible: true,
              message: getErrorMessage(responseData.errorCode, responseData.errorMessage),
            }),
          );
        }
      }
    }
  };
};

export const doGoogleLogin = (token: string, cookies?: Cookies) => {
  return async (dispatch, getState) => {
    dispatch(updateProgress({ visible: true, message: getLocalization('loggingin') }));
    const response = await userApi.doGoogleLogin(token, getState().clientPersist);

    dispatch(updateProgress({ visible: false }));
    if (response.status === 200) {
      let responseData = response.data as ClientPersistInterface;
      responseData = setCookieDataForLoginSuccess(responseData, false, true, cookies);
      cookies?.set('manualLogout', false, { expires: getExpiryDate(7) });
      dispatch(loginUserSuccess(responseData));
      globalWindow.open(`/index.jsp?lang=${responseData.lang}`, '_parent');
    } else {
      const responseData = response.error as any;
      if (responseData.errorCode) {
        dispatch(
          showAlert({
            visible: true,
            message: getErrorMessage(responseData.errorCode, responseData.errorMessage),
          }),
        );
      }
    }
  };
};

export const resetPassword = (email, instance) => {
  return (dispatch) => {
    dispatch(updateProgress({ visible: true, message: getLocalization('loading') }));
    return fetch('/json/app/reset/resetpassword/' + email + '/' + instance, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(updateProgress({ visible: false }));
        dispatch(showAlert({ visible: true, message: json.message }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateProgress({ visible: false }));
      });
  };
};

export const geoLocationSensor = (loadedLanguage: languagesEnum, cookies: Cookies) => {
  return async (dispatch) => {
    const response = await userApi.getGeoLocationSensor();
    if (response.status === 200) {
      const data = response.data;
      cookies.set('geo_lat', data.latitude, { expires: getExpiryDate(7) });
      cookies.set('geo_lon', data.longitude, { expires: getExpiryDate(7) });
      const countriesMap = [
        { lang: languagesEnum.fi, name: 'Finnish' },
        { lang: languagesEnum.fr, name: 'French' },
        { lang: languagesEnum.de, name: 'German' },
        { lang: languagesEnum.es, name: 'Spanish' },
      ];
      const currentCountry = countriesMap.find((country) => `${country.lang}` === data.country.toLowerCase());
      if (`${loadedLanguage}` !== data.country.toLowerCase() && !!currentCountry) {
        const confirm = {
          message: `We detect that you are in ${data.countryName}, change your language to ${currentCountry.name}?`,
          visible: true,
          onConfirm: () => {
            cookies.set('geo_country', currentCountry.lang, { expires: getExpiryDate(7) });
            setTimeout(() => {
              globalWindow.open(`/login.jsp?lang=${currentCountry.lang}`, '_parent');
            }, 100);
          },
        };
        dispatch(showConfirm(confirm));
      } else {
        cookies.set('geo_country', loadedLanguage, { expires: getExpiryDate(7) });
      }
    }
  };
};

export const setUsers = actionCreator<User[]>(USERS.SET_USERS);

export const checkSAMLLogin = (email: string, callBack) => {
  return (dispatch) => {
    dispatch(updateProgress({ visible: true, message: getLocalization('loggingin') }));
    const samlResponse = userApi.checkSAMLLogin(email);
    samlResponse
      .then((response) => response.json())
      .then((json) => {
        dispatch(updateProgress({ visible: false }));
        if (json.saml) {
          if (json.provider === 'saml') {
            if (globalWindow.location.hostname.indexOf('portal.poimapper.com') !== -1) {
              // const subdomain = account === 'test' ? 'samltest' : account;
              globalWindow.open(
                `https://portal.poimapper.com/json/auth/samlrequest/${email}/portal`,
                '_parent',
              );
            } else {
              globalWindow.open(`/json/auth/samlrequest/${email}/portal`, '_parent');
            }
          } else if (`${json.provider}`.toLowerCase() === 'aad') {
            globalWindow.open(
              `/json/auth/aad?callback=${globalWindow.location.origin}/index.jsp`,
              '_parent',
            );
          }
        } else {
          if (json.errorCode) {
            dispatch(showAlert({ visible: true, message: json.errorMessage }));
          } else {
            callBack();
          }
        }
      })
      .catch((error) => {
        callBack(error);
        dispatch(updateProgress({ visible: false }));
        if (error.errorCode) {
          dispatch(showAlert({ visible: true, message: error.errorMessage }));
        }
        console.log(error);
      });
  };
};

export const setClientPersist = (json) => {
  return (dispatch, getState) => {
    let responseData = userApi.reformatResponseDataForLoginSuccess(json, false, getState().clientPersist);
    const cookies = new Cookies();
    responseData = setCookieDataForLoginSuccess(responseData, false, false, cookies);
    dispatch(loginUserSuccess(responseData));
    // We are updating from outside the app, seems it won't update the global state so we call the index
    // page directly.
    globalWindow.open(`/index.jsp?lang=${responseData.lang}`, '_parent');
  };
};

// export const updateClientPersist = actionCreator<ClientPersistInterface>(CLIENT_PERSIST.UPDATE);

export const initClientPersist = () => {
  return (dispatch, getState) => {
    dispatch(updateProgress({ visible: true, message: getLocalization('loading') }));
    const initResponse = userApi.doInit();
    initResponse
      .then((response) => response.json())
      .then((json) => {
        if (json.errorCode) {
          const { lang } = getState().clientPersist;
          globalWindow.open(`/login.jsp?lang=${lang}`, '_parent');
          return;
        }
        let responseData = userApi.reformatResponseDataForLoginSuccess(json, false, getState().clientPersist);
        const cookies = new Cookies();
        responseData = setCookieDataForLoginSuccess(responseData, false, false, cookies);
        dispatch(updateProgress({ visible: false }));
        dispatch(updateClientPersist(responseData));
        dispatch(initUserData(responseData));
      })
      .catch((error) => {
        dispatch(updateProgress({ visible: false }));
        console.log(error);
        const { lang } = getState().clientPersist;
        globalWindow.open(`/login.jsp?lang=${lang}`, '_parent');
      });
  };
};

export const loadTaskUsers = () => {
  return (dispatch, getState) => {
    const loadTaskUsersResponse = userApi.doLoadTaskUsers(getState().clientPersist.groupId);
    loadTaskUsersResponse
      .then((response) => response.json())
      .then((json) => {
        if (json.userDTOList) {
          dispatch(setTaskUsers(json.userDTOList));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const setTaskUsers = (users) => ({ type: USERS.SET_TASK_USERS, users });

export const initUserData = (clientPersist?: ClientPersistInterface) => {
  return (dispatch) => {
    dispatch(loadTaskUsers());
    dispatch(getReports());
    dispatch(getCharts());
    dispatch(loadUsers(clientPersist));
    dispatch(getGroupDiagrams());
  };
};

export const loadUsers = (clientPersist?: ClientPersistInterface) => {
  return (dispatch, getState) => {
    const cp = clientPersist || getState().clientPersist;
    const loadTaskUsersResponse = userApi.doLoadUsers(cp.groupId, cp.db);
    loadTaskUsersResponse
      .then((response) => response.json())
      .then((json) => {
        if (json.userDTOList) {
          dispatch(setUsers(json.userDTOList));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const createPassword = (account: string, otp: string, password: string) => {
  return (dispatch, getState) => {
    dispatch(updateProgress({ visible: true, message: getLocalization('creatingPassword') }));
    const createPasswordPromise = userApi.doCreatePassword(otp, account, password);
    createPasswordPromise
      .then((response) => response.json())
      .then((json) => {
        dispatch(updateProgress({ visible: false }));
        if (json.errorCode) {
          dispatch(showAlert({ visible: true, message: json.errorMessage }));
        } else {
          const data = userApi.reformatResponseDataForLoginSuccess(json, false, getState().clientPersist);
          dispatch(loginUserSuccess(data));
          globalWindow.open(`/index.jsp?lang=${data.lang}`, '_parent');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const refreshToken = (userName: string, account: string, token: string) => {
  return (dispatch, getState) => {
    dispatch(updateProgress({ visible: true, message: getLocalization('refreshingSession') }));
    const refreshTokenPromise = userApi.doRefreshToken(userName, account, token);
    refreshTokenPromise
      .then((response) => response.json())
      .then((json) => {
        dispatch(updateProgress({ visible: false }));
        if (!json.errorCode) {
          const data = userApi.reformatResponseDataForLoginSuccess(json, false, getState().clientPersist);
          globalWindow.pwrd = json.key;
          dispatch(loginUserSuccess(data));
          dispatch(showAlert({ message: getLocalization('sessionRefreshed'), visible: true }));
        } else {
          dispatch(showAlert({ message: getLocalization('sessionExpired'), visible: true }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const saveSetting = (name: string, value: string) => {
  return (dispatch, getState: () => StateInterface) => {
    const { clientPersist } = getState();
    if (clientPersist.user_id) {
      void userApi
        .doSaveSetting(name, value, Number(clientPersist.user_id))
        .then((res) => res.json())
        .then((json) => {
          if (json.status && json.status === 'OK') {
            const { clientPersist } = getState();
            dispatch(updateClientPersist({ ...clientPersist, [name]: value }));
          }
        });
    }
  };
};

export const verifyTwoFACode = (code: string) => {
  return (dispatch, getState: () => StateInterface) => {
    const { clientPersist } = getState();
    if (clientPersist.user_id) {
      dispatch(updateProgress({ visible: true, message: getLocalization('loading') }));
      void userApi
        .doVerifyTwoFACode(Number(clientPersist.user_id), code)
        .then((res) => res.json())
        .then((json) => {
          dispatch(updateProgress({ visible: false }));
          if (json.status && json.status === 'OK') {
            const { clientPersist } = getState();
            dispatch(updateClientPersist({ ...clientPersist, checkTwoFactorAuthCode: false }));
          } else {
            if (json.errorMessage) {
              if (json.errorCode === '1036') {
                dispatch(resetClientPersist());
              }
              dispatch(
                showAlert({
                  visible: true,
                  message: getErrorMessage(json.errorCode, json.errorMessage),
                }),
              );
            }
          }
        });
    }
  };
};

export const setupEmail2FA = (email: string) => (): Promise<JSONInterface> => {
  return new Promise((resolve, reject) => {
    const req = userApi.doSetupEmail2FA(email);
    req.then(res => res.json().then(json => {
      resolve(json);
    })).catch(() => {
      reject({});
    });
  });
};

export const setupAuthenticator2FA = () => (): Promise<JSONInterface> => {
  return new Promise((resolve, reject) => {
    const req = userApi.doSetupAuthenticator2FA();
    req.then(res => res.json().then(json => {
      resolve(json);
    })).catch(() => {
      reject({});
    });
  });
};

export const checkUserChanges = () => {
  return (dispatch, getState) => {
    const userChangesResponse = userApi.doCheckUserChanges();
    userChangesResponse
      .then((response) => response.json())
      .then((json) => {
        if (!json.errorCode) {
          const { clientPersist } = getState();
          if (clientPersist.groupId !== json.groupId || !json.active) {
            dispatch(updateClientPersist({ logout: true }));
          } else {
            dispatch(updateClientPersist({...json}));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const request2FACode = () => (): Promise<JSONInterface> => {
  return new Promise((resolve, reject) => {
    const req = userApi.doRequest2FACode();
    req.then(res => res.json().then(json => {
      resolve(json);
    })).catch(() => {
      reject({});
    });
  });
};
