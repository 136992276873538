import { Action } from 'typescript-fsa';
import { createSelector } from 'reselect';
import { LOCATIONS } from '../actions/actionTypes';
import { LocationInterface, LocationsInterface } from '../Interfaces/LocationInterface';
import { StateInterface } from '../Interfaces/StateInterface';
import ReducerBuilder from './ReducerBuilder';

export const INITIAL_STATE: LocationsInterface = {
  loaded: false,
  collection: [],
};

export type PayloadType = LocationInterface[];

const loadLocations = (state: LocationsInterface, { payload }): LocationsInterface => {
  return {
    loaded: true,
    collection: payload,
  };
};

const addNewLocations = (state: LocationsInterface, { payload }): LocationsInterface => {
  return {
    loaded: true,
    collection: state.collection.concat(payload),
  };
};

export default new ReducerBuilder<LocationsInterface, Action<PayloadType>>()
  .setInitialState(INITIAL_STATE)
  .addReducer(LOCATIONS.LOAD, loadLocations)
  .addReducer(LOCATIONS.ADD_NEW, addNewLocations)
  .build();

// Selectors
export const locationsSelector = (state: StateInterface): LocationsInterface => state.locations;

export const rootLocationsSelector = createSelector<StateInterface, LocationsInterface, LocationInterface[]>(
  locationsSelector,
  (locations) => locations.collection.filter((location) => location.parent === '-1'),
);
