import { PaymentPlanInfoInterface } from 'Interfaces/PaymentPlanInterface';
import { AnyAction } from 'redux';
import { PAYMENTS } from '../actions/actionTypes';

const INITIAL_STATE: PaymentPlanInfoInterface = {
  paymentPlanInfo: null,
  card: {},
};

const paymentPlanInfo = (state = INITIAL_STATE, action: AnyAction): PaymentPlanInfoInterface => {
  switch (action.type) {
    case PAYMENTS.LOADED_PLAN_INFO:
      if (action.paymentPlanInfo) {
        return {
          paymentPlanInfo: action.paymentPlanInfo,
          card: action.card,
        };
      }
      return state;
    case PAYMENTS.DELETE_PAYMENT_METHOD:
      if (action.token) {
        const cards = {...state.card};
        const keys = Object.keys(cards);
        let deleteKey = '';
        for (const key of keys) {
          if (cards[key].token === action.token) {
            deleteKey = key;
          }
        }
        if (deleteKey) {
          delete cards[deleteKey];
          return {
            ...state,
            card: cards
          };
        }
        return state;
      }
      return state;
    default:
      return state;
  }
};

export default paymentPlanInfo;
