import * as groupApi from '../api/groupApi';
import { GROUPS } from './actionTypes';


export const getGroups = () => {
  return (dispatch, getState) => {
    groupApi.doGetGroups(dispatch, getState)
      .then(res => res.json()).then(json => {
        if (!json.errorCode) {
          dispatch(setGroups(json));
        }
      }).catch(e => {
        console.log(e);
      });
  };
};

export const setGroups = (payload) => ({
  type: GROUPS.SET, payload
});
