import './Style.scss';
import * as React from 'react';

import { getLocalization, globalWindow } from 'global/global';
import { Alert, Button, Form } from 'react-bootstrap';
import country from 'country-list-js';
import { ToastContainer, toast } from 'react-toastify';
import { getRequestConfig } from 'api';
import { getQueryParams, validEmail, validatePassword } from 'utils/utils';
import { PasswordInput } from 'views/components/PasswordInput';

export default function Register() {
  const { accountType } = getQueryParams(globalWindow.location.search);
  const [form, setForm] = React.useState({
    registration_from: 'portal',
    agreepolicy: false,
    demo_data: 0,
    accountName: '',
    accountType
  });
  const [countryList] = React.useState(country.names().sort());
  const [validate, setValidate] = React.useState(false);

  const onChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value });
  };

  const accountNameChangeHandler = (value) => {
    if (!value.match(/[^a-zA-Z]/)) {
      setForm({...form, accountName: value });
    }
  };

  const valid = (value) => {
    if (value) {
      return true;
    }
    return false;
  };

  const register = () => {
    if (
      !valid(form['first_name']) ||
      !valid(form['last_name']) ||
      !valid(form['password']) ||
      !valid(form['reg_mail']) ||
      !valid(form['phone_number']) ||
      !valid(form['country']) ||
      !valid(form['organization']) ||
      (accountType && !valid(form['accountName']))
    ) {
      setValidate(true);
      return;
    }

    if (
      !validEmail(form['reg_mail']) ||
      form['password'] !== form['confirm_pass'] ||
      !validatePassword(form['password'])
    ) {
      setValidate(true);
      return;
    }
    const toastId = Date.now() + Math.floor(Math.random() * 100);
    toast('Registering user...', {
      toastId: toastId, type: toast.TYPE.INFO
    });
    const headers = getRequestConfig();
    headers['body'] = JSON.stringify({...form, instance: form.accountName, acc_package: form.accountType});
    headers['method'] = 'POST';
    fetch('/json/app/register', headers).then(res => res.json()).then(json => {
      if (json.status === 'OK') {
        toast.update(toastId, {
          type: toast.TYPE.SUCCESS,
          render: json.message
        });
        globalWindow.open('//' + globalWindow.location.host + '/thankyou.jsp', '_self');
      } else {
        toast.update(toastId, {
          type: toast.TYPE.ERROR,
          render: json.errorMessage || json.message
        });
      }
    }).catch(() => {
      toast.update(toastId, {
        type: toast.TYPE.ERROR,
        render: 'Error registering user.'
      });
    });
  };

  const countries = React.useMemo(() => {
    return countryList.map((name) => {
      return (
        <option value={name} key={`country-${name}`}>{name}</option>
      );
    });
  }, [countryList]);

  return (
    <>
      <div className="item_header">
        {getLocalization('register')}
      </div>
      <p className="item_sub_header">{getLocalization('fillfields')}</p>
      <Form.Group>
        <Form.Label>{getLocalization('firstName')}</Form.Label>
        <Form.Control
          type='text'
          name="first_name"
          value={form['first_name']}
          onChange={onChange}
          isInvalid={!form['first_name'] && validate}
          size="sm"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('lastName')}</Form.Label>
        <Form.Control
          type='text'
          name="last_name"
          value={form['last_name']}
          onChange={onChange}
          isInvalid={!form['last_name'] && validate}
          size="sm"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('org')}</Form.Label>
        <Form.Control
          type='text'
          name="organization"
          value={form['organization']}
          onChange={onChange}
          isInvalid={!form['organization'] && validate}
          size="sm"
        />
      </Form.Group>
      {accountType === 'light' && (
        <Form.Group>
          <Form.Label>{getLocalization('accountName')}</Form.Label>
          <Form.Control
            type='text'
            name="accountName"
            value={form['accountName']}
            onChange={(e) => accountNameChangeHandler(e.target.value)}
            isInvalid={!form['accountName'] && validate}
            size="sm"
          />
        </Form.Group>
      )}
      <Form.Group>
        <Form.Label>{getLocalization('emailaddr')}</Form.Label>
        <Form.Control
          type='email'
          name="reg_mail"
          value={form['reg_mail']}
          onChange={onChange}
          isInvalid={(!form['reg_mail'] || !validEmail(form['reg_mail'])) && validate}
          size="sm"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('phoneNum')}</Form.Label>
        <Form.Control
          type='text'
          name="phone_number"
          value={form['phone_number']}
          onChange={onChange}
          isInvalid={!form['phone_number'] && validate}
          size="sm"
        />
      </Form.Group>
      <Form.Group>
        <Alert variant={'info'}>
          <p>
            {getLocalization('passwordRule1')}<br />
            {getLocalization('passwordRule2')}
          </p>
        </Alert>
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('password')}</Form.Label>
        <PasswordInput
          passwordError={validate &&
            (!form['password'] || !validatePassword(form['password']) || form['password'] !== form['confirm_pass'])
          }
          value={form['password']}
          onChange={onChange}
          name="password"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('confirmPassword')}</Form.Label>
        <PasswordInput
          passwordError={validate && (
            !form['confirm_pass'] ||
            !validatePassword(form['confirm_pass']) ||
            form['password'] !== form['confirm_pass']
          )}
          value={form['confirm_pass']}
          onChange={onChange}
          name="confirm_pass"
          placeholder={getLocalization('confirmPassword')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('country')}</Form.Label>
        <Form.Control
          as="select"
          name="country"
          value={form['country']}
          onChange={onChange}
          isInvalid={!form['country'] && validate}
          size="sm"
        >
          <option value={'none'}>{getLocalization('selectCountry')}</option>
          {countries}
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Check
          id="agree-policy-check"
          type="checkbox"
          name="agreepolicy"
          checked={form['agreepolicy']}
          onChange={(e) => setForm({...form, ['agreepolicy']: e.target.checked })}
          label={
            <>
              {getLocalization('agree')}
              <a href="termofservice.jsp" target="_blank">&nbsp;{getLocalization("terms")}</a>
              {getLocalization('and')}
              <a href="privacy.jsp" target="_blank">{getLocalization("policy")}</a>
            </>
          }
        />
      </Form.Group>
      <Button onClick={register} disabled={form['agreepolicy'] === false}>
        {getLocalization('register')}
      </Button>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
    </>
  );
}
