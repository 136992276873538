import * as React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import bind from 'bind-decorator';
import { ProgressInterface } from '../../Interfaces/ProgressInterface';

interface State {
  isOpened: boolean;
}

export default class ProgressComponent extends React.Component<ProgressInterface, State> {

  constructor(props) {
    super(props);
    this.state = { isOpened : props.visible };
  }

  @bind
  private onClose() {
    console.info('not-implemented');
  }

  public static getDerivedStateFromProps(props, state) {
    if (props.visible === true && !state.isOpened) {
      return { isOpened : props.visible };
    } else if (!props.visible) {
      return { isOpened : props.visible };
    }
    return null;
  }

  public render() {
    const messageDiv = this.props.message ? (<p>{this.props.message}</p>) : null;
    return (
      <Modal show={this.state.isOpened} backdrop={'static'} onHide={this.onClose}>
        <Modal.Body>
          <ProgressBar animated now={100} />
          {messageDiv}
        </Modal.Body>
      </Modal>
    );
  }
}
