import { getLocalization, globalWindow } from 'global/global';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { LayersControl, Map } from 'react-leaflet';
import { NODE_TYPE_LH } from 'views/LocationHierarchy/utils/Constants';
import MapboxTileLayer from 'views/Map/layers/MapboxTileLayer';
import MapQuestTileLayer from 'views/Map/layers/MapQuestTileLayer';
import { ILocationTreeNode } from '../Workspace';

interface Props {
  node: ILocationTreeNode;
  updateNode: (attr: ILocationTreeNode) => void;
}

interface MapView {
  center: number[];
  zoom: number;
}

export const MapComponent = (props: Props) => {
  const { node } = props;
  const [mapView, setMapView ] = React.useState<MapView | undefined>();

  const mapLayer = globalWindow.L.mapquest ? (
    <MapQuestTileLayer
      attribution={''}
    />
  ) : (
    <MapboxTileLayer />);

  const map = React.useRef<Map>(null);

  React.useEffect(() => {
    if (node.data && node.data.center) {
      const center = node.data.center.split(',');
      if (center.length === 2) {
        setMapView({
          center: [Number(center[0]), Number(center[1])],
          zoom: node.data.zoomlevel ? Number(node.data.zoomlevel) : 3
        });
        if (map.current) {
          const zoom = node.data.zoomlevel ? Number(node.data.zoomlevel) : 3;
          map.current.leafletElement.setView([Number(center[0]), Number(center[1])], zoom);
        }
        return;
      }
    }
    if (map.current) {
      map.current.leafletElement.setView([0, 0], 3);
    }
  }, [node]);

  const setView = () => {
    if (map.current) {
      const center = map.current.leafletElement.getCenter();
      const zoom = map.current.leafletElement.getZoom();
      const newNode = {...props.node};
      newNode.data['center'] = `${center.lat},${center.lng}`;
      newNode.data['zoomlevel'] = zoom;
      props.updateNode(newNode);
    }
  };

  return (
    <>
      <Button size={'sm'} onClick={setView}>
        {node.nodeType === NODE_TYPE_LH ? getLocalization('setHierarchyView') : getLocalization('setMapView')}
      </Button>
      <Map
        className="lh-map"
        center={mapView ? mapView.center : [0, 0]}
        zoom={mapView ? mapView.zoom : 3}
        ref={map}
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Map">
            {mapLayer}
          </LayersControl.BaseLayer>
        </LayersControl>
      </Map>
    </>
  );
};
