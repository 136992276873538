import './Style.scss';
import 'rc-tree/assets/index.css';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import Tree, { TreeNode } from 'rc-tree';
import { JSONInterface, JSONValue } from 'Interfaces/JsonInterface';
import { getJSON, toXML } from 'views/LocationHierarchy/utils';
import { saveAs } from 'file-saver';
import TextInputComponent from 'views/SingleInstance/elements/TextInputComponent';
import { getLocalization } from 'global/global';
import { Actions } from '../Actions';


export interface ILocationTreeNode {
  key: string;
  value: string;
  title: string | JSX.Element;
  level: number;
  parent?: string;
  searchValue?: string;
  data?: JSONValue;
  children?: ILocationTreeNode[];
  nodeType: 'LH' | 'LOC';
  deleted?: boolean;
  newNode?: boolean;
}

interface Props {
  tree: ILocationTreeNode[];
  onSelect: (node: ILocationTreeNode) => void;
  saveToDatabase: (hierarchies) => void;
  newHierarchy: () => void;
  openFile: (xml: string) => void;
  addHierarchyFromJSON: (json: JSONInterface) => void;
  clearWorkspace: () => void;
}

export const Workspace = (props: Props) => {
  const className = 'LHWorkSpace';

  const [search, setSearch] = React.useState('');
  const [expandedKeys, setExpandedKeys] = React.useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = React.useState(false);

  const filterKeys = React.useRef<string[]>([]);
  const [stateFilterKeys, setStateFilterKeys] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (props.tree.length === 0) {
      setExpandedKeys([]);
      filterKeys.current = [];
    }
  }, [props.tree]);

  const onSave = () => {
    const hierarchies: JSONInterface[] = [];
    for (const tree of props.tree) {
      hierarchies.push(getJSON(tree));
    }
    props.saveToDatabase({ LocationHierarchy: hierarchies});
  };

  const saveLocal = () => {
    const xml = toXML(props.tree[0]);
    const blob = new Blob([xml], {type: 'text/plain;charset=utf-8'});
    saveAs(blob, 'LocationHierarchyXML.txt');
  };

  const onFilterTreeNode = (node) => {
    if (search === '') {
      return false;
    }
    if (filterKeys.current.indexOf(node.key) > -1) {
      // filterKeys.current.push(node.props.key);
      return true;
    }
    return false;
  };

  const onSearch = (value: string) => {
    if (!value) {
      setAutoExpandParent(false);
    } else {
      setAutoExpandParent(true);
    }
    filterKeys.current = [];
    setStateFilterKeys([]);
    setSearch(value);
  };

  const handleOnExpand = (expandedKeys1) => {
    filterKeys.current = [];
    setExpandedKeys(expandedKeys1);
    setAutoExpandParent(false);
    /* if (expanded) {
      setExpandedKeys([...expandedKeys, node.key])
    } else {
      setExpandedKeys(expandedKeys.filter(key => key !== node.key));
    }*/
  };

  const onSelect = (selectedKeys, e) => {
    props.onSelect(e.node.item);
  };

  const loop = (data, top) => {
    const tree = data.map(item => {
      if (search && item.value && item.value.toLowerCase().search(search.toLowerCase()) > -1) {
        filterKeys.current.push(item.key);
      }
      if (item.children) {
        return (
          <TreeNode key={item.key} title={item.title} item={item}>
            {loop(item.children, false)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} title={item.title} item={item} />;
    });
    if (top) {
      const newFilter = new Set(filterKeys.current);
      const newFilterArray = Array.from(newFilter);
      const filteredArray = newFilterArray.filter(value => !stateFilterKeys.includes(value));
      if (filteredArray.length > 0) {
        setStateFilterKeys(Array.from(newFilter));
      }
    }
    return tree;
  };

  let expanded = expandedKeys;
  if (search && filterKeys.current.length > 0) {
    expanded = filterKeys.current;
  }

  return (
    <Col md={7}>
      <Actions
        onSave={onSave}
        saveLocal={saveLocal}
        newHierarchy={props.newHierarchy}
        openFile={(xml) => props.openFile(xml)}
        clearWorkSpace={props.clearWorkspace}
        workspaceEmpty={props.tree.length === 0}
        addHierarchyFromJSON={props.addHierarchyFromJSON}
      />
      <div className="tree-workspace">
        <Form.Group>
          <TextInputComponent
            onChange={(v) => onSearch(`${v}`)}
            name={'search'}
            placeholder={getLocalization('searchHierarchy')}
            extraAttrs={{ size: 'sm' }}
          />
        </Form.Group>
        <Tree
          className={`${className}-tree`}
          checkable={false}
          showLine
          showIcon={false}
          onSelect={onSelect}
          filterTreeNode={onFilterTreeNode}
          expandedKeys={expanded}
          autoExpandParent={autoExpandParent}
          onExpand={handleOnExpand}
        >
          {loop(props.tree, true)}
        </Tree>
      </div>
    </Col>
  );
};
