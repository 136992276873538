import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'typescript-fsa';
import { useParams } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { StateInterface } from 'Interfaces/StateInterface';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { SingleInstanceInterface } from 'Interfaces/ModuleSelectionInterface';
import { uniqueFormsSelector } from '../../../reducers/formsReducer';
import { setSingleInstance } from '../../../actions/moduleSelectionActions';
import SingleInstanceContainer from './SingleInstanceContainer';

interface StateProps {
  forms: FormInterface[];
}

interface ActionProps {
  setSingleInstance: (singleInstance) => void;
}

type SingleInstanceProps =  ActionProps & StateProps;

const SingleInstance = (props: SingleInstanceProps) => {
  const {formRef, answerId, rowId, isNewAnswer} = useParams<{
    formRef: FormInterface['ref'];
    answerId: DataPoint['id'];
    rowId: string;
    isNewAnswer: string;
  }>();

  useEffect(() => {
    const form = props.forms.find(f => f.ref = formRef);
    if (form) {
      const singleInstance: SingleInstanceInterface = {
        newAnswer: Boolean(isNewAnswer),
        model: form,
        dataPoint: {
          formId: form.id,
          questionnaire_id: form.ref,
          row_id: Number(rowId) > 0 ? Number(rowId) : undefined,
          id: answerId
        }
      };
      props.setSingleInstance(singleInstance);
    }
  }, [formRef, answerId]);

  return (
    <SingleInstanceContainer />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {

  return {
    forms: uniqueFormsSelector(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    setSingleInstance: (singleInstance) => dispatch(setSingleInstance(singleInstance))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleInstance);

