import { toast } from 'react-toastify';
import { DataPoint } from 'Interfaces/DataPoint';
import { formatDate } from 'utils/utils';
import { SchedulesInterface, ScheduleInterface } from 'Interfaces/ScheduleInterface';
import { formsForFiltersSelector, formsWithScheduleStatusSelector } from 'reducers/formsReducer';
import { FormInterface } from '../Interfaces/Forms/FormsInterface';
import { PayloadType } from '../reducers/scheduleAnswersReducer';
import { getDateFiltersSelector, getLocationHierarchyQuerySelector } from '../reducers/filtersMenuReducer';
import { StateInterface } from '../Interfaces/StateInterface';
import * as ScheduleApi from '../api/schedulesApi';
import { ThunkDispatchAction, actionCreator } from './';
import { SCHEDULE_ACTIONS, SCHEDULE_ANSWERS } from './actionTypes';
import { fetchPOI } from './pois';

export const loadScheduleAnswers = actionCreator<PayloadType>(SCHEDULE_ANSWERS.LOAD);

export const fetchSchedulePOIs = (formRef: FormInterface['ref']) => {
  return async (dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
    const fields = [
      'Name',
      'id',
      'user_id',
      'location1',
      'location2',
      'location3',
      'location4',
      'questionnaire_id',
      'Coordinates',
      'created',
    ];
    const state = getState();
    const locationHierarchyQuery = getLocationHierarchyQuerySelector(state);
    const datesQuery = getDateFiltersSelector(state);
    const response = await (dispatch(fetchPOI(
      formRef,
      undefined,
      undefined,
      datesQuery,
      locationHierarchyQuery,
      fields.join(','),
      undefined,
      ['core=true']
    )));
    void response.json().then((dataPoints) => {
      dispatch(loadScheduleAnswers({ dataPoints, formRef }));
    });
  };
};

export const getSchedules = () => {
  return (dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
    const forms = formsWithScheduleStatusSelector(getState());
    const { clientPersist } = getState();
    forms.forEach((form) => {
      const fetchPromise = ScheduleApi.doLoadSchedule(form.ref, `${clientPersist.user_id}`);
      fetchPromise
        .then((res) => res.json())
        .then((json) => {
          if (!json.errorCode) {
            const newItems = json;
            if (newItems.length > 0) {
              dispatch(
                scheduleLoaded(
                  newItems.map((si) => {
                    return {
                      ...si,
                      invalidDate: si.date < formatDate(new Date()) && si.status !== 'Done',
                    };
                  }),
                ),
              );
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };
};

export const getMySchedules = (signal: AbortSignal) => {
  return (dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
    const questionnaire = formsForFiltersSelector(getState());
    const { schedules } = getState().mySchedule;
    if (schedules.length > 0) {
      const formsThatHaveScheduledStatus: {
        [key: string]: boolean;
      } = questionnaire.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.ref]: cur.hasScheduledStatus,
        };
      }, {});
      const requestParams: { [key: string]: { ids: string[]; hasSchedule: boolean } } = {};
      schedules.forEach((scheduleItem: ScheduleInterface) => {
        if (formsThatHaveScheduledStatus[scheduleItem.formId]) {
          requestParams[scheduleItem.formId] = requestParams[scheduleItem.formId] || {
            ids: [],
            hasSchedule: formsThatHaveScheduledStatus[scheduleItem.formId],
          };
          requestParams[scheduleItem.formId].ids.push(scheduleItem.answerId);
        }
      });

      const fields = ['Name', 'id', 'user_id', 'questionnaire_id', 'created', 'modified'];
      const state = getState();
      const locationHierarchyQuery = getLocationHierarchyQuerySelector(state);
      const datesQuery = getDateFiltersSelector(state);

      const keys = Object.keys(requestParams);

      const checkKeys = async (key) => {
        if (requestParams[key].hasSchedule) {
          const ids = requestParams[key].ids;
          const batch = 100;
          let start = 0;
          while (start < ids.length) {
            const batchEnd = start + batch;
            const idBatch = ids.slice(start, batchEnd);
            start = batchEnd;
            const scheduledQuery = {
              id: idBatch.join(','),
            };
            const scheduled = await dispatch(
              fetchPOI(
                key,
                signal,
                undefined,
                datesQuery,
                { ...locationHierarchyQuery, ...scheduledQuery },
                fields.join(','),
                undefined,
                ['core=true'],
              ),
            ).then((res) => res.json()).then((data) => data);
            dispatch(
              scheduleDatapointsLoaded(
                [...scheduled].map((dp) => {
                  return {
                    ...dp,
                    invalidDate: dp.scheduleDate < formatDate(new Date()),
                  };
                }),
                key,
              ),
            );
          }
        }
      };
      keys?.forEach((key) => {
        void checkKeys(key);
      });
    }
  };
};

export const scheduleLoaded = (scheduleItems: SchedulesInterface) => ({
  type: SCHEDULE_ACTIONS.MY_SCHEDULE_LOADED,
  scheduleItems,
});

export const scheduleDatapointsLoaded = (data: DataPoint[], formId: string) => ({
  type: SCHEDULE_ACTIONS.MY_SCHEDULE_DATA_LOADED,
  data,
  formId,
});

export const updateSchedule = (schedule: ScheduleInterface) => {
  return (dispatch: ThunkDispatchAction) => {
    const updateToastId = Date.now() + Math.floor(Math.random() * 100);
    toast('Updating schedule', {
      toastId: updateToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    ScheduleApi.updateSchedule(schedule)
      .then((res) => res.json())
      .then((json) => {
        if (!json.errorCode) {
          toast.update(updateToastId, {
            type: toast.TYPE.SUCCESS,
            render: 'Schedule updated.',
          });
          dispatch(scheduleLoaded([json]));
        }
        setTimeout(() => toast.dismiss(updateToastId), 3000);
      })
      .catch(() => {
        toast.update(updateToastId, {
          type: toast.TYPE.ERROR,
          render: 'Error updating schedule.',
        });
        setTimeout(() => toast.dismiss(updateToastId), 3000);
      });
  };
};
