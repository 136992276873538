import { ThunkDispatchAction } from 'actions';
import { StateInterface } from 'Interfaces/StateInterface';
import { getRequestConfig } from './index';
import { request } from './request';

export const doGetCharts = (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  formId: string,
  requestParams: string[],
) => {
  const headers = getRequestConfig();
  return request(`/json/app/gallery/${formId}?${requestParams.join('&')}`, headers, dispatch, getState);
};
