import {
  DocumentFileInterface, DocumentFolder, DocumentFolders, DocumentInterface, DocumentVersionInterface
} from "Interfaces/DocumentInterface";
import { DocumentFolderPermissionInterface } from "Interfaces/Documents/DocumentFolderPermissionInterface";
import { StateInterface } from "Interfaces/StateInterface";
import { toast } from "react-toastify";
import { AnyAction } from "redux";
import { getLocalization } from "global/global";
import * as DocumentApi from "../api/documentApi";
import { DOCUMENTS } from "./actionTypes";


export const getDataPointDocumentVersions = (id: string, format: string, signal: AbortSignal) => {
  return async (dispatch, getState): Promise<DocumentInterface[]> => {
    const req = await DocumentApi.doGetDataPointDocumentVersions(dispatch, getState, id, format, signal)
      .then(res => res.json()).then(json => json);
    return req;
  };
};

export const approveVersion = (id: number, signal: AbortSignal) => {
  return async (dispatch, getState): Promise<DocumentInterface[]> => {
    const req = await DocumentApi.doApproveVersion(dispatch, getState, id, signal)
      .then(res => res.json()).then(json => json);
    return req;
  };
};

export const getDocuments = () => {
  return async (dispatch, getState, signal: AbortSignal) => {
    const req = await DocumentApi.doGetDocuments(dispatch, getState, signal)
      .then(res => res.json()).then(json => json);
    dispatch(documentsLoaded(req));
  };
};

export const getDocumentFolders = () => {
  return async (dispatch, getState, signal: AbortSignal) => {
    const req = await DocumentApi.doGetDocumentFolders(dispatch, getState, signal)
      .then(res => res.json()).then(json => json);
    dispatch(documentFoldersLoaded(req));
  };
};

export const documentsLoaded = (documents: DocumentInterface[]) => ({
  type: DOCUMENTS.LOADED, documents
});

export const documentFoldersLoaded = (documentFolders: DocumentFolders) => ({
  type: DOCUMENTS.FOLDERS_LOADED, documentFolders
});

export const saveDocument = (
  document: DocumentInterface, files: DocumentFileInterface[], signal: AbortSignal, version?: DocumentVersionInterface
) => {
  return (dispatch, getState): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const config = {
        body: JSON.stringify({ document, version, files }),
        method: 'POST'
      };
      const saveToastId = Date.now() + Math.floor(Math.random() * 100);
      toast('Saving document...', {
        toastId: saveToastId,
        type: toast.TYPE.INFO,
        autoClose: false,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: false,
      });
      const response = DocumentApi.doSaveDocument(dispatch, getState, config, signal);
      response.then(res => res.json()).then(json => {
        console.log(json);
        let message;
        if (!json.errorCode) {
          dispatch(addDocument(json));
          message = 'Document saved.';
          resolve(true);
          dispatch(getDocumentActivity());
          dispatch(getDocumentFiles());
        } else {
          message = 'Error saving document.';
          reject(false);
        }
        toast.update(saveToastId, {
          type: !json.errorCode ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
          render: message,
        });
        setTimeout(() => toast.dismiss(saveToastId), 3000);
      }).catch(e => {
        console.log(e);
        reject(false);
      });
    });
  };
};

export const addDocument = (document: DocumentInterface) => ({
  type: DOCUMENTS.ADD, document
});

export const getDocumentFiles = () => {
  return async (dispatch, getState, signal: AbortSignal) => {
    const req = await DocumentApi.doGetDocumentFiles(dispatch, getState, signal)
      .then(res => res.json()).then(json => json);
    dispatch(documentFilesLoaded(req));
  };
};

export const documentFilesLoaded = (documentFiles: DocumentFileInterface[]) => ({
  type: DOCUMENTS.FILES_LOADED, documentFiles
});

export const getDocumentActivity = () => {
  return async (dispatch, getState, signal: AbortSignal) => {
    const req = await DocumentApi.doGetDocumentActivity(dispatch, getState, signal)
      .then(res => res.json()).then(json => json);
    dispatch(documentActivityLoaded(req));
  };
};

export const documentActivityLoaded = (documentActivity: DocumentFileInterface[]) => ({
  type: DOCUMENTS.ACTIVITY_LOADED, documentActivity
});

export const addDocumentFile = (file: DocumentFileInterface) => ({
  type: DOCUMENTS.ADD_FILE, file
});

export const deleteDocumentFile = (id: number) => {
  return (dispatch, getState) => {
    const config = {
      method: 'GET'
    };
    const deleteToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('deletingDocumentFile'), {
      toastId: deleteToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    DocumentApi.doDeleteDocumentFile(dispatch, getState, config, id)
      .then(res => res.json()).then(json => {
        let message = '';
        if (json.status === 'OK') {
          message = getLocalization('fileDeleted');
          dispatch(deletedDocumentFile(id));
        } else {
          message = json.message;
        }
        toast.update(deleteToastId, {
          type: json.status === 'OK' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
          render: message,
        });
        setTimeout(() => toast.dismiss(deleteToastId), 3000);
      }).catch(e => {
        console.log(e);
      });
  };
};

export const deletedDocumentFile = (id: number) => ({
  type: DOCUMENTS.DELETE_FILE, id
});

export const createFolder = (folder: DocumentFolder, signal: AbortSignal) => {
  return (dispatch, getState) => {
    const config = {
      method: 'POST',
      body: JSON.stringify(folder)
    };
    const saveToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('savingFolder'), {
      toastId: saveToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    DocumentApi.doCreateFolder(dispatch, getState, config, signal)
      .then(res => res.json()).then(json => {
        let message = '';
        if (json.id) {
          message = getLocalization('folderCreated');
          dispatch(createdFolder(json));
        } else {
          message = json.message;
        }
        toast.update(saveToastId, {
          type: json.id ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
          render: message,
        });
        setTimeout(() => toast.dismiss(saveToastId), 3000);
      }).catch(e => {
        console.log(e);
      });
  };
};

export const createdFolder = (folder: DocumentFolder) => ({
  type: DOCUMENTS.FOLDER_CREATED, folder
});

export const getDocumentFolderPermissions = () => {
  return (dispatch, getState) => {
    DocumentApi.doGetDocumentFolderPermissions(dispatch, getState)
      .then(res => res.json()).then(json => {
        dispatch(documentFolderPermissionsLoaded(json));
      }).catch(e => console.log(e));
  };
};

export const documentFolderPermissionsLoaded = (permissions: DocumentFolderPermissionInterface[]) => ({
  type: DOCUMENTS.FOLDER_PERMISSIONS_LOADED, permissions
});

export const deleteDocument = (id: number) => {
  return (dispatch: AnyAction, getState: () => StateInterface) => {
    const config = {
      method: 'GET'
    };
    const deleteToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('deletingDocument'), {
      toastId: deleteToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    DocumentApi.doDeleteDocument(dispatch, getState, config, id)
      .then(res => res.json()).then(json => {
        let message = '';
        if (json.status === 'OK') {
          message = getLocalization('documentDeleted');
          dispatch(deletedDocument(id));
        } else {
          message = json.message;
        }
        toast.update(deleteToastId, {
          type: json.status === 'OK' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
          render: message,
        });
        setTimeout(() => toast.dismiss(deleteToastId), 3000);
      }).catch(e => {
        console.log(e);
      });
  };
};

export const deletedDocument = (id: number) => ({
  type: DOCUMENTS.DELETED, id
});
