import './Style.scss';
import * as React from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { AnyAction } from 'redux';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { createPassword } from '../../actions/userActions';
import { getLocalization } from '../../global/global';
import { StateInterface } from '../../Interfaces/StateInterface';
import { getQueryParams } from '../../utils/utils';
import AlertContainer from '../Alert/AlertContainer';
import ConfirmContainer from '../Confirm/ConfirmContainer';

import { PasswordInput } from '../components/PasswordInput';
import ProgressContainer from '../Progress/ProgressContainer';

interface ActionProps {
  createPassword: (account: string, otp: string, password: string) => void;
}

export const CreatePassword = (props: ActionProps) => {
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');

  const handleInputChange = (e) => {
    if (e.target.name === 'password') {
      setPassword(e.target.value);
    } else if (e.target.name === 'confirmPassword') {
      setConfirmPassword(e.target.value);
    }
  };

  const onChangePassword = () => {
    if (password === '') {
      setPasswordError(true);
    } else if (confirmPassword === '') {
      setConfirmPasswordError(true);
    } else if (password !== confirmPassword) {
      setErrorMsg(getLocalization('passwordMismatch'));
      setPasswordError(true);
      setConfirmPasswordError(true);
    } else if (((/[A-Z]/.test(password) === false
    || /[a-z]/.test(password) === false
    || /[0-9]/.test(password) === false
    || /\d/.test(password) === false
    || /[\W_]/.test(password) === false
    ) && password.length < 10)) {
      setErrorMsg(getLocalization('missingRequiredCharacters'));
      setPasswordError(true);
      setConfirmPasswordError(true);
    } else {
      const {c: account, otp} = getQueryParams(window.location.search);
      props.createPassword(account, otp, password);
    }
  };

  return (
    <Container>
      <Row>
        <Col
          md={{
            offset: 3,
            span: 6
          }}
        >
          <h2 className="text-center">{getLocalization('createPassword')}</h2>
          {errorMsg !== '' && (
            <Alert variant={'danger'}>
              <p>{errorMsg}</p>
            </Alert>
          )}
          <Form.Group>
            <Form.Label>{getLocalization('password')}</Form.Label>
            <PasswordInput
              passwordError={passwordError}
              value={password}
              onChange={(e) => handleInputChange(e)}
              name="password"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{getLocalization('confirmPasssword')}</Form.Label>
            <PasswordInput
              passwordError={confirmPasswordError}
              value={confirmPassword}
              onChange={handleInputChange}
              name="confirmPassword"
            />
          </Form.Group>
          <Button
            size="sm"
            onClick={onChangePassword}
          >
            {getLocalization('createPassword')}
          </Button>
          <Container fluid className="password-info">
            <Alert variant={'info'}>
              <p>{getLocalization('passwordRule')}</p>
              <ul>
                <li>{getLocalization('passwordRule1')}</li>
                <li>{getLocalization('passwordRule2')}</li>
              </ul>
            </Alert>
          </Container>
        </Col>
      </Row>
      <ProgressContainer />
      <AlertContainer />
      <ConfirmContainer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    createPassword: (account, otp, password) => {
      dispatch(createPassword(account, otp, password));
    },
  };
};


export const CreatePasswordApp = withCookies(
  connect(null, mapDispatchToProps)(CreatePassword));
export default CreatePasswordApp;
