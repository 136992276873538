import { UPDATE_CONFIRM } from '../actions/actionTypes';
import { ConfirmInterface } from '../Interfaces/ConfirmInterface';

const confirm = (state = { visible: false }, action): ConfirmInterface => {
  switch (action.type) {
    case UPDATE_CONFIRM:
      if (action.confirm) {
        return Object.assign({}, state, action.confirm);
      }
      return state;
    default:
      return state;
  }
};

export default confirm;
