import { Action } from 'typescript-fsa';
import { createSelector } from 'reselect';
import { LooseObject } from 'Interfaces/LooseObject';
import { StateInterface } from '../Interfaces/StateInterface';
import { FILTERS_MENU, FORMS } from '../actions/actionTypes';
import {
  DateFilters,
  FiltersMenuInterface,
  filtersSelectedFormType,
  filtersSelectedLocationType,
  filtersSelectedUsers,
} from '../Interfaces/FiltersMenuInterface';
import { persistAuthorizationKey } from '../global/constants';
import { ClientPersistInterface } from '../Interfaces/ClientPersistInterface';
import ReducerBuilder from './ReducerBuilder';

export const INITIAL_STATE: FiltersMenuInterface = {
  open: false,
  settings: {
    height: 400,
  },
  selectedForms: [],
  selectedLocations: [],
  selectedUsers: [],
  selectedDates: { from: '', to: '', filterBy: 'modified' },
};

export type PayloadType =
    | boolean
    | number
    | filtersSelectedFormType
    | filtersSelectedFormType[]
    | filtersSelectedLocationType[]
    | filtersSelectedUsers[]
    | DateFilters
    | ClientPersistInterface;

const toggleFilterMenu = (state: FiltersMenuInterface, { payload }): FiltersMenuInterface => {
  return { ...state, open: payload };
};

const resizeFilterMenu = (state: FiltersMenuInterface, { payload }): FiltersMenuInterface => {
  return { ...state, settings: { height: payload } };
};

const selectForm = (state: FiltersMenuInterface, { payload }): FiltersMenuInterface => {
  const { selectedForms } = state;
  if (selectedForms.find((selectedForm) => selectedForm.ref === payload.ref)) {
    return { ...state };
  } else {
    return { ...state, selectedForms: [...selectedForms, payload] };
  }
};

const unSelectForm = (state: FiltersMenuInterface, { payload }): FiltersMenuInterface => {
  const { selectedForms } = state;
  return { ...state, selectedForms: selectedForms.filter((form) => form.ref !== payload.ref) };
};

const selectUnSelectAllForms = (state: FiltersMenuInterface, { payload }): FiltersMenuInterface => {
  return { ...state, selectedForms: payload };
};

const selectUnSelectLocations = (state: FiltersMenuInterface, { payload }): FiltersMenuInterface => {
  return { ...state, selectedLocations: payload };
};

const selectUnSelectUsers = (state: FiltersMenuInterface, { payload }): FiltersMenuInterface => {
  return { ...state, selectedUsers: payload };
};

const setSelectedDates = (state: FiltersMenuInterface, { payload }): FiltersMenuInterface => {
  return { ...state, selectedDates: payload };
};

const onFormsLoaded = (state: FiltersMenuInterface, { payload }): FiltersMenuInterface => {
  if (payload.length > 0) {
    const formIds: string[] = [];
    payload.forEach((f) => {
      if (f.type === 'POI' && !f.isChild) {
        if (formIds.indexOf(f.ref) === -1) {
          formIds.push(f.ref);
        }
      }
    });
    const uniqueForm: string[] = [];
    formIds.forEach((id) => {
      if (uniqueForm.indexOf(id) === -1) {
        uniqueForm.push(id);
      }
    });
    if (uniqueForm.length === 1) {
      const form = payload.find((f) => f.ref === uniqueForm[0]);
      if (form) {
        const selectedForm = { ref: form.ref, name: form.name, id: form.id };
        return { ...state, selectedForms: [selectedForm] };
      }
    } else {
      const validForms: filtersSelectedFormType[] = [];
      state.selectedForms.forEach((selected) => {
        if (uniqueForm.indexOf(selected.ref) !== -1) {
          const f = payload.find((lf) => lf.ref === selected.ref);
          validForms.push({ ...selected, id: f.id });
        }
      });
      return { ...state, selectedForms: validForms };
    }
  }
  return { ...state };
};

const persistRehydrate = (state: FiltersMenuInterface, action): FiltersMenuInterface => {
  // This is triggered during REHYDRATE of redux-persist
  // If clientPersist.rememberFilterSelections is false
  // then all filters are ignored.
  if (action.key === persistAuthorizationKey && action.payload && action.payload.rememberFilterSelections === false) {
    return { ...INITIAL_STATE, settings: state.settings };
  }
  return state;
};

export default new ReducerBuilder<FiltersMenuInterface, Action<PayloadType>>()
  .setInitialState(INITIAL_STATE)
  .addReducer(FILTERS_MENU.TOGGLE, toggleFilterMenu)
  .addReducer(FILTERS_MENU.RESIZE, resizeFilterMenu)
  .addReducer(FILTERS_MENU.SELECT_FORM, selectForm)
  .addReducer(FILTERS_MENU.UNSELECT_FORM, unSelectForm)
  .addReducer(FILTERS_MENU.SELECT_ALL_FORMS, selectUnSelectAllForms)
  .addReducer(FILTERS_MENU.UNSELECT_ALL_FORMS, selectUnSelectAllForms)
  .addReducer(FILTERS_MENU.SELECT_UNSELECT_LOCATIONS, selectUnSelectLocations)
  .addReducer(FILTERS_MENU.SELECT_ALL_LOCATIONS, selectUnSelectLocations)
  .addReducer(FILTERS_MENU.UNSELECT_ALL_LOCATIONS, selectUnSelectLocations)
  .addReducer(FILTERS_MENU.SELECT_UNSELECT_USERS, selectUnSelectUsers)
  .addReducer(FILTERS_MENU.SET_SELECTED_DATES, setSelectedDates)
  .addReducer(FORMS.LOAD, onFormsLoaded)
  .addReducer('persist/REHYDRATE', persistRehydrate)
  .build();

// Selectors
export const filtersMenuSelector = (state: StateInterface): FiltersMenuInterface => state.filtersMenu;

export const filtersMenuOpenSelector = createSelector<
StateInterface,
FiltersMenuInterface,
FiltersMenuInterface['open']
>(filtersMenuSelector, (filters) => filters.open);

export const filtersMenuSettingsSelector = createSelector<
StateInterface,
FiltersMenuInterface,
FiltersMenuInterface['settings']
>(filtersMenuSelector, (filters) => filters.settings);

// Returns selected/filtered forms
export const filtersMenuSelectedFormsSelector = createSelector<
StateInterface,
FiltersMenuInterface,
FiltersMenuInterface['selectedForms']
>(filtersMenuSelector, (filters) => filters.selectedForms);

// Returns selected/filtered locations
export const filtersMenuSelectedLocationsSelector = createSelector<
StateInterface,
FiltersMenuInterface,
FiltersMenuInterface['selectedLocations']
>(filtersMenuSelector, (filters) => filters.selectedLocations);

export const getLocationHierarchyQuerySelector = createSelector<
StateInterface,
FiltersMenuInterface['selectedLocations'],
LooseObject
>(filtersMenuSelectedLocationsSelector, (selectedLocations) => {
  const query = {};
  selectedLocations.forEach((location) => {
    const level = location.level ? (Number(location.level) < 0 ? 0 : Number(location.level)) : 0;
    const key = `location${level + 1}`;
    if (!query[key]) {
      query[key] = [];
    }
    query[key].push(location.key);
  });
  return query;
});

// Returns selected users
export const filtersMenuSelectedUsersSelector = createSelector<
StateInterface,
FiltersMenuInterface,
FiltersMenuInterface['selectedUsers']
>(filtersMenuSelector, (filters) => filters.selectedUsers);

// Returns selected dates
export const filtersMenuSelectedDatesSelector = createSelector<
StateInterface,
FiltersMenuInterface,
FiltersMenuInterface['selectedDates']
>(filtersMenuSelector, (filters) => filters.selectedDates);

export interface QueryFiltersInterface {
  condition: 'GREATER_THAN_OR_EQUAL' | 'LESS_THAN_OR_EQUAL' | 'IN';
  field: 'modified' | 'users';
  value: string;
  operator: string;
  fieldOperator: string;
}

export const getDateFiltersSelector = createSelector<
StateInterface,
FiltersMenuInterface['selectedDates'],
QueryFiltersInterface[]
>(filtersMenuSelectedDatesSelector, (selectedDates) => {
  let filters: QueryFiltersInterface[] = [];
  if (selectedDates.from && selectedDates.from !== '') {
    filters = [
      ...filters,
      {
        condition: 'GREATER_THAN_OR_EQUAL',
        field: 'modified',
        value: selectedDates.from,
        operator: '0',
        fieldOperator: '1',
      },
    ];
  }
  if (selectedDates.to && selectedDates.to !== '') {
    filters = [
      ...filters,
      {
        condition: 'LESS_THAN_OR_EQUAL',
        field: 'modified',
        value: selectedDates.to,
        operator: '0',
        fieldOperator: '1',
      },
    ];
  }
  return filters;
});
