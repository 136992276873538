import './Style.scss';
import { getLocalization } from 'global/global';
import * as React from 'react';
import { ListGroup, ProgressBar } from 'react-bootstrap';
import { Col, Form } from 'react-bootstrap';
import { LocationHierarchyInterface } from 'Interfaces/HierarchyInterface';
import { useSelector } from 'react-redux';
import { StateInterface } from 'Interfaces/StateInterface';
import ListItem from './ListItem';

interface Props {
  loading: boolean;
  locationHierarchies: LocationHierarchyInterface[];
  open: (xml: string) => void;
}

export const List = (props: Props) => {

  const [active, setActive] = React.useState(true);
  const locationHierarchies = useSelector((state: StateInterface) =>
    state.locationHierarchies.locationHierarchies.filter(lh => lh.active === active));
  return (
    <Col md={2}>
      {props.loading ? (
        <ProgressBar
          animated
          now={100}
          striped
        />
      ) : (
        <>
          <div className="active-div">
            <Form.Control
              as="select"
              size={'sm'}
              value={active ? 'active' : 'discarded'}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setActive(e.target.value === 'active')}
            >
              <option value={'active'}>{getLocalization('active')}</option>
              <option value={'discarded'}>{getLocalization('discarded')}</option>
            </Form.Control>
          </div>
          <ListGroup className="lh-list">
            {locationHierarchies.map((lh) => (
              <ListItem
                key={lh.id}
                locationHierarchy={lh}
                onSelect={(xml) => props.open(xml)}
              />
            ))}
          </ListGroup>
        </>
      )}
    </Col>
  );
};
