import './FormsContainer.scss';
import * as React from 'react';
import { StateInterface } from 'Interfaces/StateInterface';
import { connect } from 'react-redux';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { Alert, Badge, Form } from 'react-bootstrap';
import { FiltersMenuInterface, filtersSelectedFormType } from 'Interfaces/FiltersMenuInterface';
import bind from 'bind-decorator';
import { ThunkDispatchAction } from 'actions';
import { Parameters } from 'enzyme';
import * as formsActions from '../../../actions/formsActions';
import { getLocations } from '../../../actions/locationsActions';
import * as filtersMenuActions from '../../../actions/filtersMenuActions';
import { checkLicenceValidity } from '../../../actions/systemActions';
import { FormInterface } from '../../../Interfaces/Forms/FormsInterface';
import { formsForFiltersSelector, formsSelector } from '../../../reducers/formsReducer';
import { filtersMenuSelectedFormsSelector } from '../../../reducers/filtersMenuReducer';
import { getLocalization, globalWindow } from '../../../global/global';

const className = 'FormsContainer';
interface IStateProps {
  areFormsLoaded: boolean;
  forms: FormInterface[];
  selectedForms: FiltersMenuInterface['selectedForms'];
  roles: string;
}

interface IActionProps {
  actions: {
    getForms: (...args: Parameters<typeof formsActions.getFormsAndHierarchyAndPOICount>) => Promise<void>;
    selectForm: (form: filtersSelectedFormType) => void;
    unSelectForm: (form: filtersSelectedFormType) => void;
    selectUnselectAllForms: (...args: Parameters<typeof filtersMenuActions.selectUnselectAllForms>) => void;
    getLocations: () => Promise<void>;
    checkLicenceValidity: () => void;
  };
}

export interface IOwnState {
  areAllFormsSelected: boolean;
}

export type FormContainerPropsInterface = IStateProps & IActionProps & Pick<ReactCookieProps, 'cookies'>;

export class FormsContainerClass extends React.Component<FormContainerPropsInterface, IOwnState> {

  constructor(props) {
    super(props);
    this.state = {
      areAllFormsSelected: false
    };
  }

  public componentDidMount() {
    if (this.props.cookies && !this.props.areFormsLoaded) {
      void this.props.actions.getForms(this.props.cookies);
      void this.props.actions.getLocations();
      this.props.actions.checkLicenceValidity();
    }
  }

  private handleChange(form: FormInterface, checked: boolean) {
    if (checked) {
      this.props.actions.selectForm({
        ref: form.ref,
        name: form.name,
        id: form.id
      });
    } else {
      this.props.actions.unSelectForm({
        ref: form.ref,
        name: form.name,
        id: form.id
      });
    }
  }

  @bind
  private getFormCheckRow(form: FormInterface): JSX.Element {
    const isSelected = Boolean(this.props.selectedForms.find((selectedForm) => form.ref === selectedForm.ref));
    return (
      <div
        className={`${className}__forms-form`}
        key={form.ref}
      >
        <img
          src={form.smallIconUrl}
          className={`${className}__forms-form__img`}
          alt=""
        />
        <Form.Check
          id={`form-filter-${form.ref}`}
          className={`${className}__forms-form__checkbox`}
          checked={isSelected}
          onChange={(event) => {
            this.handleChange(form, event.target['checked']);
          }}
          label={form.name}
        />

        <Badge className={`${className}__forms-form__badge`}>
          {form.count}
        </Badge>
      </div>
    );
  }

  private selectUnselectAllForms(checked: boolean) {
    this.setState({areAllFormsSelected: checked});
    const task = checked ? 'selectAll' : 'unSelectAll';
    this.props.actions.selectUnselectAllForms(task);
  }

  private getHeader(): JSX.Element {
    return (
      <div className={`${className}__header`}>
        <Form.Check
          id={'form-filters-select-form'}
          className={`${className}__header__checkbox`}
          checked={this.state.areAllFormsSelected}
          onChange={(event) => {
            this.selectUnselectAllForms(event.target['checked']);
          }}
          label={getLocalization('filterFormSelect')}
        />
        {this.props.selectedForms.length > 0 && (
          <div
            className={`${className}__header__unSelectAll`}
            onClick={() => this.selectUnselectAllForms(false)}
          >
            {getLocalization('none')}
          </div>
        )}
      </div>
    );
  }

  private getBody(): JSX.Element {
    const { roles, areFormsLoaded } = this.props;
    const formsFilterList: JSX.Element[] = this.props.forms.map(this.getFormCheckRow);
    return (
      <div className={`${className}__forms`}>
        {formsFilterList.length > 0 || !areFormsLoaded ? (formsFilterList) : (
          <Alert variant="warning">
            {(roles.indexOf('admin') !== -1 || roles.indexOf('modeler') !== -1 ) ?
              getLocalization('noFormList') : getLocalization('noFormListEnum')}
          </Alert>
        )}
      </div>
    );
  }

  public render(): JSX.Element | null {
    const { forms } = this.props;
    if (forms.length === 1 && globalWindow.mobile) {
      return null;
    }
    return (
      <div className={`${className} col`}>
        {this.getHeader()}
        {this.getBody()}
      </div>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    areFormsLoaded: formsSelector(state).loaded,
    forms: formsForFiltersSelector(state),
    selectedForms: filtersMenuSelectedFormsSelector(state),
    roles: state.clientPersist.roles
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchAction): IActionProps => {
  return {
    actions: {
      getForms: (...args) => dispatch(formsActions.getFormsAndHierarchyAndPOICount(args[0])),
      selectForm: (form) => dispatch(filtersMenuActions.selectForm(form)),
      unSelectForm: (form) => dispatch(filtersMenuActions.unSelectForm(form)),
      selectUnselectAllForms: (...args) => dispatch(filtersMenuActions.selectUnselectAllForms(args[0])),
      getLocations: () => (dispatch(getLocations())),
      checkLicenceValidity: () => dispatch(checkLicenceValidity())
    }
  };
};

export const FormsContainer = connect<
IStateProps, IActionProps, ReactCookieProps, StateInterface
>(mapStateToProps, mapDispatchToProps)(FormsContainerClass);

export default withCookies(FormsContainer);
