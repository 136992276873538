import { ClientPersistInterface } from './ClientPersistInterface';

export enum premiumPackages {
  Light = 'Light',
  Pro = 'Pro',
  Custom = 'Custom',
}

export enum pricing {
  per_user = 'Per User',
  per_transaction = 'Per Transaction',
}

export interface UpgradeAccountInterface {
  organization: ClientPersistInterface['organization'];
  accountName: string;
  premiumPackage: premiumPackages;
  pricing: pricing;
}
