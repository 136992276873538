
import './Style.scss';
import { StateInterface } from 'Interfaces/StateInterface';
import * as React from 'react';
import { Table } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { getLocalization } from 'global/global';
import { getDocumentActivity } from 'actions/documentActions';
import { formatDisplayDate } from 'utils/utils';
import { DocumentActivities } from 'Interfaces/DocumentInterface';

interface Props {
  folderId: number;
}

interface ActionProps {
  getDocumentActivity: () => void;
}

function Activity(props: ActionProps & Props) {

  const [loading, setLoading] = React.useState(false);
  const documents = useSelector((state: StateInterface) => state.documents);
  const files = useSelector((state: StateInterface) => state.documentFiles);
  const folders = useSelector((state: StateInterface) => state.documentFolders);
  const documentActivities = useSelector((state: StateInterface) => state.documentActivities);
  const users = useSelector((state: StateInterface) => state.users);
  const [visibleActivities, setVisibleActivities] = React.useState<DocumentActivities>([]);

  React.useEffect(() => {
    if (props.folderId === -1) {
      setVisibleActivities(documentActivities.collection);
    } else {
      const vDocs = documents.collection.filter(d => d.folderId === props.folderId).map(d => d.id);
      const vFiles = files.collection.filter(f => vDocs.indexOf(f.documentId) > -1).map(f => f.id);
      const vActivities = documentActivities.collection.filter(
        da => da.folderId === props.folderId || vDocs.indexOf(da.documentId) > -1 || vFiles.indexOf(da.fileId) > -1
      );
      setVisibleActivities(vActivities);
    }
  }, [documentActivities, props.folderId, files, documents]);

  React.useEffect(() => {
    if (!loading) {
      if (!documentActivities.loaded) {
        props.getDocumentActivity();
      }
      setLoading(true);
    } else if (documentActivities.loaded) {
      setLoading(false);
    }
  }, [props]);

  const user = React.useCallback((id) => {
    return users.find(u => u.id === id);
  }, [document]);

  const documentName = React.useCallback((id) => {
    const doc = documents.collection.find(d => d.id === id);
    return doc ? doc.name : '';
  }, [documents]);

  const fileName = React.useCallback((id) => {
    const file = files.collection.find(f => f.id === id);
    return file ? file.name : '';
  }, [files]);

  const folderName = React.useCallback((id) => {
    const folder = folders.collection.find(f => f.id === id);
    return folder ? folder.name : '';
  }, [folders]);

  return (
    <div className="doc-sub-view">
      <h3>{getLocalization('latestActivity')}</h3>
      <Table className="mt-3" hover={true}>
        <thead>
          <tr>
            <th>{getLocalization('date')}</th>
            <th>{getLocalization('user')}</th>
            <th>{getLocalization('version')}</th>
            <th>{getLocalization('action')}</th>
          </tr>
        </thead>
        {visibleActivities.map((activity) => {
          return (
            <tr key={`activity-${activity.id}`}>
              <td className="text-muted">{formatDisplayDate(activity.date)}</td>
              <td>{user(activity.userId)?.name}</td>
              <td>{activity.version}</td>
              <td>
                {activity.action}
                <span className="activity-document-file-name">
                  {
                    ` ${documentName(activity.documentId)}
                    ${fileName(activity.fileId)}
                    ${folderName(activity.folderId)}`
                  }
                </span>
              </td>
            </tr>
          );
        })}
      </Table>
    </div>
  );
}


const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    getDocumentActivity: () => dispatch(getDocumentActivity()),
  };
};

export default connect(null, mapDispatchToProps)(Activity);
