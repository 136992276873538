import * as React from 'react';
import { connect } from 'react-redux';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { DateFilters } from '../../../Interfaces/FiltersMenuInterface';
import { setSelectedDates } from '../../../actions/filtersMenuActions';
import DateComponent from './DateComponent';

interface StateProps {
  selectedDates: DateFilters;
}

interface ActionProps {
  setSelectedDates: (selectedDates: DateFilters) => void;
}

export type DateFilterProps = StateProps & ActionProps;

const DatesContainer = (props: DateFilterProps) => {
  return (<DateComponent {...props} />);
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    selectedDates: state.filtersMenu.selectedDates
  };
};

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    setSelectedDates: (dates) => dispatch(setSelectedDates(dates))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatesContainer);
