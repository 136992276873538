import { StateInterface } from 'Interfaces/StateInterface';
import { toast } from 'react-toastify';
import { getLocalization } from 'global/global';
import { doDeleteDiagram, doGetGroupDiagrams, doGetTileMapResource } from '../api/diagrams';
import { LooseObject } from '../Interfaces/LooseObject';
import { actionCreator, ThunkDispatchAction } from './';
import { DIAGRAMS } from './actionTypes';

export const getGroupDiagrams = () => {
  return (dispatch, getState) => {
    const promise = doGetGroupDiagrams(getState().clientPersist.groupId, dispatch, getState);
    promise
      .then((response) => response.json())
      .then((json) => {
        dispatch(setGroupDiagrams(json));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const setGroupDiagrams = actionCreator<LooseObject[]>(DIAGRAMS.LIST_LOADED);

export const getTileMapResource = (
  diagramName: string,
  callBack: (maxZoom: number, bounds: number[] | null) => void,
) => {
  return (dispatch, getState) => {
    const diagramPromise = doGetTileMapResource(diagramName, dispatch, getState);
    void diagramPromise
      .then((response) => response.text())
      .then((data) => {
        if (callBack) {
          if (data !== '') {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(
              data
                .trim()
                .substring(1, data.length - 1)
                .replace(/\\n/g, '')
                .replace(/\\/g, ''),
              'text/xml',
            );
            const tileSet = xmlDoc.getElementsByTagName('TileSet');
            const maxZoom = tileSet.length - 1;
            const boundingBox = xmlDoc.getElementsByTagName('BoundingBox');
            let bounds;
            if (boundingBox) {
              const max = boundingBox[0].getAttribute('maxx');
              const min = boundingBox[0].getAttribute('miny');
              if (max && min) {
                const maxX = Math.abs(parseFloat(max));
                const minY = Math.abs(parseFloat(min));
                bounds = [maxX, minY];
              }
            }
            callBack(maxZoom, bounds);
          } else {
            callBack(5, null);
          }
        }
      })
      .catch((e) => {
        console.error(e);
        callBack(5, null);
      });
  };
};

export const deleteDiagram = (id: number, signal: AbortSignal) => {
  return (dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
    const deleteToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('removingMap'), {
      toastId: deleteToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    const response = doDeleteDiagram(id, signal, dispatch, getState);
    response
      .then((res) => res.json())
      .then((json) => {
        if (json && json.status === 'OK') {
          dispatch(removeDiagram(id));
          toast.update(deleteToastId, {
            type: toast.TYPE.SUCCESS,
            render: getLocalization('mapRemoved'),
          });
        } else {
          toast.update(deleteToastId, {
            type: toast.TYPE.SUCCESS,
            render: getLocalization('errorRemovingMap'),
          });
        }
        setTimeout(() => toast.dismiss(deleteToastId), 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const removeDiagram = (id) => ({
  type: DIAGRAMS.DELETE,
  id,
});
