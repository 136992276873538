import * as React from 'react';
import { connect } from 'react-redux';
import { Cookies, ReactCookieProps, withCookies } from 'react-cookie';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'typescript-fsa';
import { Persistor } from 'redux-persist/es/types';
import { LoginRequestInterface } from '../../Interfaces/LoginInterface';
import { resetClientPersist, setLanguageClientPersist } from '../../actions/clientPersistActions';
import { StateInterface } from '../../Interfaces/StateInterface';
import { ClientPersistInterface, languagesEnum } from '../../Interfaces/ClientPersistInterface';
import { globalWindow } from '../../global/global';
import { persistedStore } from '../../index';
import { checkSAMLLogin, doGoogleLogin, doLogin, geoLocationSensor, resetPassword } from '../../actions/userActions';
import LoginComponent from './LoginComponent';

interface IStateProps {
  clientPersist: ClientPersistInterface;
  persistedStore: Persistor;
}

interface IActionProps {
  login: (data: LoginRequestInterface,
    rememberMe: boolean,
    cookies?: Cookies) => Promise<void>;
  resetPassword: (data, instance) => Promise<void>;
  geoLocationSensor: (language: languagesEnum, cookies: Cookies) => Promise<void>;
  doGoogleLogin: (token: string, cookies?: Cookies) => Promise<void>;
  checkSAMLLogin: (email: string, callBack: () => void) => void;
  resetClientPersist: () => void;
  setLanguage: (language: languagesEnum) => void;
}

interface IExternalProps {
  cookies?: Cookies;
}

export type LoginPropsInterface = IActionProps & IExternalProps & IStateProps;

class LoginContainerClass extends React.Component<IStateProps & LoginPropsInterface> {
  constructor(props) {
    super(props);
  }

  public componentWillMount() {
    // This resets the user authorization related data
    this.props.resetClientPersist();
  }

  public async componentDidUpdate(prevProps) {
    const {instance, userName, user_id, lang, checkTwoFactorAuthCode} = this.props.clientPersist;
    if (prevProps.clientPersist.instance !== instance
      || checkTwoFactorAuthCode !== prevProps.clientPersist.checkTwoFactorAuthCode) {
      if (!!instance && !!userName && !!user_id && !checkTwoFactorAuthCode) {
        // Force redux state to update persistance local storage
        await this.props.persistedStore.flush();
        const hash = globalWindow.location.hash;
        globalWindow.open(`/index.jsp?lang=${lang}${hash}`, '_parent');
      }
    }
  }

  public render(): JSX.Element {
    return (
      <LoginComponent {...this.props} />
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    clientPersist: state.clientPersist,
    persistedStore: persistedStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): IActionProps => {
  return {
    login: (data, rememberme, cookies) => {
      return dispatch(doLogin(data, rememberme, cookies));
    },
    resetPassword: (data, instance) => {
      return dispatch(resetPassword(data, instance));
    },
    geoLocationSensor: (language, cookies) => {
      return dispatch(geoLocationSensor(language, cookies));
    },
    doGoogleLogin: (tokenId: string, cookies) => {
      return dispatch(doGoogleLogin(tokenId, cookies));
    },
    checkSAMLLogin: (email: string, callBack) => {
      dispatch(checkSAMLLogin(email, callBack));
    },
    resetClientPersist: () => dispatch(resetClientPersist()),
    setLanguage: (language) => (dispatch(setLanguageClientPersist(language)))
  };
};

export const LoginContainer = withCookies(
  connect<IStateProps, IActionProps, ReactCookieProps, StateInterface>(
    mapStateToProps, mapDispatchToProps
  )(LoginContainerClass));
