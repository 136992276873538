import * as React from 'react';
import { JSONInterface } from 'Interfaces/JsonInterface';
import X2JS from 'x2js';
import { TREE_ACTIONS } from '..';
import { ILocationTreeNode } from '../components/Workspace';
import { NODE_TYPE_LH, NODE_TYPE_LOC } from './Constants';
import { uuid } from './Maths';

export const parseXML = (xmlStr) => {
  if (typeof window.DOMParser !== 'undefined') {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlStr, 'text/xml');
    return xmlDoc;
  } else {
    throw new Error('No XML parser found');
  }
};

export const getTreeFromXML = (xml: string, nodeActionHandler: (action, node) => void) => {
  // const xmlDoc = parseXML(xml);
  const x2js = new X2JS();
  const jsonObj = x2js.xml2js(xml);
  // @ts-ignore
  if (jsonObj && jsonObj.Document && jsonObj.Document.LocationHierarchy) {
    // @ts-ignore
    const lh = jsonObj.Document.LocationHierarchy;
    return getTreeFromJSON(lh, nodeActionHandler);
  }
  return undefined;
};

export const getTreeFromJSON = (lh: JSONInterface, nodeActionHandler: (action, node) => void) => {
  const node: ILocationTreeNode = {
    key: lh._ID,
    value: lh._name,
    title: '',
    nodeType: NODE_TYPE_LH,
    level: 0,
    data: {
      id: lh._id,
    }
  };
  const lnames = lh.LocationNames;
  if (lnames) {
    const keys = Object.keys(lnames);
    for (const k of keys) {
      node.data[k] = lnames[k];
    }
  }
  const keys = Object.keys(lh);
  for (const key of keys) {
    if (!Array.isArray(node[key]) && typeof node[key] !== 'object') {
      if (key !== '_ID' && key !== '_name' && key !== 'LocationNames') {
        node.data[key.replace('_', '')] = lh[key];
      }
    }
  }
  node.title = getNodeTitle(node, nodeActionHandler);
  if (lh.Location1Question) {
    const children = getLocations(lh.Location1Question, 1, nodeActionHandler);
    node.children = children;
  }
  return node;
};

export const getNodeTitle =
  (node: ILocationTreeNode, nodeActionHandler?: (action: TREE_ACTIONS, node: ILocationTreeNode) => void) => {
    const deleted = node.data ? `${node.data.deleted}` === `true` : false;
    return (
      <>
        <span className={`lh-node-type ${deleted ? `deleted-node` : ''}`}>
          {node.nodeType === NODE_TYPE_LH ? `LH :` : 'LOC :'}
        </span>
        <span className={`lh-node-title ${deleted ? `deleted-node` : ''}`}>
          {node.value}
        </span>
        {!deleted && (
          <>
            <div
              className="btn-tree-icon"
              onClick={() => nodeActionHandler && nodeActionHandler('DELETE', node)}
            >
              <li className="fa fa-close" aria-hidden="true" />
            </div>
            {node.level < 4 && (
              <div
                className="btn-tree-icon"
                onClick={() => nodeActionHandler && nodeActionHandler('ADD', node)}
              >
                <li className="fa fa-plus" aria-hidden="true" />
              </div>
            )}
            <div
              className="btn-tree-icon"
              onClick={() => nodeActionHandler && nodeActionHandler('COPY', node)}
            >
              <li className="fa fa-copy" aria-hidden="true" />
            </div>
          </>
        )}
      </>
    );
  };

export const getLocations = (parent: JSONInterface, level: number, nodeActionHandler: (action, node) => void) => {
  const nodes = Array.isArray(parent) ? parent : [parent];
  const children: ILocationTreeNode[] = [];
  for (const node of nodes) {
    const  n: ILocationTreeNode = {
      key: node['_ID'],
      title: '',
      value: node['__text'],
      nodeType: NODE_TYPE_LOC,
      level: level,
      data: {
        deleted: `${node['_deleted']}` === 'true' ? true : false
      }
    };
    n.title = getNodeTitle(n, nodeActionHandler);
    const keys = Object.keys(node);
    for (const key of keys) {
      if (Array.isArray(node[key]) || typeof node[key] === 'object') {
        n.children = getLocations(node[key], level + 1, nodeActionHandler);
      } else {
        if (key !== '_ID' && key !== '__text') {
          n.data = n.data || {};
          n.data[key.replace('_', '')] = node[key];
        }
      }
    }
    children.push(n);
  }
  return children;
};

export const generateID = () => {
  return `loc${uuid(15, 16)}`;
};

export const copyNode =
  (node: ILocationTreeNode, nodeActionHandler: (action: TREE_ACTIONS, node: ILocationTreeNode) => void) => {
    node.key = `${node.nodeType}${uuid(15, 16)}`;
    node.title = getNodeTitle(node, nodeActionHandler);
    if (node.data) {
      delete node.data.id;
    }
    if (node.children) {
      node.children.forEach((n) => {
        copyNode(n, nodeActionHandler);
      });
    }
    return node;
  };

export const createNewNode = (type, level, nodeActionHandler: (action, node) => void) => {
  const newNode: ILocationTreeNode = {
    key: `${type}${uuid(15, 16)}`,
    value: '',
    title: '',
    nodeType: type,
    level: level,
  };
  if (type === 'LH') {
    newNode.data = {
      Location1: '',
      Location2: '',
      Location3: '',
      Location4: '',
    };
  } else {
    newNode.data = {
      newNode: true
    };
  }
  newNode.title = getNodeTitle(newNode, nodeActionHandler);
  return newNode;
};

export const addNode = (tree: ILocationTreeNode[], node: ILocationTreeNode, parentId?: string, after?: string) => {
  const loop = (children, newTreeNode, parent, after) => {
    let index = -1;
    for (let i = 0; i < children.length; i++) {
      const item = children[i];
      if (item.key === after) {
        index = i;
        break;
      } else if (item.key === parent) {
        item.children = item.children || [];
        item.children.push(newTreeNode);
        return;
      }
      if (item.children) {
        loop(item.children, newTreeNode, parent, after);
      }
    }
    if (index !== -1) {
      children.splice(index + 1, 0, newTreeNode);
      return newTreeNode;
    }
  };
  loop(tree, node, parentId, after);
  return tree;
};

export const markDeleted = (nodes: ILocationTreeNode[]) => {
  for (const node of nodes) {
    node.data = node.data || {};
    node.data.deleted = true;
    node.data.hidden = true;
    node.title = getNodeTitle(node);
    if (node.children) {
      markDeleted(node.children);
    }
  }
};

export const removeNodeAction = (tree: ILocationTreeNode[], key?: string) => {
  const loop = (children, key) => {
    let index = -1;
    for (let i = 0; i < children.length; i++) {
      const item = children[i];
      if (item.key === key) {
        item.data = item.data || {};
        if (!item.data.newNode) {
          item.data.deleted = true;
          item.data.hidden = true;
          item.title = getNodeTitle(item);
          markDeleted(item.children || []);
        } else {
          index = i;
        }
        break;
      }
      if (item.children) {
        loop(item.children, key);
      }
    }
    if (index !== -1) {
      children.splice(index, 1);
    }
  };
  loop(tree, key);
  return tree;
};

export const toXML = (tree: ILocationTreeNode) => {
  const xml = ['<LocationHierarchy '];
  xml.push(` ID="${tree.key}" name="${tree.value}" `);
  const ln = [`<LocationNames>`];
  if (tree.data) {
    const keys = Object.keys(tree.data);
    const data = tree.data;
    for (const key of keys) {
      if (['Location1', 'Location2', 'Location3', 'Location4'].indexOf(key) > -1) {
        ln.push(`<${key}>${data[key]}</${key}>`);
      } else if (key !== 'Location1Question' && key !== 'LocationNames') {
        xml.push(` ${key}="${encodeString(data[key])}"`);
      }
    }
  }
  ln.push('</LocationNames>');
  xml.push('>');
  if (tree.children) {
    xml.push(tree.children.map((n) => {
      return nodeToXML(n);
    }).join(''));
  }
  xml.push(ln.join(''));
  xml.push(`</LocationHierarchy>`);
  console.log(xml.join(''));
  return `<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Document>${xml.join('')}</Document>`;
};

export const nodeToXML = (node: ILocationTreeNode) => {
  const tag = `Location${node.level}Question`;
  const xml = [`<${tag} ID="${node.key}" `];
  if (node.data) {
    const keys = Object.keys(node.data);
    const data = node.data;
    for (const key of keys) {
      if (key !== 'toString' && key !== '__text' && key !== 'newNode') {
        xml.push(` ${key}="${encodeString(data[key])}"`);
      }
    }
  }
  xml.push(`>`);
  if (node.children) {
    xml.push(node.children.map((n) => {
      return nodeToXML(n);
    }).join(''));
  }
  xml.push(`${encodeString(node.value)}</${tag}>`);
  return xml.join('');
};

export const getJSON = (tree: ILocationTreeNode) => {
  const lh = {
    ID: tree.key,
    name: tree.value,
    LocationNames: {}
  };
  if (tree.data) {
    const keys = Object.keys(tree.data);
    const data = tree.data;
    for (const key of keys) {
      if (key.indexOf('Location') > -1) {
        lh.LocationNames[key] = data[key];
      } else {
        lh[key] = data[key];
      }
    }
  }
  if (tree.children) {
    lh[`Location1Question`] = tree.children.map((n) => {
      return toJSON(n);
    });
  }
  console.log(lh);
  return lh;
};

export const toJSON = (node: ILocationTreeNode) => {
  const json = {
    __text: node.value,
    ID: node.key
  };
  if (node.data) {
    const keys = Object.keys(node.data);
    const data = node.data;
    for (const key of keys) {
      if (key !== 'newNode') {
        json[key] = data[key];
      }
    }
  }
  if (node.children) {
    json[`Location${node.level + 1}Question`] = node.children.map((n) => {
      return toJSON(n);
    });
  }
  return json;
};

export const encodeString = (val: string) => {
  if (val === null) {
    return '';
  }

  val = `${val}`;

  val = val.replace(/&/g, '&amp;');
  val = val.replace(/</g, '&lt;');
  val = val.replace(/>/g, '&gt;');
  val = val.replace(/"/g, `'`);
  val = val.replace(/\t/g, ' ');
  val = val.replace(/\n/g, '&#xD;');
  val = val.replace(/\r/g, '&#xD;');
  return val.trim();
};

export const cleanLHJSON = (jsonObj: JSONInterface) => {
  const keys = Object.keys(jsonObj);
  for (const key of keys) {
    if (typeof jsonObj[key] === 'object') {
      if (Array.isArray(jsonObj[key])) {
        for (const k of jsonObj[key]) {
          cleanLHJSON(k);
        }
      } else {
        cleanLHJSON(jsonObj[key]);
      }
    } else if (key.indexOf('_') > -1 && key !== '__text') {
      jsonObj[key.replace('_', '')] = jsonObj[key];
      delete jsonObj[key];
    }
  }
};
