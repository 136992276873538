import * as React from 'react';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { PowerBiUrl } from 'Interfaces/PowerBiUrl';
import { ConfirmationModal } from 'views/Modals/ConfirmationModal';
import { Button, Form } from 'react-bootstrap';
import { uniqueFormsSelector } from 'reducers/formsReducer';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { getLocalization } from 'global/global';
import { FiltersMenuInterface } from 'Interfaces/FiltersMenuInterface';
import { toast } from 'react-toastify';
import { Locations } from 'Interfaces/LocationInterface';
import { Users } from 'Interfaces/User';
import { createPowerBiUrl, deleteDataUrls, loadPowerBiUrls } from '../../actions/powerBiUrlActions';

interface StateProps {
  clientPersist: ClientPersistInterface;
  powerBiUrls: PowerBiUrl[];
  forms: FormInterface[];
  filters: FiltersMenuInterface;
  locations: Locations;
  users: Users;
}

interface ActionProps {
  loadPowerBiUrls: (signal: AbortSignal) => void;
  createPowerBiUrl: (powerBiUrl: PowerBiUrl, signal: AbortSignal) => Promise<Response>;
  deleteDataUrls: (ids: number[], signal: AbortSignal) => void;
}
type PowerBIViewProps = StateProps & ActionProps;


let abortController: AbortController | null = new AbortController();

const PowerBIView = (props: PowerBIViewProps) => {
  const { clientPersist } = props;
  const [init, setInit] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const [sizePerPage, setSizePerPage] = React.useState(10);
  const [totalSize, setTotalSize] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const sizePerPageList = [5, 10, 20, 25, 30];
  const [selectedForm, setSelectedForm] = React.useState('');
  const [applyFilters, setApplyFilters] = React.useState(false);
  const [fieldLabels, setFieldLabels] = React.useState<'reference_name' | 'question_text' | ''>('reference_name');
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
  const [data, setData] = React.useState(props.powerBiUrls || []);

  const columns =  [{
    dataField: 'id',
    text: 'ID',
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    hidden: true
  }, {
    dataField: 'formIdentifier',
    text: 'Form',
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    formatter: (cell, row) => {
      if (row) {
        const form = props.forms.find(f => f.ref === row.formIdentifier);
        return (
          <span>{form ? form.name : row.formIdentifier}</span>
        );
      }
      return cell;
    }
  }, {
    dataField: 'filters',
    text: 'Filters',
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    formatter: (cell, row) => {
      if (row && row.filters) {
        const filtersJSON = JSON.parse(row.filters);
        return (
          <div>
            <p>
              {filtersJSON.locations && filtersJSON.locations.length > 0 && (
                <><strong>{getLocalization('locations')} : </strong>
                  {filtersJSON.locations.map(fl => {
                    const loc = props.locations.find(l => `${l.key}` === `${fl}`);
                    if (loc) {
                      return loc.title;
                    }
                    return null;
                  })}
                </>
              )}
              {filtersJSON.users && filtersJSON.users.length > 0 && (
                <><strong>  {getLocalization('users')} : </strong>
                  {filtersJSON.users.map(fu => {
                    const user = props.users.find(l => `${l.id}` === `${fu}`);
                    if (user) {
                      return user.name;
                    }
                    return null;
                  })}
                </>
              )}
            </p>
            <p>
              {filtersJSON.fromDate && (
                <>
                  <strong>{getLocalization('from')}: </strong>
                  {filtersJSON.fromDate}
                </>
              )}
              {filtersJSON.toDate && (
                <>
                  <strong> {getLocalization('to')}: </strong>
                  {filtersJSON.toDate}
                </>
              )}
            </p>
          </div>
        );
      }
      return cell;
    }
  }, {
    text: 'Url',
    headerClasses: 'default-column-width header-cell',
    classes: 'default-column-width',
    formatter: (cell, row) => {
      return (
        <Button
          size={'sm'}
          variant={'link'}
          onClick={() => {
            void navigator.clipboard
              .writeText(`${location.origin}/json/app/ext/tenant/${clientPersist.instance}/${row.token}`)
              .then(() => {
                toast('Copied to clipboard', { type: toast.TYPE.INFO, autoClose: 3000 });
              });
          }}
        >
          <i className="fa fa-link"/>
        </Button>
      );
    }
  }];

  React.useEffect(() => {
    if (!init) {
      if (abortController === null) {
        abortController = new AbortController();
      }
      props.loadPowerBiUrls(abortController.signal);
      setInit(true);
    }
    return () => {
      abortController?.abort();
    };
  }, [props]);

  React.useEffect(() => {
    setTotalSize(props.powerBiUrls.length);
    setData([...props.powerBiUrls]);
  }, [props.powerBiUrls]);

  const onRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedIds([...selectedIds, row.id]);
    } else {
      setSelectedIds(selectedIds.filter(x => x !== row.id));
    }
  };

  const onSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelectedIds(rows.map(r => r.id));
    } else {
      setSelectedIds([]);
    }
  };

  const onSizePerPageChange = (sizePerPage) => {
    setSizePerPage(sizePerPage);
    setPage(1);
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  const create = () => {
    if (selectedForm) {
      const toastId = Date.now() + Math.floor(Math.random() * 100);
      toast('Creating power bi url...', {
        toastId,
        type: toast.TYPE.INFO,
        autoClose: false
      });
      if (abortController === null) {
        abortController = new AbortController();
      }
      const filters = applyFilters ? ({
        locations: props.filters.selectedLocations.map(l => l.key),
        users: props.filters.selectedUsers.map(u => u.id),
        fromDate: props.filters.selectedDates.from,
        toDate: props.filters.selectedDates.to
      }) : undefined;
      void props.createPowerBiUrl({
        id: -1,
        formIdentifier: selectedForm,
        createdOn: Date.now(),
        filters: JSON.stringify(filters),
        fieldLabel: fieldLabels
      }, abortController?.signal).then(resp => resp.json())
        .then(json => {
          if (json.id > 0) {
            toast.update(toastId, {
              type: toast.TYPE.SUCCESS,
              render: 'Url created.'
            });
            setShowModal(false);
            setSelectedForm('');
            setApplyFilters(false);
          } else {
            toast.update(toastId, {
              type: toast.TYPE.ERROR,
              render: 'Error creating url.'
            });
          }
          setTimeout(() => toast.dismiss(toastId), 3000);
        });
    }
  };

  const deleteSelected = () => {
    if (abortController === null) {
      abortController = new AbortController();
    }
    setShowConfirmDelete(false);
    props.deleteDataUrls(selectedIds, abortController?.signal);
  };

  const renderToolbar = () => {
    return (
      <div className="table-toolbar">
        <span className="table-toolbar-name">
          {getLocalization('dataUrls')}
        </span>
        <div className="table-toolbar-buttons">
          <button
            className="btn btn-danger btn-sm btn-delete"
            onClick={() => setShowConfirmDelete(true)}
            disabled={!(selectedIds.length > 0)}
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </button>
          <button
            className="btn btn-success btn-sm btn-plus"
            onClick={() => setShowModal(true)}
          >
            <i className="fa fa-plus" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  };

  const createUrlModal = showModal ? (
    <ConfirmationModal
      onConfirm={create}
      onClose={() => setShowModal(false)}
      localizations={
        {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('createUrl'),
          confirmStyle: 'success',
          header: (
            <h5>{getLocalization('createPowerBiUrl')}</h5>
          ),
          body: (
            <div className="container">
              <Form.Group>
                <Form.Label>{getLocalization('selectform')}</Form.Label>
                <Form.Control
                  as="select"
                  // @ts-ignore
                  onChange={(e) => setSelectedForm(e.target?.value)}
                  name={'form'}
                  value={selectedForm}
                >
                  <option value={''}>{getLocalization('selectOne')}</option>
                  {props.forms.map(f => {
                    return (
                      <option value={f.ref} key={`power-bi-url-form-${f.ref}`}>{f.name}</option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Check
                  name="applyFilters"
                  id="power-bi-apply-filters"
                  label={getLocalization('applyFilters')}
                  checked={applyFilters}
                  onChange={(e) => setApplyFilters(e.target.checked)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{getLocalization('selectFieldLabels')}</Form.Label>
                <Form.Check
                  name="fieldLabels"
                  type="radio"
                  id="power-bi-apply-check-question-text"
                  label={getLocalization('questioncaption')}
                  checked={fieldLabels === 'question_text'}
                  onChange={(e) => setFieldLabels(e.target.checked ? 'question_text' : '')}
                />
                <Form.Check
                  name="fieldLabels"
                  type="radio"
                  id="power-bi-apply-check-reference-names"
                  label={getLocalization('referencename')}
                  checked={fieldLabels === 'reference_name'}
                  onChange={(e) => setFieldLabels(e.target.checked ? 'reference_name' : '')}
                />
              </Form.Group>
            </div>
          )
        }
      }
    />
  ) : null;

  const confirmDelete = showConfirmDelete ? (
    <ConfirmationModal
      onConfirm={deleteSelected}
      onClose={() => setShowConfirmDelete(false)}
      localizations={
        {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('deleteData'),
          confirmStyle: 'danger',
          header: (
            <h5>{getLocalization('confirm')}</h5>
          ),
          body: (
            <div className="container">
              {getLocalization('confirmDeleteUrls')}
            </div>
          )
        }
      }
    />
  ) : null;

  return (
    <div>
      {createUrlModal}
      {confirmDelete}
      <div className="form-group">
        {renderToolbar()}
        <BootstrapTable
          keyField="id"
          bootstrap4
          data={data}
          columns={columns}
          condensed
          wrapperClasses={`table-responsive table-view share_data__table`}
          selectRow={{
            mode: 'checkbox',
            selected: selectedIds,
            onSelect: onRowSelect,
            onSelectAll: onSelectAll
          }}
          noDataIndication={getLocalization('noPowerBiUrls')}
          pagination={paginationFactory({
            page,
            sizePerPage,
            totalSize,
            sizePerPageList,
            onSizePerPageChange: onSizePerPageChange,
            onPageChange: onPageChange
          })}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    clientPersist: state.clientPersist,
    powerBiUrls: state.powerBiUrls,
    forms: uniqueFormsSelector(state),
    filters: state.filtersMenu,
    locations: state.locations.collection,
    users: state.users
  };
};

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    loadPowerBiUrls: (signal: AbortSignal) => dispatch(loadPowerBiUrls(signal)),
    createPowerBiUrl: (powerBiUrl: PowerBiUrl, signal: AbortSignal) =>
      dispatch(createPowerBiUrl(powerBiUrl, signal)),
    deleteDataUrls: (ids: number[], signal: AbortSignal) => dispatch(deleteDataUrls(ids, signal))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PowerBIView);
