import * as React from 'react';
import { globalWindow } from '../../../global/global';

interface Props {
  view?: JSX.Element;
  type: string;
  url: string;
}

const MediaPreview = (props: Props) => {
  const { type } = props;
  const getView = () => {
    if (type === 'IMAGE') {
      return (<img src={props.url} className="img-responsive"/>);
    } else if (type === 'AUDIO') {
      return (
        <audio src={props.url} controls>
          Your browser does not support the <code>audio</code> element.
        </audio>
      );
    } else if (type === 'VIDEO') {
      return (
        <video src={props.url} controls className="img-responsive">
          Your browser does not support the <code>video</code> element.
        </video>
      );
    } else {
      return (
        <React.Fragment>
          <img src="/res/images/pdf_icon.png" height="170" />
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        {getView()}
      </div>
      <div className="row text-center">
        <button
          className="btn btn-default btn-sm action-btn rotate-right"
          onClick={() => globalWindow.open(props.url, '_blank')}
        >
          <i className="fa fa-download" />
        </button>
      </div>
    </React.Fragment>
  );
};

export default MediaPreview;
