import * as React from 'react';
import { createRef, FunctionComponent, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useDispatch, useSelector } from 'react-redux';
import EventApi from '@fullcalendar/core/api/EventApi';
import View from '@fullcalendar/core/View';
import esLocale from '@fullcalendar/core/locales/es';
import fiLocale from '@fullcalendar/core/locales/fi';
import frLocale from '@fullcalendar/core/locales/fr';
import { ScheduleInterface, SchedulesInterface } from '../../Interfaces/ScheduleInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { scheduleFormAnswersSelector } from '../../reducers/scheduleAnswersReducer';
import { User } from '../../Interfaces/User';
import { navigateAddModal, navigateRemoveModal } from '../../actions/navigationAction';
import { ModalComponentNames, ModalInterface } from '../../Interfaces/ModalInterface';
import { DayScheduleInterface, IScheduleDayInfoModalProps } from '../Modals/ScheduleDayInfoModal';
import { IScheduleUserSelectionModalProps } from '../Modals/ScheduleUserSelectionModal';
import { clientPersistSelector } from '../../reducers/clientPersistReducer';
import { languagesEnum } from '../../Interfaces/ClientPersistInterface';
import { IScheduleConfirmCopyModalProps } from '../../views/Modals/ScheduleConfirmCopyModal';
import { getUsersSelector } from '../../reducers/users';

interface IExternalProps {
  baseClassName: string;
  selectedForm: FormInterface;
  schedules: SchedulesInterface;
  addNewSchedule: (schedule: Omit<ScheduleInterface, 'id'>, copy?: boolean) => Promise<void>;
  deleteSchedule: (id: ScheduleInterface['id']) => Promise<void>;
}

interface ICalendarEvents {
  title: string;
  id: string;
  start: Date;
}

const eventsStyling = {
  eventColor: '#c3e6cb',
  eventTextColor: ''
};

const evenStatusColors = {
  ongoing: {
    background: '#c3e6cb',
    text: '#155724'
  },
  scheduled: {
    background: '#4c87d2',
    text: '#ffffff'
  }
};

export const ScheduleCalendar: FunctionComponent<IExternalProps> = (props) => {
  const dispatch = useDispatch();
  const calendarComponentRef = createRef<FullCalendar>();
  const {selectedForm, schedules} = props;
  const [calendarEvents, setCalendarEvents] = useState<ICalendarEvents[]>([]);

  const formAnswersSelector = scheduleFormAnswersSelector(selectedForm.ref);
  const answers = useSelector(formAnswersSelector);
  const clientPersist = useSelector(clientPersistSelector);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const allUsers = useSelector(getUsersSelector);

  useEffect(() => {
    const newCalendarEvents = schedules
    // .filter((schedule) => schedule.date >= today.valueOf())
      .map((schedule) => {
        const answer = answers?.find((answer) => answer.id === schedule.answerId);
        const answerName = answer?.Name;
        const status = schedule.status;
        return {
          title: answerName || `1 POI`,
          id: `${schedule.answerId}__${schedule.id}`,
          start: new Date(schedule.date),
          allDay: true,
          extendedProps: {schedule},
          backgroundColor: evenStatusColors[status]?.background,
          textColor: evenStatusColors[status]?.text
        };
      });
    setCalendarEvents(newCalendarEvents);
  }, [schedules, answers]);

  const refreshEvents = () => {
    const calendarApi = calendarComponentRef.current!.getApi();
    calendarApi.removeAllEvents();
    calendarApi.removeAllEventSources();
    calendarApi.addEventSource(calendarEvents);
  };

  useEffect(() => {
    refreshEvents();
  }, [calendarEvents]);

  const addSchedule = async (
    answerId: string, selectedDate: number | string, user: User, scheduleStatus?: string , copy?: boolean
  ) => {
    await props.addNewSchedule({
      answerId,
      formId: selectedForm.ref,
      userId: user.id,
      date: selectedDate,
      initialized: false,
      status: scheduleStatus || 'Scheduled'
    }, copy);
  };

  const addNewSchedule = (answerId: string, selectedDate: number, copy?: boolean) => {
    const modalProps: ModalInterface<IScheduleUserSelectionModalProps> = {
      component: ModalComponentNames.ScheduleUserSelectionModal,
      props: {
        onClose: () => {
          dispatch(navigateRemoveModal(ModalComponentNames.ScheduleUserSelectionModal));
          if (calendarComponentRef.current) {
            const e = calendarComponentRef.current.getApi().getEventById(answerId);
            if (e) {
              e.remove();
            }
          }
        },
        addNewSchedule: (user: User) => {
          void addSchedule(answerId, selectedDate, user, undefined, copy);
          dispatch(navigateRemoveModal(ModalComponentNames.ScheduleUserSelectionModal));
        }
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  const confirmCopyDataPoint = (answerId: string, selectedDate: number, schedule: ScheduleInterface) => {
    const user = allUsers.find(u => u.id === schedule.userId);
    const modalProps: ModalInterface<IScheduleConfirmCopyModalProps> = {
      component: ModalComponentNames.ScheduleConfirmCopyModal,
      props: {
        onClose: (clear: boolean) => {
          dispatch(navigateRemoveModal(ModalComponentNames.ScheduleConfirmCopyModal));
          if (clear && calendarComponentRef.current) {
            const e = calendarComponentRef.current.getApi().getEventById(answerId);
            if (e) {
              e.remove();
            }
          }
        },
        userName: user ? user.name : '',
        date: typeof schedule.date === 'number' ? new Date(schedule.date).toLocaleDateString() : `${schedule.date}`,
        addNewSchedule: (copy: boolean) => {
          addNewSchedule(answerId, selectedDate, copy);
          dispatch(navigateRemoveModal(ModalComponentNames.ScheduleConfirmCopyModal));
        }
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  const eventReceive = (info) => {
    const { event } = info;
    // when new event is added, saves it to backend
    const selectedDate = event.start.setHours(0, 0, 0, 0);
    const answerId = event.id;
    const scheduled = schedules.find(s => s.answerId === answerId && new Date(s.date).getTime() > new Date().getTime());
    if (scheduled) {
      console.log('confirm copy');
      // addNewSchedule(answerId, selectedDate, true);
      confirmCopyDataPoint(answerId, selectedDate, scheduled);
    } else {
      addNewSchedule(answerId, selectedDate);
    }
  };

  const eventAllow = (dropInfo, draggedEvent): boolean => {
    // https://fullcalendar.io/docs/eventAllow
    const selectedDate = dropInfo.start.valueOf();
    if (selectedDate < today) {
      // cannot schedule new events in the past
      return false;
    }
    const id = draggedEvent.id;
    const existingSchedule = schedules.find((schedule) => {
      // cannot schedule a new event if its already scheduled for that date
      return schedule.date === selectedDate && schedule.answerId === id;
    });
    return !existingSchedule;
  };

  const openDayModal = (selectedDate: number) => {
    if (selectedDate < today.valueOf()) {
      return;
    }
    const calendarApi = calendarComponentRef.current!.getApi();
    const daySchedules: DayScheduleInterface[] = calendarApi
      .getEvents()
      .filter((event) => {
        return event.start?.setHours(0, 0, 0, 0) === selectedDate;
      })
      .map((event) => {
        const {title, extendedProps: {schedule: {id, answerId, date, userId, status}}} = event;
        return {id, title, answerId, date, userId, status};
      });

    const modalProps: ModalInterface<IScheduleDayInfoModalProps> = {
      component: ModalComponentNames.ScheduleDayInfoModal,
      props: {
        onClose: () => dispatch(navigateRemoveModal(ModalComponentNames.ScheduleDayInfoModal)),
        deleteSchedule: props.deleteSchedule,
        daySchedules,
        selectedForm,
        selectedDate,
        addNewSchedule: (answerId: string, user: User, scheduleDate?: string, scheduleStatus?: string) =>
          void addSchedule(answerId, scheduleDate || selectedDate, user, scheduleStatus)
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  const getLocale = () => {
    switch (clientPersist.lang) {
      case languagesEnum.es:
        return esLocale;
      case languagesEnum.fi:
        return fiLocale;
      case languagesEnum.fr:
        return frLocale;
      default:
        return undefined;
    }
  };

  const dateClick = (info: {
    date: Date;
    dateStr: string;
    allDay: boolean;
    resource?: any;
    dayEl: HTMLElement;
    jsEvent: MouseEvent;
    view: View;
  }) => {
    const selectedDate = info.date.setHours(0, 0, 0, 0).valueOf();
    openDayModal(selectedDate);
  };

  const eventClick = (info: {
    el: HTMLElement;
    event: EventApi;
    jsEvent: MouseEvent;
    view: View;
  }) => {
    const selectedDate = info.event.start?.setHours(0, 0, 0, 0).valueOf();
    if (selectedDate) {
      openDayModal(selectedDate);
    }
  };

  return (
    <FullCalendar
      initialView="dayGridMonth"
      height={'90vh'}
      themeSystem={'bootstrap'}
      eventColor={eventsStyling.eventColor}
      eventTextColor={eventsStyling.eventTextColor}
      headerToolbar={{
        left: 'title',
        center: '',
        right: 'today prev,next'
      }}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      ref={calendarComponentRef}
      rerenderDelay={10}
      droppable
      editable={false}
      eventReceive={eventReceive}
      eventAllow={eventAllow}
      dateClick={dateClick}
      eventClick={eventClick}
      locale={getLocale()}
    />
  );
};
