import * as React from 'react';
import GenericModal from '../Modals/GenericModal';
import { getTableHeaderText } from './Columns';

interface Props {
  value: string;
  row?: any;
  question: any;
}

interface State {
  visible: boolean;
}

export class MoreTextComponent extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  public render(): JSX.Element {
    const { question } = this.props;
    const modal = this.state.visible ? (
      <GenericModal
        visible={this.state.visible}
        onConfirm={() => this.setState({ visible: false })}
        body={<label dangerouslySetInnerHTML={{ __html: this.props.value}} />}
        confirmText={'Close'}
        title={getTableHeaderText(question)}
      />
    ) : null;
    return (
      <React.Fragment>
        {modal}&nbsp;&nbsp;
        <a
          onClick={() => this.setState({ visible: true })}
          style={{ textDecoration: 'underline', cursor: 'pointer', color: '#32b8c4' }}
        >
          more...
        </a>
      </React.Fragment>
    );
  }
}
