import { ThunkDispatchAction } from 'actions';
import { StateInterface } from 'Interfaces/StateInterface';
import { getRequestConfig } from './index';
import { request } from './request';

export const doGetReports = (userId, dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
  const headers = getRequestConfig();
  return request(`/json/app/getreports/${userId}`, headers, dispatch, getState);
};

export const doSaveReports = (
  reportName: string,
  parameters: string,
  reportId: string,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  const requestParams = {
    report_name: reportName,
    parameters: parameters,
    report_id: reportId,
  };
  const newConfig = Object.assign({ body: JSON.stringify(requestParams), method: 'POST' }, headers);
  return request('/json/app/savereport', newConfig, dispatch, getState);
};

export const doDeleteReport = (reportId: string, dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
  const headers = getRequestConfig();
  const newConfig = Object.assign({ method: 'GET' }, headers);
  return request(`/json/app/deletereport/${reportId}`, newConfig, dispatch, getState);
};
