import { forEach, isArray } from 'lodash-es';
import { LooseObject } from '../../Interfaces/LooseObject';
import { DataPoint, FileInterface } from '../../Interfaces/DataPoint';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { createRequestFilters } from '../../utils/utils';
import { getIconUrl } from '../../views/Map/utils/utils';

/**
 * This function extracts the file objesct from the datamodel.
 * These are uploaded separately.
 */
export const extractFiles = (dataPoint: DataPoint, isChild?: boolean): LooseObject => {
  let files: FileInterface[] = [];
  forEach(dataPoint, (value, key) => {
    if (isArray(value) && key !== 'files') {
      for (let v of value) {
        v = extractFiles(v, true);
        if (v.files) {
          files = files.concat(v.files);
        }
      }
    } else if (isArray(value) && key === 'files') {
      const tempFiles =
                isChild && dataPoint.files
                  ? dataPoint['files'].map((f) => {
                    f.partOfId = dataPoint.id;
                    return f;
                  })
                  : dataPoint['files'];
      if (tempFiles) {
        files = files.concat(tempFiles);
      }
      if (dataPoint.files) {
        dataPoint.files = dataPoint.files?.map((f) => {
          const file = { ...f };
          delete file.file;
          delete file.url;
          // ts-ignore
          delete file['thumbnailsrc'];
          return file;
        });
      }
      // delete dataPoint['files'];
    }
  });
  return { dataPoint: dataPoint, files: files };
};

export const dataURItoBlob = (dataURI: string): any => {
  const binary = atob(dataURI.split(',')[1]);
  const array: number[] = [];
  let i = 0;
  while (i < binary.length) {
    array.push(binary.charCodeAt(i));
    i++;
  }
  return new Blob([new Uint8Array(array)], { type: 'image/png' });
};

/**
 * This function prepares the files by organizing them into 3
 * new files - this are files which have not been uploaded to the server/s3 yet except Diagram files.
 * diagram files - this are diagram files which have not been uploaded to the server.
 * updated - This are files which already exist in S3
 * The files are assigned the new row id for the POI or Subforms
 */
export const prepareFiles = (poiFiles, responseJSON, newRowId) => {
  const files: LooseObject[] = [];
  const updateFiles: LooseObject[] = [];
  const diagrams: LooseObject[] = [];
  let i = 0;
  for (const f of poiFiles) {
    if (f.partOfId && responseJSON.newRowIds && responseJSON.newRowIds[f.partOfId]) {
      f.rowId = responseJSON.newRowIds[f.partOfId];
    } else {
      f.rowId = newRowId;
    }
    if (f.id) {
      // this is an existing file.
      const updateFile: LooseObject = {};
      updateFile.id = f.id;
      updateFile.rowId = f.rowId;
      updateFile.questionId = f.questionId;
      updateFiles.push(updateFile);
    } else if (f.questionId === 'BackgroundDiagram') {
      // this is an existing Diagram
      diagrams.push(f);
    } else {
      // this is a new file.
      f.thumbnail = false;
      f.thumnailsrc = '';
      f.fileObj = i;
      const fileObj = Object.assign({}, f);
      delete fileObj.file;
      delete fileObj.url;
      files.push(fileObj);
      if (f.mimeType.indexOf('image') === 0) {
        const mainImg = new Image();
        mainImg.src = f.url;
        const canvas = document.createElement('canvas');
        canvas.width = 200;
        canvas.height = 200;
        const context = canvas.getContext('2d');
        if (context) {
          context.drawImage(mainImg, 0, 0, canvas.width, canvas.height);
        }
        const imageObj = new Image();
        imageObj.src = canvas.toDataURL();
        const thumbnail: LooseObject = {};
        thumbnail.fileName = f.fileName;
        thumbnail.mimeType = 'image/png';
        thumbnail.rowId = f.rowId;
        thumbnail.questionId = f.questionId;
        thumbnail.fileSize = f.fileSize;
        thumbnail.thumbnail = true;
        thumbnail.thumnailsrc = imageObj.src;
        files.push(thumbnail);
      }
    }
    i++;
  }
  return {
    files: files,
    diagrams: diagrams,
    updateFiles: updateFiles,
  };
};

/**
 * When a POI has been saved, we need to pass the rowId to the callback.
 * The save POI can be a subform/task and therefore the row id would not be the value at the top level object.
 * We need to find the correct row id and return it.
 */
export const getPOIRowId = (dataPoint, response) => {
  if (!dataPoint.id || dataPoint.id === response.id) {
    return response.row_id;
  }
  if (response.newRowIds) {
    if (response.newRowIds[dataPoint.id]) {
      return response.newRowIds[dataPoint.id];
    }
  }
  return response.row_id;
};

/*
 * Constructs the request parameters for fetching map data.
 */
export const getMapFetchParams = (filtersMenu: FiltersMenuInterface, forms: FormInterface[]) => {
  const requestForms = filtersMenu.selectedForms.map((f) => {
    const index = forms.findIndex((form) => form.ref === f.ref);
    const form = forms[index];
    return { id: f.ref, iconUrl: getIconUrl(forms, form) };
  });
  const query = {
    forms: requestForms,
  };
  for (const loc of filtersMenu.selectedLocations) {
    if (!query[`location${Number(loc.level) + 1}`]) {
      query[`location${Number(loc.level) + 1}`] = [];
    }
    query[`location${Number(loc.level) + 1}`].push(loc.key);
  }
  const filter = createRequestFilters(filtersMenu);
  const params: string[] = [];
  params.push(`query=${encodeURIComponent(JSON.stringify(query))}`);
  params.push(`filter=${encodeURIComponent(JSON.stringify(filter))}`);
  params.push(`fields=${['Name', 'Coordinates', 'id'].join(',')}`);
  return params;
};
