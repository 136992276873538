import * as React from 'react';
import './filters.scss';
import 'rc-calendar/assets/index.css';
import bind from 'bind-decorator';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import DatePicker from 'rc-calendar/lib/Picker';

interface Props {
  showValue: any;
  endValue: any;
  type: string;
  onChange: (value) => void;
  value: any;
  open: any;
  onOpenChange: any;
  hidden: boolean;
}

interface State {
  hoverValue: string[];
  value: string[];
}
export default class DateRangePicker extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      hoverValue: [],
      value: []
    };
  }
  @bind
  private onHoverChange(hoverValue) {
    this.setState({ hoverValue });
  }

  public render() {
    const props = this.props;
    const { value } = props;
    const endValueDisplay = `${(value[1] && value[1].format('DD/MM/YYYY')) || ''}`;
    const showValueDisplay = `${(value[0] && value[0].format('DD/MM/YYYY')) || ''}`;
    const displayValue = `${showValueDisplay}${endValueDisplay ? '-' + endValueDisplay : ''}`;
    const className = `input-sm form-control date-input ${props.hidden ? 'd-none' : ''}`;
    const calendar = (
      <RangeCalendar
        hoverValue={this.state.hoverValue}
        onHoverChange={this.onHoverChange}
        onChange={props.onChange}
      />
    );
    return (
      <>
        <DatePicker
          onChange={this.props.onOpenChange}
          calendar={calendar}
          value={props.value}
          className={'date-picker'}
        >
          {
            () => {
              return (
                <span>
                  <input
                    className={className}
                    value={displayValue}
                  />
                </span>
              );
            }
          }
        </DatePicker>
        <span className="clear-date" onClick={() => props.onChange([])}>x</span>
      </>
    );
  }
}
