import './Style.scss';
import * as React from 'react';
import { Alert, Button, Form, InputGroup, Modal, ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import bind from 'bind-decorator';
import { ThunkDispatch } from 'redux-thunk';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { BaseModalInterface } from '../../../Interfaces/ModalInterface';
import { getLocalization } from '../../../global/global';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { ClientPersistInterface, TwoFactorMethods } from '../../../Interfaces/ClientPersistInterface';
import { updateClientPersist } from '../../../actions/clientPersistActions';
import { changePasswordOrEmail, request2FACode } from '../../../actions/userActions';
import { ChangePasswordInterface } from '../../../Interfaces/ChangePasswordInterface';
import { PasswordInput } from '../../components/PasswordInput';

interface IStateProps {
  userEmail: string;
  passwordExpired: boolean | undefined;
  twoFactorAuthEnabled?: TwoFactorMethods;
}

interface IActionProps {
  actions: {
    updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) => void;
    changePassword: (payload: ChangePasswordInterface) => Promise<boolean>;
    request2FACode: () => Promise<JSONInterface>;
  };
}

type validationState = 'success' | 'warning' | 'error' | null;

interface IError {
  state: validationState;
  message?: string;
}

interface IOwnState {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  userEmail: string | null;
  twoFACode: string;
  loading: boolean;
  errors: {
    currentPassword: IError;
    newPassword: IError;
    confirmPassword: IError;
    userEmail: IError;
    twoFACode: IError;
  };
  updating: boolean;
}

const className = 'ChangePasswordModal';

export class ChangePasswordModalClass extends React.Component<IStateProps &
IActionProps & BaseModalInterface, IOwnState> {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      userEmail: Boolean(props.userEmail) && props.userEmail !== '' ? props.userEmail : null,
      twoFACode: '',
      loading: false,
      errors: {
        currentPassword: {
          state: null,
        },
        newPassword: {
          state: null,
        },
        confirmPassword: {
          state: null
        },
        userEmail: {
          state: Boolean(props.userEmail) && props.userEmail !== '' ? 'success' : null
        },
        twoFACode: {
          state: !props.twoFactorAuthEnabled ? 'success' : null
        }
      },
      updating: false,
    };
  }

  private static isValidEmail(email: string): boolean {
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  @bind
  private handleChange({target}) {
    const copyState: IOwnState = Object.assign({}, this.state);
    const {name, value}:
    {name: 'currentPassword' | 'newPassword' | 'confirmPassword' | 'userEmail' | 'twoFACode';
      value: string; } = target;
    const errors = {...copyState.errors};
    errors[name].state = 'success';
    if (['currentPassword', 'newPassword', 'confirmPassword'].includes(name.toString())) {
      if (value === '') {
        errors[name.toString()].state = 'error';
        errors[name.toString()].message = 'required';
      } else if (['newPassword', 'confirmPassword'].includes(name.toString())) {
        if (name.toString() === 'newPassword') {
          if ((/[A-Z]/.test(value) === false
              || /[a-z]/.test(value) === false
              || /\d/.test(value) === false
              || /[\W_]/.test(value) === false
          ) && value.length < 10) {
            errors.newPassword.state = 'error';
            errors.newPassword.message = 'The password is too weak. A valid password should have' +
                ' at least one Upper case character, lower case character(s), a number and one of these' +
                ' special characters: !$^&*()_+-=:";\'?,/  . or be at least 10 characters long';
          } else if (value.length < 8) {
            errors.newPassword.state = 'error';
            errors.newPassword.message = 'The password is too short. A valid password should at ' +
                'least be 8 characters long.';
          }
          if (this.state.newPassword.length > 0 && this.state.newPassword !== value) {
            errors.confirmPassword.state = 'error';
            errors.confirmPassword.message = 'The password fields do not match. Please enter passwords again.';
          }
        } else if (value !== this.state.newPassword) {
          errors.confirmPassword.state = 'error';
          errors.confirmPassword.message = 'The password fields do not match. Please enter passwords again.';
        }
      }
    } else if (Boolean(this.state.userEmail) && name.toString() === 'userEmail') {
      if (!ChangePasswordModalClass.isValidEmail(value )) {
        errors[name.toString()].state = 'error';
        errors[name.toString()].message = 'The email provided is not valid.';
      }
    }
    copyState[name] = value;
    copyState.errors = errors;
    this.setState({...copyState});
  }

  @bind
  private async onSave() {
    const {currentPassword, newPassword, confirmPassword, userEmail, twoFACode} = this.state;
    this.setState({ updating: true });
    const updated = await this.props.actions.changePassword({
      currentPassword,
      newPassword,
      confirmPassword,
      userEmail,
      twoFACode
    });
    if (updated) {
      this.props.onClose();
    }
    this.setState({ updating: false });
  }

  private async get2FACode() {
    this.setState({ loading: true });
    await this.props.actions.request2FACode();
  }

  private FieldGroup(
    {id, label, isValid, type, onChange, name, get2FACode, ...props }
    : {id: string; label: string; isValid: IError; type: string; [key: string]: any}): JSX.Element {
    return (
      <Form.Group
        controlId={id}
      >
        <Form.Label>{label}</Form.Label>
        {type === 'password' ? (
          <PasswordInput
            {...props}
            onChange={onChange}
            value={props.value}
            name={name}
            passwordError={isValid.state === 'error'}
          />
        ) : (
          <InputGroup>
            <Form.Control
              {...props}
              name={name}
              onChange={onChange}
              isInvalid={isValid.state === 'error'}
            />
            {name === 'twoFACode' && get2FACode && (
              <InputGroup.Append>
                <Button size="sm" onClick={() => get2FACode()}>
                  {getLocalization('getCode')}
                </Button>
              </InputGroup.Append>
            )}
          </InputGroup>
        )}
        {isValid.state === 'error' && <Form.Text className="red-text">{isValid.message}</Form.Text>}
      </Form.Group>
    );
  }

  private getBody(): JSX.Element {
    const FieldGroup = this.FieldGroup;
    const { twoFactorAuthEnabled, passwordExpired } = this.props;
    return (
      <form>
        {passwordExpired && (
          <Alert variant="warning">
            {getLocalization('passwordExpired')}
          </Alert>
        )}
        <FieldGroup
          id={`${className}-current`}
          label={`${getLocalization('currentPassword')}:`}
          type="password"
          name="currentPassword"
          className="mandatory"
          value={this.state.currentPassword}
          onChange={this.handleChange}
          placeholder={`${getLocalization('currentPassword')}`}
          isValid={this.state.errors.currentPassword}
        />
        <FieldGroup
          id={`${className}-newPassword`}
          label={`${getLocalization('newPassword')}:`}
          type="password"
          name="newPassword"
          className="mandatory"
          value={this.state.newPassword}
          onChange={this.handleChange}
          placeholder={`${getLocalization('newPassword')}`}
          isValid={this.state.errors.newPassword}
          required
        />
        <FieldGroup
          id={`${className}-confirmPassword`}
          label={`${getLocalization('confirmPassword')}:`}
          type="password"
          name="confirmPassword"
          className="mandatory"
          value={this.state.confirmPassword}
          onChange={this.handleChange}
          placeholder={`${getLocalization('confirmPassword')}`}
          isValid={this.state.errors.confirmPassword}
          required
        />
        {twoFactorAuthEnabled && (
          <FieldGroup
            id={`${className}-two-factor-code`}
            label={`${getLocalization(
              twoFactorAuthEnabled === 'AUTHENTICATOR' ? 'enterAppAuthenticatorCode' : 'enterTwoFACode'
            )}:`}
            type="text"
            name="twoFACode"
            className="mandatory"
            value={this.state.twoFACode}
            onChange={this.handleChange}
            placeholder={`${getLocalization(
              twoFactorAuthEnabled === 'AUTHENTICATOR' ? 'enterAppAuthenticatorCode' : 'enterTwoFACode'
            )}`}
            isValid={this.state.errors.twoFACode}
            size="sm"
            get2FACode={
              twoFactorAuthEnabled === 'AUTHENTICATOR' ? null : () => void this.get2FACode()
            }
            required
          />
        )}
        {/* Boolean(this.state.userEmail) && (
          <FieldGroup
            id={`${className}-userEmail`}
            label={`${getLocalization('changePasswordEmail')}:`}
            type="text"
            name="userEmail"
            className="mandatory"
            value={this.state.userEmail}
            onChange={this.handleChange}
            placeholder={`${getLocalization('changePasswordEmail')}`}
            isValid={this.state.errors.userEmail}
          />
        )*/}
      </form>
    );
  }
  public render(): JSX.Element {
    const hasError = Object.keys(this.state.errors).every((key) => {
      /* if (!this.state.userEmail && key === 'userEmail') {
        // Exception since userEmail might not be needed
        return true;
      }*/
      return this.state.errors[key].state === 'success';
    });
    return (
      <Modal
        show
        onHide={this.props.onClose}
        backdrop
        className={className}
      >
        <Modal.Header closeButton={!this.props.passwordExpired}>
          <Modal.Title>
            {getLocalization('changepasswd')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.updating ? (<ProgressBar striped={true} variant="primary" animated={true} />) : this.getBody()}
        </Modal.Body>
        <Modal.Footer>
          {!this.props.passwordExpired &&  (
            <Button size="sm" variant="primary" onClick={this.props.onClose} id={'change-password-cencel-btn'}>
              {getLocalization('cancel')}
            </Button>
          )}
          <Button
            size="sm"
            variant={'primary'}
            onClick={() => void this.onSave()}
            disabled={!hasError}
            id={'change-password-btn'}
          >
            {getLocalization('changepasswd')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  const {
    useremail: userEmail,
    passwordExpired, twoFactorAuthEnabled
  } = state.clientPersist;
  return {
    userEmail, passwordExpired, twoFactorAuthEnabled
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IActionProps => {
  return {
    actions: {
      updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) => {
        dispatch(updateClientPersist(clientPersist));
      },
      changePassword: (payload: ChangePasswordInterface) => {
        return dispatch(changePasswordOrEmail(payload));
      },
      request2FACode: () => dispatch(request2FACode())
    }
  };
};

export const ChangePasswordModal = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModalClass);
