import { DocumentInterface } from 'Interfaces/DocumentInterface';
import { getLocalization, globalWindow } from 'global/global';
import * as React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import GenericModal from 'views/Modals/GenericModal';

interface Props {
  document: DocumentInterface;
}

export default function ExportButton(props: Props) {
  const { document } = props;

  const [showModal, setShowModal] = React.useState(false);
  const [includeMetadata, setIncludeMetadata] = React.useState(false);
  const [includeComments, setIncludeComments] = React.useState(false);

  const downloadFile = () => {
    const params = [`key=${globalWindow.pwrd}`, `userid=${globalWindow.userName}`];
    const url = `/json/app/document/download/${document.id}?` + params.join('&');
    globalWindow.open(url, '_blank');
  };

  const onDocumentClick = () => {
    setShowModal(true);
  };

  const downloadDocument = () => {
    setShowModal(false);
    const params = [
      `key=${globalWindow.pwrd}`,
      `userid=${globalWindow.userName}`,
      `includeMetadata=${includeMetadata ? 1 : 0}`,
      `includeComments=${includeComments ? 1 : 0}`
    ];
    const url = `/json/app/document/download/${document.id}?` + params.join('&');
    globalWindow.open(url, '_blank');
    setIncludeComments(false);
    setIncludeMetadata(false);
  };

  const modal = showModal ? (
    <GenericModal
      visible={true}
      onConfirm={downloadDocument}
      cancel={() => setShowModal(false)}
      confirmText={getLocalization('exports')}
      cancelText={getLocalization('cancel')}
      title={getLocalization('exportDocument')}
      body={(
        <>
          <Form.Group>
            <Form.Check
              id={'include-meta-data-checkbox'}
              type={'checkbox'}
              label={getLocalization('includeMetadata')}
              checked={includeMetadata}
              onChange={(e) => setIncludeMetadata(e.target.checked)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              id={'include-comments-checkbox'}
              type={'checkbox'}
              label={getLocalization('includeComments')}
              checked={includeComments}
              onChange={(e) => setIncludeComments(e.target.checked)}
            />
          </Form.Group>
        </>
      )}
    />
  ) : null;

  return (
    <>
      {modal}
      <Dropdown className="btn-group">
        <Dropdown.Toggle id="document-export" size="sm">
          <i className="fa fa-download" aria-hidden="true" />
          <span>{getLocalization('exports')}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={downloadFile}
          >
            <i className="fa fa-file" aria-hidden="true" />
            <span>{getLocalization('downloadFiles')}</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={onDocumentClick}
          >
            <i className="fa fa-file-text-o" aria-hidden="true" />
            <span>{getLocalization('document')}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
