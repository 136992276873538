import { JSONInterface } from "Interfaces/JsonInterface";
import { getRequestConfig } from "api";

export const importUsers = (file: File, user) => {
  return new Promise((resolve, reject) => {
    const headers = getRequestConfig();
    const data = { ...headers };

    const formData = new FormData();
    formData.append('file', file);
    formData.append('user', JSON.stringify(user));
    delete data.headers['Content-Type'];

    data['method'] = 'POST';
    data['body'] = formData;

    fetch('/json/admin/import/users', data).then(res => res.json()).then(json => {
      resolve(json);
    }).catch(e => {
      reject(e);
    });
  });
};

export const getAccountUserStats = () => {
  return new Promise<JSONInterface>((resolve, reject) => {
    const headers = getRequestConfig();
    const data = { ...headers };

    fetch('/json/admin/getUserStats', data).then(res => res.json()).then(json => {
      resolve(json);
    }).catch(e => {
      reject(e);
    });
  });
};
