import { getLocalization } from 'global/global';
import { JSONInterface } from 'Interfaces/JsonInterface';
import * as React from 'react';
import { Button, Row } from 'react-bootstrap';
import GenericModal from 'views/Modals/GenericModal';
import ImportHierarchy from './ImportHierarchy';
import ImportPostalCodes from './ImportPostalCodes';
import './Style.scss';

interface Props {
  workspaceEmpty: boolean;
  onSave: () => void;
  saveLocal: () => void;
  newHierarchy: () => void;
  clearWorkSpace: () => void;
  openFile: (xml: string) => void;
  addHierarchyFromJSON: (json: JSONInterface) => void;
}

export const Actions = (props: Props) => {

  const [confirm, setShowConfirm] = React.useState(false);
  const openFileInput = React.useRef<HTMLInputElement>(null);

  const onOpenFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    const files = e.target.files;
    let i = 0;
    if (files) {
      while (i < files?.length) {
        const f = files[i];
        if (!(f.type.match('text/xml') || f.type.match('text/plain') || f.type.match(''))) {
          return;
        }
        const reader = new FileReader();
        reader.onload = ((theFile) => {
          console.log(theFile);
          return (fe) => {
            if (fe) {
              const text = fe.target?.result;
              if (text && typeof text === 'string') {
                props.openFile(`${text}`);
              }
            }
          };
        })(f);
        reader.readAsText(f);
        i++;
      }
    }
    e.target.value = '';
  };

  return (
    <Row className={'action'}>
      {confirm && (
        <GenericModal
          visible={confirm}
          title={getLocalization('confirm')}
          body={<p>{getLocalization('clearLHWorkspace')}</p>}
          onConfirm={() => {
            setShowConfirm(false);
            props.clearWorkSpace();
          }}
          confirmText={getLocalization('yes')}
          cancelText={getLocalization('no')}
          cancel={() => setShowConfirm(false)}
        />
      )}
      <Button
        size={'sm'}
        onClick={props.newHierarchy}
      >
        {getLocalization('newHierarchy')}
      </Button>
      <Button
        size={'sm'}
        onClick={props.onSave}
        disabled={props.workspaceEmpty}
      >
        {getLocalization('saveLHToDatabase')}
      </Button>
      <Button
        size={'sm'}
        onClick={props.saveLocal}
        disabled={props.workspaceEmpty}
      >
        {getLocalization('saveLocal')}
      </Button>
      <Button
        size={'sm'}
        onClick={() => {
          if (openFileInput.current) {
            openFileInput.current.click();
          }
        }}
      >
        <input
          ref={openFileInput}
          className={'d-none'}
          onChange={onOpenFileChange}
          type={'file'}
          accept={'.xml, .txt, plain/text, text/xml'}
        />
        {getLocalization('open')}
      </Button>
      <Button
        size={'sm'}
        onClick={() => setShowConfirm(true)}
        disabled={props.workspaceEmpty}
      >
        {getLocalization('clear')}
      </Button>
      <ImportPostalCodes
        openFile={props.openFile}
      />
      <ImportHierarchy
        addHierarchyFromJSON={props.addHierarchyFromJSON}
      />
    </Row>
  );
};
