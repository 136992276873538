import * as React from 'react';

import { connect } from 'react-redux';
import moment from 'moment';

import { Col, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import {
  textFilter
} from 'react-bootstrap-table2-filter';

import { getLocalization, globalWindow } from 'global/global';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { Payment, PaymentRegistration } from 'Interfaces/PaymentPlanInterface';
import { getClientToken, payWithCard, registerCard } from 'actions/paymentActions';
import { StateInterface } from 'Interfaces/StateInterface';

interface StateProps {
  transactions: JSONInterface;
}

interface ActionProps {
  getClientToken: () => Promise<string>;
  registerCard: (paymentRegistration: PaymentRegistration) => Promise<boolean>;
  payWithCard: (payment: Payment) => Promise<boolean>;
}

function Transactions(props:  ActionProps & StateProps) {

  const [sizePerPage, setSizePerPage] = React.useState(10);
  const [totalSize, setTotalSize] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const sizePerPageList = [5, 10, 20, 25, 30];

  React.useEffect(() => {
    setTotalSize(props.transactions.length);
  }, [props.transactions]);

  const columns = React.useMemo(() => {
    return [{
      dataField: 'id',
      text: getLocalization('receiptNo'),
      classes: 'default-column-width',
      headerClasses: 'default-column-width header-cell',
      formatter: (cell) => {
        const url = `/json/app/epayment/ereceipt/${cell}?userid=${globalWindow.userName}&key=${globalWindow.pwrd}`;
        return (
          <a href="#" onClick={() => globalWindow.open(url, '_blank')}>{cell}</a>
        );
      },
    }, {
      dataField: 'createdAt',
      text: getLocalization('date'),
      classes: 'default-column-width',
      formatter: (cell) => `${moment(cell).format('L')} ${moment(cell).format('LT')}`,
      headerClasses: 'default-column-width header-cell',
    }, {
      dataField: 'type',
      text: getLocalization('type'),
      classes: 'default-column-width',
      headerClasses: 'default-column-width header-cell',
      filter: textFilter()
    }, {
      dataField: 'status',
      text: getLocalization('statusText'),
      classes: 'default-column-width',
      headerClasses: 'default-column-width header-cell',
      filter: textFilter(),
    }, {
      dataField: 'creditCard.maskedNumber',
      text: getLocalization('creditCard'),
      classes: 'default-column-width',
      headerClasses: 'default-column-width header-cell',
      filter: textFilter()
    }, {
      dataField: 'amount',
      text: getLocalization('amount'),
      classes: 'default-column-width',
      headerClasses: 'default-column-width header-cell',
      formatter: (cell) => `$${cell}`
    }, {
      dataField: 'customer.company',
      text: getLocalization('company'),
      classes: 'default-column-width',
      headerClasses: 'default-column-width header-cell'
    }
    ];
  }, [props.transactions]);

  const onSizePerPageChange = (sizePerPage) => {
    setSizePerPage(sizePerPage);
    setPage(1);
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  return (
    <>
      <Row>
        <Col>
          <BootstrapTable
            keyField="id"
            bootstrap4
            data={props.transactions}
            columns={columns}
            condensed
            wrapperClasses={`table-responsive table-view share_data__table`}
            selectRow={{
              mode: 'checkbox'
            }}
            noDataIndication={'No transactions data'}
            pagination={paginationFactory({
              page,
              sizePerPage,
              totalSize,
              sizePerPageList,
              onSizePerPageChange: onSizePerPageChange,
              onPageChange: onPageChange
            })}
            filter={filterFactory()}
          />
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    transactions: state.transactions
  };
};

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    getClientToken: () => dispatch(getClientToken()),
    registerCard: (paymentRegistration: PaymentRegistration) => dispatch(registerCard(paymentRegistration)),
    payWithCard: (payment: Payment) => dispatch(payWithCard(payment))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
