import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import React from 'react';
import { User } from '../../Interfaces/User';
import { getUsersSelector, selectedUsersSelector } from '../../reducers/users';
import { getLocalization } from '../../global/global';

interface IExternalProps {
  baseClassName: string;
  setSelectedUser: (user: User) => void;
}

export const ScheduleSelectUser: FunctionComponent<IExternalProps> = (props) => {
  const selectedUsers = useSelector(selectedUsersSelector);
  const allUsers = useSelector(getUsersSelector);
  const selectionList = (selectedUsers.length > 0 ? selectedUsers : allUsers).filter(u => !u.name.endsWith('-deleted'));
  const users = [
    {id: 'select', name: getLocalization('selectUser')},
    ...selectionList
  ];

  return (
    <Form.Group
      controlId="schedule.UserSelect"
      className={props.baseClassName}
    >
      <Form.Control
        as="select"
        // value={`${props.selectedUser.id}`}
        onChange={(e) => {
          if (e.currentTarget.value === 'select') {
            return;
          }
          const selected = users.find(user => `${user.id}` === e.currentTarget.value);
          if (selected) {
            props.setSelectedUser(selected as User);
          }
        }}
      >
        {users.map((user) => (
          <option
            value={`${user.id}`}
            key={user.id}
          >
            {user.name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};
