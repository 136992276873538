import { LocationInterface } from '../Interfaces/LocationInterface';
import * as locationsApi from '../api/locationsApi';
import { StateInterface } from '../Interfaces/StateInterface';
import { sortObjects } from '../utils/utils';
import { LOCATIONS } from './actionTypes';
import { actionCreator } from './index';

export const loadLocations = actionCreator<LocationInterface[]>(LOCATIONS.LOAD);

export const addLocations = actionCreator<LocationInterface[]>(LOCATIONS.ADD_NEW);

export const getLocations = () => {
  return async (dispatch, getState: () => StateInterface) => {
    const { clientPersist } = getState();
    const locations = await locationsApi.getLocations(clientPersist.user_id);
    dispatch(loadLocations(locations.sort((a, b) => sortObjects(a, b, 'title'))));
  };
};

export const addNewLocation = (locations: Pick<LocationInterface, 'title' | 'level' | 'parent' | 'identifier'>[]) => {
  return (dispatch, getState: () => StateInterface) => {
    const { hierarchy } = getState();
    const promise = locationsApi.addNewLocation(
      hierarchy.id,
      locations.map((l) => {
        return { ...l, location: l.title };
      }),
      dispatch,
      getState,
    );
    promise
      .then((response) => response.json())
      .then((json) => {
        dispatch(
          addLocations(
            json.map((l) => {
              return { ...l, key: l.id, title: l.location };
            }),
          ),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
