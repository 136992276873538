import { MittariRegistrationDetails } from 'Interfaces/User';
import { getRequestConfig } from './index';

export const doCheckLicenceValidity = async (id: number): Promise<Response> => {
  const headers = getRequestConfig();
  return fetch(`/json/app/data/checklicense/${id}`, { ...headers, method: 'POST' });
};

export const doSetupAccount = (details: MittariRegistrationDetails, token: string) => {
  const config = getRequestConfig();
  return fetch(
    `/json/app/register/mittari/finalize`,
    Object.assign(
      {},
      {
        method: 'POST',
        body: JSON.stringify({
          ...details,
          token,
        }),
      },
      config,
    ),
  );
};

export const doGetTokenDetails = (token: string) => {
  const config = getRequestConfig();
  return fetch(
    `/json/app/register/mittari/token`,
    Object.assign(
      {},
      {
        method: 'POST',
        body: JSON.stringify({
          token,
        }),
      },
      config,
    ),
  );
};
