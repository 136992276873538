import './GalleryContainer.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import Viewer from 'react-viewer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'typescript-fsa';
import { connect } from 'react-redux';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import { Alert } from 'react-bootstrap';
import { selectAnswersForGallerySelectedForm } from 'reducers/galleryItems';
import { DataPoint, FileInterface, GalleryFileInterface } from '../../Interfaces/DataPoint';
import { FormInterface, FormsInterface } from '../../Interfaces/Forms/FormsInterface';
import { GalleryMenuInterface } from '../../Interfaces/GalleryMenuInterface';
import { SingleInstanceInterface } from '../../Interfaces/ModuleSelectionInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { formsSelector } from '../../reducers/formsReducer';
import { getDateFiltersSelector, getLocationHierarchyQuerySelector } from '../../reducers/filtersMenuReducer';
import { galleryMenuSelector } from '../../reducers/galleryMenuReducer';
import { fetchGallery } from '../../actions/galleryActions';
import { setSingleInstance } from '../../actions/moduleSelectionActions';
import { ModalComponentNames, ModalInterface } from '../../Interfaces/ModalInterface';
import { navigateAddModal, navigateRemoveModal } from '../../actions/navigationAction';
import { LoadingComponent } from '../components/LoadingComponent';
import { GalleryItemComponent } from './GalleryItemComponent';

const className = 'GalleryContainer';

const LIMIT = 30;

interface IStateProps {
  forms: FormsInterface['collection'];
  galleryMenu: GalleryMenuInterface;
  locationHierarchyQuery: ReturnType<typeof getLocationHierarchyQuerySelector>;
  dateFilters: ReturnType<typeof getDateFiltersSelector>;
  answers: GalleryFileInterface[];
}

interface IActionProps {
  fetchGallery: (requestParams?: string[]) => Promise<Response>;
  setSingleInstance: (singleInstance: SingleInstanceInterface) => void;
  navigateAddModal: (modal: ModalInterface) => void;
  navigateRemoveModal: (modalName: ModalComponentNames) => void;
}

interface IGalleryItem {
  file: FileInterface;
  dataPoint: DataPoint;
  form?: FormInterface;
}

export interface IOwnState {
  offset: number;
  showViewer: boolean;
  activeIndex: number;
  subFormIds: string[];
  viewerImages: ImageDecorator[];
  galleryItems: IGalleryItem[];
  cacheGalleryItems: IGalleryItem[];
  loading: boolean;
  loaded: boolean;
  total: number;
}

export type GalleryContainerProps = IActionProps & IStateProps;

export class GalleryContainerClass extends React.Component<GalleryContainerProps, IOwnState> {
  constructor(props) {
    super(props);
    const total = this.props.answers.length > 0 ? this.props.answers[0].count : -1;
    this.state = {
      offset: props.answers.length,
      showViewer: false,
      activeIndex: -1,
      subFormIds: [],
      viewerImages: [],
      galleryItems: [],
      cacheGalleryItems: [],
      loading: false,
      loaded: false,
      total: total
    };
  }

  public componentDidMount() {
    if (this.state.total === -1) {
      this.loadGallery();
    } else {
      this.getGalleryItems();
    }
  }

  public componentDidUpdate(prevProps: GalleryContainerProps, prevState: IOwnState) {
    if (this.props.galleryMenu.selectedForm !== prevProps.galleryMenu.selectedForm) {
      this.setState({ offset: 0, galleryItems: [] } , this.loadGallery);
    }
    if (
      this.props.locationHierarchyQuery !== prevProps.locationHierarchyQuery
        || this.state.subFormIds !== prevState.subFormIds
        || this.props.dateFilters !== prevProps.dateFilters
    ) {
      this.setState({
        offset: 0,
        loading: false,
        galleryItems: [],
        total: 0,
      }, () => this.loadGallery());
    }

    if (this.props.answers !== prevProps.answers && this.props.answers.length > prevProps.answers.length) {
      const newAnswers = this.props.answers.slice(prevProps.answers.length);
      let newGalleryItems: IGalleryItem[] = [];
      const total = newAnswers[0].count;
      if (total === 0) {
        this.setState({ total });
        return;
      }
      newAnswers.forEach((dataPoint) => {
        newGalleryItems = [
          ...newGalleryItems,
          ...this.getImages(dataPoint)
        ];
      });
      const cache = this.state.cacheGalleryItems.length;
      if ((cache > 0 && (cache + newAnswers.length) < 30) || (cache === 0 && newAnswers.length < 30 && total > 0)) {
        this.setState({
          offset: this.state.offset + LIMIT,
          loading: false,
          total,
          cacheGalleryItems: [...this.state.cacheGalleryItems, ...newGalleryItems]
        }, () => this.loadGallery());
      } else {
        this.setState({
          offset: this.state.offset + LIMIT,
          loading: false,
          cacheGalleryItems: [],
          total,
          galleryItems: this.state.galleryItems.concat([...this.state.cacheGalleryItems, ...newGalleryItems])
        });
      }
    }
    if (this.state.galleryItems !== prevState.galleryItems) {
      this.updateViewerImages();
    }
  }

  private loadGallery() {
    const { galleryMenu: {selectedForm} } = this.props;
    if (this.state.loading) {
      return;
    }
    if (selectedForm) {
      if (this.props.answers.length > 0) {
        // Check if the offset has gone over the total number of POIs available.
        const total = this.state.total;
        if (total && total <= this.state.offset) {
          if (this.state.cacheGalleryItems.length > 0) {
            this.setState({
              cacheGalleryItems: [],
              galleryItems: this.state.galleryItems.concat(this.state.cacheGalleryItems)
            });
          }
          return;
        }
      }
      const params = [
        `off=${this.state.offset}`, `limit=${LIMIT}`
      ];
      const fetchPromise = this.props.fetchGallery(
        params
      );
      this.setState({ loading: true, loaded: false });
      fetchPromise.then(response => response.json()).then(json => {
        if (json.loaded) {
          if (json.count > 0) {
            this.setState({ loading: false, loaded: true });
          } else {
            if (this.state.offset === 0) {
              this.setState({ loading: false, loaded: true , total: 0 });
            } else {
              this.setState({ loading: false, loaded: true, offset: this.state.offset + LIMIT }, this.loadGallery);
            }
          }
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }

  @bind
  private onScroll(e) {
    // const st = e.target.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    // we are at the bottom
    if (e.target.scrollTop + 600 >= e.target.scrollHeight) {
      // this.setState((prevState, props) => ({ loading: true }));
      this.loadGallery();
    }
    // this.lastScrollTop = st <= 0 ? 0 : st;
  }

  @bind
  private hideViewer() {
    this.setState({ showViewer: false });
  }

  @bind
  private showViewer(id: number) {
    this.setState({
      showViewer: true,
      activeIndex: id
    });
  }

  /**
   *
   * @param dataPoint - the data point with the images
   * @param parentDataPoint - the parent datapoint if any. we need this so that we open the main form instead of
   *                           subform when the image is clicked.
   */
  private getImagesFromDataPointFiles(file: FileInterface): IGalleryItem[] {
    const {forms} = this.props;
    const form = forms.find(f => f.ref === this.props.galleryMenu.selectedForm);
    const galleryItems: IGalleryItem[] = [{
      file,
      dataPoint: {
        questionnaire_id: form ? form.ref : undefined,
        row_id: file.rowId,
      },
      form
    }];
    return galleryItems;
  }

  private getImages(file: FileInterface): IGalleryItem[] {
    return [
      ...this.getImagesFromDataPointFiles(file),
      // ...this.getImagesFromSubForms(dataPoint)
    ];
  }

  private updateViewerImages() {
    const viewerImages: IOwnState['viewerImages'] = this.state.galleryItems.map((item): ImageDecorator => {
      return {
        src: item.file.url || '',
        alt: item.file.fileName
      };
    });
    this.setState({viewerImages});
  }

  private getGalleryItems() {
    let galleryItems: IGalleryItem[] = [];
    this.props.answers.forEach((dataPoint) => {
      galleryItems = [
        ...galleryItems,
        ...this.getImages(dataPoint)
      ];
    });
    this.setState({ galleryItems });
  }

  private renderGalleryItems(): JSX.Element[] {
    return this.state.galleryItems.map((item, index) => {
      return (
        <GalleryItemComponent
          key={item.file.id}
          file={item.file}
          imageIndex={index}
          dataPoint={item.dataPoint}
          // confirmDelete={this.confirmDeleteImage}
          setSingleInstance={this.props.setSingleInstance}
          form={item.form}
          showViewer={this.showViewer}
        />
      );
    });
  }

  private getEndMessage(): JSX.Element | null {
    if (this.props.answers.length > 0) {
      // Check if the offset has gone over the total number of POIs available.
      const total = this.state.total;
      if (total === 0) {
        return (
          <Alert variant={'primary'}>
            <span>No images based on selected filters.</span>
          </Alert>
        );
      }
      if (total && total <= this.state.offset) {
        return (
          <Alert variant={'primary'}>
            {this.state.galleryItems.length === 0 ? (
              <span>No images based on selected filters.</span>
            ) : (
              <span>All images have been loaded.</span>
            )}
          </Alert>
        );
      }
    } else {
      if (this.props.answers.length === 0 && this.state.loaded) {
        return (
          <Alert variant={'primary'}>
            <span>No images based on selected filters.</span>
          </Alert>
        );
      }
    }
    return null;
  }

  public render(): JSX.Element {
    return (
      <div className={className} onScroll={this.onScroll}>
        <Viewer
          className={`${className}-viewer`}
          visible={this.state.showViewer}
          onClose={this.hideViewer}
          images={this.state.viewerImages}
          activeIndex={this.state.activeIndex}
        />
        {this.renderGalleryItems()}
        {this.state.loading && (<LoadingComponent />)}
        {this.getEndMessage()}
      </div>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  const galleryMenu = galleryMenuSelector(state);
  return {
    forms: formsSelector(state).collection,
    galleryMenu,
    locationHierarchyQuery: getLocationHierarchyQuerySelector(state),
    dateFilters: getDateFiltersSelector(state),
    answers: selectAnswersForGallerySelectedForm(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): IActionProps => {
  return {
    fetchGallery: (requestParams?: string[]) => {
      return dispatch(fetchGallery(requestParams));
    },
    setSingleInstance: (singleInstance) => dispatch(setSingleInstance(singleInstance)),
    navigateAddModal: (modal: ModalInterface) => {
      dispatch(navigateAddModal(modal));
    },
    navigateRemoveModal: (modalName: ModalComponentNames) => {
      dispatch(navigateRemoveModal(modalName));
    },
  };
};

export const GalleryContainer = connect<IStateProps, IActionProps, Record<string, never>, StateInterface>(
  mapStateToProps, mapDispatchToProps
)(GalleryContainerClass);

export default GalleryContainer;
