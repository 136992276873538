import './Style.scss';
import * as React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { StateInterface } from 'Interfaces/StateInterface';
import { ThunkDispatch } from 'redux-thunk';
import { Persistor } from 'redux-persist';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { getQueryParams } from 'utils/utils';
import { RouteComponentProps, withRouter } from 'react-router';
import ConfirmContainer from 'views/Confirm/ConfirmContainer';
import { ReactModals } from 'views/Modals/ReactModals';
import { ToastContainer } from 'react-toastify';
import Footer from 'views/Footer';
import AlertContainer from 'views/Alert/AlertContainer';
import { LoginRequestInterface } from 'Interfaces/LoginInterface';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { setupAccount } from '../../actions/systemActions';
import { ActivationInterface, MittariRegistrationDetails } from '../../Interfaces/User';
import { getLocalization, globalWindow } from '../../global/global';
import { doLogin } from '../../actions/userActions';
import ProgressContainer from '../Progress/ProgressContainer';
import { persistedStore } from '../../index';

interface ActionProps {
  setupAccount: (registrationDetails: MittariRegistrationDetails, token: string) => void;
  doLogin: (data: LoginRequestInterface, rememberme: boolean) => Promise<void>;
}

interface StateProps {
  clientPersist: ClientPersistInterface;
  persistedStore: Persistor;
}

type IExternalProps = ActionProps & StateProps & RouteComponentProps & ReactCookieProps;

const INITIAL_STATE: ActivationInterface = {
  userName: '',
  activationID: '',
};

export const Activation = (props: RouteComponentProps<> & IExternalProps) => {
  const [details, setDetails] = React.useState(INITIAL_STATE);
  const [validate, setValidate] = React.useState(false);
  const [instance] = React.useState(props.clientPersist.instance);

  React.useEffect(() => {
    const { activationID, mail } = getQueryParams(props.location.search);
    if (activationID && mail && !details.activationID && !details.userName) {
      setDetails({ activationID, userName: mail });
      void props.doLogin({
        userName: mail,
        password: '',
        activation_id: activationID,
        instance: 'poimapper'
      }, false);
    }
  }, [props]);

  React.useEffect(() => {
    const saveState = async () => {
      await props.persistedStore.flush();
    };
    const {instance: newInstance, userName, user_id, lang} = props.clientPersist;
    if (newInstance !== instance) {
      if (!!newInstance && !!userName && !!user_id) {
        // Force redux state to update persistance local storage
        void saveState();
        const hash = globalWindow.location.hash;
        globalWindow.open(`/index.jsp?lang=${lang}${hash}`, '_parent');
      }
    }
  }, [props.clientPersist]);

  const signIn = (e): false | undefined => {
    e.preventDefault();
    const { userName, activationID } = details;
    if (userName === '' || activationID === '') {
      setValidate(true);
      return false;
    }
    void props.doLogin({
      userName: details.userName,
      password: '',
      activation_id: details.activationID,
      instance: 'poimapper'
    }, false);
    return false;
  };

  return (
    <div className="container-md activation-body">
      {(
        <>
          <h4>
            {getLocalization('activate')}
          </h4>
          <div className="activation-wrapper">
            <Form noValidate>
              <Form.Group>
                <Form.Label>{getLocalization('username')}</Form.Label>
                <Form.Control
                  type="text"
                  name="userName"
                  value={details.userName}
                  size="sm"
                  onChange={(e) => setDetails({...details, userName: e.target.value })}
                  isValid={details.userName !== '' && validate}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{getLocalization('activationId')}</Form.Label>
                <Form.Control
                  type="text"
                  name="activationId"
                  value={details.activationID}
                  size="sm"
                  onChange={(e) => setDetails({...details, activationID: e.target.value })}
                  isValid={details.activationID !== '' && validate}
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                onClick={signIn}
              >
                {getLocalization('activate')}
              </Button>
            </Form>
          </div>
        </>
      )}
      <ReactModals />
      <ConfirmContainer />
      <AlertContainer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
      <ProgressContainer />
      <Footer />
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    setupAccount: (registrationDetails: MittariRegistrationDetails, token: string) => {
      dispatch(setupAccount(registrationDetails, token));
    },
    doLogin: (data: LoginRequestInterface, rememberme: boolean) => dispatch(doLogin(data, rememberme))
  };
};

const mapStateToProps = (state: StateInterface) => {
  return {
    clientPersist: state.clientPersist,
    persistedStore: persistedStore
  };
};


export const ActivationApp = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Activation));

export default withCookies(ActivationApp);
