import './ScheduleDayInfoModal.scss';
import React, { FunctionComponent } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BaseModalInterface } from '../../Interfaces/ModalInterface';
import { getLocalization } from '../../global/global';
import { ScheduleInterface } from '../../Interfaces/ScheduleInterface';

export interface DayScheduleInterface extends Omit<ScheduleInterface, 'formId' | 'initialized'> {
  title: string;
}

export interface IScheduleConfirmCopyModalProps extends BaseModalInterface {
  addNewSchedule: (copy: boolean) => void;
  userName: string;
  date: string;
}

const className = 'ScheduleConfirmCopyModal';

export const ScheduleConfirmCopyModal: FunctionComponent<IScheduleConfirmCopyModalProps> = (props) => {
  const addSchedule = (copy: boolean) => {
    props.addNewSchedule(copy);
    props.onClose(false);
  };
  return (
    <Modal
      show
      backdrop={'static'}
      className={className}
      size={'lg'}
    >
      <Modal.Header closeButton={false}>
        {getLocalization('confirm')}
      </Modal.Header>
      <Modal.Body className={`${className}-body`}>
        <p>
          {`${getLocalization('scheduleCreateCopy')}`
            .replace('{{user}}', props.userName)
            .replace('{{date}}', props.date)}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => props.onClose(true)}>
          {getLocalization('cancel')}
        </Button>
        <Button size="sm" variant="info" onClick={() => addSchedule(false)}>
          {getLocalization('scheduleSame')}
        </Button>
        <Button size="sm" variant="primary" onClick={() => addSchedule(true)}>
          {getLocalization('scheduleCopy')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
