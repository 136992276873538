import { difference } from 'lodash-es';
import { Locations } from 'Interfaces/LocationInterface';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { FeedItem } from '../../Interfaces/FeedItem';

export const getFeedRequestFilters = (
  clientPersist: ClientPersistInterface,
  filtersMenu: FiltersMenuInterface,
  locationHierarchy: Locations,
  forms: FormInterface[],
) => {
  const params: string[] = [];
  const locations = filtersMenu.selectedLocations.map((l) => l.key);
  let defaultLocations: any = [];
  let locationParam: any = [];
  const undefinedLocs = locations.indexOf('0ul0') !== -1;

  /**
   *  If a user has been assigned locations, show data
   */
  if (clientPersist.userLevel !== '' && clientPersist.assignedLocationOnly) {
    const userLocations = clientPersist.userLocations.split(',').map(Number);
    locationHierarchy.forEach((l) => {
      if (userLocations.indexOf(Number(l.parent)) !== -1) {
        userLocations.push(Number(l.key));
      }
    });
    defaultLocations = locations.filter((loc) => userLocations.indexOf(Number(loc)) !== -1);
    locationParam = defaultLocations;
  } else {
    if (locations.length > 0) {
      if (defaultLocations.length > 0) {
        if (difference(defaultLocations, locations).length === 0) {
          locationParam = [];
        }
      } else {
        locationParam = locations.filter((loc) => loc !== '0ul0');
      }
    }
  }
  const levels: string[] = [];
  locationParam.forEach((elm) => {
    const location = filtersMenu.selectedLocations.find((loc) => Number(loc.key) === Number(elm));
    if (location && location.level) {
      levels.push(`${Number(location.level) + 1}`);
    }
  });

  const f = filtersMenu.selectedForms.map((f) => f.ref);
  params.push(
    `forms=${
      f.length > 0
        ? f.join(',')
        : Array.from(new Set(forms
          .filter((form) => !form.isChild)
          .map((form) => form.ref))
        ).join(',')
    }`,
  );
  params.push(`locations=${locationParam}`);
  params.push(`undefinedlocs=${undefinedLocs}`);
  params.push(`location_id=${clientPersist.userLocations}`);
  params.push(`location_level=${clientPersist.userLevel !== '' ? Number(clientPersist.userLevel) + 1 : -1}`);
  params.push(`location_levels=${levels.join(',')}`);

  const users = filtersMenu.selectedUsers;
  const selectedDates = filtersMenu.selectedDates;
  params.push(`users=${users.length > 0 ? users.map((u) => u.id).join(',') : null}`);
  params.push(`limit=${30}`);
  params.push(`from=${selectedDates.from && selectedDates.from !== '' ? selectedDates.from : null}`);
  params.push(`to=${selectedDates.to && selectedDates.to !== '' ? selectedDates.to : null}`);
  params.push(`dateFilterBy=${selectedDates.filterBy}`);
  return params;
};

export const prepareFeed = (forms, feedData) => {
  const icons: { [key: string]: string } = {};
  const newFeed: FeedItem[] = feedData.map((feedItem) => {
    const name = feedItem.Name || feedItem.name;
    const newFeedItem: FeedItem = {
      name: name ? name : 'NO NAME',
      editor: feedItem.editor,
      modified: new Date(feedItem.modified).toString().substr(0, 24),
      createdOn: new Date(feedItem.created).toString().substr(0, 24),
      createdBy: feedItem.createdBy ? feedItem.createdBy : '',
      status: feedItem.draftPoi
        ? 'draft'
        : feedItem.statusField === '0' || feedItem.status === '0'
          ? ''
          : feedItem.Status || feedItem.statusField,
      formid: feedItem.formid,
      rowId: feedItem.row_id,
      id: feedItem.id || feedItem.questionid,
      iconUrl: '',
      formName: feedItem.formname,
      feedCount: feedItem.feedCount,
    };
    if (icons[feedItem.formid]) {
      newFeedItem.iconUrl = icons[feedItem.formid];
    } else {
      const form = forms.find((f) => f.ref === feedItem.formid);
      if (form) {
        const index = forms.indexOf(form);
        let iconUrl = form.smallIconUrl;
        if (!iconUrl) {
          iconUrl = `/icons/small/poi_${(index % 20) + 1}.png`;
        }
        icons[feedItem.formid] = iconUrl;
        newFeedItem.iconUrl = icons[feedItem.formid];
      }
    }
    return newFeedItem;
  });
  return newFeed;
};
