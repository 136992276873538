import * as React from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { getLocalization } from '../../../global/global';

interface Props {
  onChange: (e) => void;
  value: string;
  disabled?: boolean;
  name: string;
  passwordError?: boolean;
  placeholder?: string;
}

export const PasswordInput = (props: Props) => {
  const [hidePassword, setHidePassword] = React.useState(true);

  return (
    <InputGroup>
      <FormControl
        type={hidePassword ? 'password' : 'text'}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder || getLocalization('enterPassword')}
        disabled={props.disabled}
        className={props.passwordError === true ? 'is-invalid' : ''}
        size="sm"
        autoComplete="off"
        autoCapitalize="off"
        isInvalid={props.passwordError === true}
      />
      <InputGroup.Append>
        <Button
          variant="outline-secondary"
          onClick={() => setHidePassword(!hidePassword)}
          size="sm"
        >
          <i className={`fa ${hidePassword ? 'fa-eye-slash' : 'fa-eye'} fa-lg`} />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
};
