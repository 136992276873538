import '../styles/UserQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { Button, FormCheck } from 'react-bootstrap';
import { ConfirmationModal } from '../../../views/Modals/ConfirmationModal';
import { getLocalization } from '../../../global/global';
import { DeleteButtonProps } from './Containers/DeleteButtonContainer';

interface State {
  showAlert: boolean;
  deleteConfirmed: boolean;
}

export default class DeleteButton extends React.Component <DeleteButtonProps, State> {

  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      deleteConfirmed: false
    };
  }

  @bind
  private onClick() {
    this.setState({ showAlert: true });
  }

  @bind
  private deleteConfirmed() {
    const { dataPoint, unrender } = this.props;
    this.setState({ showAlert: false });
    if (dataPoint.id && dataPoint.questionnaire_id) {
      this.props.deletePOI(dataPoint.questionnaire_id, unrender, dataPoint.id);
    }
  }

  @bind
  private closeAlert() {
    this.setState({ showAlert: false });
  }

  public render(): JSX.Element {
    const { deleteConfirmed, showAlert } = this.state;
    return (
      <React.Fragment>
        {showAlert ? (
          <ConfirmationModal
            onConfirm={this.deleteConfirmed}
            onClose={this.closeAlert}
            confirmEnabled={deleteConfirmed}
            localizations={
              {
                cancel: getLocalization('cancel'),
                confirm: getLocalization('generaldelete'),
                confirmStyle: 'danger',
                header: (
                  <label>{getLocalization('confirm')}</label>
                ),
                body: (
                  <>
                    <FormCheck
                      id={'datapoint-confirm-delete-btn'}
                      checked={deleteConfirmed}
                      label={getLocalization('deletePoi')}
                      onChange={(e) => this.setState({ deleteConfirmed: e.target.checked })}
                    />
                  </>
                )
              }
            }
          />
        )
          : null }
        <Button size="sm" onClick={this.onClick} variant="danger" id="delete-btn">
          <i className="fa fa-trash" aria-hidden="true" />
        </Button>
      </React.Fragment>
    );
  }
}
