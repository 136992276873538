/*
 * Renders the Chart View.
 * For each chart, render the chart panel.
 */

import * as React from 'react';
import './ChartViewContainer.scss';
import { connect } from 'react-redux';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { Locations } from 'Interfaces/LocationInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { FormInterface, FormsInterface } from '../../Interfaces/Forms/FormsInterface';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { ChartsMenu } from '../../Interfaces/ChartsMenu';
import {
  deleteCharts, loadBarChart, loadChartData, saveChart, selectChart, unSelectChart
} from '../../actions/chartsActions';
import { ModalComponentNames, ModalInterface } from '../../Interfaces/ModalInterface';
import { navigateAddModal, navigateRemoveModal } from '../../actions/navigationAction';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { userNameSelector } from '../../reducers/clientPersistReducer';
import { ChartModel } from '../../Interfaces/ChartInterface';
import ChartPanel from './ChartPanel';

interface IStateProps {
  userName: ClientPersistInterface['userName'];
  chartsMenu: ChartsMenu;
  forms: FormsInterface;
  filtersMenu: FiltersMenuInterface;
  locationLabels: string[];
  locations: Locations;
}

interface IActionProps {
  loadChartData: (url: string, callBack: (data) => void) => void;
  loadBarChart: (chartModel: ChartModel) => Promise<Response>;
  unSelectChart: (chartId: string) => void;
  selectChart: (chartId: string) => void;
  saveChart: (chart: ChartModel, tempId?) => Promise<Response>;
  deleteChartsReport: (reportId: string) => Promise<void>;
  navigateAddModal: (modal: ModalInterface) => void;
  navigateRemoveModal: (modalName: ModalComponentNames) => void;
}

type Props = IActionProps & IStateProps;

const ChartViewContainer = (props: Props) => {
  const { selectedCharts, charts } = props.chartsMenu;
  const { locations } = props;
  return (
    <div>
      {selectedCharts.map((chartId) => {
        const chart = charts.find(c => `${c.id}` === `${chartId}`);
        if (chart) {
          const form = props.forms.collection.find(f => f.ref === chart.formId || chart.formId.startsWith(f.ref));
          if (form) {
            let parentForm: FormInterface | undefined;
            if (form.type === 'TABLE' && form.static) {
              parentForm = props.forms.collection.find(f => f.ref === form.source);
            }
            return (
              <ChartPanel
                key={chart.id ? `chart-panel-${chart.id}` : `chart-panel-${chartId}`}
                userName={props.userName}
                loadChartData={props.loadChartData}
                loadBarChart={props.loadBarChart}
                model={chart}
                form={form}
                forms={props.forms.collection}
                filtersMenu={props.filtersMenu}
                locationLabels={props.locationLabels}
                unSelectChart={props.unSelectChart}
                selectChart={props.selectChart}
                saveChart={props.saveChart}
                deleteChartsReport={props.deleteChartsReport}
                navigateAddModal={props.navigateAddModal}
                navigateRemoveModal={props.navigateRemoveModal}
                parentForm={parentForm}
                locations={locations}
              />
            );
          }
        }
        return null;
      })}
    </div>
  );
};

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    userName: userNameSelector(state),
    chartsMenu: state.chartsMenu,
    forms: state.forms,
    filtersMenu: state.filtersMenu,
    locationLabels: state.clientPersist.locationLabels,
    locations: state.locations.collection
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): IActionProps => {
  return {
    loadChartData: (url: string, callBack: (data) => void) => dispatch(loadChartData(url, callBack)),
    loadBarChart: (chartModel) => {
      return dispatch(loadBarChart(chartModel));
    },
    unSelectChart: (chartId: string) => dispatch(unSelectChart(chartId)),
    selectChart: (chartId: string) => dispatch(selectChart(chartId)),
    saveChart: (chart: ChartModel, tempId?) =>  {
      return dispatch(saveChart(chart, tempId));
    },
    deleteChartsReport: (reportId) => dispatch(deleteCharts(reportId)),
    navigateAddModal: (modal: ModalInterface) => {
      dispatch(navigateAddModal(modal));
    },
    navigateRemoveModal: (modalName: ModalComponentNames) => {
      dispatch(navigateRemoveModal(modalName));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartViewContainer);
