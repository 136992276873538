import { ThunkDispatchAction } from 'actions';
import { StateInterface } from 'Interfaces/StateInterface';
import { getRequestConfig } from './index';
import { request } from './request';

export const doReverseGeoCode = (
  lat: number,
  lng: number,
  userId: string,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const config = getRequestConfig();
  config['method'] = 'POST';
  config['credentials'] = 'same-origin';
  return request(`/json/app/geocode/reverse/${lng}/${lat}/${userId}`, config, dispatch, getState);
};

export const doGeoCodeAddress = (
  address: string,
  userId: string,
  signal: AbortSignal,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const config = getRequestConfig();
  config['method'] = 'POST';
  config['credentials'] = 'same-origin';
  return request(
    `/json/app/geocode/address/${userId}/${encodeURIComponent(address)}`,
    { ...config, signal: signal },
    dispatch,
    getState,
  );
};
