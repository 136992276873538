import { getLocationHierarchyQuerySelector } from 'reducers/filtersMenuReducer';
import { galleryMenuSelector } from 'reducers/galleryMenuReducer';
import { StateInterface } from 'Interfaces/StateInterface';
import { actionCreator } from '../actions/index';
import { FormInterface } from '../Interfaces/Forms/FormsInterface';
import * as galleryApi from '../api/galleryApi';
import { GALLERY } from './actionTypes';

export const selectGalleryForm = actionCreator<FormInterface['ref']>(GALLERY.SELECT_FORM);

export const galleryItemsLoaded = (formId, galleryItems) => ({ type: GALLERY.LOADED, formId, galleryItems });
export const fetchGallery = (requestParams?: string[]) => {
  return (dispatch, getState: () => StateInterface): Promise<Response> => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const query = getLocationHierarchyQuerySelector(state);
      const selectedForm = galleryMenuSelector(state).selectedForm;
      const filtersMenu = state.filtersMenu;
      if (filtersMenu.selectedDates.from && filtersMenu.selectedDates.from !== '') {
        query['dateFilterBy'] = filtersMenu.selectedDates.filterBy;
        query['from'] = filtersMenu.selectedDates.from;
      }
      if (filtersMenu.selectedDates.to && filtersMenu.selectedDates.to !== '') {
        query['dateFilterBy'] = filtersMenu.selectedDates.filterBy;
        query['to'] = filtersMenu.selectedDates.to;
      }
      if (filtersMenu.selectedUsers.length > 0) {
        query['users'] = filtersMenu.selectedUsers.map((u) => u.id).join(',');
      }
      if (selectedForm) {
        const params = requestParams || [];
        params.push(`query=${encodeURIComponent(JSON.stringify(query))}`);
        const response = galleryApi.doGetCharts(dispatch, getState, selectedForm, params);
        void response.then(res => res.json()).then((json) => {
          dispatch(galleryItemsLoaded(selectedForm, json));
          resolve(new Response(JSON.stringify({ loaded: true, count: json.length })));
        });
      } else {
        reject(new Response(JSON.stringify({ loaded: false })));
      }
    });
  };
};
