import { DataPoint } from './DataPoint';
import { FormInterface } from './Forms/FormsInterface';

export enum MODULES {
  FEED = 'FEED',
  MAP = 'MAP',
  TABLE = 'TABLE',
  MAP_AND_TABLE = 'MAP_AND_TABLE',
  GALLERY = 'GALLERY',
  CHARTS = 'CHARTS',
  TASKS = 'TASKS',
  SCHEDULE = 'SCHEDULE',
  SHARED_DATA = 'SHARED_DATA',
  DATA_URLS = 'DATA_URLS',
  MANAGE_MAPS = 'MANAGE_MAPS',
  MY_SCHEDULE = 'MY_SCHEDULE',
  DASHBOARD = 'DASHBOARD',
}

export interface SingleInstanceMessages {
  editMessage?: string;
  sameUserMessage?: string;
}

export interface SingleInstanceInterface {
  model: FormInterface;
  dataPoint: DataPoint;
  newAnswer: boolean;
  message?: SingleInstanceMessages;
  onSave?: (answerId: string, scheduleDate?: string, scheduleStatus?: string) => void;
  history?: boolean;
  updatedDataPoint?: DataPoint;
}

export interface ModuleSelectionInterface {
  selected: MODULES;
  singleInstance?: SingleInstanceInterface;
}
