import { RolePermissions } from 'Interfaces/Documents/PermissionInterface';
import * as React from 'react';
import Select from 'react-dropdown-select';
import { Container, Form } from 'react-bootstrap';
import { DocumentFolder } from 'Interfaces/DocumentInterface';
import { Groups } from 'Interfaces/GroupInterface';
import { getLocalization } from 'global/global';
import PermissionView from '../PermissionView';

interface Props {
  documentFolder: DocumentFolder;
  groups: Groups;
  groupId: string;
  permissions: RolePermissions;
  setGroupId: (id) => void;
  updateRolePermission: (permission, role) => void;
}

export default function GroupPermission(props: Props) {
  const { permissions: rolePermissions } = props;

  const selectedGroup = React.useCallback((id) => {
    return props.groups.filter(g => g.id === id);
  }, [props.groupId]);

  return (
    <Container>
      <Form.Group>
        <Form.Label>{getLocalization('group')}</Form.Label>
        <Select
          multi={false}
          options={props.groups}
          onChange={(u) => props.setGroupId(u.length === 1 ? `${u[0].id}` : '')}
          values={selectedGroup(Number(props.groupId))}
          labelField='name'
          valueField='id'
          searchBy='name'
          searchable
          clearable
          placeholder={''}
          create={false}
          disabled={rolePermissions['admin'].id ? true : false}
        />
      </Form.Group>
      {rolePermissions && (
        <PermissionView
          id={Number(props.documentFolder.id)}
          rolePermissions={rolePermissions}
          updatePermission={props.updateRolePermission}
        />
      )}
    </Container>
  );
}
