import './Style.scss';
import { StateInterface } from 'Interfaces/StateInterface';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import {
  DocumentFileInterface,
  DocumentFilesInterface, DocumentFolderInterface, DocumentInterface, DocumentsInterface
} from 'Interfaces/DocumentInterface';
import * as React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Persistor } from 'redux-persist';
import { ToastContainer } from 'react-toastify';
import { persistedStore } from 'index';
import { getLocalization, globalWindow } from 'global/global';
import Footer from 'views/Footer';
import { getGroups } from 'actions/groupActions';
import { GroupInterface } from 'Interfaces/GroupInterface';
import { DocumentFolderPermissions } from 'Interfaces/Documents/DocumentFolderPermissionInterface';
import {
  getDocuments, getDocumentFolders, getDocumentFiles, getDocumentFolderPermissions
} from '../../actions/documentActions';
import { Header } from '../Header/HeaderContainer';
import { ReactModals } from '../Modals/ReactModals';
import ProgressContainer from '../Progress/ProgressContainer';
import Actions from './Actions';
import DocumentFolders from './DocumentFolders';
import DocumentList from './DocumentList';
import DocumentEditor from './DocumentEditor';
import Activity from './Activity';
import DocumentView from './DocumentView';
import DocumentFiles from './DocumentFiles';
import FolderView from './FolderView';

interface StateProps {
  clientPersist: ClientPersistInterface;
  persistedStore: Persistor;
  documents: DocumentsInterface;
  documentFolders: DocumentFolderInterface;
  documentFiles: DocumentFilesInterface;
  groups: GroupInterface;
  documentFolderPermissions: DocumentFolderPermissions;
}

interface ActionProps {
  getDocuments: () => void;
  getDocumentFolders: () => void;
  getDocumentFiles: () => void;
  getGroups: () => void;
  getDocumentFolderPermissions: () => void;
}

export type VIEW = 'EDITOR' | 'DOCUMENT' | 'TAGS' | 'FILE' | 'FOLDER' | '';

const  Documents = (props: StateProps & ActionProps) => {

  const [loading, setLoading] = React.useState(false);
  const [view, setView] = React.useState<VIEW>('');
  const [search, setSearch] =  React.useState('');
  const [document, setDocument] = React.useState<DocumentInterface | undefined>(undefined);
  const [newFiles, setNewFiles] = React.useState<DocumentFileInterface[] | undefined>();
  const [selectedFolder, setSelectedFolder] = React.useState(-1);
  const [instance] = React.useState(props.clientPersist.instance);

  React.useEffect(() => {
    if (!loading) {
      if (!props.documents.loaded) {
        props.getDocuments();
      }
      if (!props.documentFolders.loaded) {
        props.getDocumentFolders();
      }
      if (!props.documentFiles.loaded) {
        props.getDocumentFiles();
      }
      if (!props.groups.loaded) {
        props.getGroups();
      }
      if (!props.documentFolderPermissions.loaded) {
        props.getDocumentFolderPermissions();
      }
      setLoading(true);
    } else if (
      props.documents.loaded && props.documentFolders.loaded && props.documentFiles.loaded && props.groups.loaded
    ) {
      setLoading(false);
    }
  }, [props]);

  React.useEffect(() => {
    if (document) {
      setView('DOCUMENT');
    }
  }, [document]);

  React.useEffect(() => {
    if (document) {
      const doc = props.documents.collection.find(d => d.id === document.id);
      if (doc) {
        setDocument(doc);
      }
    }
  }, [props.documents.collection]);

  React.useEffect(() => {
    const {instance: newInstance, userName, user_id, lang} = props.clientPersist;
    if (newInstance !== instance) {
      if (!instance || !userName || !user_id) {
        // Force redux state to update persistance local storage
        // await this.props.persistedStore.flush();
        globalWindow.open(`/login.jsp?lang=${lang}`, '_parent');
      }
    }
  }, [props.clientPersist]);

  const getView = () => {
    switch (view) {
      case 'EDITOR':
        return (
          <div className="doc-sub-view">
            <h4>{getLocalization('newDocument')}</h4>
            <DocumentEditor
              onClose={() => {
                setView('');
                setNewFiles([]);
              }}
              files={newFiles}
              selectedFolder={selectedFolder}
            />
          </div>
        );
      case 'DOCUMENT':
        if (document) {
          return (<DocumentView document={document} />);
        }
        return (<Activity folderId={selectedFolder} />);
      case 'FILE':
        return (
          <DocumentFiles
            createNewDocument={(files) => {
              setNewFiles(files);
              setView('EDITOR');
            }}
          />
        );
      case 'FOLDER':
        return (
          <FolderView
            folderId={selectedFolder}
          />
        );
      case 'TAGS':
      default:
        return (<Activity folderId={selectedFolder} />);
    }
  };

  return (
    <div className={'ReactApp'}>
      <Header />
      <ReactModals />
      <ProgressContainer />
      <Container fluid className="body-container">
        <Actions setView={setView} view={view} />
        <Row className="doc-container">
          <div className="doc-sub-view docs-left-bar">
            <Form.Group>
              <Form.Control
                type="text"
                size="sm"
                value={search}
                placeholder={getLocalization('search')}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Group>
            <DocumentFolders
              onSelect={setSelectedFolder}
            />
            <DocumentList
              search={search}
              activeId={document?.id}
              setDocument={setDocument}
              selectedFolder={selectedFolder}
            />
          </div>
          <Col className="docs-main-content">
            {getView()}
          </Col>
        </Row>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    persistedStore: persistedStore,
    clientPersist: state.clientPersist,
    documents: state.documents,
    documentFolders: state.documentFolders,
    documentFiles: state.documentFiles,
    groups: state.groups,
    documentFolderPermissions: state.documentFolderPermissions,
  };
};


const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    getDocuments: () => dispatch(getDocuments()),
    getDocumentFolders: () => dispatch(getDocumentFolders()),
    getDocumentFiles: () => dispatch(getDocumentFiles()),
    getGroups: () => dispatch(getGroups()),
    getDocumentFolderPermissions: () => dispatch(getDocumentFolderPermissions())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
