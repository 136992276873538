import * as React from 'react';
import { connect } from 'react-redux';
import Select from 'react-dropdown-select';
import { DocumentFileInterface, DocumentInterface, DocumentVersionInterface } from 'Interfaces/DocumentInterface';
import { getLocalization, globalWindow } from 'global/global';
import { Alert, Form, ProgressBar } from 'react-bootstrap';
import { Users } from 'Interfaces/User';
import { FormsInterface } from 'Interfaces/Forms/FormsInterface';
import { locationsSelector } from 'reducers/locationsReducer';
import { StateInterface } from 'Interfaces/StateInterface';
import { activeUsersSelector } from 'reducers/users';
import { formsSelector } from 'reducers/formsReducer';
import { LocationsInterface } from 'Interfaces/LocationInterface';
import { formatDate } from 'utils/utils';
import { useSelector } from 'react-redux';
import { ConfirmationModal } from 'views/Modals/ConfirmationModal';

interface Props {
  document: DocumentInterface;
  version?: DocumentVersionInterface;
  files?: DocumentFileInterface[];
  export?: boolean;
  validate?: boolean;
  updateDocument: (document: DocumentInterface) => void;
  updateVersion: (version: DocumentVersionInterface) => void;
  setFile?: (file: DocumentFileInterface) => void;
}

interface StateProps {
  users: Users;
  forms: FormsInterface;
  locations: LocationsInterface;
}

function NewDocument(props: Props & StateProps) {
  const { document, version, files, validate } = props;
  const [form, setForm] = React.useState(props.forms.collection.find(f => f.ref === document.form)?.name);
  const [uploading, setUploading] = React.useState(false);
  const [confirmFile, setConfirmFile] = React.useState(false);
  const [formData, setFormData] = React.useState<FormData | undefined>(undefined);
  const [file, setFile] = React.useState<{ name: string; format: string} | undefined>(undefined);

  const folders = useSelector((state: StateInterface) => state.documentFolders);
  const getLocations = () => {
    return document.locations.split(',')
      .map(key => {
        const loc = props.locations.collection.find(l => `${l.key}` === key);
        return loc ? loc.title : '';
      })
      .join(', ');
  };

  const [location, setLocations] = React.useState(getLocations());

  React.useEffect(() => {
    setForm(props.forms.collection.find(f => f.ref === document.form)?.name);
  }, [document.locations]);

  React.useEffect(() => {
    setLocations(getLocations());
  }, [document.form]);

  const selectedUser = React.useCallback((id) => {
    return props.users.filter(u => u.id === id);
  }, [document]);

  const selectedFolder = React.useCallback((id) => {
    return folders.collection.filter(f => f.id === id);
  }, [document.folderId]);

  const getFormat = (f: File) => {
    let format = '';
    if (f.type.match('pdf')) {
      format = 'pdf';
    } else if (f.type.match('word')) {
      format = 'word';
    } else if (f.type.match('spreadsheet')) {
      format = 'excel';
    } else {
      format = f.type;
    }
    if (format === '') {
      format = 'document';
    }
    if (document.id && document.format && document.format !== format) {
      return null;
    }
    return format;
  };

  const uploadFile = (formData, format, name) => {
    setUploading(true);
    console.log(uploading);
    void fetch("/json/atool/app/uploadMedia", {
      method : "POST",
      credentials: 'same-origin',
      headers: {
        cache: false,
        processData: false,
        contentType: false,
        'userid': globalWindow.userName,
        'key': globalWindow.pwrd
      },
      body: formData
    }).then(response => response.json()).then((json) => {
      setFormData(undefined);
      setFile(undefined);
      if (json.id && props.setFile) {
        props.setFile({
          fileId: json.id,
          name: name
        });
        props.updateDocument({...document, format });
      }
      setUploading(false);
    });
  };

  const processFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      const f = files[0];
      const format = getFormat(f);
      if (format) {
        const data = new FormData();
        data.append("file", f);
        if (props.files && props.files.length > 0) {
          setConfirmFile(true);
          setFormData(data);
          setFile({ format, name: f.name });
        } else {
          uploadFile(data, format, f.name);
        }
      } else {
        e.target.value = '';
        alert(getLocalization('formatAlert'));
      }
    }
  };

  const format = React.useCallback(() => {
    const { format } = document;
    if (format === 'excel_template') {
      return 'Excel';
    } else if (format === 'word_template') {
      return 'Word';
    } else {
      return format;
    }
  }, [document.format]);

  const getConfirmModal = React.useCallback(() => {
    return confirmFile ? (
      <ConfirmationModal
        localizations={{
          cancel: getLocalization('cancel'),
          confirm: getLocalization('ok'),
          confirmStyle: 'danger',
          header: (<strong>{getLocalization('confirm')}</strong>),
          body: (<p>{getLocalization('attachNewFile')}</p>)
        }}
        onConfirm={() => {
          setConfirmFile(false);
          uploadFile(formData, file?.format, file?.name);
        }}
        onClose={() => {
          setFormData(undefined);
          setFile(undefined);
          setConfirmFile(false);
        }}
      />
    ) : null;
  }, [confirmFile]);

  const required = (<span className="required">*</span>);

  const fileRequired = () => !props.export && required;
  return (
    <>
      {getConfirmModal()}
      <Form.Group>
        <Form.Label>{getLocalization('name')} {required}</Form.Label>
        <Form.Control
          type="text"
          size="sm"
          value={document.name}
          isInvalid={validate && !document.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.updateDocument({...document, 'name' : e.target.value})}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('subject')}</Form.Label>
        <Form.Control
          type="text"
          size="sm"
          value={document.subject}
          placeholder={getLocalization('documentTopic')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.updateDocument({...document, 'subject': e.target.value})}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('description')}</Form.Label>
        <Form.Control
          as="textarea"
          size="sm"
          rows={3}
          value={document.description}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.updateDocument({...document, 'description': e.target.value})}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('creationDate')}</Form.Label>
        <Form.Control
          type="date"
          size="sm"
          disabled={document.id ? true : false}
          value={formatDate(
            typeof document.creationDate === 'number' ? new Date(document.creationDate) : document.creationDate)
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.updateDocument({...document, 'creationDate': new Date(e.target.value)})}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('language')}</Form.Label>
        <Form.Control
          as="select"
          size="sm"
          value={document.language}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.updateDocument({...document, 'language': e.target.value})}
        >
          <option value={""} />
          <option value={"en"}>English</option>
          <option value={"es"}>Spanish</option>
          <option value={"fr"}>French</option>
          <option value={"fi"}>Finnish</option>
          <option value={"se"}>Swedish</option>
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('attachFile')} {fileRequired()} </Form.Label>
        {validate && (!files || files?.length === 0) ? (
          <Alert variant="danger">{getLocalization('attachFileWarning')}</Alert>
        ) : null}
        {files ? (
          <Form.Text as={'p'} muted>{files.map(f => f.name).join(',')}</Form.Text>
        ) : null}
        {!props.export && (
          <Form.Control
            type="file"
            size="sm"
            onChange={processFile}
          />
        )}
        {uploading ? (
          <ProgressBar
            striped={true}
            animated={true}
            now={100}
            label={'Uploading file...'}
          />
        ) : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('createdBy')}</Form.Label>
        <Select
          multi={false}
          options={props.users}
          onChange={(u) => props.updateDocument({...document, createdBy: u.length === 1 ? u[0].id : ''})}
          values={selectedUser(document.createdBy)}
          labelField='name'
          valueField='id'
          searchBy='name'
          searchable
          clearable
          placeholder={''}
          create={false}
          className={!document.createdBy ? "form-control form-control-sm is-invalid" : ""}
          disabled={true}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{getLocalization('format')}</Form.Label>
        <Form.Text as={'p'} muted>{format()}</Form.Text>
      </Form.Group>
      {!props.export ? (
        folders.collection.length > 1 && (
          <Form.Group>
            <Form.Label>{getLocalization('folder')}</Form.Label>
            <Select
              multi={false}
              options={folders.collection}
              onChange={(u) => props.updateDocument({...document, folderId: u.length === 1 ? u[0].id : undefined })}
              values={selectedFolder(document.folderId) || []}
              labelField='name'
              valueField='id'
              searchBy='name'
              searchable
              clearable
              placeholder={''}
              create={false}
            />
          </Form.Group>
        )
      ) : (
        <>
          <Form.Group>
            <Form.Label>{getLocalization('form')}</Form.Label>
            <Form.Text as={'p'} muted>{form}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>{getLocalization('location')}</Form.Label>
            <Form.Text as={'p'} muted>{location}</Form.Text>
          </Form.Group>
        </>
      )}
      {!document.id && version && (
        <>
          <Form.Group>
            <Form.Label>{getLocalization('editedBy')} {required}</Form.Label>
            <Select
              multi={false}
              options={props.users}
              onChange={(u) => props.updateVersion({...version, editor: u.length === 1 ? u[0].id : ''})}
              values={selectedUser(version.editor)}
              labelField='name'
              valueField='id'
              searchBy='name'
              searchable
              clearable
              placeholder={''}
              create={false}
              className={!version.editor ? "form-control form-control-sm is-invalid" : ""}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{getLocalization('version')} {required}</Form.Label>
            <Form.Control
              type="text"
              size="sm"
              value={version?.version}
              isInvalid={validate && !version?.version}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.updateVersion({...(version), 'version': e.target.value})}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{getLocalization('changeLog')} {required}</Form.Label>
            <Form.Control
              type="text"
              size="sm"
              value={version?.changeLog}
              isInvalid={validate && !version?.changeLog}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                props.updateVersion({...version, changeLog: e.target.value})}
            />
          </Form.Group>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    users: activeUsersSelector(state),
    forms: formsSelector(state),
    locations: locationsSelector(state)
  };
};

export default connect(mapStateToProps, {})(NewDocument);
