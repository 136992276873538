import './Style.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import marked from 'marked';
import DOMPurify from 'dompurify';
import { find } from 'lodash-es';
import { globalWindow } from 'global/global';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { QuestionInterface, questionTypes } from 'Interfaces/Forms/QuestionInterface';
import { LooseObject } from '../../../../Interfaces/LooseObject';
import OriginalFormText from '../OriginalFormText';

interface Props {
  getSubQuestions: (option: string, value: string) => JSX.Element[] | null;
  checked: boolean;
  option: LooseObject;
  handleChange: (e) => void;
  question: QuestionInterface;
  version: number;
  isTable: boolean;
  inputType: string;
  likertClass: string;
  namePrefix: string;
  edit: boolean;
  errorClass: string;
  form: FormInterface;
}

interface State {
  showSubQuestions: boolean;
}

export default class SelectQuestionOption extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    const { option, question } = props;
    this.state = {
      showSubQuestions: this.props.checked || (question.type === 'DigitalSignatureQuestion' && option.value === 'Yes')
    };
  }

  @bind
  private hasVisibleSubQuestions(option: string): boolean {
    const { question } = this.props;
    if (question.triggerValues && question.triggerValues.triggerValue) {
      for (const triggerValue of question.triggerValues.triggerValue) {
        if (triggerValue.value === option) {
          // if at least one non-hidden subquestion found, then return true
          if (find(triggerValue.action.subQuestions.question, (question) => {
            return !question.inVisible && !question.hideInSingleInstance && !question.hideInPortal;
          })) {
            return true;
          }
        }
      }
    }
    return false;
  }

  @bind
  private hasRenderableSubQuestions(option: string): boolean {
    const { question } = this.props;
    if (question.triggerValues && question.triggerValues.triggerValue) {
      for (const triggerValue of question.triggerValues.triggerValue) {
        if (triggerValue.value === option) {
          // if at least one non-hidden or calculatedvalue or lookupvalues subquestion found, then return true
          if (find(triggerValue.action.subQuestions.question, (question) => {
            return !question.inVisible && (!(question.hideInSingleInstance || question.hideInPortal) ||
              question.type === questionTypes.CALCULATED_VALUE_QUESTION ||
              question.type === questionTypes.LOOKUP_VALUES_QUESTION
            );
          })) {
            return true;
          }
        }
      }
    }
    return false;
  }

  @bind
  private handleChange(e) {
    if (!this.props.checked && !this.state.showSubQuestions) {
      this.setState({ showSubQuestions: true });
    }
    this.props.handleChange(e);
  }

  @bind
  private onClick() {
    if (this.props.inputType === 'radio' && this.props.checked) {
      // only when unselecting selected radio button
      // this.handleChange is not called in that case but is called in all other cases
      this.props.handleChange(null);
    }
  }

  /*
   * Returns the option media element.
   */
  @bind
  private getOptionMedia(option) {
    const cred = `&userid=${globalWindow.userName}&key=${globalWindow.pwrd}`;
    if (option.imageId || (option.mimeType && option.mimeType.indexOf('image') !== -1)) {
      return (
        <img
          src={`/json/atool/app/getMedia?action=optionMedia&id=${option.imageId || option.mediaId}${cred}`}
          alt=""
          className="option-media"
        />
      );
    } else if (option.mimeType && option.mimeType.indexOf('video') !== -1) {
      return (
        <video
          controls
          src={`/json/atool/app/getMedia?action=optionMedia&id=${option.mediaId}${cred}`}
          className="option-media"
        />
      );
    } else if (option.mimeType && option.mimeType.indexOf('audio') !== -1) {
      return (
        <audio
          controls
          src={`/atool/app/getMedia?action=optionMedia&id=${option.mediaId}${cred}`}
          className="option-media"
        />
      );
    }
    return null;
  }

  @bind
  private getOptionBox() {
    const { question, option, checked, inputType, namePrefix, edit, form } = this.props;
    const text = DOMPurify.sanitize(marked.parse(option.value));
    const styledOption = option.color && checked ? { backgroundColor: option.color, color: 'white' } : undefined;
    const styledClass = question.styleBoolean && checked ?
      (`styled-boolean-${option.id.toLowerCase()}` ) : '';
    const id = `question-${question.id}-option-${option.id ? option.id : option.value}`;
    return (
      <label className={`option-box ${styledClass}`} style={styledOption}>
        <input
          id={id}
          type={inputType}
          checked={checked}
          onChange={this.handleChange}
          name={namePrefix}
          data-code={option.code}
          onClick={this.onClick}
          disabled={!edit}
          value={option.id}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: text
          }}
        />
        {form.showOriginalLanguage && question.type !== questionTypes.BOOLEAN_QUESTION ? (
          <OriginalFormText formId={form.ref} viewId={option.id} />
        ) : null}
      </label>
    );
  }

  @bind
  private getStyledOptionBox() {
    const { question, option, checked, inputType, namePrefix, edit, isTable, form } = this.props;
    const text = DOMPurify.sanitize(marked.parse(option.value));
    const id = `question-${question.id}-option-${option.id ? option.id : option.value}`;
    const styledOption = option.color && checked ? { backgroundColor: option.color, color: 'white' } : undefined;
    const responsiveLayout = form.responsiveLayout && !question.text?.endsWith('---');
    const responsiveClass = !responsiveLayout && !isTable ? 'col-4' : '';
    return (
      <label
        className={`${responsiveClass} option-box option-box-default ${checked ? 'option-box-default-selected' : ''}`}
        style={styledOption}
      >
        <input
          id={id}
          type={inputType}
          checked={checked}
          onChange={this.handleChange}
          name={namePrefix}
          data-code={option.code}
          onClick={this.onClick}
          disabled={!edit}
          value={option.id}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: text
          }}
        />
        {form.showOriginalLanguage && question.type !== questionTypes.BOOLEAN_QUESTION ? (
          <OriginalFormText formId={form.ref} viewId={option.id} />
        ) : null}
      </label>
    );
  }

  public render(): JSX.Element | null {
    const { option, question, inputType, isTable, likertClass } = this.props;
    const { showSubQuestions } = this.state;
    /*
      Set the media div if available.
    */
    const mediaSrc = this.getOptionMedia(option);
    const mediaEl = mediaSrc ? (
      <div className="row col-md-12">
        {mediaSrc}
      </div>
    ) : null;

    const v = option.id;
    const opt = question.type === questionTypes.BOOLEAN_QUESTION ||
      question.type === questionTypes.DIGITAL_SIGNATURE_QUESTION ? option.id : option.value;
    // calculatedvalue, lookupvalues need to be 'rendered' even if hidden (to calculate their value)
    // but they are not shown in UI
    const sqs = !showSubQuestions || !this.hasRenderableSubQuestions(opt)
      ? null : this.props.getSubQuestions(opt, v);
    const subQuestions = sqs ? (
      <div className="container-fluid subquestions row" key={`SubQuestion_${question.id}_${option.id}_$(checked)`}>
        {sqs}
      </div>
    ) : null;

    if (question.type === questionTypes.DIGITAL_SIGNATURE_QUESTION) {
      return (
        <React.Fragment>
          {sqs}
        </React.Fragment>
      );
    }
    // Subquestion indicator
    const indicator = isTable || !this.hasVisibleSubQuestions(opt) ? null : sqs ? (
      <img
        src="/res/images/triangle-down.png"
        className="subquestion-indicator pointer"
        onClick={() => this.setState({ showSubQuestions: false })}
      />
    ) : (
      <img
        src="/res/images/triangle-right.png"
        className="subquestion-indicator pointer"
        onClick={() => this.setState({ showSubQuestions: true })}
      />
    );
    /* const id = `question-${question.id}-option-${option.id ? option.id : option.value}`;
    const styledClass = question.styleBoolean && checked ?
      (`styled-boolean-${option.id.toLowerCase()}` ) : '';
    const styledOption = option.color && checked ? { color: option.color } : undefined;*/
    return (
      <React.Fragment key={`${question.id}_${option.id}`}>
        <div
          className={`form-check ${inputType} ${likertClass} ${option.color && likertClass ? 'colored-option' : ''}`}
        >
          {(option.color || question.styleBoolean) && likertClass ? this.getOptionBox() : this.getStyledOptionBox() /* (
            <label
              className={styledClass}
              style={styledOption}
            >
              <input
                id={id}
                className={`form-check-input ${this.props.errorClass}`}
                type={inputType}
                value={v}
                name={namePrefix}
                data-code={option.code}
                onChange={this.handleChange}
                onClick={this.onClick}
                checked={checked}
                disabled={!edit}
              />
              <span
                className="option-label"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(marked.parse(option.value))
                }}
              />
            </label>
          )*/}
          {indicator}
          {mediaEl}
          {!question.styleBoolean && !question.showOptionsHorizontally && subQuestions}
        </div>
      </React.Fragment>
    );
  }
}
