import './App.scss';
import 'react-toastify/scss/main.scss';
import 'font-awesome/scss/font-awesome.scss';
import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'typescript-fsa';
import { Persistor } from 'redux-persist/es/types';
import { Cookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { AlertInterface } from 'Interfaces/AlertInterface';
import { showAlert } from 'actions';
import { Header } from './views/Header/HeaderContainer';
import { ReactModals } from './views/Modals/ReactModals';
import ProgressContainer from './views/Progress/ProgressContainer';
import { ClientPersistInterface, languagesEnum } from './Interfaces/ClientPersistInterface';
import { setLanguageClientPersist, updateClientPersist } from './actions/clientPersistActions';
import { getQueryParams } from './utils/utils';
import { getLocalization, globalWindow } from './global/global';
import { persistedStore } from './index';
import { SystemInterface } from './Interfaces/SystemInterface';
import { StateInterface } from './Interfaces/StateInterface';
import { checkUserChanges, initClientPersist, saveSetting } from './actions/userActions';
import { initWS } from './actions/websocket';
import { initUserData, logout } from './actions/userActions';
import AlertContainer from './views/Alert/AlertContainer';
import ConfirmContainer from './views/Confirm/ConfirmContainer';
import Footer from './views/Footer';
import LogoutAlert from './views/components/LogoutAlert';

interface IStateProps {
  clientPersist: ClientPersistInterface;
  system: SystemInterface;
  persistedStore: Persistor;
}

interface IActionProps {
  setLanguage: (language: languagesEnum) => void;
  initClientPersist: () => void;
  initWS: () => void;
  initUserData: () => void;
  logout: (cookies?: Cookies) => void;
  saveSetting: (name: string, value: string) => void;
  showAlert: (alert: AlertInterface) => void;
  updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) => void;
  checkUserChanges: () => void;
}

interface IExternalProps extends RouteComponentProps<Record<string, never>> {
  children?: React.ReactNode;
  showHeader: boolean;
}

interface State {
  showLogoutToast: boolean;
}

export type IAppProps = IStateProps & IActionProps & IExternalProps;

export class AppClass extends React.Component<IAppProps, State> {

  constructor(props: IAppProps) {
    super(props);
    this.state = {
      showLogoutToast: false
    };
  }

  public componentDidMount() {
    const { lang } = getQueryParams(this.props.location.search);
    const { clientPersist } = this.props;
    const loadedLanguage = lang ? lang as languagesEnum :
      clientPersist.lang ? clientPersist.lang :
        languagesEnum.en;
    if (clientPersist.lang !== loadedLanguage) {
      this.props.setLanguage(loadedLanguage);
    }
    const { instance, userName, user_id, transactionsBalance, roles } = clientPersist;
    if (!instance || !userName || !user_id) {
      this.props.initClientPersist();
    } else {
      this.props.initUserData();
    }
    this.props.initWS();
    const params = getQueryParams(this.props.location.search);
    if (params.logout && params.logout === 'true') {
      this.props.logout();
    }
    if (this.props.clientPersist.logout) {
      this.props.updateClientPersist({ logout: false });
      void this.props.persistedStore.flush();
      setTimeout(() => this.props.logout(), 1000);
    }
    this.props.saveSetting('lang', loadedLanguage);
    ReactGA.initialize('UA-40683775-3', {
      debug: true,
      gaOptions: {
        userId: `${userName}-${instance}`
      }
    });
    if (transactionsBalance && transactionsBalance <= 10 && roles === 'enumerator') {
      this.props.showAlert({ visible: true, message:
        getLocalization('transactionBalanceAlert').replace('{{balance}}', `${transactionsBalance}`)});
    }
    setInterval(() => {
      this.props.checkUserChanges();
    }, 300000);
  }

  public async componentDidUpdate(prevProps) {
    const {instance, userName, user_id, lang, checkTwoFactorAuthCode, logout} = this.props.clientPersist;
    if (prevProps.clientPersist.instance !== instance) {
      if (!instance || !userName || !user_id || checkTwoFactorAuthCode) {
        // Force redux state to update persistance local storage
        await this.props.persistedStore.flush();
        globalWindow.open(`/login.jsp?lang=${lang}`, '_parent');
      }
    }
    if (!prevProps.clientPersist.logout && logout) {
      this.setState({ showLogoutToast: true });
    }
  }

  public render(): JSX.Element {
    const { instance, userName, user_id } = this.props.clientPersist;
    let showChildren = true;
    if (!instance || !userName || !user_id) {
      showChildren = false;
    }
    return (
      <div className={'ReactApp'}>
        {this.props.showHeader && (
          <Header />
        )}
        {showChildren && this.props.children}
        <ReactModals />
        <ProgressContainer />
        <AlertContainer />
        <ConfirmContainer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
        />
        <Footer />
        {this.state.showLogoutToast && (
          <LogoutAlert logout={true} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    clientPersist: state.clientPersist,
    persistedStore: persistedStore,
    system: state.system
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): IActionProps => {
  return {
    setLanguage: (language) => (dispatch(setLanguageClientPersist(language))),
    initClientPersist: () => (dispatch(initClientPersist())),
    initWS: () => (dispatch(initWS())),
    initUserData: () => (dispatch(initUserData())),
    logout: (cookies?: Cookies) => void dispatch(logout(cookies)),
    saveSetting: (name: string, value: string) => dispatch(saveSetting(name, value)),
    showAlert: (alert: AlertInterface) => dispatch(showAlert(alert)),
    updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) =>
      dispatch(updateClientPersist(clientPersist)),
    checkUserChanges: () => dispatch(checkUserChanges())
  };
};

export const App = connect<IStateProps, IActionProps, IExternalProps, StateInterface>(
  mapStateToProps, mapDispatchToProps
)(AppClass);
export default withRouter(App);
