import './UpgradeAccount.scss';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import * as React from 'react';
import { Button, Form, FormControl, Modal } from 'react-bootstrap';
import bind from 'bind-decorator';
import { StateInterface } from '../../Interfaces/StateInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { upgradeAccount } from '../../actions/userActions';
import { BaseModalInterface } from '../../Interfaces/ModalInterface';
import { getLocalization } from '../../global/global';
import { premiumPackages, pricing, UpgradeAccountInterface } from '../../Interfaces/UpgradeAccountInterface';

interface IStateProps {
  userId: ClientPersistInterface['user_id'];
  organization: ClientPersistInterface['organization'];
}

interface IActionProps {
  actions: {
    upgradeAccount: (data: UpgradeAccountInterface) => Promise<void>;
  };
}

interface IFieldGroup {
  id: string;
  label?: string;
  labelJSX?: string;
  isValid: IError;
  children?: JSX.Element;
  [key: string]: any;
}

type validationState = 'success' | 'warning' | 'error' | null;

interface IError {
  state: validationState;
  message?: string;
}

interface IOwnState extends UpgradeAccountInterface {
  errors: {
    organization: IError;
    accountName: IError;
  };
}

const className = 'UpgradeAccountModal';

class UpdateAccountModalClass  extends React.Component<IStateProps & IActionProps & BaseModalInterface,
IOwnState> {
  constructor(props) {
    super(props);
    this.state = {
      organization: props.organization,
      accountName: '',
      premiumPackage: premiumPackages.Light,
      pricing: pricing.per_user,
      errors: {
        organization: {
          state: props.organization === '' || props.organization === null ? null : 'success'
        },
        accountName: {
          state: null
        }
      }
    };
  }

  @bind
  private onUpgrade() {
    const { ...rest } = this.state;
    void this.props.actions.upgradeAccount(rest);
    this.props.onClose();
  }

  @bind
  private handleChange({target}) {
    const copyState: IOwnState = Object.assign({}, this.state);
    const {name, value}:
    {
      name: 'organization' | 'accountName' | 'premiumPackage' | 'pricing';
      value: string | premiumPackages;
    } = target;
    const errors = {...copyState.errors};
    if (['organization', 'accountName'].includes(name)) {
      errors[name].state = 'success';
      if (value === '') {
        errors[name].state = 'error';
        errors[name].message = name === 'organization' ?
          'Fill in your organization\'s name '
          :
          'Fill in your unique account name without capital letters, numbers and special characters';
      }
    }
    // @ts-ignore
    copyState[name] = value;
    copyState.errors = errors;
    this.setState({...copyState});
  }

  private FieldGroup(args: IFieldGroup): JSX.Element {
    const {id, label, labelJSX, isValid, children, ...rest} = args;
    return (
      <Form.Group
        controlId={id}
      >
        {labelJSX ?
          <Form.Label dangerouslySetInnerHTML={{__html: labelJSX}} />
          :
          <Form.Label>{label}</Form.Label>
        }
        {children ?
          children
          :
          <Form.Control {...rest} />
        }
        <FormControl.Feedback />
        {isValid.state === 'error' && <Form.Control.Feedback>{isValid.message}</Form.Control.Feedback>}
      </Form.Group>
    );
  }

  private getBody(): JSX.Element {
    const FieldGroup = this.FieldGroup;
    return (
      <form>
        <FieldGroup
          id={`${className}-organization`}
          label={`${getLocalization('orgname')}:`}
          type="text"
          name="organization"
          className=""
          value={this.state.organization}
          onChange={this.handleChange}
          placeholder={'Organization'}
          isValid={this.state.errors.organization}
        />

        <FieldGroup
          id={`${className}-account`}
          labelJSX={`${getLocalization('accountname')}:`}
          type="text"
          name="accountName"
          className="account-name"
          value={this.state.accountName}
          onChange={this.handleChange}
          placeholder={'Account Name'}
          isValid={this.state.errors.accountName}
        />

        <FieldGroup
          id={`${className}-package`}
          labelJSX={`${getLocalization('packageMsg')}:`}
          name="premiumPackage"
          className=""
          onChange={this.handleChange}
          isValid={{state: null, message: ''}}
        >
          <React.Fragment>
            <Form.Check
              id="package-light-check"
              type="radio"
              name={'premiumPackage'}
              onChange={this.handleChange}
              value={premiumPackages.Light}
              checked={this.state.premiumPackage === premiumPackages.Light}
              label={getLocalization('light')}
            />
            <Form.Check
              id="package-pro-check"
              type="radio"
              name={'premiumPackage'}
              onChange={this.handleChange}
              value={premiumPackages.Pro}
              checked={this.state.premiumPackage === premiumPackages.Pro}
              label={getLocalization('pro')}
            />
            <Form.Check
              id="package-custom-check"
              type="radio"
              name={'premiumPackage'}
              onChange={this.handleChange}
              value={premiumPackages.Custom}
              checked={this.state.premiumPackage === premiumPackages.Custom}
              label={getLocalization('custom')}
            />
          </React.Fragment>
        </FieldGroup>

        <FieldGroup
          id={`${className}-pricing`}
          labelJSX={`${getLocalization('selpricingplan')}:`}
          name="pricing"
          className=""
          onChange={this.handleChange}
          isValid={{state: null, message: ''}}
        >
          <React.Fragment>
            <Form.Check
              name={'pricing'}
              type="radio"
              onChange={this.handleChange}
              value={pricing.per_user}
              checked={this.state.pricing === pricing.per_user}
              label={getLocalization('peruser')}
            />
            <Form.Check
              type="radio"
              name={'pricing'}
              onChange={this.handleChange}
              value={pricing.per_transaction}
              checked={this.state.pricing === pricing.per_transaction}
              label={getLocalization('pertransaction')}
            />
          </React.Fragment>
        </FieldGroup>
      </form>
    );
  }

  public render(): JSX.Element {
    const hasError = Object.keys(this.state.errors).every((key) => {
      return this.state.errors[key].state === 'success';
    });
    return (
      <Modal
        show
        onHide={this.props.onClose}
        backdrop
        className={className}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {getLocalization('intro')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.getBody()}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="primary" onClick={this.props.onClose}>
            {getLocalization('cancel')}
          </Button>
          <Button
            size="sm"
            variant={'primary'}
            onClick={this.onUpgrade}
            disabled={!hasError}
          >
            {getLocalization('upgradeacc')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  const {
    user_id: userId,
    organization
  } = state.clientPersist;
  return {
    organization,
    userId
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IActionProps => {
  return {
    actions: {
      upgradeAccount: (data: UpgradeAccountInterface) => {
        return dispatch(upgradeAccount(data));
      }
    }
  };
};

export const UpgradeAccountModal = connect(mapStateToProps, mapDispatchToProps)(UpdateAccountModalClass);
