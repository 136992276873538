import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { SystemStatus } from 'Interfaces/SystemInterface';
import { LooseObject } from '../../Interfaces/LooseObject';
import { StateInterface } from '../../Interfaces/StateInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { Locations } from '../../Interfaces/LocationInterface';
import { Users } from '../../Interfaces/User';
import { deletePOIs, fetchPOI, savePOI, sendReminders } from '../../actions/pois';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { getLocationHierarchyQuerySelector, QueryFiltersInterface } from '../../reducers/filtersMenuReducer';
import { uniqueFormsSelector } from '../../reducers/formsReducer';
import { QuestionInterface } from '../../Interfaces/Forms/QuestionInterface';
import TableComponent from './TableComponent';

interface IStateProps {
  clientPersist: ClientPersistInterface;
  locationHierarchy: Locations;
  users: Users;
  forms: FormInterface[];
  filtersMenu: FiltersMenuInterface;
  locationHierarchyQuery: ReturnType<typeof getLocationHierarchyQuerySelector>;
  selectedModule: string;
  status: SystemStatus;
}

interface IActionProps {
  fetchPOI: (
    formId: string, signal: AbortSignal, rowId?: number,
    filter?: QueryFiltersInterface[], query?: LooseObject, fields?: string, sort?: LooseObject, requestParams?: string[]
  ) => Promise<Response>;
  deletePOIs: (rowIds: string[], formId: string, callBack?: (ids: string[]) => void) => void;
  sendReminders: (pois) => void;
  savePOI: (dataPoint, parameters: JSONInterface) => Promise<Response>;
}

interface IOwnProps {
  model: FormInterface;
  data?: DataPoint[];
  tableQuestion?: QuestionInterface;
}

export type TableProps = IStateProps & IActionProps & IOwnProps;

class TableContainer extends React.Component<TableProps> {
  constructor(props) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <TableComponent {...this.props} />
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    clientPersist: state.clientPersist,
    locationHierarchy: state.locations.collection,
    users: state.users,
    forms: uniqueFormsSelector(state),
    filtersMenu: state.filtersMenu,
    locationHierarchyQuery: getLocationHierarchyQuerySelector(state),
    selectedModule: state.moduleSelection.selected,
    status: state.system.status,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): IActionProps => {
  return {
    fetchPOI: (
      formId, signal, rowId?, filter?,
      query?, fields? , sort?, requestParams?
    ) => {
      return dispatch(fetchPOI(formId, signal, rowId, filter, query, fields, sort, requestParams));
    },
    deletePOIs: (rowIds: string[], formId: string, callBack?: (ids: string[]) => void) => {
      dispatch(deletePOIs(rowIds, formId, callBack));
    },
    sendReminders: (pois) => dispatch(sendReminders(pois)),
    savePOI: (dataPoint, parameters) =>  dispatch(savePOI(dataPoint, parameters))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableContainer);
