import * as React from 'react';
import './ActionMenusContainer.scss';
import { connect } from 'react-redux';
import { ButtonGroup } from 'react-bootstrap';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { ExcelData } from 'Interfaces/ImportDataInterfaces';
import { SystemInterface } from 'Interfaces/SystemInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { LooseObject } from '../../Interfaces/LooseObject';
import { deleteReport, saveReportTemplate } from '../../actions/reports';
import {
  importData, loadImportMappings, uploadDataImport, uploadImportExcelFile, uploadMapImport
} from '../../actions/import';
import { setSingleInstance } from '../../actions/moduleSelectionActions';
import { SingleInstanceInterface } from '../../Interfaces/ModuleSelectionInterface';
import { addPoiFormsSelector } from '../../reducers/formsReducer';
import AddPoiComponent from './AddPoiComponent';
import ImportMenuComponent from './import/ImportMenuComponent';
import ReportMenuComponent from './reports/ReportMenuComponent';
import ExportMenuComponent from './ExportMenuComponent';

interface StateProps {
  clientPersist: ClientPersistInterface;
  forms: FormInterface[];
  addPoiForms: FormInterface[];
  reports: any;
  importMappings: LooseObject;
  filtersMenu: FiltersMenuInterface;
  system: SystemInterface;
}

interface ActionProps {
  saveReportTemplate: (reportName: string, parameters: string, reportId: string) => void;
  deleteReport: (reportId: string) => void;
  uploadMapImport: (file, diagramName: string, srs: string) => void;
  loadImportMappings: (formIds: string[]) => void;
  uploadDataImport: (file: File, mappings: LooseObject, formId: string, geocode: boolean) => void;
  setSingleInstance: (singleInstance: SingleInstanceInterface) => void;
  uploadImportExcelFile: (file: File, signal: AbortSignal) => Promise<Response>;
  importData: (excelData: ExcelData) => void;
}

type Props = ActionProps & StateProps;

const ActionMenusContainer = (props: Props) => {
  const { clientPersist, system } = props;
  const viewer = clientPersist.roles.indexOf('viewer') !== -1;
  const enumerator = clientPersist.roles.indexOf('enumerator') !== -1;
  const addData = !clientPersist.accessSharedDataOnly && !viewer;
  return (
    <div className="action-menu-container">
      <ButtonGroup>
        {addData && (
          <AddPoiComponent
            forms={props.addPoiForms}
            setSingleInstance={props.setSingleInstance}
            enumerator={enumerator}
            instance={clientPersist.instance || ''}
            status={system.status}
          />
        )}
        {clientPersist.roles.indexOf('enumerator') === -1  && (
          <React.Fragment>
            {!viewer && (
              <ExportMenuComponent
                forms={props.forms}
                clientPersist={props.clientPersist}
                filtersMenu={props.filtersMenu}
              />
            )}
            {!viewer && (
              <>
                <ReportMenuComponent
                  forms={props.forms}
                  clientPersist={props.clientPersist}
                  saveReportTemplate={props.saveReportTemplate}
                  deleteReport={props.deleteReport}
                  reports={props.reports}
                  filtersMenu={props.filtersMenu}
                />
                <ImportMenuComponent
                  forms={props.forms}
                  clientPersist={props.clientPersist}
                  uploadMapImport={props.uploadMapImport}
                  loadImportMappings={props.loadImportMappings}
                  importMappings={props.importMappings}
                  uploadDataImport={props.uploadDataImport}
                  uploadImportExcelFile={props.uploadImportExcelFile}
                  importData={props.importData}
                  status={system.status}
                />
              </>
            )}
          </React.Fragment>
        )}
      </ButtonGroup>
    </div>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    clientPersist: state.clientPersist,
    forms: state.forms.collection || [],
    addPoiForms: addPoiFormsSelector(state),
    reports: state.reports,
    importMappings: state.importMappings,
    filtersMenu: state.filtersMenu,
    system: state.system
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    saveReportTemplate: (reportName: string, parameters: string, reportId: string)  => {
      dispatch(saveReportTemplate(reportName, parameters, reportId) );
    },
    deleteReport: (reportId: string) => dispatch(deleteReport(reportId)),
    uploadMapImport: (file, diagramName: string, srs: string) => dispatch(uploadMapImport(file, diagramName, srs)),
    loadImportMappings: (formIds: string[]) => dispatch(loadImportMappings(formIds)),
    uploadDataImport: (file: File, mappings: LooseObject, formId: string, geocode: boolean) => {
      dispatch(uploadDataImport(file, mappings, formId, geocode));
    },
    setSingleInstance: (singleInstance) => dispatch(setSingleInstance(singleInstance)),
    uploadImportExcelFile: (file: File, signal: AbortSignal) => {
      return dispatch(uploadImportExcelFile(file, signal));
    },
    importData: (excelData: ExcelData) => dispatch(importData(excelData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionMenusContainer);
