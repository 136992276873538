import './Style.scss';
import 'rc-tree/assets/index.css';
import * as React from 'react';
import { Button, Container, Form, Row } from 'react-bootstrap';
import Tree, { TreeNode } from 'rc-tree';
import { connect } from 'react-redux';
import { StateInterface } from 'Interfaces/StateInterface';
import { DocumentFolder, DocumentFolderInterface, } from 'Interfaces/DocumentInterface';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { getLocalization, globalWindow } from 'global/global';
import GenericModal from 'views/Modals/GenericModal';
import { ThunkDispatchAction } from 'actions';
import { createFolder } from 'actions/documentActions';
import { GroupInterface } from 'Interfaces/GroupInterface';

interface StateProps {
  documentFolders: DocumentFolderInterface;
  groups: GroupInterface;
  clientPersist: ClientPersistInterface;
}

interface ActionProps {
  createFolder: (folder: DocumentFolder, signal: AbortSignal) => void;
}

interface Props {
  onSelect: (id: number) => void;
}

interface FolderTreeNode extends DocumentFolder {
  children?: FolderTreeNode[];
  title?: JSX.Element;
}

function DocumentFolders(props: Props & StateProps & ActionProps) {

  const className = 'FolderViewTree';
  const abortController = React.useRef(new AbortController());
  const [tree, setTree] = React.useState<FolderTreeNode[]>([]);
  const [show, setShow] = React.useState(true);
  const [newFolder, setNewFolder] = React.useState<DocumentFolder | undefined>();
  const [validate, setValidate] = React.useState(false);

  React.useEffect(() => {
    const t: FolderTreeNode[] = [];
    const folders: FolderTreeNode[] = props.documentFolders.collection.map(f => {
      return {...f};
    });
    for (const folder of folders) {
      if (!folder.parentId) {
        setFolderTitle(folder);
        t.push(folder);
      } else {
        for (const f of folders) {
          if (f.id === folder.parentId) {
            f.children = f.children || [];
            setFolderTitle(folder);
            f.children?.push(folder);
          }
        }
      }
    }
    setTree(t);
  }, [props.documentFolders.collection]);

  const onSelect = (selected) => {
    if (selected) {
      props.onSelect(selected.item.id);
    } else {
      props.onSelect(-1);
    }
  };

  const addFolder = (parent: number) => {
    setNewFolder({
      name: '',
      parentId: parent,
      groupId: Number(globalWindow.groupID)
    });
    console.log(`adding folder ${parent}`);
  };

  const createFolder = () => {
    if (newFolder && newFolder.name) {
      props.createFolder(newFolder, abortController.current.signal);
      setNewFolder(undefined);
    } else {
      setValidate(true);
    }
  };

  const setFolderTitle = (folder: FolderTreeNode) => {
    folder.title = (
      <>
        <span>{folder.name}</span>
        {['enumerator', 'viewer'].indexOf(props.clientPersist.roles) === -1 && (
          <div
            className="btn-tree-icon"
            onClick={() => addFolder(Number(folder.id))}
          >
            <li className="fa fa-plus" aria-hidden="true" />
          </div>
        )}
      </>
    );
  };

  const loop = (data: FolderTreeNode[]) => {
    const tree = data.map(item => {
      if (item.children) {
        return (
          <TreeNode
            key={`folder-view-${item.id}`}
            title={item.title}
            icon={(<span className="rc-tree-iconEle rc-tree-icon__close" />)}
            item={item}
          >
            {loop(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={`folder-view-${item.id}`}
          title={item.title}
          icon={(<span className="rc-tree-iconEle rc-tree-icon__close" />)}
          item={item}
        />
      );
    });
    /* if (top) {
      const newFilter = new Set(filterKeys.current);
      const newFilterArray = Array.from(newFilter);
      const filteredArray = newFilterArray.filter(value => !stateFilterKeys.includes(value));
      if (filteredArray.length > 0) {
        setStateFilterKeys(Array.from(newFilter));
      }
    }*/
    return tree;
  };

  const modal = newFolder ? (
    <GenericModal
      visible={true}
      title='New folder'
      body={(
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            value={newFolder.name}
            isInvalid={validate && !name}
            onChange={(e) => setNewFolder({...newFolder, name: e.target.value})}
          />
        </Form.Group>
      )}
      onConfirm={createFolder}
      cancel={() => setNewFolder(undefined)}
      confirmText={getLocalization('save')}
      cancelText={getLocalization('cancel')}
    />
  ) : null;

  return (
    <Row className="folder-tree-view">
      {modal}
      <Container>
        <Button
          variant='light'
          className="pull-right"
          onClick={() => setShow(!show)}
        >
          <i className={`fa fa-folder${show ? '-open' : ''}`} />
        </Button>
      </Container>
      {show ? (
        <Tree
          className={`${className}-tree`}
          checkable={false}
          showLine={true}
          showIcon={true}
          onSelect={(key, e) => onSelect(e.selected ? e.node : null)}
        >
          {loop(tree)}
        </Tree>
      ) : null}
    </Row>
  );
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    documentFolders: state.documentFolders,
    groups: state.groups,
    clientPersist: state.clientPersist
  };
};

const mapActionsToProps = (dispatch: ThunkDispatchAction): ActionProps => {
  return {
    createFolder: (folder: DocumentFolder, signal: AbortSignal) => dispatch(createFolder(folder, signal))
  };
};

export default connect(mapStateToProps, mapActionsToProps)(DocumentFolders);
