import { AnyAction, ThunkDispatch, createAsyncThunk } from "@reduxjs/toolkit";
import { StateInterface } from "Interfaces/StateInterface";
import { addedChartToDashboard, dashboardsLoaded, updateDashboardLayout } from "../reducers/dashboard";
import { request } from "../api/request";
import { getRequestConfig } from "../api";
import { DASHBOARD_ACTIONS } from "./actionTypes";

export const selectDashboard = (active: string) => ({
  type: DASHBOARD_ACTIONS.SELECT, active
});

export const saveNewDashboard = createAsyncThunk(
  'dashboard/new',
  async (name: string, thunkApi) => {
    const state = thunkApi.getState() as StateInterface;
    const config = getRequestConfig();
    const data = await request(
      `/json/chart/dashboard`,
      {...config, method: 'POST', body: JSON.stringify({ name })},
      thunkApi.dispatch as ThunkDispatch<StateInterface, any, AnyAction>,
      () => state
    ).then(res => res.json()).catch(e => {
      console.log(e);
    });
    if (data.id) {
      return data;
    }
    return null;
  }
);

export const getDashboards = createAsyncThunk(
  'dashboards/get',
  async (arge, thunkApi) => {
    const state = thunkApi.getState() as StateInterface;
    const config = getRequestConfig();
    const data = await request(
      `/json/chart/dashboards`,
      {...config},
      thunkApi.dispatch as ThunkDispatch<StateInterface, any, AnyAction>,
      () => state
    ).then(res => res.json()).catch(e => {
      console.log(e);
    });
    if (Array.isArray(data)) {
      thunkApi.dispatch(dashboardsLoaded(data));
      return data;
    }
    return null;
  }
);


export const addChartToDashboard = createAsyncThunk(
  'dashboard/addChart',
  async (args: { dashboardId: number; chartId: number}, thunkApi) => {
    const state = thunkApi.getState() as StateInterface;
    const config = getRequestConfig();
    const data = await request(
      `/json/chart/dashboard/${args.dashboardId}/${args.chartId}`,
      {...config, method: 'POST'},
      thunkApi.dispatch as ThunkDispatch<StateInterface, any, AnyAction>,
      () => state
    ).then(res => res.json()).catch(e => {
      console.log(e);
    });
    if (data.id) {
      thunkApi.dispatch(addedChartToDashboard(data));
      const dashboard = state.dashboard.collection.find(d => d.id = args.dashboardId);
      if (dashboard) {
        const layout = dashboard.layout || [];
        const length = layout.length;
        const newLayout = layout.concat([{
          i: `${args.chartId}`,
          x: (length % 2) * 2,
          y: Math.floor( length / 2),
          w: 1,
          h: 4
        }]);
        void thunkApi.dispatch(updateLayout({ dashboardId: dashboard.id, layout: JSON.stringify(newLayout)}));
      }
      return data;
    }
    return null;
  }
);

export const removeChartFromDashboard = createAsyncThunk(
  'dashboard/removeChart',
  async (args: { dashboardId: number; chartId: number}, thunkApi) => {
    const state = thunkApi.getState() as StateInterface;
    const config = getRequestConfig();
    const data = await request(
      `/json/chart/dashboard/${args.dashboardId}/${args.chartId}`,
      {...config, method: 'GET'},
      thunkApi.dispatch as ThunkDispatch<StateInterface, any, AnyAction>,
      () => state
    ).then(res => res.json()).catch(e => {
      console.log(e);
    });
    if (data.id) {
      const dashboard = state.dashboard.collection.find(d => d.id = args.dashboardId);
      if (dashboard) {
        const layout = dashboard.layout || [];
        const newLayout = layout.filter(l => l.i !== `${args.chartId}`);
        void thunkApi.dispatch(updateLayout({ dashboardId: dashboard.id, layout: JSON.stringify(newLayout)}));
      }
      thunkApi.dispatch(addedChartToDashboard(data));
      return data;
    }
    return null;
  }
);

export const updateLayout = createAsyncThunk(
  'dashboard/updateLayout',
  async (args: { dashboardId: number; layout: string}, thunkApi) => {
    const state = thunkApi.getState() as StateInterface;
    const config = getRequestConfig();
    await request(
      `/json/chart/dashboard/layout/${args.dashboardId}`,
      {...config, method: 'POST', body: args.layout},
      thunkApi.dispatch as ThunkDispatch<StateInterface, any, AnyAction>,
      () => state
    ).then(res => res.json()).catch(e => {
      thunkApi.dispatch(updateDashboardLayout({...args}));
      console.log(e);
    });
    return null;
  }
);
