import '../styles/GPSQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { getLocalization } from '../../../global/global';
import MapContainer from '../../Map/MapContainer';
import MapSelectButton from '../Buttons/MapSelectButton';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { GPSQuestionPropsInterface } from '../Containers/GPSQuestionContainer';
import { getDiagram } from '../utils/DiagramUtils';
import { processGeocodedData } from '../utils/utils';

interface State {
  value: LooseObject;
  editable: boolean;
  diagramName: string | undefined;
}

/*
  Renders the GPS question and the map.
*/
export default class GPSQuestion extends React.Component <GPSQuestionPropsInterface, State> {

  constructor(props) {
    super(props);
    const { dataPoint, question } = this.props;
    this.state = {
      value: dataPoint[question.id] ? this.getValue(dataPoint[question.id]) : { lat : '', lng: '' },
      editable: false,
      diagramName: undefined
    };
  }

  @bind
  private getValue(value: string): LooseObject {
    if (value.indexOf('POINT(') !== -1) {
      value = value.replace('POINT', '');
      value = value.replace(')', '');
      const vals = value.split(' ');
      return { lat: vals[1], lng: vals[0] } as LooseObject;
      // values = vals.reverse() # reverse to lat lon
    } else {
      const values = value.split(' ');
      return { lat: values[0], lng: values[1] } as LooseObject;
    }
  }

  @bind
  private handleChange(e) {
    const oldValue = Object.assign({}, this.state.value);
    oldValue[e.target.name] = e.target.value;

    this.setState({ value: oldValue });
    const { updateAnswer, question } = this.props;
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = oldValue.lat + ' ' + oldValue.lng;
      updateAnswer(newAns);
    }
  }

  @bind
  private onEditable() {
    this.setState({ editable: !this.state.editable });
  }

  @bind
  private onFeatureAdded(e) {
    const latlng = e.layer.getLatLng();
    const value = { lat: Number(latlng.lat.toFixed(6)), lng: Number(latlng.lng.toFixed(6)) };
    this.setState({ value });
    const { updateAnswer, question } = this.props;
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value.lat + ' ' + value.lng;
      updateAnswer(newAns);
      this.reverseGeoCode();
    }
  }

  @bind
  private reverseGeoCode() {
    const { formUtils } = this.props;
    if (formUtils.getModel().includeAddress) {
      const { reverseGeoCode } = this.props;
      const { value } = this.state;
      reverseGeoCode(value.lat, value.lng, this.processGeoCodedData);
    }
  }

  @bind
  private processGeoCodedData(geoCodedData) {
    const { updateAnswer, formUtils } = this.props;
    if (geoCodedData.mappedLocations) {
      formUtils.setMappedLocations(geoCodedData.mappedLocations);
    }
    if (geoCodedData.data) {
      updateAnswer(processGeocodedData(geoCodedData, formUtils).data);
    }
    /* if (geoCodedData.data) {
      const locations = pick(geoCodedData.data, ['location1', 'location2', 'location3', 'location4']);
      if (keys(locations).length > 0) {
        let i = 1;
        while (i <= 4) {
          if (!geoCodedData.data[`location${i}`]) {
            geoCodedData.data[`location${i}`] = null;
          }
          i++;
        }
      }
      updateAnswer(geoCodedData.data);
    }*/
  }
  /*
    Here we update the state.
    1. Check if there is a diagram which has been selected/not selected.
        - if a diagram has been selected and the Data point allows adding Datapoints to diagrams,
          then we use the diagram as the main map.
  */
  public static getDerivedStateFromProps(props: GPSQuestionPropsInterface, state: State) {
    const { formUtils, dataPoint, parentDataPoint } = props;
    let diagram;
    // if this is a main form which allows it's coordinates to be added to a diagram
    // or it is a subform which can have it's own background diagram.
    if ((formUtils.getModel().addToDiagram && formUtils.getModel().backgroundDiagram)
      || (formUtils.getModel().isChild && formUtils.getModel().backgroundDiagram)) {
      diagram = getDiagram(dataPoint['files']);
    } else if (formUtils.getModel().isChild && parentDataPoint) {
      diagram = getDiagram(parentDataPoint['files']);
    }
    if (diagram) {
      if (state.diagramName !== diagram) {
        return { diagramName: diagram };
      }
    } else if (state.diagramName) {
      return { diagramName: undefined };
    }
    if (dataPoint['Coordinates'] && dataPoint['Coordinates'] !== (`${state.value.lat} ${state.value.lng}`)) {
      const values = dataPoint['Coordinates'].split(' ');
      if (values.length === 2) {
        return { value: { lat: values[0], lng: values[1] }};
      }
    }
    return null;
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value || this.state.editable !== nextState.editable
      || this.state.diagramName !== nextState.diagramName
      || nextProps.dataPoint.Coordinates !== this.props.dataPoint.Coordinates;
  }

  public render(): JSX.Element {
    const { question, formUtils, edit  } = this.props;
    const required = question.optional ? null : (<span className="text-danger">*</span>);
    // const map = this.state.editable ? (<MapComponent onFeatureAdded={this.onFeatureAdded} model={this.state.value}/>)
    // : null;
    const coordinateField = (
      <div className="row gps-row">
        <div className="col-sm-5 col-xs-12">
          <label className="coordinateField">
            {getLocalization('longitude')}
            {required}
          </label>
          <input
            className="form-control GPSQuestionInputLongitude coordinateField"
            name="lng"
            type="number"
            value={this.state.value.lng}
            onChange={this.handleChange}
            readOnly={!this.state.editable}
          />
        </div>
        <div className="col-sm-5 col-xs-12">
          <label className="coordinateField">
            {getLocalization('latitude')}
            {required}
          </label>
          <input
            className="form-control GPSQuestionInputLatitude coordinateField"
            name="lat"
            type="number"
            value={this.state.value.lat}
            onChange={this.handleChange}
            readOnly={!this.state.editable}
          />
        </div>
        {edit && (
          <div className="col-sm-2 col-xs-12 btn-div">
            <button type="button" className="btn btn-primary btn-sm" onClick={this.onEditable}>

              {getLocalization('edit')}
            </button>
          </div>
        )}
      </div>
    );

    return (
      <React.Fragment>
        {
          formUtils.getModel().addToDiagram ? (
            <MapSelectButton
              groupDiagrams={this.props.groupDiagrams}
              updateAnswer={this.props.updateAnswer}
              dataPoint={this.props.dataPoint}
            />
          ) : this.props.formUtils.getModel().isChild && this.state.diagramName ? null : coordinateField
        }
        <div className="container-fluid map">
          <MapContainer
            id={formUtils.getModel().ref}
            onFeatureAdded={edit ? this.onFeatureAdded : undefined}
            dataPoint={this.props.dataPoint}
            diagramName={this.state.diagramName}
          />
        </div>
      </React.Fragment>
    );
  }
}
