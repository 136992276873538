import './Style.scss';
import * as React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { StateInterface } from 'Interfaces/StateInterface';
import { ThunkDispatch } from 'redux-thunk';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { getQueryParams, isNullOrUndefined } from 'utils/utils';
import { RouteComponentProps, withRouter } from 'react-router';
import ConfirmContainer from 'views/Confirm/ConfirmContainer';
import { ReactModals } from 'views/Modals/ReactModals';
import { ToastContainer } from 'react-toastify';
import Footer from 'views/Footer';
import AlertContainer from 'views/Alert/AlertContainer';
import { PasswordInput } from '../components/PasswordInput';
import { checkToken, setupAccount } from '../../actions/systemActions';
import { MittariRegistrationDetails } from '../../Interfaces/User';
import { getLocalization, globalWindow } from '../../global/global';

interface ActionProps {
  setupAccount: (registrationDetails: MittariRegistrationDetails, token: string) => void;
  checkToken: (token: string) => Promise<Response>;
}

type IExternalProps = ActionProps & RouteComponentProps & ReactCookieProps;

const INITIAL_STATE: MittariRegistrationDetails = {
  userName: '',
  accountName: '',
  password: '',
  googleSignIn: false
};

export const MittariRegistration = (props: RouteComponentProps & IExternalProps) => {
  const [details, setDetails] = React.useState(INITIAL_STATE);
  const [validate, setValidate] = React.useState(false);
  const [invalidToken, setInvalidToken] = React.useState(false);
  const [invalidTokenMsg, setInvalidTokenMsg] = React.useState('');

  React.useEffect(() => {
    const { token, lang } = getQueryParams(props.location.search);
    if (isNullOrUndefined(lang)) {
      globalWindow.location = `/accountSetup.jsp?lang=fi&token=${token}`;
    }
    const response = props.checkToken(token);
    void response.then(res => res.json()).then(json => {
      if (!json.errorCode) {
        setDetails({...details, userName: json.email });
      } else {
        if (json.errorCode === '1085') {
          setInvalidTokenMsg(getLocalization('invalidToken'));
        } else if (json.errorCode === '1086') {
          setInvalidTokenMsg(getLocalization('registeredAlready'));
        }
        setInvalidToken(true);
      }
    });
  }, [props]);

  const validateAccountName = () => {
    return details.accountName.match(/^[a-z]+$/g);
  };

  const finishRegistration = (e): false | undefined => {
    e.preventDefault();
    const { userName, googleSignIn } = details;
    if (!validateAccountName() || userName === '' || (!googleSignIn && !validPassword())) {
      setValidate(true);
      return false;
    }
    const { token } = getQueryParams(props.location.search);
    props.setupAccount(details, token);
    return false;
  };

  const validPassword = () => {
    if (((/[A-Z]/.test(details.password) === false
    || /[a-z]/.test(details.password) === false
    || /\d/.test(details.password) === false
    || /[\W_]/.test(details.password) === false
    ) && details.password.length < 10)) {
      return false;
    }
    return true;
  };

  return (
    <div className="container-md registration-body">
      {invalidToken && (
        <h4>{invalidTokenMsg}</h4>
      )}
      {!invalidToken && (
        <>
          <h4>
            {getLocalization('accountSetupHeader')}
          </h4>
          <Form noValidate>
            <Form.Group>
              <Form.Label>{getLocalization('accountname')}</Form.Label>
              <Form.Control
                type="text"
                name="accountName"
                value={details.accountName}
                size="sm"
                onChange={(e) => setDetails({...details, accountName: e.target.value })}
                isInvalid={validate && validateAccountName() === null}
                isValid={validate && validateAccountName() !== null}
              />
              {validate && !validateAccountName() && (
                <Form.Text className="text-muted">
                  {getLocalization('accountNameInfo')}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>{getLocalization('username')}</Form.Label>
              <Form.Control
                type="text"
                name="userName"
                value={details.userName}
                size="sm"
                onChange={(e) => setDetails({...details, userName: e.target.value })}
                isValid={details.userName !== ''}
              />
            </Form.Group>
            {!details.googleSignIn && (
              <Form.Group>
                <Form.Label>{getLocalization('password')}</Form.Label>
                <PasswordInput
                  value={details.password}
                  onChange={(e) => setDetails({...details, password: e.target.value })}
                  name="password"
                  passwordError={validate && !validPassword() && !details.googleSignIn}
                />
              </Form.Group>
            )}
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label={getLocalization('useGoogleSignin')}
                name="googleSignin"
                onChange={(e) => setDetails({...details, googleSignIn: e.target.checked, password: '' })}
              />
              <Form.Text className="text-muted">
                {getLocalization('googleSigninInfo')}
              </Form.Text>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              onClick={finishRegistration}
            >
              {getLocalization('setupAccount')}
            </Button>
            { validate && !validPassword() && !details.googleSignIn && (
              <Container fluid className="password-info">
                <Alert variant={'info'}>
                  <p>{getLocalization('passwordRule')}</p>
                  <ul>
                    <li>{getLocalization('passwordRule1')}</li>
                    <li>{getLocalization('passwordRule2')}</li>
                  </ul>
                </Alert>
              </Container>
            )}
          </Form>
        </>
      )}
      <ReactModals />
      <ConfirmContainer />
      <AlertContainer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
      <Footer />
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    setupAccount: (registrationDetails: MittariRegistrationDetails, token: string) => {
      dispatch(setupAccount(registrationDetails, token));
    },
    checkToken: (token) => dispatch(checkToken(token))
  };
};


export const MittariRegistrationApp = withRouter(
  connect(null, mapDispatchToProps)((MittariRegistration)));

export default withCookies(MittariRegistrationApp);
