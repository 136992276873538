import { getLocalization } from 'global/global';
import * as React from 'react';
import { Button, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { StateInterface } from 'Interfaces/StateInterface';
import { VIEW } from '..';

interface Props {
  view: VIEW;
  setView: (view: VIEW) => void;
}

export default function Actions(props: Props) {
  const { roles } = useSelector((state: StateInterface) => state.clientPersist);
  return (
    <Row className="container-fluid">
      <Button
        size="sm"
        active={props.view === 'EDITOR'}
        onClick={() => props.setView('EDITOR')}
        disabled={`viewer` === roles}
      >
        {getLocalization('addDocument')}
      </Button>
      <Button
        size="sm"
        active={props.view === ''}
        className="ml-1"
        onClick={() => props.setView('')}
      >
        {getLocalization('activity')}
      </Button>
    </Row>
  );
}
