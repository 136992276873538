import { ModalComponentNames, ModalInterface } from 'Interfaces/ModalInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { navigateAddModal, navigateRemoveModal } from 'actions/navigationAction';
import { getLocalization } from 'global/global';
import { useAppDispatch } from 'index';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Setup2FAModalProps } from 'views/Modals/Setup2FAModal';
import NavCheckBox from 'views/components/NavCheckBox';

export default function TwoFASetting() {
  const clientPersist = useSelector((state: StateInterface) => state.clientPersist);
  const dispatch = useAppDispatch();

  const updateTwoFa = (checked: boolean) => {
    if (checked) {
      setup2FA(clientPersist.useremail);
    }
  };

  const setup2FA = (email?: string) => {
    const modalProps: ModalInterface<Setup2FAModalProps> = {
      component: ModalComponentNames.Setup2FAModal,
      props: {
        onClose: () => {
          dispatch(navigateRemoveModal(ModalComponentNames.Setup2FAModal));
        },
        email,
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  return (
    <NavCheckBox
      label={getLocalization('useTwoFA')}
      checked={['true', 'EMAIL', 'AUTHENTICATOR'].indexOf(`${clientPersist.twoFactorAuthEnabled}`) > -1 || false}
      disabled={['true', 'EMAIL', 'AUTHENTICATOR'].indexOf(`${clientPersist.twoFactorAuthEnabled}`) > -1}
      onChange={updateTwoFa}
    />
  );
}
