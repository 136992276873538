import * as React from 'react';
import bind from 'bind-decorator';
import { LooseObject } from '../../../Interfaces/LooseObject';
import FormUtils from '../utils/FormUtils';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { hasScript } from '../utils/utils';
import { getMediaView } from '../utils/qnrenderer';
import QuestionLabel from './QuestionLabel';
import { Elements } from './index';

interface Props {
  question: LooseObject;
  dataPoint: DataPoint;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isSubQuestion?: boolean;
  edit: boolean;
  isTable: boolean;
}

interface State {
  hasScript: boolean;
}

export default class LabelQuestion extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      hasScript: hasScript(props.question)
    };
  }

  /*
    renders include file.
  */
  @bind
  private renderIncludeFile(): JSX.Element[] | undefined {
    const { question, dataPoint, formUtils, updateAnswer } = this.props;
    if (question.includeFile || formUtils.getModel().childUI) {
      return [(
        <Elements.FileQuestion
          key={`include-file-label-question-${question.id}-${dataPoint.id}`}
          accept={question.accept}
          question={question}
          dataPoint={dataPoint}
          updateAnswer={updateAnswer}
          edit={formUtils.getModel().childUI ? false : this.props.edit}
        />
      )];
      /* const types = question.accept.split(',');
      const fileInputs = types.map((type, index) => {
        return (
          <Elements.FileQuestion
            key={`${question.id}-file-${index}`}
            accept={type}
            question={question}
            dataPoint={dataPoint}
            updateAnswer={updateAnswer}
            edit={this.props.edit}
          />
        );
      });
      return fileInputs;*/
    }
    return undefined;
  }

  public shouldComponentUpdate(nextProps) {
    return this.props.dataPoint.files !== nextProps.dataPoint.files || this.state.hasScript;
  }

  public render(): JSX.Element {
    const { question, formUtils, dataPoint, isTable } = this.props;
    return (
      <div className={`form-group ${formUtils.getResponsiveView(question)}`}>
        {getMediaView(question)}
        <QuestionLabel
          question={question}
          dataPoint={dataPoint}
          formUtils={formUtils}
          isTable={isTable}
        />
        {this.renderIncludeFile()}
      </div>
    );
  }
}
