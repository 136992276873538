import * as React from 'react';
import { connect } from 'react-redux';
import { getDataPointDocumentVersions } from 'actions/documentActions';
import { SingleInstanceComponentClass } from 'views/SingleInstance/SingleInstanceComponent';
import { LooseObject } from 'Interfaces/LooseObject';
import { DocumentInterface } from 'Interfaces/DocumentInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import ExportButton from '../ExportButton';

interface Props {
  dataPoint: DataPoint;
  model: LooseObject;
  singleInstance: SingleInstanceComponentClass;
  history?: boolean;
}

interface ActionProps {
  getDataPointDocumentVersions: (id: string, format: string, signal: AbortSignal) => Promise<DocumentInterface[]>;
}

export type ExportButtonProps = Props & ActionProps;

const ExportButtonContainer = (props: ExportButtonProps) => {
  return (
    <ExportButton {...props} />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDataPointDocumentVersions: (id: string, format, signal: AbortSignal) => {
      return dispatch(getDataPointDocumentVersions(id, format, signal));
    },
  };
};

export default connect(null, mapDispatchToProps)(ExportButtonContainer);
