import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatchAction } from '../../actions';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { toggleFilterMenu } from '../../actions/filtersMenuActions';
import { selectedFormsSelector, taskFormsSelector } from '../../reducers/formsReducer';
import { TableMenu } from '../../Interfaces/TableMenu';
import TableContainer from '../Table/TableContainer';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { getLocalization } from '../../global/global';
import { selectTable } from '../../actions/tableActions';

interface StateProps {
  filtersMenu: FiltersMenuInterface;
  forms: FormInterface[];
  tableMenu: TableMenu;
  taskForms: FormInterface[];
}

interface ActionProps {
  toggleFilterMenu: (open: boolean) => void;
  selectTable: (formId) => void;
}

interface Props {
  tasks: boolean;
}

export type TableModuleAreaProps = StateProps & ActionProps & Props;

const TableModuleArea = (props: TableModuleAreaProps) => {
  const selectedForms = props.filtersMenu.selectedForms;
  let module;
  if (props.tasks) {
    const form = props.taskForms && props.taskForms.find(f => f.type === 'TASKFORM');
    if (form) {
      module = (<TableContainer key={form.ref} model={form} />);
    }
  } else {
    if (!props.tableMenu.selectedTable) {
      if (selectedForms.length === 0) {
        if (!props.filtersMenu.open) {
          props.toggleFilterMenu(true);
        }
        return (
          <div className="alert alert-primary" role="alert">
            {getLocalization('selectOneForm')}
          </div>
        );
      } else {
        props.selectTable(selectedForms[0].ref);
      }
    }
    const form = props.forms && props.forms.find(f => f.ref === props.tableMenu.selectedTable);
    if (form) {
      return (<TableContainer key={`table-view-${form.ref}`} model={form} />);
    }
  }

  return (
    <div className="table-module-area">
      {module}
    </div>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    filtersMenu: state.filtersMenu,
    forms: selectedFormsSelector(state),
    taskForms: taskFormsSelector(state),
    tableMenu: state.tableMenu
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchAction): ActionProps => {
  return {
    toggleFilterMenu: (open: boolean) => {
      dispatch(toggleFilterMenu(open));
    },
    selectTable: (formId: string) => dispatch(selectTable(formId))
  };
};

export default connect<StateProps, ActionProps, Props, StateInterface>
(mapStateToProps, mapDispatchToProps)(TableModuleArea);
