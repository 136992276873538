import * as React from 'react';
import { useEffect } from 'react';
import { globalWindow } from '../../global/global';

const TeamsApp = () => {

  useEffect(() => {
    // const { token } = getQueryParams(globalWindow.location.search);
    // @ts-ignore
    const authToken = globalWindow.authToken || null;
    // @ts-ignore
    const callBack = globalWindow.callBack || null;
    // @ts-ignore
    const refreshToken = globalWindow.refreshToken || null;
    if (authToken && callBack && refreshToken) {
      const json = JSON.parse(authToken);
      const auth = JSON.stringify({
        userName: json.userName, key: json.key, refreshToken: json.refreshToken,
        id: json.id, groupId: json.groupId
      });
      if (callBack.indexOf('auth-end') === -1) {
        globalWindow.open(`${callBack}`, '_self');
      } else {
        globalWindow.open(`${callBack}?auth=${encodeURIComponent(auth)}`, '_self');
      }
      document.cookie = `auth=${auth}; SameSite=None; Secure`;
    }
  });

  return (
    <div />
  );
};

export default TeamsApp;
