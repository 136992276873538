import './Style.scss';
import * as React from 'react';
import { Button, ButtonGroup, Col, Container, Figure, Form, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  DocumentActivities,
  DocumentActivityInterface,
  DocumentFileInterface,
  DocumentFilesInterface,
  DocumentInterface,
  DocumentVersionInterface
} from 'Interfaces/DocumentInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { DocumentFolderPermissionInterface } from 'Interfaces/Documents/DocumentFolderPermissionInterface';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { RolePermissions } from 'Interfaces/Documents/PermissionInterface';
import { ModalComponentNames, ModalInterface } from 'Interfaces/ModalInterface';
import { DocumentComments, INITIAL_COMMENTS } from 'Interfaces/Documents/DocumentComments';
import { documentFolderPermssionsSelector } from 'reducers/documentFolderPermissions';
import { Users } from 'Interfaces/User';
import { getLocalization, globalWindow } from 'global/global';
import { connect, useDispatch, useSelector } from 'react-redux';
import { formatDisplayDate, isNullOrUndefined } from 'utils/utils';
import { doGetDocumentVersions, doGetComments } from 'api/documentApi';
import { navigateAddModal, navigateRemoveModal } from 'actions/navigationAction';
import { deleteDocument } from 'actions/documentActions';
import { ConfirmationModalProps } from 'views/Modals/ConfirmationModal';
import DocumentPermissions from '../DocumentPermissions';
import DocumentEditor from '../DocumentEditor';
import ExportButton from './ExportButton';
import CommentEditor from './CommentEditor';

interface Props {
  document: DocumentInterface;
}

export type DocumentViewProps = Props;

interface StateProps {
  users: Users;
  documentActivities: DocumentActivityInterface;
  documentFiles: DocumentFilesInterface;
  clientPersist: ClientPersistInterface;
  documentFolderPermissions: DocumentFolderPermissionInterface[];
}

export type VIEW = 'CONTENT' | 'PERMISSIONS' | 'ACTIVITY' | 'EDIT' | 'COMMENTS' | '';

function DocumentView(props: Props & StateProps) {
  const dispatch = useDispatch();
  const folders = useSelector((state: StateInterface) => state.documentFolders);
  const [view, setView] = React.useState<VIEW>('CONTENT');
  const [document, setDocument] = React.useState(props.document);
  const [documentActivity, setDocumentActivity] = React.useState<DocumentActivities>([]);
  const [documentFiles, setDocumentFiles] = React.useState<DocumentFileInterface[]>([]);
  const [documentVersions, setDocumentVersions] = React.useState<DocumentVersionInterface[] | undefined>();
  const [loading, setLoading] = React.useState(false);
  const abortController = React.useRef(new AbortController());
  const [canEdit, setCanEdit] = React.useState(false);
  const [canDelete, setCanDelete] = React.useState(false);
  const [documentComments, setDocumentComments] = React.useState<DocumentComments>({...INITIAL_COMMENTS});
  const [role] = React.useState(() => {
    const { roles } = props.clientPersist;
    if (roles === 'admin,modeler') {
      return 'admin';
    }
    return roles;
  });

  const [documentPermissions, setDocumentPermissions] = React.useState<RolePermissions | undefined>(undefined);

  React.useEffect(() => {
    /*
      Disabled permission code
      if (!loadingPermissons) {
        setLoadingPermissions(true);
        setView('CONTENT');
        const getPermissions = async () => {
          const config = getRequestConfig();
          const permissions = await fetch(`/json/app/document/permission/${props.document.id}`, {
            ...config
          }).then(res => res.json());
          const docPermission: Partial<RolePermissions> = {};
          roles.forEach(role => {
            docPermission[role] = {
              ...DEFAULT_PERMISSION,
              documentId: Number(props.document.id),
              role
            };
          });
          if (permissions.length > 0) {
            permissions.forEach((perm) => {
              docPermission[perm.role] = perm;
            });
          }
          setDocumentPermissions(docPermission);
          setLoadingPermissions(false);
        };
        void getPermissions();
      }
    */
    const { clientPersist } = props;
    const canEdit = (clientPersist.editOwn && `${document.createdBy}` === `${clientPersist.user_id}`)
      || !clientPersist.editOwn || !clientPersist.roles === 'viewer';
    setCanEdit(canEdit);
    setCanDelete(clientPersist.canDelete);
  }, [props.document.id]);


  React.useEffect(() => setDocument({...props.document}), [props.document]);

  React.useEffect(() => {
    if (!documentComments.loading && document.id) {
      setDocumentComments({...documentComments, loading: true });
      doGetComments(document.id).then(res => res.json()).then(json => {
        if (Array.isArray(json)) {
          setDocumentComments({ loaded: true, loading: false, comments: json });
        } else {
          toast(`${json.errorMessage || getLocalization('')} `, {
            type: toast.TYPE.ERROR,
            autoClose: 4000
          });
        }
      }).catch(e => {
        console.log(e);
      });
    }
  }, [document.id]);

  React.useEffect(() => {
    if (props.clientPersist.roles === 'viewer') {
      setCanEdit(false);
      setCanDelete(false);
    } else {
      const permission = props.documentFolderPermissions.find(dfp => dfp.role === role);
      if (permission) {
        if (permission.write) {
          setCanEdit(true);
        }
        if (permission.delete) {
          setCanDelete(true);
        }
      } else {
        if (documentPermissions) {
          const write = documentPermissions[role].write;
          const del = documentPermissions[role].delete;
          if (write || isNullOrUndefined(write)) {
            setCanEdit(true);
          }
          if (del || isNullOrUndefined(del)) {
            setCanDelete(true);
          }
        }
      }
    }
  }, [props.documentFolderPermissions, documentPermissions]);

  React.useEffect(() => {
    setDocumentActivity(props.documentActivities.collection.filter(da => da.documentId === document.id));
  }, [props.documentActivities.collection, document.id]);

  React.useEffect(() => {
    setDocumentFiles(props.documentFiles.collection.filter(df => df.documentId === document.id));
  }, [props.documentFiles.collection, document.id]);

  React.useEffect(() => {
    setLoading(false);
    setDocumentVersions(undefined);
  }, [document.id]);

  React.useEffect(() => {
    if (!loading) {
      const getVersions = async () => {
        if (document.id) {
          const dvs = await doGetDocumentVersions(document.id, abortController.current.signal);
          setDocumentVersions(dvs);
          setLoading(false);
        }
      };
      setLoading(true);
      void getVersions();
    }
  }, [documentVersions, document, document.id]);

  const creator = React.useCallback((id) => {
    return props.users.find(u => u.id === id);
  }, [document]);

  const onDeleteDocument = () => {
    console.log('delete');
    const modalProps: ModalInterface<ConfirmationModalProps> = {
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onConfirm: () => {
          dispatch(deleteDocument(document.id));
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
        },
        onClose: () => dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal)),
        localizations: {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('deleteData'),
          confirmStyle: 'danger',
          header: (<label>{getLocalization('confirm')}</label>),
          body: (<p>{getLocalization('deleteDocument')}</p>)
        },
        confirmEnabled: true
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  const user = React.useCallback((id) => {
    return props.users.find(u => u.id === id);
  }, [document]);

  const getActivityView = () => {
    return (
      <Table className="mt-3" hover={true}>
        <thead>
          <tr>
            <th>{getLocalization('date')}</th>
            <th>{getLocalization('user')}</th>
            <th>{getLocalization('version')}</th>
            <th>{getLocalization('action')}</th>
          </tr>
        </thead>
        {documentActivity.map((activity) => {
          return (
            <tr key={`activity-${activity.id}`}>
              <td className="text-muted">{formatDisplayDate(activity.date)}</td>
              <td>{user(activity.userId)?.name}</td>
              <td>{activity.version}</td>
              <td>{activity.action}</td>
            </tr>
          );
        })}
      </Table>
    );
  };

  const downloadFile = (id: string) => {
    const params = [`key=${globalWindow.pwrd}`, `userid=${globalWindow.userName}`];
    const url = `/json/app/document/file/version/${id}?` + params.join('&');
    globalWindow.open(url, '_blank');
  };

  const getContentView = () => {
    return (
      <>
        <Container className="document-content" key={`document-content-${document.id}`}>
          <Form.Group>
            <Form.Label>{getLocalization('name')}</Form.Label>
            <Form.Control
              plaintext={true}
              readOnly={true}
              defaultValue={document.name}
              size="sm"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{getLocalization('subject')}</Form.Label>
            <Form.Control
              plaintext={true}
              readOnly={true}
              defaultValue={document.subject}
              size="sm"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{getLocalization('description')}</Form.Label>
            <Form.Control
              plaintext={true}
              readOnly={true}
              defaultValue={document.description}
              size="sm"
            />
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>{getLocalization('creationDate')}</Form.Label>
              <Form.Control
                plaintext={true}
                readOnly={true}
                defaultValue={formatDisplayDate(document.creationDate)}
                size="sm"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>{getLocalization('language')}</Form.Label>
              <Form.Control
                plaintext={true}
                readOnly={true}
                defaultValue={document.language}
                size="sm"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>{getLocalization('folder')}</Form.Label>
              <Form.Control
                plaintext={true}
                readOnly={true}
                defaultValue={folders.collection.find((f) => f.id === document.folderId)?.name}
                size="sm"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>{getLocalization('createdBy')}</Form.Label>
              <Form.Control
                plaintext={true}
                readOnly={true}
                defaultValue={user(document.createdBy)?.name}
                size="sm"
              />
            </Form.Group>
          </Form.Row>
          <h5>{getLocalization('file')}</h5>
          <Row>
            {documentFiles.map((file) => {
              return (
                <Figure key={`document-file-${file.id}`}>
                  <Figure.Image
                    width={200}
                    height={200}
                  />
                  <Figure.Caption>{file.name}</Figure.Caption>
                </Figure>
              );
            })}
          </Row>
        </Container>
        <Table className="mt-3" hover={true}>
          <thead>
            <tr>
              <th>{getLocalization('version')}</th>
              <th>{getLocalization('editedBy')}</th>
              <th>{getLocalization('editDate')}</th>
              <th>{getLocalization('changeLog')}</th>
              <th>{getLocalization('approvedby')}</th>
              <th>{getLocalization('approveddate')}</th>
              <th>{getLocalization('file')}</th>
            </tr>
          </thead>
          <tbody>
            {documentVersions?.map((version) => {
              return (
                <tr key={`doc-$${version.id}`}>
                  <td>{version?.version}</td>
                  <td>{user(version?.editor)?.name}</td>
                  <td>{formatDisplayDate(new Date(version?.editDate))}</td>
                  <td>{version?.changeLog}</td>
                  <td>{version.approvedBy === 0 ? '' : user(version?.approvedBy)?.name}</td>
                  <td>{version?.approvalDate ? formatDisplayDate(new Date(version.approvalDate)) : ''}</td>
                  <td>
                    <Button size="sm" variant="link" onClick={() => downloadFile(`${version.id}`)}>
                      <i className="fa fa-download" aria-hidden="true" />
                      {` ${getLocalization('downloadValidationList')}`}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  const getCommentsView = () => {
    return (
      <div className="mt-3">
        <CommentEditor
          documentId={Number(document.id)}
          addComment={(comment) => {
            setDocumentComments({
              ...documentComments, comments: [comment].concat(documentComments.comments)
            });
          }}
        />
        {documentComments.comments.map((comment) => {
          return (
            <Container key={`${comment.id}-${document.id}`} fluid={true}>
              <p className="mb-1">
                <strong>{`${user(comment.createdBy)?.name}`}</strong>
                <small className="text-muted ml-2">{formatDisplayDate(new Date(comment.createdOn))}</small>
              </p>
              <p>{comment.comment}</p>
              <hr />
            </Container>
          );
        })}
      </div>
    );
  };

  const getView = () => {
    switch (view) {
      case 'ACTIVITY':
        return getActivityView();
      case 'EDIT':
        return (
          <div className="mt-3">
            <DocumentEditor
              document={document}
              documentVersions={documentVersions}
              files={documentFiles}
              onClose={() => setView('CONTENT')}
            />
          </div>
        );
      case 'CONTENT':
        return getContentView();
      case 'COMMENTS':
        return getCommentsView();
      case 'PERMISSIONS':
        return (
          <DocumentPermissions
            document={document}
            documentPermissions={documentPermissions}
            setDocumentPermissions={setDocumentPermissions}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="doc-sub-view document-view">
      <Row className="container-fluid header">
        <h4 className="mr-2">{document.name}</h4>
        <span className="text-muted mr-2">{formatDisplayDate(new Date(document.creationDate))}</span>
        <span>by {creator(document.createdBy)?.name}</span>
      </Row>
      <Container>
        <Row>
          <ButtonGroup>
            <Button
              size="sm"
              active={view === 'CONTENT'}
              onClick={() => setView('CONTENT')}
            >
              <i className="fa fa-file" aria-hidden="true" />
              <span>{getLocalization('content')}</span>
            </Button>
            <Button
              size="sm"
              active={view === 'ACTIVITY'}
              onClick={() => setView('ACTIVITY')}
            >
              <i className="fa fa-list" aria-hidden="true" />
              <span>{getLocalization('activity')}</span>
            </Button>
            <Button
              size="sm"
              active={view === 'COMMENTS'}
              onClick={() => setView('COMMENTS')}
            >
              <i className="fa fa-comments" aria-hidden="true" />
              <span>{getLocalization('comments')}</span>
            </Button>
            <Button
              size="sm"
              active={view === 'EDIT'}
              disabled={!canEdit}
              onClick={() => setView('EDIT')}
            >
              <i className="fa fa-edit" aria-hidden="true" />
              <span>{getLocalization('edit')}</span>
            </Button>
            <ExportButton document={document} />
            <Button
              size="sm"
              variant="danger"
              disabled={!canDelete}
              onClick={() => onDeleteDocument()}
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </Button>
          </ButtonGroup>
        </Row>
        {getView()}
      </Container>
    </div>
  );
}

const mapStateToProps = (state: StateInterface, props: Props): StateProps => {
  return {
    users: state.users,
    documentActivities: state.documentActivities,
    documentFiles: state.documentFiles,
    clientPersist: state.clientPersist,
    documentFolderPermissions: documentFolderPermssionsSelector(state, props),
  };
};

export default connect(mapStateToProps, null)(DocumentView);
