import { AnyAction } from 'redux';
import {
  DocumentFolderPermissionInterface, DocumentFolderPermissions
} from 'Interfaces/Documents/DocumentFolderPermissionInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { DocumentViewProps } from 'views/Document/DocumentView';
import { DOCUMENTS } from '../actions/actionTypes';

const INITIAL_STATE: DocumentFolderPermissions = {
  loaded: false,
  permissions: []
};

export const folderPermissionsSelector =
  (state: StateInterface): DocumentFolderPermissionInterface[] => state.documentFolderPermissions.permissions;

export const documentFolderPermssionsSelector = (state: StateInterface, props: DocumentViewProps) =>
  state.documentFolderPermissions.permissions.filter(dfp => dfp.folderId === props.document.folderId);

const documentFolderPermissions = (state = INITIAL_STATE, action: AnyAction): DocumentFolderPermissions => {
  switch (action.type) {
    case DOCUMENTS.FOLDER_PERMISSIONS_LOADED:
      if (action.permissions) {
        return {
          loaded: true,
          permissions: action.permissions
        };
      }
      return state;
    default:
      return state;
  }
};

export default documentFolderPermissions;
