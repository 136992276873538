import { TileLayer as LeafletTileLayer } from 'leaflet';
import L from 'leaflet';
import * as MapBox from 'mapbox.js'; // eslint-disable-line @typescript-eslint/no-unused-vars
import { withLeaflet } from 'react-leaflet';
import { GridLayer } from 'react-leaflet';
// import { globalWindow } from '../../../global/global';

export type LeafletElement = LeafletTileLayer;
interface Props { mapType: string }
/*
  This component renders the mapquest later.
  https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/
  This requires the Mapquest js to have been included for it to work out.
*/
class MapboxTileLayer extends GridLayer<LeafletTileLayer, Props> {
  public createLeafletElement(): LeafletTileLayer {
    return L.tileLayer(
      `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=` +
      `pk.eyJ1Ijoiam9obnBvaW1hcHBlciIsImEiOiJjaXF4ZWliamYwMDM5aHhtZ210anhoeHl5In0.Uc0-t0xOxhOOQVgpKSTg-g`, {
        attribution: '&copy; <a href="http://mapbox.com/copyright">Mapbox</a> contributors',
      });
    /* return globalWindow.L.mapbox.tileLayer('mapbox.streets', {
      accessToken: ''
    });*/
  }

  public updateLeafletElement(fromProps: Props, toProps: Props) {
    super.updateLeafletElement(fromProps, toProps);
  }
}

export default withLeaflet(MapboxTileLayer);
