import { updateClientPersist } from '../actions/clientPersistActions';
import { ThunkDispatchAction } from '../actions';

import { StateInterface } from '../Interfaces/StateInterface';

export const request = async (
  url: string,
  data: any,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
): Promise<any> => {
  dispatch(updateClientPersist({ ...getState().clientPersist, sessionTime: Date.now() }));
  return fetch(url, data);
};
