import * as React from 'react';
import { Route, Switch } from 'react-router';
import App from './App';
import LoginApp from './LoginApp';
import helpPaths from './views/Header/helpPaths';
import { ModuleAreaContainer } from './views/ModuleSelection/ModuleAreaContainer';
import { FiltersMenuContainer } from './views/Filters/FiltersMenuContainer';
import { ModuleSelectionContainer } from './views/ModuleSelection/ModuleSelectionContainer';
import SingleInstance from './views/SingleInstance/Containers/SingleInstance';
import CreatePasswordApp from './views/CreatePassword';
import MittariRegistrationApp from './views/Registration/MittariRegistration';
import TeamsApp from './views/Teams';
import Admin from './views/admin';
import CampaignApp from './views/Campaign';
import LocationHierarchyApp from './views/LocationHierarchy';
import ActivationApp from './views/activation';
import Payments from './views/Payments';
import Register from './views/Register';
import Document from './views/Document';

export const Routes = (): JSX.Element => {
  return (
    <Switch>
      <Route path={'/login.jsp'} component={LoginApp} />
      <Route
        exact
        path={'/index.jsp#:formRef/:answerId/:rowId/:isNewAnswer'}
      >
        <App showHeader={false}>
          <SingleInstance/>
        </App>
      </Route>
      <Route path={['/index.jsp', '/']} exact>
        <App showHeader>
          <ModuleSelectionContainer />
          <FiltersMenuContainer/>
          <ModuleAreaContainer/>
        </App>
      </Route>
      <Route path={helpPaths} component={App} />
      <Route path={'/createPassword.jsp'} component={CreatePasswordApp} />
      <Route path={'/accountSetup.jsp'} component={MittariRegistrationApp} />
      <Route path={'/activation.jsp'} component={ActivationApp} />
      <Route path={'/teams.jsp'} component={TeamsApp} />
      <Route path={'/admin.jsp'} component={Admin} />
      <Route path={'/online-survey.jsp'} component={CampaignApp} />
      <Route path={'/locationHierarchy.jsp'} component={LocationHierarchyApp} />
      <Route path={'/payments.jsp'} component={Payments} />
      <Route path={'/register.jsp'} component={Register} />
      <Route path={'/document.jsp'} component={Document} />
    </Switch>
  );
};
