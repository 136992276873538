import { UPDATE_PROGRESS } from '../actions/actionTypes';

const progress = (state = { visible: false }, action) => {
  switch (action.type) {
    case UPDATE_PROGRESS:
      if (action.progress !== null) {
        return Object.assign({}, state, action.progress);
      }
      return state;
    default:
      return state; //
  }
};

export default progress;
