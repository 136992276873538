import { MODULES } from 'Interfaces/ModuleSelectionInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import * as React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ModalComponentNames, ModalInterface } from 'Interfaces/ModalInterface';
import { navigateAddModal, navigateRemoveModal } from 'actions/navigationAction';
import { NewDashboardModalProps } from 'views/Modals/NewDashboardModal';
import { useAppDispatch } from 'index';
import { getDashboards, saveNewDashboard, selectDashboard } from '../../../actions/dashboardActions';

interface Props {
  buttonInnerJSX: JSX.Element;
  selectedModule: string;
  classNames: string;
  onClick: () => void;
}

export default function DashBoardButton(props: Props) {

  const dashboard = useSelector((state: StateInterface) => state.dashboard);
  const dashboardMenu = useSelector((state: StateInterface) => state.dashboardMenu);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const onClick = (e) => {
    e.stopPropagation();
  };

  React.useEffect(() => {
    if (!dashboard.loaded && !loading) {
      setLoading(true);
      void dispatch(getDashboards());
    } else if (dashboard.loaded && loading) {
      setLoading(false);
    }
  });

  const newDashboard = () => {
    const modalProps: ModalInterface<NewDashboardModalProps> = {
      component: ModalComponentNames.NewDashboardModal,
      props: {
        onClose: () => {
          dispatch(navigateRemoveModal(ModalComponentNames.NewDashboardModal));
        },
        onConfirm: (name: string) => {
          void dispatch(saveNewDashboard(name));
          dispatch(navigateRemoveModal(ModalComponentNames.NewDashboardModal));
        }
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  const getDashboardList = React.useCallback(() => {
    return dashboard.collection.map((d, index) => {
      return (
        <Dropdown.Item
          as="div"
          eventKey={`${index}`}
          active={`${d.id}` === `${dashboardMenu.active}`}
          key={`dashboard-dropdown-item-${d.id}`}
          onClick={() => dispatch(selectDashboard(`${d.id}`))}
        >
          {d.name}
        </Dropdown.Item>
      );
    });
  }, [dashboard, dashboardMenu.active]);

  return (
    <React.Fragment>
      <Dropdown
        id="chart-btn"
        onToggle={(isOpen, event, metadata) => {
          if (metadata.source === 'click' && props.selectedModule !== `${MODULES.DASHBOARD}`) {
            props.onClick();
          }
        }}
      >
        <Dropdown.Toggle id="chart-btn-toggle" variant="primary" size={'sm'} className={props.classNames}>
          {props.buttonInnerJSX}
        </Dropdown.Toggle>
        <Dropdown.Menu rootCloseEvent="click" onClick={onClick}>
          {getDashboardList()}
        </Dropdown.Menu>
      </Dropdown>
      <Button variant="primary" size="sm" onClick={newDashboard}>
        <i className="fa fa-plus"/>
      </Button>
    </React.Fragment>
  );
}
