import { getRequestConfig } from 'api';
import { PowerBiUrl } from 'Interfaces/PowerBiUrl';

export const doLoadPowerBiUrls = (signal: AbortSignal) => {
  const headers = getRequestConfig();
  return fetch('/json/app/dataurl/list', { ...headers, signal: signal });
};

export const doCreatePowerBiUrl = (powerBiUrl: PowerBiUrl, signal: AbortSignal) => {
  const config = getRequestConfig();
  return fetch(
    `/json/app/dataurl/generateUrl`,
    Object.assign(
      {},
      {
        method: 'POST',
        body: JSON.stringify(powerBiUrl),
      },
      config,
      signal,
    ),
  );
};

export const doDeleteDataUrls = (ids: number[], signal: AbortSignal) => {
  const config = getRequestConfig();
  return fetch(
    `/json/app/dataurl/deleteUrls`,
    Object.assign(
      {},
      {
        method: 'POST',
        body: JSON.stringify(ids),
      },
      config,
      signal,
    ),
  );
};
