import * as React from 'react';
import { connect } from 'react-redux';
import { DataPoint } from '../../../../Interfaces/DataPoint';
import DeleteButton from '../DeleteButton';
import { deletePOI } from '../../../../actions/pois';

interface Props {
  dataPoint: DataPoint;
  unrender: () => void;
  bsSize?: 'xs' | 'xsmall' | 'sm' | 'small';
}

interface ActionProps {
  deletePOI: (formId: string, callBack: () => void, id: string) => void;
}

export type DeleteButtonProps = Props & ActionProps;

export class DeleteButtonContainer extends React.Component <DeleteButtonProps> {

  constructor(props) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <DeleteButton {...this.props} />
    );
  }
}

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    deletePOI: (formId: string, callBack: () => void, id: string) => {
      dispatch(deletePOI(formId, callBack, id));
    }
  };
};

export default connect(null, mapDispatchToProps)(DeleteButtonContainer);
