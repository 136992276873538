import './Style.scss';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import { NODE_TYPE_LH, NODE_TYPE_LOC } from 'views/LocationHierarchy/utils/Constants';
import { ILocationTreeNode } from '../Workspace';
import { Hierarchy } from './Hierarchy';
import { Location } from './Location';

interface Props {
  node: ILocationTreeNode;
  updateNode: (attr: ILocationTreeNode) => void;
}

export const Attributes = (props: Props) => {
  const { nodeType } = props.node;
  return (
    <Col md={3} className="attributes">
      {nodeType === NODE_TYPE_LH && (
        <Hierarchy
          node={props.node}
          updateNode={props.updateNode}
        />
      )}
      {nodeType === NODE_TYPE_LOC && (
        <Location
          node={props.node}
          updateNode={props.updateNode}
        />
      )}
    </Col>
  );
};
