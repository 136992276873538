import '../styles/LocationHierarchyComponent.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { filter, keys, mapKeys, pick } from 'lodash-es';
import Select from 'react-dropdown-select';
import { getLocalization } from 'global/global';
import { LooseObject } from '../../../Interfaces/LooseObject';
// import FormUtils from '../utils/FormUtils';
import { LocationInterface, Locations } from '../../../Interfaces/LocationInterface';
import { LHPropsInterface } from '../Containers/LocationHierarchyContainer';
import { generateRandomId } from '../../../utils/utils';

interface State {
  value: LooseObject;
  labels: string[];
  minLevel: number;
  mainParent: Locations | undefined;
  mandatory: number;
  edit: boolean;
  originalLabels: string[];
}

/*
  TODO: Reverse geocoding and applying assigned locations check.
*/
export default class LocationHierarchyComponent extends React.Component <LHPropsInterface, State> {

  constructor(props: LHPropsInterface) {
    super(props);
    const initState = this.initializeState();

    this.state = {
      value: pick(props.dataPoint, ['location1', 'location2', 'location3', 'location4']),
      minLevel: initState.minLevel ? initState.minLevel : 0,
      mainParent: initState.mainParent,
      mandatory: Number(props.formUtils.getModel().locationsMandatory),
      edit: props.edit,
      ...this.getLocationLabels()
    };
  }

  @bind
  private getLocationLabels() {
    const { clientPersist, formUtils } = this.props;
    let labels = formUtils.getModel().locationLabels;
    let originalLabels: string[] = [];
    if (labels && labels !== ['Location 1', 'Location 2', 'Location 3', 'Location 4'].join(',')) {
      labels = labels.split(',');
      originalLabels = clientPersist.locationLabels.length > 0 ? clientPersist.locationLabels :
        ['Location 1', 'Location 2', 'Location 3', 'Location 4'];
    } else {
      labels = clientPersist.locationLabels.length > 0 ?
        clientPersist.locationLabels : ['Location 1', 'Location 2', 'Location 3', 'Location 4'];
    }
    return { labels, originalLabels };
  }

  @bind
  private initializeState(): Partial<State> {
    const { locationHierarchy, clientPersist, formUtils } = this.props;
    const model = formUtils.getModel();
    // when we have address and coordinates we show full lh
    if (model.includeAddress && model.hasCoordinates) {
      return {
        minLevel: 0,
        mainParent: undefined
      };
    }
    const locations = clientPersist.userLocations.split(',').filter( l => l !== '');

    if (locations.length === 0) {
      return {
        minLevel: 0,
        mainParent: undefined
      };
    }
    // when we have just one assigned location, we get the children.
    if (locations.length === 1) {
      const mainParents = locationHierarchy.filter(l => l.parent === locations[0]);
      return {
        minLevel: Number(clientPersist.userLevel) + 1,
        mainParent: mainParents
      };
    } else {
      // const mainParents = locationHierarchy.filter(l => l.parent && locations.indexOf(l.parent) !== -1);
      const mainParents = locationHierarchy.filter(l =>  locations.indexOf(`${l.key}`) !== -1);
      return {
        minLevel: Number(clientPersist.userLevel),
        mainParent: mainParents
      };
    }
  }

  /*
    Handles change when a location is selected.
    This function resets the levels below the changed level to null.
  */
  @bind
  private handleChange(location: LocationInterface) {
    const { minLevel, mainParent } = this.state;
    const { formUtils } = this.props;
    let newValue = Object.assign({}, this.state.value);
    const level = Number(location['level']) + 1;
    const name = `location${level}`;
    const value = location.key;
    newValue[name] = value;
    let i = Number(level) + 1;
    while (i <= 4) {
      newValue[`location${i}`] = '';
      i++;
    }
    // set top level when lower level changes if top levels are hidden.
    if (Number(level) === (minLevel + 1) && mainParent) {
      i = minLevel;
      let currentLocation = this.getLocation(Number(value));
      while (i > 0 && currentLocation) {
        newValue[`location${i}`] = currentLocation.parent;
        currentLocation = this.getLocation(Number(currentLocation.parent));
        i--;
      }
    }
    if (formUtils.getMappedLocations()) {
      const populatedValues = this.autoPopulateLocations(Number(level) - 1, value);
      if (populatedValues) {
        newValue = Object.assign({}, newValue, populatedValues);
      }
    }
    this.setState({ value: newValue });
    const { updateAnswer } = this.props;
    if (updateAnswer) {
      updateAnswer(newValue);
    }
  }

  @bind
  private autoPopulateLocations(level, locationKey) {
    const { formUtils } = this.props;

    const mappedLocations = formUtils.getMappedLocations();
    let found: any = [];

    if (Number(level) + 1 === 1) {
      found = filter(mappedLocations, (value) =>
        value['Location1'] === Number(locationKey)
      );
    } else if (Number(level) + 1 === 2) {
      found = filter(mappedLocations, (value) =>
        value['Location2'] === Number(locationKey)
      );
    } else if (Number(level) + 1 === 3) {
      found = filter(mappedLocations, (value) =>
        value['Location3'] === Number(locationKey)
      );
    } else if (Number(level) + 1 === 4) {
      found = filter(mappedLocations, (value) =>
        value['Location4'] === Number(locationKey)
      );
    }

    if (found.length === 1) {
      const assignedLoc = found[0];
      const locationKeys = keys(assignedLoc);
      locationKeys.sort();
      const newLocs = locationKeys.splice(Number(level) + 1);

      const populatedValues = pick(assignedLoc, newLocs);
      return mapKeys(populatedValues, (value, key) => key.toLowerCase());
      /* _.each(newLocs, function(key) {
        return $("select[name='" + (key.toLowerCase()) + "']").val(assignedLoc[key]).trigger("change");
      });*/
    }
    return undefined;
  }

  @bind
  private getLocation(key: number): LocationInterface | undefined {
    const { locationHierarchy } = this.props;
    return locationHierarchy.find(l => Number(l.key) === key);
  }

  /*
    Given a parent id, this function returns select options with all it's immediate child locations.
  */
  @bind
  private getLocations(parent: number): Locations {
    const { locationHierarchy } = this.props;
    if (parent === -1 && this.state.mainParent) {
      const options: any[] = this.state.mainParent.map((loc) => {
        if (loc.key === '0ul0') {
          return null;
        }
        return loc;
        /* return (
          <option value={loc.key} key={`location-${loc.key}`}>
            {loc.title}
          </option>
        );*/
      }).filter(l => l !== null);
      return options;
    }
    const options: any[] = locationHierarchy.map((loc) => {
      if (Number(loc.parent) === parent && loc.key !== '0ul0') {
        return loc;
      }
      return undefined;
    }).filter(opt => opt !== undefined);
    return options;
  }

  @bind
  private onCreateNewItem(item: LocationInterface, level: number) {
    const parent = this.state.value[`location${level - 1}`] || -1;
    this.props.addNewLocation([{
      ...item, level: `${level - 1}`, parent, identifier: `loc${generateRandomId()}`
    }]);
  }
  /*
    This function returns the location select items for each level.
  */
  @bind
  private getLocationItems(): JSX.Element[] {
    /* const locs: string[] = ['location1', 'location2', 'location3', 'location4'];*/
    const { labels, value, minLevel, mandatory, edit, originalLabels } = this.state;
    const locationItems: JSX.Element[] = [];
    let i = minLevel + 1;
    let parent = -1;
    let l = minLevel;
    let previousLocations: Locations = [];

    while (l < 4) {
      let locations = (parent === -1 && i === minLevel + 1) || parent > 0 ? this.getLocations(parent) : null;
      /**
       *  the second condition checks if the current level has no locations
       *  and there is a previous level being displayed.
       *  it would then show the level to allows adding of new locations.
       */
      if ((locations && locations.length > 0) || (
        (locations === null || locations.length === 0) && labels[l]
        && l <= 3 && locationItems.length > 0 && locationItems[locationItems.length - 1]
        && previousLocations.length > 0 && value[`location${i - 1}`]
      )) {
        const level = i;
        locations = locations || [];
        const selectedValues = locations.filter(loc => `${loc.key}` === `${value[`location${i}`]}`);
        locationItems.push(
          <div key={`location${i}`} className="form-group locationhierarchy level-1 col-3">
            <label>
              {labels[l]}
              {l + 1 <= mandatory && (<span className={'required'}>*</span>)}
              {originalLabels[l] ? (
                <span className="ml-2" style={{ fontStyle: 'italic', display: 'inline' }}>({originalLabels[l]})</span>
              ) : null}
            </label>
            <Select
              key={`Location-Select-${l}`}
              labelField={'title'}
              valueField={'key'}
              create={true}
              onCreateNew={(newItem) => this.onCreateNewItem(newItem, level)}
              values={selectedValues}
              multi={false} // { title: 'Select one', key: 'NULL' }
              options={locations}
              // @ts-ignore
              onChange={(v) => v.length > 0 ?
                this.handleChange(v[0]) : this.handleChange({ title: '', key: ``, level: `${level - 1}`})
              }
              searchable
              searchBy={'title'}
              disabled={!edit}
              style={{ zIndex: 10000 }}
              noDataLabel={getLocalization('noLocationFound')}
              createNewLabel={`${getLocalization('add')} {search}`}
            />
            {/* <select
              className="form-control"
              name={`location${i}`}
              onChange={this.handleChange}
              value={value[`location${i}`] || 'NULL'}
              data-level={i}
              disabled={!edit}
            >
              <option value="NULL">Select one</option>
              {locations}
            </select>*/}
          </div>
        );
      }
      parent = value[`location${i}`] ? Number(value[`location${i}`]) : -1;
      i++;
      l++;
      previousLocations = locations || [];
    }
    return locationItems;
  }

  public componentDidUpdate(prevProps: LHPropsInterface) {
    const locationHierarchy = [...prevProps.locationHierarchy];
    if (this.props.locationHierarchy.length > locationHierarchy.length) {
      const newLocation = this.props.locationHierarchy.filter(l => {
        const index = locationHierarchy.findIndex(loc => `${loc.key}` === `${l.key}`);
        if (index > -1) {
          locationHierarchy.splice(index, 1);
          return false;
        }
        return true;
      });
      if (newLocation && newLocation.length === 1) {
        this.handleChange(newLocation[0]);
      }
    }
  }

  public static getDerivedStateFromProps(props: LHPropsInterface, state: State) {
    const { value } = state;
    const { dataPoint } = props;
    const newValue = pick(dataPoint, ['location1', 'location2', 'location3', 'location4']);
    if (JSON.stringify(value) !== JSON.stringify(newValue)) {
      return { value: newValue };
    }
    return null;
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value || this.props.locationHierarchy !== nextProps.locationHierarchy;
  }

  public render(): JSX.Element {
    return (
      <div className="row hierarchy">
        {this.getLocationItems()}
      </div>
    );
  }
}
