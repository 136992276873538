import './Style.scss';
import * as React from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { AnyAction } from 'redux';

import { ThunkDispatch } from 'redux-thunk';
import { loadHierarchies, saveHierarchies } from 'actions/hierarchyActions';
import { LocationHierarchyStateInterface } from 'Interfaces/HierarchyInterface';
import { getLocalization, globalWindow } from 'global/global';
import GenericModal from 'views/Modals/GenericModal';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { ReactModals } from 'views/Modals/ReactModals';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { Persistor } from 'redux-persist';
import { persistedStore } from 'index';
import { Header } from '../Header/HeaderContainer';
import ProgressContainer from '../Progress/ProgressContainer';
import { StateInterface } from '../../Interfaces/StateInterface';
import { NODE_TYPE_LH } from './utils/Constants';
import {
  addNode, copyNode, createNewNode, getNodeTitle, getTreeFromJSON, getTreeFromXML, removeNodeAction
} from './utils';
import { Attributes } from './components/Attributes';
import { ILocationTreeNode, Workspace } from './components/Workspace';
import { List } from './components/List';

interface ActionProps {
  loadHierarchies: () => Promise<void>;
  saveHierarchies: (hierarchies) => Promise<JSONInterface>;
}

interface StateProps {
  locationHierarchyState: LocationHierarchyStateInterface;
  persistedStore: Persistor;
  clientPersist: ClientPersistInterface;
}

export type TREE_ACTIONS = 'ADD' | 'COPY' | 'DELETE';

export const LocationHierarchy = (props: ActionProps & StateProps) => {

  const [loading, setLoading] = React.useState(false);
  const [tree, setTree] = React.useState<ILocationTreeNode[]>([]);
  const [selectedNode, setSelectedNode] = React.useState<ILocationTreeNode | null>(null);
  const [confirmRemove, setConfirmRemove] = React.useState('');
  const [tempNode, setTempNode] = React.useState<ILocationTreeNode | null>(null);

  React.useEffect(() => {
    if (!props.locationHierarchyState.loaded && !loading) {
      setLoading(true);
      void props.loadHierarchies();
      setTimeout(() => {
        if (globalWindow.L.mapquest) {
          globalWindow.L.mapquest.key = globalWindow.mapQuestKey;
        }
      }, 5000);
    }
  });

  React.useEffect(() => {
    const { locationHierarchyState } = props;
    if (locationHierarchyState.loaded) {
      setLoading(false);
    }
  }, [props.locationHierarchyState]);

  React.useEffect(() => {
    const {instance, userName, user_id, lang} = props.clientPersist;
    if (!instance || !userName || !user_id) {
      // Force redux state to update persistance local storage
      const flush = async () => {
        await props.persistedStore.flush();
      };
      void flush();
      globalWindow.open(`/login.jsp?lang=${lang}`, '_parent');
    }
  }, [props]);

  const addLocationHierarchy = (xml: string) => {
    const node = getTreeFromXML(xml, nodeActionHandler);
    if (node) {
      setTree((prev: ILocationTreeNode[]) => {
        const arr: ILocationTreeNode[] = [];
        return arr.concat(prev).concat([node]);
      });
    }
  };

  const addHierarchyFromJSON = (json: JSONInterface) => {
    const node = getTreeFromJSON(json, nodeActionHandler);
    if (node) {
      setTree((prev: ILocationTreeNode[]) => {
        const arr: ILocationTreeNode[] = [];
        return arr.concat(prev).concat([node]);
      });
    }
  };

  const nodeActionHandler = (action: TREE_ACTIONS, node: ILocationTreeNode) => {
    if (action === 'DELETE') {
      if (node.nodeType === NODE_TYPE_LH) {
        if (node.data && node.data.newNode) {
          setConfirmRemove(getLocalization('newLocationHierarchyDeleteAlert'));
        } else {
          setConfirmRemove(getLocalization('locationHierarchyDeleteAlert'));
        }
      } else {
        if (node.data && node.data.newNode) {
          setConfirmRemove(getLocalization('deleteNewNode'));
        } else {
          setConfirmRemove(getLocalization('deleteLocation'));
        }
      }
      setTempNode(node);
    } else if (action === 'COPY') {
      const newNode = copyNode(JSON.parse(JSON.stringify(node)), nodeActionHandler);
      if (node.nodeType === NODE_TYPE_LH) {
        setTree((prev: ILocationTreeNode[]) => {
          const arr: ILocationTreeNode[] = [];
          return arr.concat(prev).concat([newNode]);
        });
        /* const newTree: ILocationTreeNode[] = new Array<ILocationTreeNode>().concat(tree);
        newTree.push(newNode);
        setTree(newTree); */
      } else {
        setTree((prev: ILocationTreeNode[]) => {
          const newTree = addNode([...prev], newNode, undefined, node.key);
          return newTree;
        });
      }
    } else if (action === 'ADD') {
      setTree((prev: ILocationTreeNode[]) => {
        const newNode = createNewNode('LOC', node.level + 1, nodeActionHandler);
        const newTree = addNode([...prev], newNode, node.key);
        return newTree;
      });
    }
  };

  const removeNode = () => {
    if (tempNode?.nodeType === NODE_TYPE_LH) {
      setTree((prev: ILocationTreeNode[]) => {
        return prev.filter(n => n.key !== tempNode.key);
      });
    } else {
      if (tempNode) {
        setTree((prev: ILocationTreeNode[]) => {
          const newTree = removeNodeAction([...prev], tempNode.key);
          return newTree;
        });
      }
    }
    setTempNode(null);
    setSelectedNode(null);
    setConfirmRemove('');
  };

  const updateTree = (n: ILocationTreeNode, node: ILocationTreeNode) => {
    if (node.key === n.key) {
      const nn = {...n};
      if (nn.value !== node.value || typeof nn.title === 'string') {
        nn.title = getNodeTitle(nn, nodeActionHandler);
      }
      return nn;
    } else {
      if (node.children) {
        node.children.forEach((child, index) => {
          const updated = updateTree(n, child);
          if (node.children) {
            node.children[index] = updated;
          }
        });
      }
    }
    return node;
  };

  const updateNode = (newNode: ILocationTreeNode) => {
    const newTree = [...tree];
    newTree.forEach((t, index) => {
      const nn = updateTree(newNode, t);
      newTree[index] = nn;
    });
    setSelectedNode(newNode);
    setTree(newTree);
  };

  const saveToDatabase = async (hierarchies) => {
    const saved = await props.saveHierarchies(hierarchies);
    if (saved && saved.status === 'OK') {
      const lhs = Object.values(saved.data);
      const newTree = tree.map((tr) => {
        const index = lhs.findIndex(l => l['identifier'] === tr.key);
        if (index > -1) {
          const lh = lhs[index];
          const newTree = getTreeFromXML(lh['xml'], nodeActionHandler);
          return newTree || tr;
        }
        return tr;
      });
      setTree(newTree);
    }
  };

  const newHierarchy = () => {
    const newNode = createNewNode('LH', 0, nodeActionHandler);
    setTree((prev) => {
      return [...prev, newNode];
    });
  };

  return (
    <>
      {confirmRemove.length > 0 && (
        <GenericModal
          visible
          title={getLocalization('confirm')}
          body={(<p>{confirmRemove}</p>)}
          cancelText={getLocalization('cancel')}
          cancel={() => {
            setConfirmRemove('');
            setTempNode(null);
          }}
          confirmText={getLocalization('ok')}
          onConfirm={() => {
            console.log('remove');
            removeNode();
          }}
        />
      )}
      <Header />
      <ReactModals />
      <ProgressContainer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
      <div className="content container-fluid row">
        <List
          locationHierarchies={props.locationHierarchyState.locationHierarchies}
          open={(xml: string) => addLocationHierarchy(xml)}
          loading={loading}
        />
        <Workspace
          tree={tree}
          onSelect={setSelectedNode}
          saveToDatabase={(h) => void saveToDatabase(h)}
          newHierarchy={newHierarchy}
          openFile={addLocationHierarchy}
          addHierarchyFromJSON={addHierarchyFromJSON}
          clearWorkspace={() => {
            setTree([]);
            setSelectedNode(null);
          }}
        />
        {selectedNode && (
          <Attributes
            node={selectedNode}
            updateNode={updateNode}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    locationHierarchyState: state.locationHierarchies,
    persistedStore: persistedStore,
    clientPersist: state.clientPersist
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    loadHierarchies: () => {
      return dispatch(loadHierarchies());
    },
    saveHierarchies: (hierarchies) => dispatch(saveHierarchies(hierarchies))
  };
};


export const LocationHierarchyApp = withCookies(
  connect(mapStateToProps, mapDispatchToProps)(LocationHierarchy));
export default LocationHierarchyApp;
