import * as React from 'react';
import bind from 'bind-decorator';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { GalleryMenuInterface } from '../../Interfaces/GalleryMenuInterface';
import { filtersSelectedFormType } from '../../Interfaces/FiltersMenuInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { selectedFormsWithImagesSelector } from '../../reducers/formsReducer';
import { ThunkDispatchAction } from '../../actions';
import { selectGalleryForm } from '../../actions/galleryActions';

interface IStateProps {
  galleryMenu: GalleryMenuInterface;
  selectedFormsWithImages: filtersSelectedFormType[];
}

interface IActionProps {
  actions: {
    selectGalleryForm: (selectedForm: string) => void;
  };
}

interface IExternalProps {
  button: JSX.Element;
}

export class GalleryButtonClass extends React.Component<IStateProps & IActionProps & IExternalProps> {

  @bind
  private selectGalleryForm(selectedForm) {
    this.props.actions.selectGalleryForm(selectedForm);
  }

  private getForms(): JSX.Element[] {
    const {selectedFormsWithImages, galleryMenu: {selectedForm}} = this.props;
    return selectedFormsWithImages.map((form, index) => {
      return (
        <Dropdown.Item
          eventKey={`${index}`}
          key={form.id}
          active={form.ref === selectedForm}
          onClick={() => this.selectGalleryForm(form.ref)}
        >
          {form.name}
        </Dropdown.Item>
      );
    });
  }

  public render(): JSX.Element | null {
    if (this.props.selectedFormsWithImages.length === 0) {
      return null;
    }
    return (
      <ButtonGroup>
        <Dropdown alignRight>
          {this.props.button}
          <Dropdown.Toggle id="gallery-btn-toggle" variant="primary" size={'sm'}/>
          <Dropdown.Menu>
            {this.getForms()}
          </Dropdown.Menu>
        </Dropdown>
      </ButtonGroup>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    galleryMenu: state.galleryMenu,
    selectedFormsWithImages: selectedFormsWithImagesSelector(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchAction): IActionProps => {
  return {
    actions: {
      selectGalleryForm: (selectedForm) => dispatch(selectGalleryForm(selectedForm))
    }
  };
};

export const GalleryButton = connect<IStateProps, IActionProps, IExternalProps, StateInterface>(
  mapStateToProps, mapDispatchToProps
)(GalleryButtonClass);

export default GalleryButton;
