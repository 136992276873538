import redux_immutable_state_invariant from 'redux-immutable-state-invariant';
import { bindActionCreators, Dispatch, Store } from 'redux';

import { History } from 'history';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { Action } from 'typescript-fsa';
import { routerMiddleware } from 'connected-react-router';

import { configureStore } from '@reduxjs/toolkit';
import * as navigationActions from '../actions/navigationAction';
import { createRootReducer } from '../reducers';
import { storeMiddleWare } from '../middleware/';
import { StateInterface } from '../Interfaces/StateInterface';

export type StoreInterface = Store<StateInterface, Action<any>>;
let middlewares = [thunkMiddleware as ThunkMiddleware<StateInterface, any>, storeMiddleWare];
if (process.env.NODE_ENV !== 'production') { // eslint-disable-line no-undef
  middlewares = [
    ...middlewares,
    redux_immutable_state_invariant({
      ignore: ['clientPersist'],
    }),
  ];
}

export const configureStores = (
  history: History,
  initialState?: StateInterface,
) => {
  middlewares.push(routerMiddleware(history));
  /*
  const appliedMiddleware = applyMiddleware(...middlewares);
  let composeMiddlewares;
  if (process.env.NODE_ENV !== 'production') { // eslint-disable-line no-undef
    composeMiddlewares = composeWithDevTools(appliedMiddleware);
  } else {
    composeMiddlewares = compose(appliedMiddleware);
  }*/
  console.log(initialState);
  /*
  const store = createStore<StateInterface, any, Record<string, never>, Record<string, never>>(
    createRootReducer(history),
    initialState,
    composeMiddlewares,
  );*/
  const store = configureStore({
    reducer: createRootReducer(history),
    // middleware: composeMiddlewares
  });

  exposeActions(store);
  if (module.hot) { // eslint-disable-line no-undef
    module.hot.accept('Reducers', () => { // eslint-disable-line no-undef
      const nextReducer = require('Reducers'); // eslint-disable-line no-undef
      store.replaceReducer(nextReducer);
    });
  }
  return { store, persistedStore: persistStore(store) };
};

const exposeActions = (store: StoreInterface) => {
  const actions = {
    ...navigationActions
  };
  window['ReactAppBridge'] = {};
  (window as any).ReactAppBridge.ReduxActions = bindActionCreators(actions, (store.dispatch as Dispatch));
};
