import * as React from 'react';
import './styles/SingleInstance.scss';
import './styles/ValidationStyle.scss';
import bind from 'bind-decorator';
import { Button, ProgressBar } from 'react-bootstrap';
import { ButtonGroup } from 'react-bootstrap';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { Locations } from 'Interfaces/LocationInterface';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { SingleInstance } from 'Interfaces/ModuleSelectionInterface';
import { RouteComponentProps, withRouter } from 'react-router';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { SystemStatus } from 'Interfaces/SystemInterface';
import { AlertInterface } from 'Interfaces/AlertInterface';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { LooseObject } from '../../Interfaces/LooseObject';
import { DataPoint } from '../../Interfaces/DataPoint';
import { getLocalization, globalWindow } from '../../global/global';
import * as scheduleApi from '../../api/schedulesApi';
import GenericModal from '../Modals/GenericModal';
import { QueryFiltersInterface } from '../../reducers/filtersMenuReducer';
import FormComponent from './FormComponent';
import DeleteButtonContainer from './Buttons/Containers/DeleteButtonContainer';
import ShareButtonContainer from './Buttons/Containers/ShareButtonContainer';
import CommentsButtonContainer from './Buttons/Containers/CommentsButtonContainer';
import ReportsButtonContainer from './Buttons/Containers/ReportsButtonContainer';
import SaveButton, { IOwnState as ISaveButtonState } from './Buttons/SaveButton';
import CopyButton from './Buttons/CopyButton';
import CloseButton from './Buttons/CloseButton';
import { getReports } from './utils/Reports';
import { freezeFormData, generateName, initDataPoint } from './utils/utils';
import { canCopy, canDelete, canExport, canShare, canViewReport } from './utils/RolesChecker';
import { SingleInstanceProps } from './Containers/SingleInstanceContainer';
import { getFormUtils } from './utils/FormUtilsHolder';
import { mergeDataPoint } from './utils/merge';
import ExportButtonContainer from './Buttons/Containers/ExportButtonContainer';

interface IExternalProps {
  clientPersist: ClientPersistInterface;
  locationHierarchy: Locations;
  forms: FormInterface[];
  singleInstance: SingleInstance;
  status: SystemStatus;
  savePOI: (dataPoint: DataPoint, parameters?: JSONInterface, showProgress?: boolean) => Promise<Response>;
  fetchPOI: (
    formId: string, signal: AbortSignal, rowId?: number, filter?: QueryFiltersInterface[], query?: LooseObject
  ) => Promise<Response>;
  unsetSingleInstance: () => void;
  setSingleInstance: (singleInstance: SingleInstance) => void;
  // deletePOI: (rowId: number, id: string, formId: string, callBack: () => void) => void;
  closeSingleInstance: (id: string) => void;
  showAlert: (alert: AlertInterface) => void;
  saveSetting: (name: string, value: string) => void;
  updateClientPersist: (clientPersist: ClientPersistInterface) => void;
  toggleFilterMenu: (opened: boolean) => void;
  updateOpenedDataPointAction: () => void;
}

interface State {
  newAnswer: boolean;
  loaded: boolean;
  dataPoint: DataPoint;
  draftPoi: boolean;
  reports: LooseObject[];
  saveType?: ISaveButtonState['saveType'];
  validationAlert: boolean;
  validate?: LooseObject;
  saveError: SaveErrorMessage | null;
  key: string;
  editingMsg: string | undefined;
  sameUserMsg: string;
  confirmAutoSave: boolean;
  enableAutoSave: boolean;
  autoSaveTime: number;
  savingInBackground: boolean;
  readOnly: boolean;
}

interface SaveErrorMessage {
  error: string;
}

const BACKGROUND_SAVE_INTERVAL = 1800000; // 30 minutes

export class SingleInstanceComponentClass extends React.Component<IExternalProps & RouteComponentProps<any>, State> {
  public formComponent = React.createRef<FormComponent>();
  private exportButton = React.createRef<ExportButtonContainer>();
  private exportOnSave: boolean | undefined = undefined;
  private abortController: AbortController = new AbortController();
  private saveInBackGroundTimeout;
  constructor(props: IExternalProps & RouteComponentProps<any>) {
    super(props);
    const {model, dataPoint, newAnswer, history} = props.singleInstance;
    const loaded = newAnswer; //  || (dataPoint.id !== undefined && !isNullOrUndefined(dataPoint.row_id));
    const dp = newAnswer ? initDataPoint(
      dataPoint, model, props.clientPersist, props.locationHierarchy, undefined, props.forms
    ) : dataPoint;
    const readOnly = history === true
      || this.props.clientPersist.roles === 'viewer' || (!newAnswer && freezeFormData(dataPoint, model))
      || props.status === 'EXPIRED';

    this.state = {
      newAnswer,
      loaded: loaded,
      dataPoint: dp,
      reports: !newAnswer ? getReports(model, props.forms, []) : [],
      validationAlert: false,
      saveError: null,
      draftPoi: false,
      key: `${dp.id || Math.random()}-${model.ref}-single-instance`,
      editingMsg: undefined,
      sameUserMsg: '',
      confirmAutoSave: false,
      enableAutoSave: false,
      autoSaveTime: -1,
      savingInBackground: false,
      readOnly
    };
    this.formComponent = React.createRef();
    if (!loaded) {
      this.loadPOI();
    } else {
      if (!readOnly) {
        this.saveInBackGroundTimeout = setInterval(this.saveInBackGround, BACKGROUND_SAVE_INTERVAL);
      }
    }
  }

  @bind
  public savePOI(saveType: ISaveButtonState['saveType'], exportOnSave?: boolean) {
    const { clientPersist } = this.props;
    if (this.formComponent.current && !this.state.saveType) {
      if (this.saveInBackGroundTimeout) {
        clearInterval(this.saveInBackGroundTimeout);
      }
      let dataPoint = this.formComponent.current.getDataModel();
      const deltaTime = Date.now() - dataPoint['portalEditEntry'];
      // dataPoint = Object.assign({}, dataPoint, { portalEditTime: deltaTime });
      if (dataPoint['portalEditTime']) {
        dataPoint['portalEditTime'] = dataPoint['portalEditTime'] + deltaTime;
      } else {
        dataPoint['portalEditTime'] = deltaTime;
      }
      if (!dataPoint.Name) {
        dataPoint = Object.assign({}, dataPoint, { Name: generateName() });
      }
      if (saveType === 'DRAFT') {
        dataPoint = Object.assign({}, dataPoint, { draft_poi: true });
        this.setState({ dataPoint: dataPoint, saveType: saveType, draftPoi: true });
      } else {
        this.setState({ saveType: saveType, draftPoi: false });
        delete dataPoint['draft_poi'];
      }
      const { newAnswer, onSave } = this.props.singleInstance;
      if (onSave && newAnswer) {
        delete dataPoint['scheduleDate'];
        delete dataPoint['scheduleStatus'];
      }
      dataPoint['modified'] = Date.now();
      delete dataPoint['needs_editing'];
      dataPoint['user_id'] = Number(clientPersist.user_id);
      this.exportOnSave = exportOnSave;
      const savePoiPromise = this.props.savePOI(dataPoint);
      this.setState({ saveType: saveType }, () => this.onPOISaved(savePoiPromise, dataPoint));
    }
  }

  @bind
  private saveInBackGround() {
    if (this.formComponent.current) {
      const { clientPersist } = this.props;
      if (clientPersist.autoSaveEnabled) {
        this.doSaveInBackground();
      } else {
        clearInterval(this.saveInBackGroundTimeout);
        this.setState({ confirmAutoSave: true });
      }
    }
  }

  @bind
  private doSaveInBackground() {
    if (this.formComponent.current) {
      const { clientPersist } = this.props;
      if (this.state.enableAutoSave) {
        this.props.saveSetting('autoSaveEnabled', 'true');
        this.props.updateClientPersist({...clientPersist, autoSaveEnabled: true });
        this.setState({ enableAutoSave: false });
      }
      if (!clientPersist.autoSaveEnabled) {
        this.saveInBackGroundTimeout = setInterval(this.saveInBackGround, BACKGROUND_SAVE_INTERVAL);
      }
      this.setState({ confirmAutoSave: false, savingInBackground: true });
      let dataPoint = this.formComponent.current.getDataModel();
      if (!dataPoint.Name) {
        dataPoint = Object.assign({}, dataPoint, { Name: generateName() });
      }
      const { newAnswer, onSave } = this.props.singleInstance;
      dataPoint['modified'] = Date.now();
      delete dataPoint['needs_editing'];
      if (onSave && newAnswer) {
        delete dataPoint['scheduleDate'];
        delete dataPoint['scheduleStatus'];
      }
      dataPoint = JSON.parse(JSON.stringify(dataPoint));
      if (newAnswer) {
        dataPoint.draft_poi = true;
      } else if (this.formComponent.current) {
        const valid = this.formComponent.current.validateForm();
        if (!valid.valid) {
          dataPoint.draft_poi = true;
        }
      }
      dataPoint['user_id'] = Number(clientPersist.user_id);

      const startTime = Date.now();
      const savePoiPromise = this.props.savePOI(dataPoint, undefined, false);
      savePoiPromise.then(response => response.json()).then(json => {
        if (json.rowId) {
          this.formComponent.current?.updateDataPoint({...json, id: json.id, row_id: json.rowId });
        }
        this.setState({ autoSaveTime: startTime, savingInBackground: false });
      }).catch(() => { console.info('error'); });
    }
  }

  @bind
  private onPOISaved(savePoiPromise: Promise<Response>, dp: DataPoint) {
    const { saveType } = this.state;
    const { clientPersist } = this.props;
    const { model, onSave } = this.props.singleInstance;
    const { setSingleInstance, unsetSingleInstance } = this.props;
    savePoiPromise.then(response => response.json()).then(json => {
      if (json.rowId && json.rowId !== -1) {
        let dataPoint: DataPoint = { questionnaire_id: model.ref, formId: model.id, id: json.id };
        if (this.state.newAnswer && json.id && onSave) {
          onSave(json.id, dp['scheduleDate'], dp['scheduleStatus']);
        }
        if (!onSave && model.hasScheduledStatus) {
          if (dp['scheduleStatus'] && dp['scheduleStatus'] !== 'Unscheduled' && dp['scheduleDate']) {
            void scheduleApi.saveSchedule({
              formId: model.ref,
              answerId: json.id,
              date: dp['scheduleDate'],
              userId: Number(clientPersist.user_id),
              initialized: false,
              status: 'Scheduled'
            });
          }
        }
        if (saveType !== 'NEW') {
          dataPoint = {...dataPoint, _id: json.rowId, id: json.id};
          this.setState((state) => {
            return { ...state, loaded: false, newAnswer: false, saveType: undefined };
          }, () => this.reInit(dataPoint));
        } else {
          const si = {
            newAnswer: true,
            model: model,
            dataPoint:  { formId: model.id, questionnaire_id: model.ref, created: Date.now() }
          };
          unsetSingleInstance();
          setTimeout(() => setSingleInstance(si), 100);
        }
      } else {
        this.setState({ saveError: { error : json.error }, saveType: undefined});
      }
    }).catch(error => {
      console.log(error);
      this.setState({ saveType: undefined });
    });
  }

  @bind
  public unrender() {
    if (this.state.newAnswer && this.formComponent.current) {
      /* const dp = this.formComponent.current.getDataModel();
      if (dp.row_id && dp.id && dp.questionnaire_id) {
        this.props.deletePOI(dp.row_id, dp.id, dp.questionnaire_id, () => {
          console.log('');
        });
      }*/
    }
    this.props.unsetSingleInstance();
    const { Tawk_API } = globalWindow; // eslint-disable-line @typescript-eslint/naming-convention
    if (Tawk_API && Tawk_API.showWidget && typeof Tawk_API.showWidget === 'function') {
      globalWindow.Tawk_API.showWidget();
    }
  }

  @bind
  public reInit(dataPoint: DataPoint) {
    const { model } = this.props.singleInstance;
    let query;
    if (dataPoint.id) {
      query = { id: dataPoint.id };
    }
    const fetchPoiPromise = this.props.fetchPOI(
      model.ref, this.abortController.signal, query ? undefined : dataPoint.row_id, undefined, query
    );
    this.loadedPOI(fetchPoiPromise);
  }

  @bind
  public copyPoi(dataPoint: DataPoint) {
    const { model } = this.props.singleInstance;
    this.setState({
      dataPoint, key: `${Math.random()}-${model.ref}-single-instance`, newAnswer: true
    });
  }

  /*
    returns the report, export, comment, copy, share and delete buttons.
    if we are adding a POI, returns null.

  */
  @bind
  private getTopButtons(): JSX.Element | undefined {
    const { newAnswer, draftPoi } = this.state;
    if (!newAnswer && !draftPoi) {
      const { clientPersist, singleInstance: {model, history}, forms } = this.props;
      const { dataPoint } = this.state;
      return (
        <React.Fragment>
          {canViewReport(clientPersist) && (
            <ReportsButtonContainer dataPoint={dataPoint} model={model} forms={forms} />
          )}
          <ButtonGroup>
            {canExport(clientPersist, model) && (
              <ExportButtonContainer
                ref={this.exportButton}
                dataPoint={dataPoint}
                model={model}
                singleInstance={this}
                history={history}
              />
            )}
            <CommentsButtonContainer dataPoint={this.state.dataPoint} />
            {canCopy(model) && <CopyButton dataPoint={dataPoint} singleInstance={this} />}
            {canShare(clientPersist) && <ShareButtonContainer dataPoint={dataPoint}/>}
            {
              canDelete(dataPoint, clientPersist) &&
              <DeleteButtonContainer dataPoint={dataPoint} unrender={this.unrender}/>
            }
          </ButtonGroup>
        </React.Fragment>
      );
    }
    return undefined;
  }

  @bind
  private getTopBar(): JSX.Element {
    const viewer = this.props.clientPersist.roles === 'viewer';
    // if new answer there is only save and close buttons, so use less space
    const style = this.state.newAnswer ? 'col-md-4 col-xs-5' : 'col-md-6 col-xs-12';
    return (
      <div className={style}>
        <div className="btn-toolbar topbar pull-right" role="toolbar">
          <div className="control-btns pull-left">
            {!this.props.singleInstance.history && !viewer && (
              <SaveButton
                newAnswer={this.state.newAnswer}
                savePOI={this.savePOI}
                validateForm={this.validateForm}
                saveType={this.state.saveType}
                savingInBackground={this.state.savingInBackground}
              />
            )}
            {!viewer && this.getTopButtons()}
          </div>
          <div className="btn-group close-button-holder">
            {/* FIXME Passing around `this` is an anti-pattern and a hack which will spiral out of control*/}
            <CloseButton singleInstance={this} />
          </div>
        </div>
      </div>
    );
  }

  @bind
  public validateForm(): boolean {
    if (this.formComponent.current) {
      const valid = this.formComponent.current.validateForm();
      if (!valid.valid) {
        this.setState({ validationAlert: true, validate: valid });
      }
      return valid.valid;
    }
    return false;
  }

  @bind
  public onCopy(dataPoint: DataPoint) {
    this.setState({ dataPoint, loaded: true });
  }

  @bind
  private loadedPOI(fetchPoiPromise: Promise<Response>) {
    fetchPoiPromise.then(response => response.json()).then(json => {
      if (json.length === 1) {
        const dataPoint = json[0];
        this.setState((prevState) => {
          if (!this.saveInBackGroundTimeout && !this.state.readOnly) {
            this.saveInBackGroundTimeout = setInterval(this.saveInBackGround, BACKGROUND_SAVE_INTERVAL);
          }
          let editingMsg: string | undefined;
          let sameUserMsg = '';
          if (dataPoint.isOpen) {
            const users = dataPoint.isOpen.users;
            if (users && users.length > 0) {
              const sameUserIndex = users.indexOf(this.props.clientPersist.userName);
              if (sameUserIndex > -1) {
                this.props.showAlert({
                  visible: true,
                  message: getLocalization('sameUserOpen')
                });
                sameUserMsg = getLocalization('sameUserOpen');
                users.splice(sameUserIndex, 1);
              }
              if (users.length > 0) {
                const userNameList = users.map(u => {
                  if (u.indexOf('@') > -1) {
                    return u.substring(0, u.indexOf('@'));
                  }
                  return u;
                });
                if (sameUserIndex === -1) {
                  this.props.showAlert({
                    visible: true,
                    message: getLocalization('editingWarning').replace(/{{user}}/g, userNameList.join(','))
                  });
                  editingMsg = getLocalization('editingMsg').replace('{{user}}', userNameList.join(','));
                }
              }
            }
            delete dataPoint.isOpen;
          }
          dataPoint['portalEditEntry'] = Date.now();
          const { singleInstance } = this.props;
          if (singleInstance['galleryId']) {
            dataPoint['galleryId'] = singleInstance['galleryId'];
          }
          const readOnly = prevState.readOnly || freezeFormData(dataPoint, this.props.singleInstance.model);
          return {...prevState, dataPoint: dataPoint, loaded: true, editingMsg, sameUserMsg, readOnly };
        }, () => {
          if (this.exportOnSave && this.exportButton.current) {
            this.exportButton.current.initExport();
            this.exportOnSave = false;
          }
          /* if (
              this.state.newAnswer &&
              this.state.saveType === 'CLOSE' &&
              this.state.dataPoint.id &&
              this.props.singleInstance.onSave
          ) {
            //this.props.singleInstance.onSave(this.state.dataPoint.id);
          }*/
        });
      } else if (json.length === 0) {
        toast(getLocalization('singleInstanceError'), {
          type: toast.TYPE.ERROR,
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
          closeOnClick: true
        });
        this.props.unsetSingleInstance();
      }
    }).catch(error => {
      console.log(error);
      toast(getLocalization('singleInstanceError'), {
        type: toast.TYPE.ERROR,
        autoClose: 3000,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: true
      });
      this.props.unsetSingleInstance();
    });
  }

  @bind
  private loadPOI() {
    const { dataPoint } = this.props.singleInstance;
    this.reInit(dataPoint);
    // const fetchPoiResponse = this.props.fetchPOI(model.ref, this.abortController.signal , dataPoint.row_id);
    // this.loadedPOI(fetchPoiResponse);
  }

  @bind
  private cancelModal() {
    this.setState({ validationAlert: false, saveError: null, confirmAutoSave: false });
  }

  public componentWillUnmount() {
    this.abortController.abort();
    if (this.state.dataPoint.id) {
      this.props.closeSingleInstance(this.state.dataPoint.id);
    }
    window.onbeforeunload = null;
  }

  public componentDidMount() {
    const { Tawk_API } = globalWindow; // eslint-disable-line @typescript-eslint/naming-convention
    if (Tawk_API && Tawk_API.hideWidget && typeof Tawk_API.hideWidget === 'function') {
      globalWindow.Tawk_API.hideWidget();
    }
    this.props.toggleFilterMenu(false);
  }

  public componentDidUpdate(prevProps: SingleInstanceProps) {
    const prevMsg = prevProps.singleInstance?.message;
    const newMsg = this.props.singleInstance?.message;
    if (newMsg !== prevMsg && newMsg) {
      const newState: Partial<State> = {};
      const { editMessage, sameUserMessage } = newMsg;
      if (editMessage !== prevMsg?.editMessage && (editMessage || prevMsg?.editMessage)) {
        newState.editingMsg = editMessage;
      }
      if (sameUserMessage !== prevMsg?.sameUserMessage && (sameUserMessage || prevMsg?.sameUserMessage)) {
        newState.sameUserMsg = sameUserMessage;
      }
      this.setState((prevState) => { return {...prevState, ...newState}; });
    }
    if (this.props.singleInstance.updatedDataPoint && !prevProps.singleInstance?.updatedDataPoint) {
      this.mergeData();
    }
  }

  @bind
  private mergeData() {
    const { singleInstance, clientPersist } = this.props;
    const formUtils = getFormUtils(singleInstance.model);
    console.log('should now merge data...');
    if (this.formComponent.current && singleInstance.updatedDataPoint) {
      const dp = this.formComponent.current.getDataModel();
      const mergeResult = mergeDataPoint(
        dp,
        singleInstance.updatedDataPoint,
        formUtils,
        clientPersist
      );
      if (
        mergeResult.merged &&
        mergeResult.mergedFields &&
        mergeResult.mergedPages?.length &&
        mergeResult.user_id !== Number(clientPersist.user_id)
      ) {
        const msg = `Saved data merged with currently open data, with updates on the following pages:
          ${mergeResult.mergedPages.map((item) => item.name).join(', ')}`;
        toast(msg, {
          autoClose: false,
          type: toast.TYPE.INFO,
          closeButton: (<Button variant="primary" size={'sm'}>{'Got it!'}</Button>),
          closeOnClick: true
        });
      }
      console.log('merged data');
      delete mergeResult.merged;
      delete mergeResult.mergedFields;
      delete mergeResult.mergedPages;
      this.setState({
        key: `${mergeResult.row_id}-${Math.random()}-${singleInstance.model.ref}-single-instance`,
        dataPoint: mergeResult
      });
      // this.formComponent.current.updateMergedDataPoint(mergeResult);
      this.props.updateOpenedDataPointAction();
    }
  }

  public render(): JSX.Element {
    if (!this.state.loaded) {
      return (
        <div>
          <ProgressBar animated now={100} />
          <p>{getLocalization('loading')}</p>
        </div>
      );
    }
    // const { clientPersist } = this.props;
    const {
      validationAlert, validate, saveError, editingMsg, sameUserMsg, confirmAutoSave, autoSaveTime
    } = this.state;
    const { clientPersist } = this.props;
    const { model } = this.props.singleInstance;
    const validationAlertModal = validationAlert ? (
      <GenericModal
        visible={validationAlert}
        body={(
          <React.Fragment>
            <p><strong>{getLocalization('mandatoryDataMissing')}</strong></p>
            {validate?.page ? (
              <p><strong>{getLocalization('page')} : </strong> {validate.page.name}</p>
            ) : null}
            <p><strong>Question</strong></p>
            <p>{validate && validate.question.text}</p>
          </React.Fragment>
        )}
        title={getLocalization('info')}
        onConfirm={this.cancelModal}
        confirmText={getLocalization('ok')}
      />
    ) : null;

    const savingAlert = saveError ? (
      <GenericModal
        visible={this.state.saveError ? true : false}
        body={(
          <React.Fragment>
            <p><strong>{getLocalization('errorSavingData')}</strong></p>
          </React.Fragment>
        )}
        title={getLocalization('error')}
        onConfirm={this.cancelModal}
        confirmText={getLocalization('ok')}
      />
    ) : null;

    const confirmAutoSaveModal = confirmAutoSave ? (
      <GenericModal
        visible={confirmAutoSave}
        body={(
          <React.Fragment>
            <p><strong>{getLocalization('autoSaveData')}</strong></p>
            <Form.Check
              type={'checkbox'}
              label={getLocalization('enableAutoSave')}
              id={`enable-auto-save`}
              onChange={(e) => this.setState({ enableAutoSave: e.target.checked })}
            />
          </React.Fragment>
        )}
        title={getLocalization('confirm')}
        onConfirm={this.doSaveInBackground}
        confirmText={getLocalization('save')}
        extraButtons={[(
          <Button
            size="sm"
            onClick={this.cancelModal}
            id={'auto-save-modal-negative-btn'}
            key={'auto-save-modal-negative-btn'}
          >
            {getLocalization('cancel')}
          </Button>
        )]}
      />
    ) : null;
    // if new answer there is only save and close buttons, so use more space for name
    const style = this.state.newAnswer ? 'col-xs-7 col-md-8' : 'col-md-6 col-xs-12';

    return (
      <div className={`single-instance ${model.useColoredBackground ? 'use-colored-background' : ''}`}>
        <div className="row container-fluid top-bar">
          <div className={style}>
            <h3 className="poi-name">
              <span className="poimapper">
                <img
                  src={this.props.singleInstance.model.smallIconUrl}
                />
              </span>
              <span>
                {this.props.singleInstance.model.name}
              </span>
            </h3>
          </div>
          {this.getTopBar()}
          {editingMsg && (
            <p className="editing-alert">{`${editingMsg}`}</p>
          )}
          {sameUserMsg && (
            <p className="editing-alert">{sameUserMsg}</p>
          )}
        </div>
        {clientPersist.autoSaveEnabled && autoSaveTime > -1 && (
          <div className="alert alert-info" role="alert">
            <span>{`${getLocalization('autoSavedAt')} ${new Date(autoSaveTime).toLocaleTimeString()}`}</span>
          </div>
        )}
        {validationAlertModal}
        {savingAlert}
        {confirmAutoSaveModal}
        <FormComponent
          key={this.state.key}
          model={this.props.singleInstance.model}
          forms={this.props.forms}
          dataPoint={this.state.dataPoint}
          ref={this.formComponent}
          newAnswer={this.state.newAnswer}
          // parentDataPoint={this.state.dataPoint}
          // parentModel={this.props.singleInstance.model}
          clientPersist={this.props.clientPersist}
          locationHierarchy={this.props.locationHierarchy}
          updateClientPersist={this.props.updateClientPersist}
          readOnly={this.state.readOnly}
        />
      </div>
    );
  }
}

export default withRouter(SingleInstanceComponentClass);
