import { toast } from 'react-toastify';
import { ExcelData } from 'Interfaces/ImportDataInterfaces';
import * as ImportApi from '../api/import';
import { getLocalization } from '../global/global';
import { LooseObject } from '../Interfaces/LooseObject';
import { IMPORT } from './actionTypes';

export const uploadMapImport = (file, name, srs) => {
  return (dispatch, getState) => {
    const uploadToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('diagramuploading'), {
      toastId: uploadToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    const reportResponse = ImportApi.doUploadMapImport(
      getState().clientPersist.groupId,
      file,
      name,
      srs,
      dispatch,
      getState,
    );
    reportResponse
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'OK') {
          toast.update(uploadToastId, {
            type: toast.TYPE.SUCCESS,
            render: getLocalization('diagramuploaded'),
          });
        } else {
          toast.update(uploadToastId, {
            type: toast.TYPE.ERROR,
            render: json.message,
          });
        }
        setTimeout(() => toast.dismiss(uploadToastId), 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const loadImportMappings = (formIds) => {
  return (dispatch, getState) => {
    const requestToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('loadingImportMappings'), {
      toastId: requestToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    const importMappingResponse = ImportApi.doLoadImportMappings(formIds, dispatch, getState);
    importMappingResponse
      .then((response) => response.json())
      .then((json) => {
        if (!json.errorCode) {
          toast.update(requestToastId, {
            type: toast.TYPE.SUCCESS,
          });
          dispatch(setImportMappings(json));
        } else {
          toast.update(requestToastId, {
            type: toast.TYPE.ERROR,
            render: json.message,
          });
        }
        setTimeout(() => toast.dismiss(requestToastId), 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const setImportMappings = (importMappings) => ({ type: IMPORT.LOADED, importMappings });

export const uploadDataImport = (file: File, mappings: LooseObject, formId: string, geocode: boolean) => {
  return (dispatch, getState) => {
    const forms = getState().forms.collection || [];
    const form = forms.find((f) => f.ref === formId);
    if (form) {
      const uploadToastId = Date.now() + Math.floor(Math.random() * 100);
      toast(getLocalization('importDataUploading'), {
        toastId: uploadToastId,
        type: toast.TYPE.INFO,
        autoClose: false,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: false,
      });
      const clientPersist = getState().clientPersist;
      const reportResponse = ImportApi.doUploadDataImport(
        clientPersist.groupId,
        clientPersist.user_id,
        file,
        form,
        mappings,
        geocode,
        dispatch,
        getState,
      );
      reportResponse
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'OK') {
            toast.update(uploadToastId, {
              type: toast.TYPE.SUCCESS,
              render: getLocalization('importDataUploaded'),
            });
          } else {
            toast.update(uploadToastId, {
              type: toast.TYPE.ERROR,
              render: json.message,
            });
          }
          setTimeout(() => toast.dismiss(uploadToastId), 3000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const uploadImportExcelFile =
    (file: File, signal: AbortSignal) =>
      (dispatch, getState): Promise<Response> => {
        return new Promise((resolve) => {
          const uploadToastId = Date.now() + Math.floor(Math.random() * 100);
          toast(getLocalization('processingFile'), {
            toastId: uploadToastId,
            type: toast.TYPE.INFO,
            autoClose: false,
            closeButton: false,
            hideProgressBar: true,
            closeOnClick: false,
          });
          const reportResponse = ImportApi.doUploadImportExcelFile(file, signal, dispatch, getState);
          reportResponse
            .then((response) => response.json())
            .then((json) => {
              if (json.errorCode) {
                resolve(new Response(JSON.stringify({ error: true })));
              } else {
                toast.update(uploadToastId, {
                  type: toast.TYPE.SUCCESS,
                  render: getLocalization('processedFile'),
                });
                resolve(new Response(JSON.stringify(json)));
              }
              setTimeout(() => toast.dismiss(uploadToastId), 3000);
            })
            .catch(() => {
              resolve(new Response(JSON.stringify({ error: true })));
            });
        });
      };

export const importData = (excelData: ExcelData) => {
  return (dispatch, getState) => {
    const uploadToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('importDataUploading'), {
      toastId: uploadToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    const reportResponse = ImportApi.doImportExcelData(excelData, dispatch, getState);
    reportResponse
      .then((response) => response.json())
      .then((json) => {
        if (json.errorCode) {
          toast.update(uploadToastId, {
            type: toast.TYPE.ERROR,
            render: 'Error uploading data',
          });
        } else {
          toast.update(uploadToastId, {
            type: toast.TYPE.SUCCESS,
            render: getLocalization('importDataUploaded'),
          });
          setTimeout(() => toast.dismiss(uploadToastId), 3000);
        }
        setTimeout(() => toast.dismiss(uploadToastId), 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };
};
