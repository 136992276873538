import './Style.scss';
import { ModalComponentNames, ModalInterface } from 'Interfaces/ModalInterface';
import { getRequestConfig } from 'api';
import { getLocalization } from 'global/global';
import * as React from 'react';
import { Button, Form, ProgressBar } from 'react-bootstrap';
import { toast, ToastPosition, ToastType } from 'react-toastify';
import GenericModal from 'views/Modals/GenericModal';

interface AccountSettingsModalProps {
  onClose: () => void;
}

const DEFAULT_SETTINGS = {
  require2FA: false
};

export default function AccountSettingsModal(props: AccountSettingsModalProps) {

  const [settings, setSettings] = React.useState(DEFAULT_SETTINGS);
  const [scim, setScimToken] = React.useState('');
  const [showCopy, setShowCopy] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const config = getRequestConfig();

  React.useEffect(() => {
    if (!loading) {
      setLoading(true);
      fetch('/json/admin/settings', config).then(res => res.json()).then(json => {
        setSettings({...DEFAULT_SETTINGS, ...json});
        setLoading(false);
      }).catch(e => {
        console.log(e);
      });
      fetch('/json/admin/scim', config).then(res => res.json()).then(json => {
        if (json.id) {
          setScimToken(json.id);
        }
      }).catch(e => {
        console.log(e);
      });
    }
  }, [props]);

  const updateSettings = (name: string, value: string) => {
    setSettings({...settings, [name]: value });
    fetch('/json/admin/settings', {...config, method: 'POST', body: JSON.stringify({ name, value })})
      .then(res => res.json()).then(json => {
        if (json.status === 'OK') {
          setSettings({...settings, [name]: value });
        }
      }).catch(e => {
        console.log(e);
      });
  };

  const generateSCIMToken = () => {
    fetch('/json/admin/scim', {...config, method: 'POST'})
      .then(res => res.json()).then(json => {
        if (json.status === 'OK') {
          setScimToken(json.id);
          setShowCopy(true);
        }
      }).catch(e => {
        console.log(e);
      });
  };

  const copySCIMToken = () => {
    void navigator.clipboard.writeText(scim);
    toast(getLocalization('copiedToClipboard'), {
      autoClose: 3000, position: ToastPosition.TOP_RIGHT, type: ToastType.INFO
    });
  };

  const getSCIMAuthToken = () => {
    return (
      <div className="scim-settings">
        <Form.Label>{getLocalization('scimToken')}</Form.Label>
        <pre>
          {scim ? `${scim}` : getLocalization('scimTokenNotSet')}
          {showCopy && <Button variant="link" onClick={copySCIMToken}><i className="fa fa-copy"/></Button>}
        </pre>
        <Button
          size="sm"
          onClick={generateSCIMToken}
        >
          {getLocalization('generateSCIMToken')}
        </Button>
      </div>
    );
  };

  const getBody = () => {
    const keys = Object.keys(settings);
    if (loading) {
      return (
        <ProgressBar animated={true} now={100} striped={true} />
      );
    }
    return (
      <>
        {getSCIMAuthToken()}
        {
          keys.map((key) => {
            switch (key) {
              case 'require2FA':
                return (
                  <Form.Group>
                    <Form.Check
                      label={getLocalization('require2FA')}
                      id={'require-2fa-check'}
                      checked={`${settings[key]}` === 'true'}
                      onChange={(e) => updateSettings('require2FA', e.target.checked ? 'true' : 'false')}
                    />
                  </Form.Group>
                );
              case 'max_password_age':
                return (
                  <Form.Group>
                    <Form.Label>{getLocalization('maxPasswordAge')}</Form.Label>
                    <Form.Control
                      type="number"
                      id={'max_password_age'}
                      value={`${settings[key]}`}
                      size="sm"
                      onChange={(e) => updateSettings('max_password_age', e.target.value)}
                    />
                  </Form.Group>
                );
              default:
                return null;
            }
          })
        }
      </>
    );
  };

  return (
    <GenericModal
      visible={true}
      title={getLocalization('accountSettings')}
      confirmText={getLocalization('close')}
      onConfirm={props.onClose}
      body={(<>{getBody()}</>)}
    />
  );
}

export const showAccountSettings = () => {
  const modalProps: ModalInterface<AccountSettingsModalProps> = {
    component: ModalComponentNames.AccountSettingsModal,
    props: {
      onClose: () => {
        (window as any).ReactAppBridge.ReduxActions.navigateRemoveModal(ModalComponentNames.AccountSettingsModal);
      },
    }
  };
  (window as any).ReactAppBridge.ReduxActions.navigateAddModal(modalProps);
};
