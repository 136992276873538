import './Style.scss';
import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DocumentComment, INITIAL_COMMENT } from 'Interfaces/Documents/DocumentComments';
import { StateInterface } from 'Interfaces/StateInterface';
import { doSaveComment } from 'api/documentApi';
import { getLocalization } from 'global/global';


interface Props {
  documentId: number;
  addComment: (comment: DocumentComment) => void;
}
export default function CommentEditor(props: Props) {

  const clientPersist = useSelector((state: StateInterface) => state.clientPersist);
  const [comment, setComment] = React.useState({
    ...INITIAL_COMMENT, comment: '',
    createdOn: Date.now(),
    createdBy: Number(clientPersist.user_id),
    documentId: props.documentId
  });

  const saveComment = async () => {
    if (!comment.comment) {
      return;
    }
    const saved = await doSaveComment({...comment, createdOn: Date.now()});
    if (saved && saved.id) {
      props.addComment(saved);
      setComment({
        ...INITIAL_COMMENT,
        createdBy: Number(clientPersist.user_id),
        documentId: props.documentId,
        comment: '',
        createdOn: Date.now(),
      });
    }
  };

  return (
    <>
      <Form.Group>
        <Form.Control
          as={"textarea"}
          rows={3}
          size="sm"
          placeholder={getLocalization('addNewComment')}
          onChange={(e) => setComment({...comment, comment: e.target.value})}
        />
      </Form.Group>
      <div className="comment-btn-container mb-1">
        <Button size="sm" className="float-right mt-2" onClick={() => void saveComment()}>
          {getLocalization('comment')}
        </Button>
      </div>
    </>
  );
}
