import React, { Dispatch, FunctionComponent, SetStateAction, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { formsForFiltersSelector, formsWithScheduleStatusSelector } from '../../reducers/formsReducer';
import { getLocalization } from '../../global/global';

interface IExternalProps {
  baseClassName: string;
  selectedForm?: FormInterface;
  setSelectedForm: Dispatch<SetStateAction<FormInterface | undefined>>;
}

export const ScheduleSelectForm: FunctionComponent<IExternalProps> = (props) => {
  const selectedForms = useSelector(formsWithScheduleStatusSelector);
  const allForms = useSelector(formsForFiltersSelector);
  const forms = selectedForms.length > 0 ? selectedForms : allForms;
  useEffect(() => {
    if (forms.length > 0) {
      props.setSelectedForm(forms[0]);
    }
  }, [forms]);
  if (props.selectedForm) {
    return (
      <Form.Group
        controlId="schedule.FormSelect"
        className={props.baseClassName}
      >
        <Form.Label column>
          {getLocalization('scheduleSelectForm')}
        </Form.Label>
        <Form.Control
          as="select"
          value={props.selectedForm.ref}
          onChange={(e) => {
            const selected = forms.find(form => form.ref === e.currentTarget.value);
            if (selected) {
              props.setSelectedForm(selected);
            }
          }}
        >
          {forms.map((form) => (
            <option
              value={form.ref}
              key={form.ref}
            >
              {form.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    );
  }
  return null;
};
