
import { GroupInterface } from 'Interfaces/GroupInterface';
import { GROUPS } from '../actions/actionTypes';

const INITIAL_STATE: GroupInterface = {
  loaded: false,
  collection: []
};

const groups = (state: GroupInterface = INITIAL_STATE, action): GroupInterface => {
  switch (action.type) {
    case GROUPS.SET:
      if (action.payload) {
        return {
          loaded: true,
          collection: action.payload
        };
      }
      return state;
    default:
      return state; //
  }
};

export default groups;
