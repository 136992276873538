import { getLocalization } from 'global/global';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MapComponent } from '../../MapComponent';
import { ILocationTreeNode } from '../../Workspace';

interface Props {
  node: ILocationTreeNode;
  updateNode: (attr: ILocationTreeNode) => void;
}

export const Hierarchy = (props: Props) => {
  const { node } = props;

  const updateNode = (attr) => {
    const newNode = {...props.node, ...attr};
    props.updateNode(newNode);
  };

  const updateData = (name: string, value: string) => {
    const newNode = {...props.node};
    newNode.data = newNode.data || {};
    newNode.data[name] = value;
    props.updateNode(newNode);
  };

  return (
    <>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>{getLocalization('locationHierarchyName')}</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          size="sm"
          value={node.value}
          // @ts-ignore
          onChange={(e) => updateNode({ value: e.target.value, title: e.target.value })}
        />
      </Form.Group>
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          label={getLocalization('overlappingCodes')}
          checked={node.data && `${node.data['OverlappingCodes']}` === 'true'}
          onChange={(e) => updateData('OverlappingCodes', e.target.checked)}
        />
      </Form.Group>
      <Form.Group controlId="locationLevel1" as={Row}>
        <Form.Label column sm={5}>{getLocalization('locationLevel1')}</Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            size="sm"
            value={node.data && node.data.Location1 ? node.data.Location1 : ''}
            onChange={(e) => updateData('Location1', e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group controlId="locationLevel2" as={Row}>
        <Form.Label column sm={5}>{getLocalization('locationLevel2')}</Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            size="sm"
            value={node.data && node.data.Location2 ? node.data.Location2 : ''}
            onChange={(e) => updateData('Location2', e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group controlId="locationLevel3" as={Row}>
        <Form.Label column sm={5}>{getLocalization('locationLevel3')}</Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            size="sm"
            value={node.data && node.data.Location3 ? node.data.Location3 : ''}
            onChange={(e) => updateData('Location3', e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group controlId="locationLevel4" as={Row}>
        <Form.Label column sm={5}>{getLocalization('locationLevel4')}</Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            size="sm"
            value={node.data && node.data.Location4 ? node.data.Location4 : ''}
            onChange={(e) => updateData('Location4', e.target.value)}
          />
        </Col>
      </Form.Group>
      <MapComponent node={node} updateNode={props.updateNode}/>
    </>
  );
};
