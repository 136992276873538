import './HeaderContainer.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { Navbar } from 'react-bootstrap';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps, withRouter } from 'react-router';
import { RouterState } from 'connected-react-router';
import { Parameters } from 'enzyme';
import { toggleSelectedModule } from 'actions/moduleSelectionActions';
import { MODULES } from 'Interfaces/ModuleSelectionInterface';
import { SystemInterface } from 'Interfaces/SystemInterface';
import bind from 'bind-decorator';
import { globalWindow } from 'global/global';
import { filtersMenuSelector } from '../../reducers/filtersMenuReducer';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { navigateAddModal, navigateRemoveModal } from '../../actions/navigationAction';
import { ModalComponentNames, ModalInterface } from '../../Interfaces/ModalInterface';
import { updateClientPersist } from '../../actions/clientPersistActions';
import { logout, saveSetting } from '../../actions/userActions';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import RightDropdown from './RightDropdown';
import Brand from './Brand';
import CollapseMenu from './CollapseMenu';
import helpPaths from './helpPaths';

export type CURRENT_SELECTION =
  'dataviewer' | 'help' | 'admin' | 'formbuilder' | 'locationHierarchy' | 'payments' | 'documents';

interface IStateProps {
  clientPersist: ClientPersistInterface;
  filtersMenu: FiltersMenuInterface;
  routerLocation: RouterState['location'];
  singleInstanceVisible: boolean;
  system: SystemInterface;
}

interface IActionProps {
  actions: {
    logout: (...args: Parameters<typeof logout>) => Promise<void>;
    updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) => void;
    navigateAddModal: (modal: ModalInterface) => void;
    navigateRemoveModal: (modalName: ModalComponentNames) => void;
    toggleSelectedModule: (module: MODULES) => void;
    saveSetting: (name: string, value: string) => void;
  };
}

export type IExternalProps = ReactCookieProps & RouteComponentProps;

export type HeaderPropsInterface = IStateProps & IActionProps & IExternalProps;

export class HeaderContainerClass extends React.Component<HeaderPropsInterface> {

  private sessionTimeOutCheck: number = -1;

  @bind
  private checkSessionStatus() {
    const { clientPersist } = this.props;
    if (clientPersist.sessionTime && (Date.now() - clientPersist.sessionTime) > 7200000) {
      void this.props.actions.logout();
    }
  }

  private getCurrentSelection(): CURRENT_SELECTION {
    let currentSelection;
    if (helpPaths.includes(this.props.routerLocation.pathname)) {
      currentSelection = 'help';
    } else {
      if (this.props.routerLocation.pathname === '/schedule') {
        currentSelection = 'schedule';
      } else if (globalWindow.location.pathname.indexOf('admin') > -1) {
        currentSelection = 'admin';
      } else if (globalWindow.location.pathname.indexOf('locationHierarchy') > -1) {
        currentSelection = 'locationHierarchy';
      }  else if (globalWindow.location.pathname.indexOf('payments') > -1) {
        currentSelection = 'payments';
      } else if (globalWindow.location.pathname.indexOf('document') > -1) {
        currentSelection = 'documents';
      } else {
        currentSelection = 'dataviewer';
      }
    }
    return currentSelection;
  }

  public componentDidMount() {
    this.sessionTimeOutCheck = globalWindow.setInterval(this.checkSessionStatus, 60000);
  }

  public componentWillUnmount() {
    if (this.sessionTimeOutCheck > -1) {
      window.clearInterval(this.sessionTimeOutCheck);
    }
  }

  public render(): JSX.Element | null {
    const { clientPersist } = this.props;
    const bg = clientPersist.instance !== 'mondelez' ? 'dark' : 'light';
    if (this.props.singleInstanceVisible) {
      return null;
    }
    return (
      <Navbar
        variant={bg}
        bg={bg}
        collapseOnSelect
        expand="lg"
        className={'HeaderContainer'}
      >
        <Brand
          instance={this.props.clientPersist.instance}
          navigateAddModal={this.props.actions.navigateAddModal}
          navigateRemoveModal={this.props.actions.navigateRemoveModal}
        />
        <CollapseMenu
          clientPersist={this.props.clientPersist}
          navigateAddModal={this.props.actions.navigateAddModal}
          navigateRemoveModal={this.props.actions.navigateRemoveModal}
          routerLocation={this.props.routerLocation}
          history={this.props.history}
          currentSelection={this.getCurrentSelection()}
        >
          <RightDropdown
            clientPersist={this.props.clientPersist}
            cookies={this.props.cookies}
            navigateAddModal={this.props.actions.navigateAddModal}
            navigateRemoveModal={this.props.actions.navigateRemoveModal}
            updateClientPersist={this.props.actions.updateClientPersist}
            logout={this.props.actions.logout}
            toggleSelectedModule={this.props.actions.toggleSelectedModule}
            saveSetting={this.props.actions.saveSetting}
            currentSelection={this.getCurrentSelection()}
            system={this.props.system}
          />
        </CollapseMenu>
      </Navbar>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    clientPersist: state.clientPersist,
    filtersMenu: filtersMenuSelector(state),
    routerLocation: state.router.location,
    singleInstanceVisible: !!state.moduleSelection.singleInstance,
    system: state.system,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): IActionProps => {
  return {
    actions: {
      logout: async (cookies) => {
        return dispatch(logout(cookies));
      },
      updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) => {
        dispatch(updateClientPersist(clientPersist));
      },
      navigateAddModal: (modal: ModalInterface) => {
        dispatch(navigateAddModal(modal));
      },
      navigateRemoveModal: (modalName: ModalComponentNames) => {
        dispatch(navigateRemoveModal(modalName));
      },
      toggleSelectedModule: (module) => dispatch(toggleSelectedModule(module)),
      saveSetting: (name, value) => dispatch(saveSetting(name, value))
    }
  };
};

export const Header = withCookies(
  withRouter(connect<
  IStateProps, IActionProps, ReactCookieProps & RouteComponentProps, StateInterface
  >(mapStateToProps, mapDispatchToProps)(HeaderContainerClass))
);
