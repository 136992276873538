import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import './custom.scss';
import { createBrowserHistory, History } from 'history';
import { Provider, TypedUseSelectorHook } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { Persistor } from 'redux-persist/es/types';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { StateInterface } from 'Interfaces/StateInterface';
import { SIApp } from './views/SingleInstance';
import { Routes } from './routes';
import { configureStores } from './store';

const App = (store: ToolkitStore, persistor: Persistor, history: History) => ({
  render() {
    ReactDOM.render(
      (
        <CookiesProvider>
          <BrowserRouter>
            <Provider store={store}>
              {/* <Router history={history}>*/}
              {/* <Routes/>*/}
              {/* </Router>*/}
              <ConnectedRouter history={history}>
                <PersistGate loading={null} persistor={persistor}>
                  <Routes />
                </PersistGate>
              </ConnectedRouter>
            </Provider>
          </BrowserRouter>
        </CookiesProvider>
      ),
      document.getElementById('app')
    );
  }
});

const initialize = () => {
  // prepare store
  const history = createBrowserHistory();
  const storeValues = configureStores(history);
  window['ReactApp'] = App(storeValues.store, storeValues.persistedStore, history);
  window['SIApp'] = SIApp(storeValues.store);
  const app = window['ReactApp'];
  app.render();
  return storeValues;
};


const { persistedStore: ps, store } = initialize();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<StateInterface> = useSelector;
export const persistedStore =  ps;
