import FormUtils from './FormUtils';
const formUtils: { [key: string]: FormUtils } = {};

export const getFormUtils = (form) => {
  if (formUtils[form.ref]) {
    return formUtils[form.ref];
  }
  const fu = new FormUtils(form);
  formUtils[form.ref] = fu;
  return fu;
};
