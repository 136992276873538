import './Style.scss';
import * as React from 'react';
import { Button, CloseButton, ListGroup } from 'react-bootstrap';
import { LocationHierarchyInterface } from 'Interfaces/HierarchyInterface';
import { connect } from 'react-redux';
import { StateInterface } from 'Interfaces/StateInterface';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { getLocalization } from '../../../../global/global';
import GenericModal from '../../../Modals/GenericModal';
import { archiveHierarchy, updateGroupLocationHierarchy } from '../../../../actions/hierarchyActions';
import { ThunkDispatchAction } from '../../../../actions';

interface Props {
  locationHierarchy: LocationHierarchyInterface;
  onSelect: (xml: string) => void;
}

interface ActionProps {
  archiveHierarchy: (id: number, action: 'true' | 'false') => void;
  updateGroupLocationHierarchy: (id: number) => void;
}

interface StateProps {
  clientPersist: ClientPersistInterface;
}

export const ListItem = (props: Props & ActionProps & StateProps) => {
  const { locationHierarchy, clientPersist } = props;
  const [confirmArchive, setConfirmArchive] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [showChangeConfirm, setShowChangeConfirm] = React.useState(false);

  return (
    <ListGroup.Item key={locationHierarchy.id} as="li" className="d-flex justify-content-between">
      {confirmArchive && (
        <GenericModal
          visible={confirmArchive}
          title={getLocalization('confirm')}
          body={(
            <p>
              {`${getLocalization(locationHierarchy.active ?
                'confirmLHArchive' : 'lhConfirmActivate')} ${locationHierarchy.name}`}
            </p>
          )}
          onConfirm={() => {
            setConfirmArchive(false);
            props.archiveHierarchy(locationHierarchy.id, locationHierarchy.active ? 'true' : 'false');
          }}
          confirmText={getLocalization('yes')}
          cancelText={getLocalization('no')}
          cancel={() => setConfirmArchive(false)}
        />
      )}
      <GenericModal
        visible={showAlert}
        title={getLocalization('alert')}
        body={(
          <>
            <p>{`${getLocalization('lhUsed').replace('{{name}}', locationHierarchy.name)}`}</p>
            <p><strong>{locationHierarchy.groupNames?.join(',')}</strong></p>
          </>
        )}
        onConfirm={() => {
          setShowAlert(false);
        }}
        confirmText={getLocalization('ok')}
      />
      <GenericModal
        visible={showChangeConfirm}
        title={getLocalization('confirm')}
        body={(
          <>
            <p>{`${getLocalization('confirmUpdateGroupLocationHierarchy')
              .replace('{{name}}', locationHierarchy.name)}`}</p>
          </>
        )}
        onConfirm={() => {
          setShowChangeConfirm(false);
          props.updateGroupLocationHierarchy(locationHierarchy.id);
        }}
        confirmText={getLocalization('yes')}
        cancelText={getLocalization('no')}
        cancel={() => setShowChangeConfirm(false)}
      />
      <div>
        <input
          type="radio"
          checked={clientPersist.locationHierarchyId === locationHierarchy.id}
          onChange={(e) => {
            if (e.target.checked) {
              setShowChangeConfirm(true);
            }
          }}
        />
        <label className={'lh-label'} onClick={() => props.onSelect(locationHierarchy.xml || '')}>
          {locationHierarchy.name}
        </label>
      </div>
      {locationHierarchy.active ? (
        <CloseButton
          onClick={() => {
            if (locationHierarchy.groupNames && locationHierarchy.groupNames.length > 0) {
              setShowAlert(true);
            } else {
              setConfirmArchive(true);
            }
          }}
        />
      ) : (
        <Button
          onClick={() => setConfirmArchive(true)}
          size={'sm'}
          variant={'outline-primary'}
        >
          <i className={'fa fa-undo'} />
        </Button>
      ) }
    </ListGroup.Item>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    clientPersist: state.clientPersist
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchAction): ActionProps => {
  return {
    archiveHierarchy: (id, action) => dispatch(archiveHierarchy(id, action)),
    updateGroupLocationHierarchy: (id) => dispatch(updateGroupLocationHierarchy(id))
  };
};

export default  (
  connect(mapStateToProps, mapDispatchToProps)(ListItem));
