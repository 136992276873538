import * as React from 'react';
import bind from 'bind-decorator';
import { Form } from 'react-bootstrap';
import { getLocalization, globalWindow } from '../../global/global';
import GenericModal from '../Modals/GenericModal';
import { DataPoint } from '../../Interfaces/DataPoint';

interface Props {
  dataPoint?: DataPoint;
  url: string | undefined;
  onClose: (close: boolean) => void;
}

interface State {
  showModal: boolean;
  smallMarkers: boolean;
}

export default class MapPrintComponent extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      smallMarkers: false
    };
  }

  @bind
  private onPrint() {
    this.props.onClose(false);
    const url = this.props.url;
    globalWindow.open(`${url}&smallMarkers=${this.state.smallMarkers}`, '_blank');
  }

  @bind
  private onCancel() {
    this.props.onClose(false);
    this.setState({ showModal: false });
  }

  @bind
  private getModalContent() {
    return (
      <Form.Group>
        <Form.Check
          type="checkbox"
          label={getLocalization('smallMarkers')}
          id="map-print-smallMarkers"
          checked={this.state.smallMarkers}
          onChange={(e) => this.setState({ smallMarkers: e.target.checked })}
        />
      </Form.Group>
    );
  }

  public componentWillUnmount() {
    this.setState({ showModal: false });
  }

  public render(): JSX.Element {
    return (
      <GenericModal
        visible={this.state.showModal}
        cancelText={getLocalization('cancel')}
        body={this.getModalContent()}
        onConfirm={this.onPrint}
        title={getLocalization('mapPrintTitle')}
        confirmText={getLocalization('print')}
        cancel={this.onCancel}
      />
    );
  }
}
