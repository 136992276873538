import { getLocalization } from 'global/global';
import * as React from 'react';
import { ConfirmationModal } from 'views/Modals/ConfirmationModal';

interface Props {
  removeRow: (index: number) => void;
  fieldsAsRows: boolean;
  index: number;
}

export default function RemoveRowBtn(props: Props) {

  const [showConfirm, setShowConfirm] = React.useState(false);

  const confirmModal = showConfirm ? (
    <ConfirmationModal
      onConfirm={() => {
        setShowConfirm(false);
        props.removeRow(props.index);
      }}
      onClose={() => setShowConfirm(false)}
      localizations={
        {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('remove'),
          confirmStyle: 'danger',
          header: (
            <label>{getLocalization('confirm')}</label>
          ),
          body: (
            <div>{getLocalization(props.fieldsAsRows ? 'confirmRemoveColumn' : 'confirmRemoveRow')}</div>
          )
        }
      }
    />
  ) : null;

  return (
    <>
      {confirmModal}
      <button
        className="btn btn-danger btn-sm"
        onClick={() => setShowConfirm(true)}
      >
        {getLocalization('remove')}
      </button>
    </>
  );
}
