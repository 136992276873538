import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { DataPoint } from '../../Interfaces/DataPoint';
import { SingleInstance } from '../../Interfaces/ModuleSelectionInterface';
import { setSingleInstance } from '../../actions/moduleSelectionActions';

interface Props {
  row: DataPoint;
  cell: string;
}

interface StateProps {
  forms: FormInterface[];
}

interface ActionProps {
  setSingleInstance: (singleInstance: SingleInstance) => void;
}

export type TaskParentCellRendererProps = Props & StateProps & ActionProps;

/**
 * Renders the name cell.
 * Because for tasks we need to check the form is it should open the parent poi when clicked,
 * we have this component which receives the forms from the global state instead of passing the Forms
 * directly from the table (not possible unless the code is so coupled together which is not good also)
 */
const TaskParentCellRenderer = (props: TaskParentCellRendererProps) => {
  const { row, cell, forms } = props;
  const value = cell ? cell : 'Name not provided';
  const form = forms && forms.find(f => f.ref === row.parentId);
  let onClick;
  if (form) {
    const dp = {
      row_id: row.parentRowId,
      questionnaire_id: row.parentId,
      formId: form.id
    };
    const singleInstance: SingleInstance = {
      model: form,
      dataPoint: dp,
      newAnswer: false
    };
    onClick = () => props.setSingleInstance(singleInstance);
  }
  return (<a onClick={onClick} href={'#'}>{value}</a>);
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    forms: state.forms.collection
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    setSingleInstance: (singleInstance) => dispatch(setSingleInstance(singleInstance))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskParentCellRenderer);
