import * as React from 'react';
import { connect } from 'react-redux';
import { ProgressInterface } from '../../Interfaces/ProgressInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import ProgressComponent from './ProgressComponent';

export class ProgressContainerClass extends React.Component <ProgressInterface> {
  public render() {
    // These changes had to be made, so that App component
    // can be tested. Please move to ReactModals
    return this.props.visible ?
      <ProgressComponent {...this.props}/>
      : <div/>;
  }
}

const mapStateToProps = (state: StateInterface): ProgressInterface => {
  return {
    visible: state.progress.visible,
    message: state.progress.message
  };
};

export default connect(mapStateToProps)(ProgressContainerClass);
