import axios from 'axios';
import { toast } from 'react-toastify';
import { FormInterface } from '../Interfaces/Forms/FormsInterface';
import { ScheduleInterface, SchedulesInterface } from '../Interfaces/ScheduleInterface';
import { getRequestConfig } from './index';

export const loadSchedules = async (form: FormInterface): Promise<SchedulesInterface> => {
  try {
    const toastId = toast.info(`Loading schedule items for ${form.name}`);
    const url = `/json/schedules/forms/${form.ref}`;
    const response = await axios.get<SchedulesInterface>(url, getRequestConfig());
    setTimeout(() => toast.dismiss(toastId), 2000);
    return response.data.map((schedule) => {
      schedule.date = new Date(schedule.date).getTime();
      return schedule;
    });
  } catch (err) {
    toast.error('An error occurred while loading schedules. Please Contact Support.');
    throw err;
  }
};

export const saveSchedule = async (
  schedule: Omit<ScheduleInterface, 'id'>,
  copy?: boolean,
): Promise<ScheduleInterface> => {
  const toastId = toast.info('Saving schedule item');
  try {
    const response = await axios.post<ScheduleInterface>(
      `/json/schedules${copy ? `?copy=${copy}` : ''}`,
      schedule,
      getRequestConfig(),
    );
    setTimeout(() => toast.dismiss(toastId), 2000);
    const savedSchedule = response.data;
    savedSchedule.date = new Date(savedSchedule.date).getTime();
    return response.data;
  } catch (err) {
    toast.error('An error occurred while loading schedules. Please Contact Support.');
    throw err;
  }
};

export const deleteSchedule = async (id: ScheduleInterface['id']) => {
  const toastId = toast.warn('Deleting schedule item');
  const url = `/json/schedules/delete${id}`;
  try {
    await axios.post(url, getRequestConfig());
    setTimeout(() => toast.dismiss(toastId), 2000);
    return true;
  } catch (err) {
    toast.error('An error occurred while loading schedules. Please Contact Support.');
    throw err;
  }
};

export const doLoadSchedule = (formId: string, userId: string) => {
  const headers = getRequestConfig();
  return fetch(`/json/schedules/forms/${formId}/users/${userId}`, headers);
};

export const updateSchedule = (schedule: ScheduleInterface) => {
  const headers = getRequestConfig();
  headers['body'] = JSON.stringify(schedule);
  headers['method'] = 'POST';
  return fetch(`/json/schedules/${schedule.id}`, headers);
};
