import * as React from 'react';
import { StateInterface } from 'Interfaces/StateInterface';
import { Users } from 'Interfaces/User';
import { connect } from 'react-redux';

import { filtersSelectedUsers } from 'Interfaces/FiltersMenuInterface';
import { selectUnSelectUsers } from '../../../actions/filtersMenuActions';
import UsersComponent from './UsersComponent';

interface StateProps {
  users: Users;
  selectedUsers: filtersSelectedUsers[];
  readOwn: boolean;
  accessSharedDataOnly: boolean;
}

interface ActionProps {
  selectUnSelectUsers: (users: filtersSelectedUsers[]) => void;
}
export type UserProps = StateProps & ActionProps;

const UsersContainer = (props: UserProps) => {
  return (props.readOwn || props.accessSharedDataOnly)  ? null : (
    <UsersComponent {...props} />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    users: state.users,
    selectedUsers: state.filtersMenu.selectedUsers,
    readOwn: state.clientPersist.readOwn || false,
    accessSharedDataOnly: state.clientPersist.accessSharedDataOnly || false
  };
};

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    selectUnSelectUsers: (users) => dispatch(selectUnSelectUsers(users))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
