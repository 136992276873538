import './Style.scss';
import * as React from 'react';

interface Props {
  label?:string;
  value?: string;
  checked?: boolean;
  onChange?: (e) => void;
}

export default function PMCheckbox(props: Props) {

  return (
    <label
      className="pm-checkbox"
    >
      {props.label}
      <input
        type="checkbox"
        checked={props.checked}
        value={props.value}
        onChange={props.onChange}
      />
      <span className="checkmark" />
    </label>
  );
}
