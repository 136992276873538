import { ThunkDispatchAction } from 'actions';
import { StateInterface } from 'Interfaces/StateInterface';
import { getRequestConfig } from './index';
import { request } from './request';

export const doGetFileUrl = (fileId: string, dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
  const fileHeaders = {};
  fileHeaders['Content-Type'] = 'text';
  const headers = getRequestConfig(fileHeaders);
  delete headers['Accept'];
  return request('/json/file/' + fileId, headers, dispatch, getState);
};

export const doRotateImage = (
  angle: string,
  fileId: number,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const fileHeaders = {};
  const headers = getRequestConfig(fileHeaders);
  return request(
    `/json/app/rotate/${angle}/${fileId}?version=2`,
    Object.assign({}, headers, { method: 'POST' }),
    dispatch,
    getState,
  );
};
