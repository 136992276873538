import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { addNewValidationListItem, removeValidationListItem } from 'actions/formsActions';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { TranscribingInterface } from 'Interfaces/TranscribingInterface';
import StringQuestion from '../elements/StringQuestion';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { geocodeAddress } from '../../../actions/reverseGeoCode';
import FormUtils from '../utils/FormUtils';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';

export interface Props {
  question: LooseObject;
  dataPoint: DataPoint;
  edit: boolean;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isSubQuestion?: boolean;
  type?: string;
  clientPersist: ClientPersistInterface;
}

interface ActionProps {
  geocodeAddress: (address: string, signal: AbortSignal) => Promise<Response>;
  addNewValidationListItem: (formId: string, questionId: string, value: string) => Promise<boolean>;
  removeValidationListItem: (formId: string, questionId: string, value: string) => Promise<boolean>;
}

interface StateProps {
  transcribing: TranscribingInterface;
}

export type StringQuestionProps = Props & ActionProps & StateProps;

const StringQuestionContainer = (props: StringQuestionProps) => {
  return (
    <StringQuestion {...props} />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    transcribing: state.transcribing,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    geocodeAddress: (address: string, signal: AbortSignal) => {
      return dispatch(geocodeAddress(address, signal));
    },
    addNewValidationListItem: (formId: string, questionId: string, value: string): Promise<boolean> => {
      return dispatch(addNewValidationListItem(formId, questionId, value));
    },
    removeValidationListItem: (formId: string, questionId: string, value: string): Promise<boolean> => {
      return dispatch(removeValidationListItem(formId, questionId, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StringQuestionContainer);
