// Also mention the name in the enum, it helps in ide search

export enum ModalComponentNames {
  EmailNotificationModal = 'EmailNotificationModal',
  ChangePasswordModal = 'ChangePasswordModal',
  UpgradeAccountModal = 'UpgradeAccountModal',
  ShowVideoModal = 'ShowVideoModal',
  ConfirmationModal = 'ConfirmationModal',
  ScheduleDayInfoModal = 'ScheduleDayInfoModal',
  ScheduleUserSelectionModal = 'ScheduleUserSelectionModal',
  ScheduleConfirmCopyModal = 'ScheduleConfirmCopyModal',
  NewDashboardModal = 'NewDashboardModal',
  AddChartToDashboardModal = 'AddChartToDashboardModal',
  ImportUsersModal = 'ImportUsersModal',
  Setup2FAModal = 'Setup2FAModal',
  AccountSettingsModal = 'AccountSettingsModal',
  AppModal = 'AppModal',
}

export interface BaseModalInterface {
  onClose: (param?) => void;
  [key: string]: any;
}

export interface ModalInterface<T = BaseModalInterface> {
  component: ModalComponentNames;
  props: T;
}
