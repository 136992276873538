import './Style.scss';
import { getLocalization } from 'global/global';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { ILocationTreeNode } from '../../Workspace';
import { MapComponent } from '../../MapComponent';
import { PostalCodes } from './PostalCodes';

interface Props {
  node: ILocationTreeNode;
  updateNode: (attr: ILocationTreeNode) => void;
}

export const Location = (props: Props) => {
  const { node } = props;

  const updateNode = (attr) => {
    const newNode = {...props.node, ...attr};
    props.updateNode(newNode);
  };

  const updateData = (name: string, value: string) => {
    const newNode = {...props.node};
    newNode.data = newNode.data || {};
    newNode.data[name] = value;
    props.updateNode(newNode);
  };

  return (
    <>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>{getLocalization('locationName')}</Form.Label>
        <Form.Control
          type="text"
          size="sm"
          value={node.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateNode({ value: e.target.value, title: e.target.value })}
        />
      </Form.Group>
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          label={getLocalization('hideLocation')}
          checked={node.data ? `${node.data.hidden}` === 'true' : false}
          onChange={(e) => updateData('hidden', e.target.checked)}
        />
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>{getLocalization('countries')}</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          size="sm"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => updateData('countries', e.target.value)}
        />
        <Form.Text className="text-muted">
          {getLocalization('countriesInfo')}
        </Form.Text>
      </Form.Group>
      <PostalCodes
        node={node}
        updateNode={props.updateNode}
      />
      <MapComponent node={node} updateNode={props.updateNode}/>
    </>
  );
};
