import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint, FileInterface } from '../../../Interfaces/DataPoint';
import { isNullOrUndefined, validEmail, validPhoneNumber } from '../../../utils/utils';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { QuestionInterface, questionTypes } from '../../../Interfaces/Forms/QuestionInterface';
import { canEdit, canView } from './RolesChecker';
import { canHaveSubQuestions, getArrayOptionTexts } from './utils';
import { isTaskQuestionVisible } from './TaskUtils';
/*
  Given a form object, this function iterates through the form checking if mandatory questions are all filled.
  If any question is not filled it would return false.
  One false encountered would break the execution and return.
*/
export const validateForm = (
  form: FormInterface,
  dataPoint: DataPoint,
  forms: LooseObject[],
  clientPersist: ClientPersistInterface,
) => {
  if (form.hasLocationHierarchy && form.locationsMandatory && !form.isChild) {
    const mandatoryLevel = Number(form.locationsMandatory.trim());
    if (!dataPoint[`location${mandatoryLevel}`] || dataPoint[`location${mandatoryLevel}`] === 'NULL') {
      return {
        valid: false,
        question: { text: `Location level ${mandatoryLevel} is required` },
        page: form.questionPage[0],
      };
    }
  }
  if (form.questionPage) {
    for (const page of form.questionPage) {
      if (page.question) {
        for (const question of page.question) {
          const isInvalid = validateQuestion(question, dataPoint, forms, clientPersist);
          if (isInvalid) {
            return { valid: false, question: isInvalid, page: page };
          }
        }
      }
    }
  }
  if (form.question) {
    for (const question of form.question) {
      const isInvalid = validateQuestion(question, dataPoint, forms, clientPersist);
      if (isInvalid) {
        return { valid: false, question: isInvalid };
      }
    }
  }
  return { valid: true };
};

export const questionIsVisible = (question, dataPoint?) => {
  if (dataPoint) {
    const visible = isTaskQuestionVisible(question, dataPoint);
    if (!visible) {
      return visible;
    }
  }
  if (!question.inVisible && !question.deleted && !question.hideInSingleInstance) {
    return true;
  }
  return false;
};

export const shouldValidateQuestion = (
  question: QuestionInterface, clientPersist: ClientPersistInterface, dataPoint: DataPoint
) => {
  // carry on, should never happen but sometimes it does.
  if (question.optional === false && question.type === questionTypes.LABEL) {
    return false;
  }
  console.log(questionIsVisible(question, dataPoint));
  if (
    questionIsVisible(question, dataPoint) &&
    canView(question, clientPersist) &&
    canEdit(question, clientPersist) &&
    (!question.optional ||
      question.type === questionTypes.PART_OF_QUESTION ||
      question.type === questionTypes.TASK_QUESTION ||
      question.type === questionTypes.TABLE_QUESTION ||
      question.fileMandatory) &&
      questionTypes.FILE_QUESTION !== question.type
  ) {
    return true;
  }
  return false;
};

/*
  This functions is used to validate a single question.
  if the question is not valid, it returns false.
  one we encounter a false, we immediately stop the validation.
*/
export const validateQuestion = (
  question: QuestionInterface,
  dataPoint: DataPoint,
  forms: LooseObject[],
  clientPersist: ClientPersistInterface,
): Pick<QuestionInterface, 'text'> | undefined => {
  const value = dataPoint[question.id];
  const shouldValidate = shouldValidateQuestion(question, clientPersist, dataPoint);

  const validate = (question: QuestionInterface) => {
    let invalidObj: Pick<QuestionInterface, 'text'> | undefined;
    /* if (question.optional) {
      return undefined;
    }*/
    switch (question.type) {
      case 'ValidationRuleQuestion':
        return validationRule(question, dataPoint[question.id]);
      case 'PicturesQuestion':
        return validateFilesQuestion(question, dataPoint['files']);
      case 'Part_of_Question':
      case 'TaskQuestion':
        invalidObj = validateSubForms(question, value, forms, clientPersist);
        if (invalidObj) {
          return {
            text: `${question.text} -> ${invalidObj.text}`,
          };
        }
        break;
      case 'TableQuestion':
        invalidObj = validateTable(question, value, forms, clientPersist);
        if (invalidObj) {
          return {
            text: `${question.text} -> ${invalidObj.text}`,
          };
        }
        break;
      default:
        return false;
    }
    return invalidObj;
  };

  if (question.fileMandatory) {
    const inv = validateFilesQuestion(question, dataPoint.files);
    if (inv) {
      return inv;
    }
  }

  if (shouldValidate) {
    const invalidObj = validate(question);
    if (invalidObj) {
      return invalidObj;
    }
    if (invalidObj === false && (value === '' || isNullOrUndefined(value)) && !question.optional) {
      return question;
    }
  }

  switch (question.type) {
    case 'IntQuestion':
    case 'FloatQuestion':
      return validateNumericQuestion(question, value);
    case 'StringQuestion':
      return validateStringQuestion(question, value);
    case questionTypes.EMAIL_QUESTION:
      return validateEmailQuestion(question, value);
    case questionTypes.PHONE_NUMBER_QUESTION:
      return validatePhoneNumber(question, value);
  }

  return validateSubQuestions(question, value, dataPoint, forms, clientPersist);
};

export const validateSubQuestions = (
  question: QuestionInterface, value, dataPoint, forms, clientPersist
) => {
  if (
    question.triggerValues &&
        question.triggerValues.triggerValue &&
        canHaveSubQuestions(question) &&
        !isNullOrUndefined(value)
  ) {
    const optionText = getArrayOptionTexts(question, value);
    for (const triggerValue of question.triggerValues.triggerValue) {
      if (optionText.indexOf(triggerValue.value) !== -1) {
        for (const q of triggerValue.action.subQuestions.question) {
          const inValid = validateQuestion(q, dataPoint, forms, clientPersist);
          if (inValid) {
            return inValid;
          }
        }
      } /* reset in data model the questions that are not visible. */
    }
  }
  return undefined;
};

export const validateNumericQuestion = (question, value) => {
  let min = -1000000000000;
  let max = 1000000000000;
  let minNotification = '';
  let maxNotification = '';
  if (!isNullOrUndefined(value) && question.triggerValues && question.triggerValues.triggerValue) {
    for (const triggerValue of question.triggerValues.triggerValue) {
      if (triggerValue.operatorType === 'LESS_THAN') {
        min = triggerValue.value;
        if (triggerValue.action && triggerValue.action.notification) {
          minNotification = triggerValue.action.notification.text;
        }
      } else if (triggerValue.operatorType === 'GREATER_THAN') {
        max = triggerValue.value;
        if (triggerValue.action && triggerValue.action.notification) {
          maxNotification = triggerValue.action.notification.text;
        }
      }
    }
  }

  if (value !== '' && (Number(value) < min || Number(value) > max)) {
    if (Number(value) < min && minNotification) {
      return {
        text: `${question.text} -> ${minNotification}`,
      };
    } else if (Number(value) > max && maxNotification) {
      return {
        text: `${question.text} -> ${maxNotification}`,
      };
    }
    return {
      text: `${question.text} -> value should be minimum of ${min} and maximum ${max}`,
    };
  }
  return undefined;
};

export const validateStringQuestion = (question: QuestionInterface, value: string) => {
  if (value && question.maxlen && value.length > question.maxlen) {
    return {
      text: `${question.text} -> should have a maximum length of ${question.maxlen}`,
    };
  }
  return undefined;
};

export const validateEmailQuestion = (question: QuestionInterface, value: string) => {
  if (value && !validEmail(value)) {
    return {
      text: `${question.text} -> email format not valid`,
    };
  }
  return undefined;
};

export const validatePhoneNumber = (question: QuestionInterface, value: string | undefined | null) => {
  if (value && !validPhoneNumber(value)) {
    return {
      text: `${question.text} -> Provided phone number is invalid`,
    };
  }
  return;
};

export const validationRule = (question: QuestionInterface, value) => {
  if (!value && value !== 'true' && questionIsVisible(question)) {
    return question;
  }
  return undefined;
};

/*
  This function goes through the table instances and checks for validation.
*/
export const validateTable = (
  question: QuestionInterface,
  value: DataPoint[],
  forms: LooseObject[],
  clientPersist: ClientPersistInterface,
): Pick<QuestionInterface, 'text'> | undefined => {
  if (!value) {
    return undefined;
  }
  const tableForm = forms.find((f) => f.ref === question.table?.columns.relation[0].ref);
  if (tableForm && value.length > 0) {
    for (const val of value) {
      const valid = validateForm(tableForm, val, [], clientPersist);
      if (!valid.valid) {
        return valid.question;
      }
    }
  }
  return undefined;
};

/*
  This function goes through the Subforms and checks for validation.
*/
export const validateSubForms = (
  question: QuestionInterface,
  value: DataPoint[],
  forms: LooseObject[],
  clientPersist: ClientPersistInterface,
): Pick<QuestionInterface, 'text'> | undefined => {
  if (!value) {
    return undefined;
  }
  const subForm = forms.find((f) => f.ref === question.listItems?.relation[0].ref);
  if (subForm && value.length > 0) {
    for (const val of value) {
      const valid = validateForm(subForm, val, forms, clientPersist);
      if (!valid.valid) {
        return valid.question;
      }
    }
  }
  return undefined;
};

export const validateFilesQuestion = (question: QuestionInterface, files?: FileInterface[]) => {
  const index =
        files &&
        files.find(
          (f) => f.questionId === question.id || (!f.questionId && question.type === questionTypes.PICTURES_QUESTION),
        );
  if (index) {
    return undefined;
  }
  return question;
};
