import './style.scss';
import { verifyTwoFACode } from 'actions/userActions';
import { getLocalization } from 'global/global';
import { useAppDispatch, useAppSelector } from 'index';
import * as React from 'react';
import { Button, Container, Form } from 'react-bootstrap';

export default function TwoFaComponent() {
  const dispatch = useAppDispatch();
  const clientPersist = useAppSelector(state => state.clientPersist);
  const [code, setCode] = React.useState('');
  const [validate, setValidate] = React.useState(false);

  return (
    <Container className="two-fa-component">
      <Form.Group>
        <Form.Label>
          {getLocalization(
            clientPersist.twoFactorAuthEnabled === 'AUTHENTICATOR' ? 'enterAppAuthenticatorCode' : 'twoFaCode'
          )}
        </Form.Label>
        <Form.Control
          type="text"
          name="two-fa-code"
          value={code}
          isInvalid={!code && validate}
          onChange={(e) => setCode(e.target.value)}
        />
      </Form.Group>
      <Button
        size="sm"
        onClick={() => {
          if (code) {
            dispatch(verifyTwoFACode(code));
          } else {
            setValidate(true);
          }
        }}>
        {getLocalization('verify')}
      </Button>
    </Container>
  );
}
