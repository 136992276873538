import * as React from 'react';
import './filters.scss';
import 'rc-calendar/assets/index.css';
import bind from 'bind-decorator';
import { LooseObject } from '../../../Interfaces/LooseObject';
import DateRangePicker from './DateRangePicker';

interface Props {
  column: LooseObject;
  onFilter: (value) => void;
}

interface State {
  startValue: any;
  endValue: any;
  startOpen: boolean;
  endOpen: boolean;
  value: string[];
}

export default class DateFilter extends React.Component<Props, State> {
  private calendarContainer = React.createRef<HTMLDivElement>();
  constructor(props) {
    super(props);
    this.state = {
      startValue: null,
      endValue: null,
      startOpen: false,
      endOpen: false,
      value: []
    };
  }

  /*
  @bind
  private getCalendarContainer() {
    return this.calendarContainer.current;
  }*

  @bind
  private onChange() {
    const { startValue, endValue } = this.state;
  }*/

  /**
   * this function ensures click events from this component do not cause sorting.
   */
  @bind
  private onClick(e) {
    e.stopPropagation();
  }

  @bind
  private onStartOpenChange(startOpen) {
    this.setState({
      startOpen,
    });
  }

  @bind
  private onStartChange(value) {
    this.setState({
      startValue: value[0],
      value
    });
    this.props.onFilter(value.length === 2 ? value : null);
  }

  public render(): JSX.Element {
    const { startValue, endValue, value } = this.state;
    return (
      <div onClick={this.onClick}>
        <div className="date-picker">
          <DateRangePicker
            onOpenChange={this.onStartOpenChange}
            type="start"
            showValue={startValue}
            endValue={endValue}
            open={this.state.startOpen}
            value={value}
            onChange={this.onStartChange}
            hidden={false}
          />
        </div>
        <div ref={this.calendarContainer} />
      </div>
    );
  }

}
