import { GroupDiagrams } from 'Interfaces/GroupDiagram';
import { DIAGRAMS } from '../actions/actionTypes';

const INITIAL_STATE: GroupDiagrams = [];
const groupDiagrams = (state = INITIAL_STATE, action): GroupDiagrams => {
  switch (action.type) {
    case DIAGRAMS.LIST_LOADED:
      if (action.payload) {
        return Object.assign([], action.payload);
      }
      return state;
    case DIAGRAMS.DELETE:
      if (action.id) {
        return state.filter((d) => d.id !== action.id);
      }
      return state;
    default:
      return state;
  }
};

export default groupDiagrams;
