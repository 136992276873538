import * as React from 'react';
import bind from 'bind-decorator';
import { Button, Form } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { getLocalization, globalWindow } from '../../global/global';
import GenericModal from '../Modals/GenericModal';

interface Props {
  forms: FormInterface[];
  clientPersist: ClientPersistInterface;
  filtersMenu: FiltersMenuInterface;
}

interface State {
  showModal: boolean;
  form: string;
  exportType: string;
  applylocationfilters: string;
  applydatefilters: string;
  applyuserfilters: string;
  separate_page: string;
  images: string;
  separate_columns: string;
  deleteddata: string;
  includeimei: string;
  subformText: string;
  skipempty: string;
  showcodes: string;
  locationcodes: string;
  include_generated_images: string;
  use_referencenames: string;
  gps_accuracy: string;
  omit_parent_text: string;
  includeID: string;
  history: string;
  editTime: string;
  validate: boolean;
  googleAccessToken: boolean;
  showRowId: string;
  editTimeInSec: string;
}

export default class ExportMenuComponent extends React.Component<Props, State> {
  private exportTypeOptions = {};
  private exportOptions = {};
  private INITIAL_STATE = {
    showModal: false,
    form: '',
    exportType: '',
    applylocationfilters: '1',
    applydatefilters: '1',
    applyuserfilters: '1',
    separate_page: '0',
    images: '0',
    separate_columns: '0',
    deleteddata: '0',
    includeimei: '0',
    subformText: '0',
    skipempty: '0',
    showcodes: '0',
    locationcodes: '0',
    include_generated_images: '0',
    use_referencenames: '0',
    gps_accuracy: '0',
    omit_parent_text: '0',
    includeID: '0',
    history: '0',
    editTime: '0',
    validate: false,
    googleAccessToken: false,
    showRowId: '1',
    editTimeInSec: '0'
  };

  constructor(props) {
    super(props);
    this.state = this.INITIAL_STATE;
    this.setExportOptions();
  }

  @bind
  private setExportOptions() {
    this.exportOptions = {
      separatePages: (props) => (
        <Form.Group key="separatePages" controlId="separatePages">
          <Form.Check
            label={getLocalization('separatepage')}
            name="separate_page"
            checked={props.separate_page === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      includeImages: (props) => (
        <Form.Group key="includeImages" controlId="includeImages">
          <Form.Check
            label={getLocalization('includeimages')}
            name="images"
            checked={props.images === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      separateColumns: (props) => (
        <Form.Group key="separateColumns" controlId="separateColumns">
          <Form.Check
            label={getLocalization('separatecolumns')}
            name="separate_columns"
            checked={props.separate_columns === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      includeDeletedDate: (props) => (
        <Form.Group key="includeDeletedDate" controlId="includeDeletedDate">
          <Form.Check
            label={getLocalization('includedeleteddata')}
            name="deleteddata"
            checked={props.deleteddata === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      includeIMEI: (props) => (
        <Form.Group key="includeIMEI" controlId="includeIMEI">
          <Form.Check
            label={getLocalization('includeimei')}
            name="includeimei"
            checked={props.includeimei === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      subformText: (props) => (
        <Form.Group key="subformText" controlId="subformText">
          <Form.Check
            label={getLocalization('subformText')}
            name="subformText"
            checked={props.subformText === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      skipEmptyRows: (props) => (
        <Form.Group key="skipEmptyRows" controlId="skipEmptyRows">
          <Form.Check
            label={getLocalization('skipemptyrows')}
            name="skipempty"
            checked={props.skipempty === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      showRowId: (props) => (
        <Form.Group key="showRowId" controlId="showRowId">
          <Form.Check
            label={getLocalization('showRowId')}
            name="showRowId"
            checked={props.showRowId === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      showCodes: (props) => (
        <Form.Group key="showCodes" controlId="showCodes">
          <Form.Check
            label={getLocalization('showcodesforsinglemultiquestion')}
            name="showcodes"
            checked={props.showcodes === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      locationCodes: (props) => (
        <Form.Group key="locationcodes" controlId="locationcodes">
          <Form.Check
            label={getLocalization('locationcodes')}
            name="locationcodes"
            checked={props.locationcodes === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      includeGeneratedImages: (props) => (
        <Form.Group key="includeGeneratedImages" controlId="includeGeneratedImages">
          <Form.Check
            label={getLocalization('includegeneratedimages')}
            name="include_generated_images"
            checked={props.include_generated_images === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      referenceNames: (props) => (
        <Form.Group key="referenceNames" controlId="referencenames">
          <Form.Check
            label={getLocalization('referencenames')}
            name="use_referencenames"
            checked={props.use_referencenames === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      gpsAccuracy: (props) => (
        <Form.Group key="gpsAccuracy" controlId="gpsAccuracy">
          <Form.Check
            label={getLocalization('gpsaccuracy')}
            name="gps_accuracy"
            checked={props.gps_accuracy === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      ),
      omitParent: (props) => (
        <Form.Group key="omitParent" controlId="omitParent">
          <Form.Check
            label={getLocalization('omitparent')}
            name="omit_parent_text"
            checked={props.omit_parent_text === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
      )
    };

    this.exportTypeOptions = {
      xls: [
        'separatePages', 'includeImages', 'separateColumns', 'includeDeletedDate', 'includeIMEI', 'subformText',
        'showCodes', 'locationCodes', 'referenceNames', 'gpsAccuracy', 'omitParent'
      ],
      xlsx: [
        'separatePages', 'includeImages', 'separateColumns', 'includeDeletedDate', 'includeIMEI', 'subformText',
        'showCodes', 'locationCodes', 'referenceNames', 'gpsAccuracy', 'omitParent', 'showRowId'
      ],
      google_sheet: [
        'separatePages', 'includeImages', 'separateColumns', 'includeDeletedDate', 'includeIMEI',
        'subformText', 'showCodes', 'locationCodes', 'referenceNames', 'gpsAccuracy', 'omitParent'
      ],
      csv: ['showCodes', 'locationCodes', 'omitParent'],
      kml: ['includeImages'],
      spss: ['separateColumns', 'showCodes', 'locationCodes'],
      word: ['includeImages', 'skipEmptyRows', 'includeGeneratedImages']
    };
  }

  @bind
  private showExportModal(showModal) {
    const state = {...this.INITIAL_STATE, showModal : showModal };
    this.setState(state);
    const { clientPersist } = this.props;
    if (clientPersist.payingCustomer === 'yes') {
      ReactGA.event({
        category: 'module-selection',
        action: `export`,
        label: `${clientPersist.instance}`
      });
    }
  }

  @bind
  private onCheckChange(e) {
    const newState: Partial<State> = {};
    newState[e.target.name] = e.target.checked ? '1' : '0';
    if (e.target.name === 'editTime') {
      newState['editTimeInSec'] = '0';
    }
    this.setState({...this.state, ...newState});
  }

  @bind
  private onSelectChange(e) {
    const newState: Partial<State> = {};
    newState[e.target.name] = e.target.value;
    this.setState({...this.state, ...newState});
  }

  @bind
  private getModalContent() {
    const { forms } = this.props;
    const getOptions = (type) => {
      switch (type) {
        case 'excel':
          return this.exportTypeOptions['xls'].map((option) => {
            return this.exportOptions[option](this.state);
          });
        case 'xlsx':
          return this.exportTypeOptions['xlsx'].map((option) => {
            return this.exportOptions[option](this.state);
          });
        case 'google_sheet':
          return this.exportTypeOptions['google_sheet'].map((option) => {
            return this.exportOptions[option](this.state);
          });
        case 'csv':
          return this.exportTypeOptions['csv'].map((option) => {
            return this.exportOptions[option](this.state);
          });
        case 'kml':
          return this.exportTypeOptions['kml'].map((option) => {
            return this.exportOptions[option](this.state);
          });
        case 'spss':
          return this.exportTypeOptions['spss'].map((option) => {
            return this.exportOptions[option](this.state);
          });
        case 'word':
          return this.exportTypeOptions['word'].map((option) => {
            return this.exportOptions[option](this.state);
          });
        default:
          return null;
      }
    };
    const error = this.state.validate ? 'has-error' : '';
    return (
      <div className="container">
        <div className={`form-group ${this.state.form === '' && error}`}>
          <label className="form-check-label">{getLocalization('selectform')}</label>
          <select className="form-control" name="form" onChange={this.onSelectChange} value={this.state.form}>
            <option value="">{getLocalization('selone')}</option>
            {forms.map(f => {
              return (f.type === 'POI' || f.type === 'TASKFORM') && !f.isChild ? (
                <option value={f.id} key={`export-${f.id}`}>
                  {f.name}
                </option>
              ) : undefined;
            }).filter( f => f !== undefined)}
          </select>
        </div>
        <div className={`form-group ${this.state.exportType === '' && error}`}>
          <label>{getLocalization('exportas')}</label>
          <select
            className="form-control"
            name="exportType"
            onChange={this.onSelectChange}
            value={this.state.exportType}
          >
            <option value="">{getLocalization('selone')}</option>
            {/* <option value="excel">{getLocalization('xls')}</option>*/}
            <option value="xlsx">{getLocalization('xlsx')}</option>
            <option value="google_sheet">{getLocalization('googleSheets')}</option>
            <option value="csv">{getLocalization('csv')}</option>
            <option value="kml">{getLocalization('kml')}</option>
            <option value="spss">{getLocalization('spss')}</option>
            <option value="word">{getLocalization('worddocument')}</option>
          </select>
        </div>
        {
          this.state.exportType === 'google_sheet' && !this.state.googleAccessToken &&
          (
            <div className="form-group">
              { this.state.validate &&
                (
                  <div className="alert alert-danger" role="alert">
                    {
                      `Sign in and authorize Poimapper to access you google account.`
                    }
                  </div>
                )
              }
              <button onClick={this.googleSignin} className="btn btn-sm btn-primary">
                {getLocalization('signInAuthorize')}
              </button>
            </div>
          )
        }
        <Form.Group controlId="applylocationfilters">
          <Form.Check
            label={getLocalization('applycurrentlocationfilters')}
            name="applylocationfilters"
            value="1"
            checked={this.state.applylocationfilters === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
        <Form.Group controlId="applycurrentdatefilters">
          <Form.Check
            label={getLocalization('applycurrentdatefilters')}
            name="applydatefilters"
            value="1"
            checked={this.state.applydatefilters === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
        <Form.Group controlId="applycurrentuserfilters">
          <Form.Check
            label={getLocalization('applycurrentuserfilters')}
            name="applyuserfilters"
            value="1"
            checked={this.state.applyuserfilters === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
        <Form.Group controlId="includeedittime">
          <Form.Check
            label={getLocalization('includeedittime')}
            name="editTime"
            value="1"
            checked={this.state.editTime === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
        {this.state.editTime === '1' && this.state.exportType === 'xlsx' && (
          <Form.Group controlId="editTimeInSec">
            <Form.Check
              label={getLocalization('editTimeInSec')}
              name="editTimeInSec"
              value="1"
              checked={this.state.editTimeInSec === '1'}
              onChange={this.onCheckChange}
            />
          </Form.Group>
        )}
        <Form.Group controlId="includehistory">
          <Form.Check
            label={getLocalization('includehistory')}
            name="history"
            value="1"
            checked={this.state.history === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
        <Form.Group controlId="includeuniqueid">
          <Form.Check
            label={getLocalization('includeuniqueid')}
            name="includeID"
            value="1"
            checked={this.state.includeID === '1'}
            onChange={this.onCheckChange}
          />
        </Form.Group>
        {getOptions(this.state.exportType)}
      </div>
    );
  }

  @bind
  private googleSignin() {
    const scope = 'https://www.googleapis.com/auth/spreadsheets';
    const redirectUri = `${globalWindow.location.origin}/json/app/export/sheets/callback`;
    const clientId = '889223543345-g564lvsqo75nahnqsr349viskj4tpeok.apps.googleusercontent.com';
    const state = globalWindow.userID;
    const url = `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&access_type=offline&
      include_granted_scopes=true&state=${state}&redirect_uri=${redirectUri}&response_type=code
      &client_id=${clientId}`;
    window.open(url, 'google-signin', 'menubar=0&toolbar=0&location=0&height=500&width=300');
  }

  @bind
  private exportData() {
    const { form, exportType, googleAccessToken } = this.state;
    const state = this.state;
    if (form === '' || exportType === '') {
      this.setState({ validate: true });
      return;
    }
    if (exportType === 'google_sheet' && !googleAccessToken) {
      this.setState({ validate: true });
      return;
    }
    const { clientPersist, filtersMenu } = this.props;
    const params: string[] = [
      `lang=${clientPersist.lang}`, `key=${globalWindow.pwrd}`, `userid=${globalWindow.userName}`
    ];
    if (this.state.applylocationfilters === '1') {
      params.push(`locs=${filtersMenu.selectedLocations.map(l => l.key).join(',')}`);
      params.push(`levels=${filtersMenu.selectedLocations.map(l => l.level ? Number(l.level) + 1 : 0).join(',')}`);
    }
    if (this.state.applyuserfilters) {
      params.push(`users=${filtersMenu.selectedUsers.map(u => u.id).join(',')}`);
    }
    if (this.state.applydatefilters) {
      params.push(`from=${filtersMenu.selectedDates.from}`);
      params.push(`to=${filtersMenu.selectedDates.to}`);
      params.push(`dateFilterBy=${filtersMenu.selectedDates.filterBy}`);
    }
    const extraParams = `&pictures=${state.images}&pages=${state.separate_page}&editTime=${state.editTime}` +
      `&history=${state.history}&includeId=${state.includeID}&showcodes=${state.showcodes}` +
      `&separatecolumns=${state.separate_columns}&dgmimages=${state.include_generated_images}&deletedData=` +
      `${state.deleteddata}&referencenames=${state.use_referencenames}&locationcodes=${state.locationcodes}` +
      `&includeimei=${state.includeimei}&subformText=${state.subformText}&skipEmpty=${state.skipempty}` +
      `&gpsAccuracy=${state.gps_accuracy}&omitParent=${state.omit_parent_text}&showRowId=${state.showRowId}` +
      `&editTimeInSec=${state.editTimeInSec}`;

    const url = `/json/app/export/${form}/${exportType}/${clientPersist.readOwn}/` +
      `${clientPersist.groupId}/${clientPersist.user_id}?` + params.join('&') + extraParams;
    globalWindow.open(url, '_blank');
    this.setState({ validate: false, showModal: false });
  }

  public static getDerivedStateFromProps(props: Props, state: State) {
    if (props.clientPersist.googleAccessToken !== state.googleAccessToken) {
      return { googleAccessToken: props.clientPersist.googleAccessToken };
    }
    return null;
  }

  public render(): JSX.Element {
    const modal = this.state.showModal ? (
      <GenericModal
        visible={this.state.showModal}
        body={this.getModalContent()}
        onConfirm={this.exportData}
        confirmText={getLocalization('exports')}
        title={getLocalization('exportdata')}
        cancel={() => this.showExportModal(false)}
        cancelText={getLocalization('cancel')}
        dialogClassName="large"
      />
    ) : null;
    return (
      <React.Fragment>
        {modal}
        <Button
          size={'sm'}
          onClick={() => this.showExportModal(true)}
          id={'export-data-action-btn'}
        >
          <i className="fa fa-download" />
          {` ${getLocalization('exports')}`}
        </Button>
      </React.Fragment>
    );
  }
}
