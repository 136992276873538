import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';

export const isTaskQuestionVisible = (question: LooseObject, task: DataPoint): boolean => {
  if (task['taskstatus'] === 'assigned' || !task['taskstatus'] || task['taskstatus'] === 'inprogress') {
    switch (question.id) {
      case 'approveddate':
      case 'toapprove':
      case 'approvedby':
      case 'doneby':
      case 'donedate':
        return false;
      default:
        return true;
    }
  } else if (task['taskstatus'] === 'done') {
    switch (question.id) {
      case 'approveddate':
      case 'approvedby':
        return false;
      default:
        return true;
    }
  }
  return true;
};
