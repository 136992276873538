import * as React from 'react';
import { getLocalization } from 'global/global';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import GenericModal from './GenericModal';

export interface NewDashboardModalProps {
  onConfirm: (name: string) => void;
  onClose: () => void;
}

export function NewDashboardModal(props: NewDashboardModalProps) {

  const [name, setName] = React.useState('');
  const [validate, setValidate] = React.useState(false);

  const onSave = () => {
    if (name === '') {
      setValidate(true);
      return;
    }
    props.onConfirm(name);
  };

  return (
    <GenericModal
      visible={true}
      title={getLocalization('newDashboard')}
      cancel={props.onClose}
      cancelText={getLocalization('cancel')}
      confirmText={getLocalization('save')}
      onConfirm={onSave}
      body={(
        <FormGroup>
          <FormLabel>{getLocalization('dashboardName')}</FormLabel>
          <FormControl
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            size='sm'
            isInvalid={validate && name === ''}
          />
        </FormGroup>
      )}
    />
  );
}
