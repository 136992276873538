import { ThunkDispatchAction } from 'actions';
import { getLocalization } from 'global/global';
import { PowerBiUrl } from 'Interfaces/PowerBiUrl';
import { toast } from 'react-toastify';
import * as powerBiUrlApi from '../api/powerBiUrlApi';
import { POWERBI_URLS } from './actionTypes';

export const loadPowerBiUrls = (signal: AbortSignal) => {
  return (dispatch: ThunkDispatchAction) => {
    powerBiUrlApi
      .doLoadPowerBiUrls(signal)
      .then((response) => response.json())
      .then((json) => {
        dispatch(powerBiUrlsLoaded(json));
      })
      .catch((err) => console.log(err));
  };
};

export const powerBiUrlsLoaded = (powerBiUrls: PowerBiUrl[]) => ({ type: POWERBI_URLS.LOADED, powerBiUrls });

export const createPowerBiUrl = (powerBiUrl: PowerBiUrl, signal: AbortSignal) => {
  return (dispatch): Promise<Response> => {
    return new Promise((resolve, reject) => {
      powerBiUrlApi
        .doCreatePowerBiUrl(powerBiUrl, signal)
        .then((response) => response.json())
        .then((json) => {
          dispatch(newPowerBiUrl(json));
          resolve(new Response(JSON.stringify(json)));
        })
        .catch((e) => {
          reject(new Response(JSON.stringify(e)));
        });
    });
  };
};

export const newPowerBiUrl = (powerBiUrl: PowerBiUrl) => ({ type: POWERBI_URLS.NEW, powerBiUrl });

export const deleteDataUrls = (ids: number[], signal: AbortSignal) => {
  return (dispatch) => {
    const toastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('deletingDataUrls'), {
      toastId: toastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    powerBiUrlApi
      .doDeleteDataUrls(ids, signal)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'OK') {
          dispatch(dataUrlsDeleted(ids));
          toast.update(toastId, {
            type: toast.TYPE.SUCCESS,
            render: getLocalization('dataUrlsdeletedSuccessfully'),
          });
        } else if (json.errorCode) {
          toast.update(toastId, {
            type: toast.TYPE.ERROR,
            render: json.errorMessage,
          });
        }
        setTimeout(() => toast.dismiss(toastId), 3000);
      })
      .catch((e) => {
        console.error(e);
      });
  };
};

export const dataUrlsDeleted = (ids: number[]) => ({ type: POWERBI_URLS.DELETED, ids });
