import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import bind from 'bind-decorator';
import { Button, Modal } from 'react-bootstrap';
import { showAlert } from '../../actions/';
import { StateInterface } from '../../Interfaces/StateInterface';
import { AlertInterface } from '../../Interfaces/AlertInterface';
import { getLocalization } from '../../global/global';

interface IActionProps {
  dispatch: Dispatch;
  showAlert: (alertInterface: AlertInterface) => void;
}

export type AlertPropsInterface = AlertInterface & IActionProps;

interface IOwnState {
  isOpened: boolean;
}

export class AlertContainerClass extends React.Component<AlertPropsInterface, IOwnState> {
  constructor(props) {
    super(props);
    this.state = { isOpened : props.visible };
  }

  @bind
  private onClose() {
    const { showAlert } = this.props;
    showAlert({ visible : false });
  }

  public static getDerivedStateFromProps(props, state) {
    if (props.visible === true && !state.isOpened) {
      return { isOpened : props.visible };
    } else if (!props.visible) {
      return { isOpened : props.visible };
    }
    return null;
  }

  public render() {
    const messageDiv = this.props.message ? (<p>{this.props.message}</p>) : null;
    return (
      <Modal show={this.state.isOpened} backdrop="static" onHide={this.onClose}>
        <Modal.Header>
          <Modal.Title>{'info'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {messageDiv}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant={'primary'} onClick={this.onClose}>{getLocalization('close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state: StateInterface): AlertInterface => {
  return {
    visible: state.alert.visible,
    message: state.alert.message
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IActionProps => {
  return {
    showAlert:(alertInterface: AlertInterface) => {
      dispatch(showAlert(alertInterface));
    },
    dispatch: dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainerClass);
