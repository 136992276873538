import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { Users } from 'Interfaces/User';
import { ActionTransmitInterface } from 'Interfaces/ActionTransmitInterface';
import TableQuestion from '../elements/TableQuestion';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { Locations } from '../../../Interfaces/LocationInterface';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import FormUtils from '../utils/FormUtils';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { FiltersMenuInterface } from '../../../Interfaces/FiltersMenuInterface';
import { fetchPOI } from '../../../actions/pois';
import { getLocationHierarchyQuerySelector, QueryFiltersInterface } from '../../../reducers/filtersMenuReducer';
import { getSharerEmail, sendActionEmail } from '../../../actions/shareActions';

interface Props {
  dataPoint: DataPoint;
  question: LooseObject;
  tableForm: LooseObject;
  clientPersist: ClientPersistInterface;
  subForm?: LooseObject;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  forms: LooseObject[];
  parentDataPoint?: LooseObject;
  parentModel?: LooseObject;
  disabled?:boolean;
}

export interface StateProps {
  clientPersist: ClientPersistInterface;
  locationHierarchy: Locations;
  filtersMenu: FiltersMenuInterface;
  locationHierarchyQuery: ReturnType<typeof getLocationHierarchyQuerySelector>;
  users: Users;
}

export interface ActionProps {
  fetchPOI: (
    formId: string, signal: AbortSignal, rowId?: number,
    filter?: QueryFiltersInterface[], query?: LooseObject, fields?: string
  ) => Promise<Response>;
  sendActionEmail: (actionTransmit: ActionTransmitInterface, signal: AbortSignal) => Promise<Response>;
  getSharerEmail: (id: string, signal: AbortSignal) => Promise<Response>;
}

export type TableQuestionPropsInterface = Props & StateProps & ActionProps;

const TableQuestionContainer = (props: TableQuestionPropsInterface) => {
  return (
    <TableQuestion {...props} />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    clientPersist: state.clientPersist, // used to get filters for POIs
    locationHierarchy: state.locations.collection,
    filtersMenu: state.filtersMenu,
    locationHierarchyQuery: getLocationHierarchyQuerySelector(state),
    users: state.users
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    fetchPOI: (
      formId: string, signal: AbortSignal, rowId?: number,
      filter?, query?: LooseObject, fields?: string
    ): Promise<Response> => {
      return dispatch(fetchPOI(formId, signal, rowId, filter, query, fields));
    },
    sendActionEmail: (actionTransmit: ActionTransmitInterface, signal: AbortSignal): Promise<Response> => {
      return dispatch(sendActionEmail(actionTransmit, signal));
    },
    getSharerEmail: (id: string, signal: AbortSignal): Promise<Response> => {
      return dispatch(getSharerEmail(id, signal));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableQuestionContainer);
