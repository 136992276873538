import { ScheduleInterface } from 'Interfaces/ScheduleInterface';
import * as React from 'react';
import { CloseButton, Form } from 'react-bootstrap';

interface Props {
  scheduleItem: ScheduleInterface;
  handleChange: (scheduleItem: ScheduleInterface) => void;
  onCancel: () => void;
}

export const StatusEditor = (props: Props) => {

  return (
    <>
      <Form.Group className="edit-form-group">
        <Form.Control
          as="select"
          value={props.scheduleItem.status}
          onChange={(e) => props.handleChange({...props.scheduleItem, status: e.target.value})}
        >
          <option value="Scheduled">Scheduled</option>
          <option value="Ongoing">Ongoing</option>
          <option value="Done">Done</option>
        </Form.Control>
      </Form.Group>
      <CloseButton onClick={props.onCancel}/>
    </>
  );
};
