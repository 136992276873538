import { MyScheduleInterface, ScheduleInterface } from 'Interfaces/ScheduleInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { SCHEDULE_ACTIONS, USER } from '../actions/actionTypes';

const INITIAL_STATE: MyScheduleInterface = {
  schedules: [],
  data: {},
};

const mergeData = (old: DataPoint[] | ScheduleInterface[], newData: DataPoint[] | ScheduleInterface[]) => {
  if (old.length === 0) {
    return newData;
  }
  const result = [...old];
  for (let x = 0; x < result.length; x++) {
    let i = newData.length - 1;
    while (i >= 0) {
      const d = newData[i];
      if (d?.id === old[x].id) {
        result[x] = d;
        newData.splice(i, 1);
        continue;
      }
      i--;
    }
  }
  return result.concat(newData);
};

const mySchedule = (state = INITIAL_STATE, action): MyScheduleInterface => {
  switch (action.type) {
    case USER.LOGIN_SUCCESS:
      return { ...INITIAL_STATE };
    case SCHEDULE_ACTIONS.MY_SCHEDULE_LOADED:
      return { ...state, schedules: mergeData(state.schedules, action.scheduleItems) };
    case SCHEDULE_ACTIONS.MY_SCHEDULE_DATA_LOADED: {
      const newData = mergeData(state.data[action.formId] || [], action.data);
      return {
        ...state,
        data: {
          ...state.data,
          [action.formId]: newData,
        },
      };
    }
    default:
      return state;
  }
};

export default mySchedule;
