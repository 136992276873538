import { JSONInterface } from 'Interfaces/JsonInterface';
import { AnyAction } from 'redux';
import { PAYMENTS } from '../actions/actionTypes';

const INITIAL_STATE: JSONInterface[] = [];

const transactions = (state = INITIAL_STATE, action: AnyAction): JSONInterface[] => {
  switch (action.type) {
    case PAYMENTS.LOADED_TRANSACTIONS:
      if (action.transactions) {
        return action.transactions;
      }
      return state;
    default:
      return state;
  }
};

export default transactions;
