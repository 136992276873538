import { ThunkDispatchAction } from 'actions';
import { StateInterface } from 'Interfaces/StateInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { LooseObject } from '../Interfaces/LooseObject';
import { request } from './request';
import { getRequestConfig } from './index';

export const doSavePOI = (url: string, config: LooseObject, dispatch, getState) => {
  const headers = getRequestConfig();
  return request(url, Object.assign({}, headers, config), dispatch, getState);
};

export const doSaveFiles = (config: LooseObject, dispatch, getState) => {
  const headers = getRequestConfig();
  return request('/json/file?version=2', Object.assign({}, headers, config), dispatch, getState);
};

export const doUploadFile = (
  url,
  config,
  file,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  fd?: JSONInterface,
) => {
  const headers = getRequestConfig();
  delete headers.headers['Content-Type']; // 'multipart/form-data';
  const formData = new FormData();
  formData.set('file', file);
  if (fd) {
    const keys = Object.keys(fd);
    for (const key of keys) {
      formData.append(key, fd[key]);
    }
  }
  const newConfig = Object.assign({}, headers, config);
  newConfig.body = formData;
  return request(url, newConfig, dispatch, getState);
};

export const uploadToS3 = (file: any, url: string) => {
  const conf: LooseObject = {};
  conf.body = file;
  conf.method = 'PUT';
  return fetch(url, conf);
};

export const getPOI = (
  url,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  signal?,
): Promise<Response> => {
  const headers = getRequestConfig();
  return request(url, { ...headers, signal: signal }, dispatch, getState);
};

export const doDeletePOI = (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  qnIdentifier: string,
  id: string,
) => {
  const headers = getRequestConfig();
  headers['method'] = 'POST';
  return request(`/json/app/delete/${id}/${qnIdentifier}`, headers, dispatch, getState);
};

export const doSharePOI = (id: string, dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
  const headers = getRequestConfig();
  return request(`/json/app/share/${id}`, headers, dispatch, getState);
};

export const doSaveComment = (
  id: string,
  userName: string,
  comment: LooseObject,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  headers['body'] = JSON.stringify(comment);
  headers['method'] = 'POST';
  return request(`/json/app/data/addComment/${id}/${userName}`, headers, dispatch, getState);
};

export const doDeleteComment = (id: string, dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
  const headers = getRequestConfig();
  return request(`/json/app/data/deleteComment/${id}`, headers, dispatch, getState);
};

export const doDeletePOIs = (
  rowIds: string[],
  qnIdentifier: string,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  headers['method'] = 'POST';
  headers['body'] = JSON.stringify({ arguments: rowIds });
  return request(`/json/app/delete/multiple/${qnIdentifier}`, headers, dispatch, getState);
};

export const doFetchHistory = (
  answerTable: string,
  formId: string,
  poiId: string,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  return request(`/json/app/answer/history/${answerTable}/${formId}/${poiId}`, headers, dispatch, getState);
};

export const doSendReminders = (pois, dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
  const headers = getRequestConfig();
  headers['method'] = 'POST';
  headers['body'] = JSON.stringify(pois);
  return request('/json/app/reminders/send', headers, dispatch, getState);
};

export const doFetchMapData = (
  params: string[],
  signal: AbortSignal,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  return request(
    `/json/app/map/answer?version=6&from=portal&${params.join('&')}`,
    { ...headers, signal: signal },
    dispatch,
    getState,
  );
};

export const doCloseSingleInstance = (id: string) => {
  const headers = getRequestConfig();
  headers['method'] = 'GET';
  return fetch(`/json/app/answer/singleInstance/close/${id}`, { ...headers });
};

export const sendTranscribeRequest = (
  formId: string,
  dataPointId: string,
) => {
  const headers = getRequestConfig();
  headers['method'] = 'POST';
  return fetch(`/json/app/answer/transcribe/${formId}/${dataPointId}`, headers);
};
