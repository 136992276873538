import * as React from 'react';
import bind from 'bind-decorator';

interface IExternalProps {
  checked: boolean;
  label: string;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

interface IOwnState {
  checked: boolean;
}

export default class NavCheckBox extends React.Component<IExternalProps, IOwnState> {

  constructor(props: IExternalProps & IOwnState) {
    super(props);
    this.state = {
      checked: this.props.checked
    };
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const checked = e.target.checked;
    this.setState({ checked });
    this.props.onChange(checked);
  }

  public render(): JSX.Element {
    const { label, disabled } = this.props;
    return (
      <a role="presentation" className="dropdown-item">
        <span>
          <label className="pointer">
            <input
              type="checkbox"
              onChange={(e) => this.onChange(e)}
              checked={this.state.checked}
              disabled={disabled}
            />
            {label}
          </label>
        </span>
      </a>
    );
  }
}
