import * as React from 'react';
import './Style.scss';
import { connect } from 'react-redux';
import braintree from 'braintree-web';
import moment from 'moment';
import countries from 'countries-list';

import { Button, Card, Col, Form, ListGroup, Row, Table } from 'react-bootstrap';
import { useAppDispatch } from 'index';
import { getLocalization } from 'global/global';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { Payment, PaymentPlanInfo, PaymentRegistration, PaymentType } from 'Interfaces/PaymentPlanInterface';
import { ModalComponentNames, ModalInterface } from 'Interfaces/ModalInterface';
import { ConfirmationModalProps } from 'views/Modals/ConfirmationModal';
import { navigateAddModal, navigateRemoveModal } from 'actions/navigationAction';
import {
  deletePaymentMethod,
  getClientToken, getPaymentPlanInfo, getPaymenyTransactions, payWithCard, registerCard
} from 'actions/paymentActions';
import { StateInterface } from 'Interfaces/StateInterface';
import { toast } from 'react-toastify';
import { getTransactionCost } from '../utils';
import { EUData } from './EuData';

interface Props {
  paymentPlanInfo: PaymentPlanInfo;
  subscription: JSONInterface;
  resetSubscription: () => void;
}

interface StateProps {
  card: JSONInterface;
}

interface ActionProps {
  getClientToken: () => Promise<string>;
  registerCard: (paymentRegistration: PaymentRegistration) => Promise<boolean>;
  payWithCard: (payment: Payment, paymentType: PaymentType) => Promise<boolean>;
  getPaymentPlanInfo: () => void;
  getPaymenyTransactions: () => void;
  deletePaymentMethod: (token: string) => void;
}

const DATA_INITIIAL_STATE = {
  fullNames: '',
  vatNo: '',
  cvv: '',
  cardNumber: '',
  expirationDate: '',
  postalCode: '',
  country: ''
};

function PaymentForm(props: Props & ActionProps & StateProps) {
  const dispatch = useAppDispatch();
  const [data, setData] = React.useState<JSONInterface>(DATA_INITIIAL_STATE);

  const [activeCard, setActiveCard] = React.useState<null | string>(null);
  const [card, setCard] = React.useState<null | JSONInterface>(null);
  const [isEu, setIsEu] = React.useState(false);
  const [taxAmount, setTaxAmount] = React.useState(0);
  const [validate, setValidate] = React.useState(false);
  const { subscription } = props;

  React.useEffect(() => {
    if (card !== null) {
      const keys = Object.keys(props.card);
      let found = false;
      for (const key of keys) {
        if (props.card[key].token === card.token) {
          found = true;
        }
      }
      if (!found) {
        setCard(null);
      }
    }
  }, [props.card]);

  React.useEffect(() => {
    if (activeCard) {
      const c = props.card[activeCard];
      setCard(props.card[activeCard]);
      if (EUData.rates[c.billingAddress.countryCodeAlpha2]) {
        setIsEu(true);
      } else {
        setIsEu(false);
      }
      if (subscription['totalCost'] && c.billingAddress.countryCodeAlpha2 === 'FI') {
        const tax = Number(subscription['totalCost'] * 0.24).toFixed(2);
        setTaxAmount(Number(tax));
      } else {
        setTaxAmount(0);
      }
      setValidate(false);
    } else {
      setCard(null);
      setData({...DATA_INITIIAL_STATE});
    }
  }, [activeCard]);

  const onDataChange = (name, value) => {
    if (name === 'durationInMonths') {
      const validTo = moment(data.validFrom).add(Number(value), 'months').format('YYYY-MM-DD');
      setData({...data, [name]: value, validTo});
    } else {
      setData({...data, [name]: value});
    }
  };

  const getCountries = React.useMemo(() => {
    return () => {
      const allCountries = countries.countries;
      const countryCodes = Object.keys(allCountries);
      return countryCodes.map((code) => {
        return (
          <option
            key={`payments-${code}`}
            value={allCountries[code].name}
            data-code={code}
          >
            {allCountries[code].name}
          </option>
        );
      });
    };
  }, []);

  const onCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange('cardNumber', e.target.value);
  };

  const onCardCVVChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange('cvv', e.target.value);
  };

  const onCardExpiryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange('expirationDate', e.target.value);
  };

  const onAddCard = async () => {
    const token = await props.getClientToken();
    console.log(token);

    if (token) {
      const toastId = Date.now() + Math.floor(Math.random() * 100);
      toast(getLocalization('processingPayment'), {
        toastId: toastId,
        type: toast.TYPE.INFO,
        autoClose: false,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: false
      });
      void braintree.client.create({ authorization: token }).then((client) => {
        const card = {
          creditCard: {
            'number': data['cardNumber'],
            cardholderName: data['fullNames'],
            cvv: data['cvv'],
            expirationDate: data['expirationDate'],
            billingAddress: {
              postalCode: data['postalCode'],
              countryName: data['country']
            },
            options: {

            }
          }
        };
        // @ts-ignore
        client.request({
          endpoint: 'payment_methods/credit_cards',
          method: 'post',
          data: card
        }).then(async (response) => {
          void dispatch(getPaymenyTransactions());
          const paymentRegistration: PaymentRegistration = {
            nonce: response.creditCards[0].nonce,
            vatNo: data['vatNo']
          };
          if (subscription['totalCost']) {
            toast.update(toastId, {
              type: toast.TYPE.INFO,
              render: getLocalization('processingPayment')
            });
            paymentRegistration.plan = {
              max_users: subscription['Per User'] ? subscription['prepNumberOfUsers'] : 0,
              no_of_office_users: subscription['Per User'] ? 0 : subscription[''],
              license_valid_from: subscription['validFrom'],
              license_valid_until: subscription['validTo'],
              max_transactions: subscription['pricingMethod'] === 'Per User' ? 0 : subscription['transactions'],
              pricing_method: subscription['pricingMethod'],
              autoRenew: subscription['autoRenew'],
            };
            paymentRegistration.package = {
              package: subscription['package'],
              action: '',
              one_time_payment: subscription['oneTimePayment'],
              ...paymentRegistration.plan
            };
            paymentRegistration.payment = {
              amount: subscription['totalCost'],
              taxAmount
            };
          }
          const registered = await props.registerCard(paymentRegistration);
          if (registered) {
            toast.update(toastId, {
              type: toast.TYPE.SUCCESS,
              render:  getLocalization(subscription['totalCost'] ? 'paymentSuccessful' : 'newCardAdded')
            });
            setData({...DATA_INITIIAL_STATE});
            if (subscription['totalCost']) {
              props.getPaymentPlanInfo();
              props.getPaymenyTransactions();
              props.resetSubscription();
            }
          } else {
            toast.update(toastId, {
              type: toast.TYPE.ERROR,
              render: getLocalization(subscription['totalCost'] ? 'errorProcessingPayment' : 'errorAddingCard')
            });
          }
          setTimeout(() => toast.dismiss(toastId), 3000);
        }).catch(e => {
          if (e.details && e.details.originalError && e.details.originalError.error) {
            toast.update(toastId, {
              type: toast.TYPE.ERROR,
              render: e.details.originalError.error.message
            });
          } else {
            toast.update(toastId, {
              type: toast.TYPE.ERROR,
              render: getLocalization('errorAddingCard')
            });
          }
          setTimeout(() => toast.dismiss(toastId), 3000);
        });
      });
    }
  };

  const updateCard = () => {
    console.log('update');
  };

  const onPayment = async () => {
    if (card) {
      const payment: Payment = {
        token: card['token'],
      };
      let paymentType: PaymentType = 'NEW';
      // A new subscription
      if (subscription['validFrom']) {
        paymentType = 'NEW';
        payment.plan = {
          max_users: subscription['pricingMethod'] === 'Per User' ? subscription['prepNumberOfUsers'] : 0,
          no_of_office_users: subscription['pricingMethod'] === 'Per User' ? 0 : subscription['prepNumberOfUsers'],
          license_valid_from: subscription['validFrom'],
          license_valid_until: subscription['validTo'],
          max_transactions: subscription['pricingMethod'] === 'Per User' ? 0 : subscription['transactions'],
          pricing_method: subscription['pricingMethod']
        };
        payment.package = {
          package: subscription['package'],
          action: '',
          one_time_payment: subscription['oneTimePayment'],
          ...payment.plan
        };
      } else if (subscription['transactions']) {
        paymentType = 'MORE_TRANSACTIONS';
        payment.plan = {
          max_transactions: subscription['transactions'],
        };
      } else if (subscription['validTo']) {
        paymentType = 'RENEW';
        payment.plan = {
          max_users: subscription['prepNumberOfUsers'],
          license_valid_until: subscription['validTo'],
        };
      }
      payment.payment = {
        amount: subscription['totalCost'],
        taxAmount
      };
      const saveToastId = Date.now() + Math.floor(Math.random() * 100);
      toast(getLocalization('processingPayment'), {
        toastId: saveToastId, type: toast.TYPE.INFO, autoClose: false, closeButton: false, hideProgressBar: true,
        closeOnClick: false
      });
      const paid = await props.payWithCard(payment, paymentType);
      if (paid) {
        toast.update(saveToastId, {
          type: toast.TYPE.SUCCESS,
          render: getLocalization('paymentSuccessful')
        });
        props.getPaymentPlanInfo();
        props.getPaymenyTransactions();
        props.resetSubscription();
        setData({...DATA_INITIIAL_STATE});
      } else {
        toast.update(saveToastId, {
          type: toast.TYPE.ERROR,
          render: getLocalization('errorProcessingPayment')
        });
      }
      setTimeout(() => toast.dismiss(saveToastId), 3000);
    }
  };

  const onCardBtnClick = () => {
    if (activeCard) {
      if (subscription['totalCost']) {
        void onPayment();
      } else {
        updateCard();
      }
    } else {
      const { cardNumber, fullNames, cvv, country, expirationDate, vatNo } = data;
      if (
        cardNumber.trim() === '' || fullNames.trim() === '' || cvv.trim() === ''
        || country.trim() === '' || expirationDate.trim() === '' || (vatNo.trim() === '' && isEu)
      ) {
        setValidate(true);
        return;
      }
      setValidate(false);
      void onAddCard();
    }
  };

  const getTable = () => {
    if (subscription['validFrom']) {
      return (
        <>
          <thead>
            <tr>
              <th>{getLocalization('plan')}</th>
              <th>{getLocalization('pricingMethod')}</th>
              <th>{getLocalization('planStart')}</th>
              <th>{getLocalization('planEnd')}</th>
              <th>{getLocalization('oneTimePayments')}</th>
              <th>{getLocalization('amount')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{subscription['package']}</td>
              <td>{subscription['pricingMethod']}</td>
              <td>{subscription['validFrom'] ? moment(subscription['validFrom']).format('L') : ''}</td>
              <td>{subscription['validTo'] ? moment(subscription['validTo']).format('L') : ''}</td>
              <td>{`$${subscription['oneTimePayment']}`}</td>
              <td>{`$${subscription['totalCost']}`}</td>
            </tr>
          </tbody>
        </>
      );
    } else if (subscription['transactions']) {
      return (
        <>
          <thead>
            <tr>
              <th>{getLocalization('transactionBundle')}</th>
              <th>{getLocalization('oneTimePayments')}</th>
              <th>{getLocalization('amount')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{
                `$${subscription['transactions']} ${getLocalization('transactions')} -
                  ${getTransactionCost(subscription['transactions'])}`
              }</td>
              <td>{`$${subscription['oneTimePayment']}`}</td>
              <td>{`$${subscription['totalCost']}`}</td>
            </tr>
          </tbody>
        </>
      );
    } else if (subscription['validTo']) {
      return (
        <>
          <thead>
            <tr>
              <th>{getLocalization('planEnd')}</th>
              <th>{getLocalization('additionalUsers')}</th>
              <th>{getLocalization('oneTimePayments')}</th>
              <th>{getLocalization('amount')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{subscription['validTo'] ? moment(subscription['validTo']).format('L') : ''}</td>
              <td>{subscription['prepNumberOfUsers']}</td>
              <td>{`$${subscription['oneTimePayment']}`}</td>
              <td>{`$${subscription['totalCost']}`}</td>
            </tr>
          </tbody>
        </>
      );
    } else {
      return (
        <>
          <thead>
            <tr>
              <th>{getLocalization('oneTimePayments')}</th>
              <th>{getLocalization('amount')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{`$${subscription['oneTimePayment']}`}</td>
              <td>{`$${subscription['totalCost']}`}</td>
            </tr>
          </tbody>
        </>
      );
    }
  };

  const getButtonLabel = (activeCard: string | null) => {
    return activeCard ? subscription['totalCost'] ? getLocalization('pay') : getLocalization('updateCard') :
      subscription['totalCost'] ? getLocalization('addNewCardAndPay') :
        getLocalization('addNewCard');
  };

  const getTaxEl = () => {
    return isEu ? (
      <Form.Group as={Col} md={6}>
        <Form.Label>{
          getLocalization('vatNo')
        }</Form.Label>
        <Form.Control
          size="sm"
          value={activeCard ? props.card.customer.customFields.vatno : data['vatNo']}
          disabled={card !== null}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onDataChange('vatNo', e.target.value.trim())}
          isInvalid={!data['vatNo'] && validate}
        />
        {taxAmount > 0 && subscription['totalCost'] && (
          <Form.Text id="passwordHelpBlock" muted>
            {getLocalization('taxAlert').replace('{{taxAmount}}', `${taxAmount}`)
              .replace('{{totalAmount}}', taxAmount + subscription['totalCost'])}
          </Form.Text>
        )}
      </Form.Group>
    ) : null;
  };

  const confirmDeleteCard = () => {
    const modalProps: ModalInterface<ConfirmationModalProps> = {
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => {
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
        },
        onConfirm: () => {
          if (card !== null) {
            props.deletePaymentMethod(card.token);
          }
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
        },
        localizations: {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('deleteCard'),
          confirmStyle: 'danger',
          header: (<h5>{getLocalization('confirm')}</h5>),
          body: (<p>{getLocalization('confirmDeleteCard')}</p>)
        }
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  return (
    <>
      {subscription['totalCost'] && subscription['totalCost'] > 0 ? (
        <Table striped className="payment-table">
          {getTable()}
        </Table>
      ) : null}
      <Row>
        <Col md={4}>
          <Card>
            <Card.Header>{getLocalization('creditCard')}</Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroup.Item
                  active={activeCard === null}
                  onClick={() => setActiveCard(null)}
                >
                  {getLocalization('addNewCard')}
                </ListGroup.Item>
                {Object.keys(props.card).map(key => {
                  if (key === 'customer') {
                    return null;
                  }
                  return (
                    <ListGroup.Item
                      key={`card-${key}`}
                      active={activeCard === key}
                      onClick={() => setActiveCard(key)}
                    >
                      {key}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            <Card.Header>{`${activeCard ? '' : getLocalization('add')} ${getLocalization('creditCard')}`}</Card.Header>
            <Card.Body>
              <Form className="payment-form">
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>{getLocalization('fullNames')}</Form.Label>
                    <Form.Control
                      type="text"
                      size="sm"
                      value={card ? card['cardholderName'] || '' : data['fullNames']}
                      disabled={card !== null}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onDataChange('fullNames', e.target.value)}
                      isInvalid={!data['fullNames'] && validate}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{getLocalization('postalCode')}</Form.Label>
                    <Form.Control
                      type="text"
                      size="sm"
                      value={card ? card['billingAddress']['postalCode'] : data['postalCode']}
                      disabled={card !== null}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onDataChange('postalCode', e.target.value.trim())}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>{
                      getLocalization('cardNumber')
                    }</Form.Label>
                    <Form.Control
                      size="sm"
                      value={card ? card['maskedNumber'] : data['cardNumber']}
                      pattern={'[0-9]'}
                      disabled={card !== null}
                      onChange={onCardNumberChange}
                      isInvalid={!data['cardNumber'] && validate}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>{
                      getLocalization('expirationDate')
                    }</Form.Label>
                    <Form.Control
                      type="text"
                      size="sm"
                      value={card ? card['expirationDate'] : data['expirationDate']}
                      placeholder={'MM/YY'}
                      disabled={card !== null}
                      pattern={'[0-9]{2}/[0-9]{2}'}
                      onChange={onCardExpiryChange}
                      isInvalid={!data['expirationDate'] && validate}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>{
                      getLocalization('cvv')
                    }</Form.Label>
                    <Form.Control
                      size="sm"
                      value={card ? '***' : data['cvv']}
                      disabled={card !== null}
                      pattern={'[0-9]{3}'}
                      onChange={onCardCVVChange}
                      isInvalid={!data['cvv'] && validate}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>{getLocalization('country')}</Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      disabled={card !== null}
                      isInvalid={!data['country'] && validate}
                      value={card ? card['billingAddress']['countryName'] : data['country']}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const index = e.target.selectedIndex;
                        const option = e.target[index];
                        if (option) {
                          const code = option.dataset['code'];
                          if (code) {
                            if (EUData.rates[code]) {
                              setIsEu(true);
                            } else {
                              setIsEu(false);
                            }
                            if (code === 'FI' && subscription['totalCost']) {
                              const tax = Number(subscription['totalCost'] * 0.24).toFixed(2);
                              setTaxAmount(Number(tax));
                            } else {
                              setTaxAmount(0);
                            }
                          }
                        }
                        onDataChange('country', e.target.value);
                      }}
                    >
                      <option value="">{getLocalization('selectCountry')}</option>
                      {getCountries()}
                    </Form.Control>
                  </Form.Group>
                  {getTaxEl()}
                </Row>
                <Row>
                  <Col>
                    {activeCard && !subscription['totalCost'] ? null : (
                      <Button onClick={onCardBtnClick} size="sm">
                        {getButtonLabel(activeCard)}
                      </Button>
                    )}
                  </Col>
                  {card !== null && (
                    <Col className="d-flex justify-content-end">
                      <Button onClick={confirmDeleteCard} variant="danger" size="sm">
                        {getLocalization('deleteCard')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    card: state.paymentPlanInfo.card,
  };
};

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    getClientToken: () => dispatch(getClientToken()),
    registerCard: (paymentRegistration: PaymentRegistration) => dispatch(registerCard(paymentRegistration)),
    payWithCard: (payment: Payment, paymentType: PaymentType) => dispatch(payWithCard(payment, paymentType)),
    deletePaymentMethod: (token: string) => dispatch(deletePaymentMethod(token)),
    getPaymentPlanInfo: () => dispatch(getPaymentPlanInfo()),
    getPaymenyTransactions: () => dispatch(getPaymenyTransactions())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
