import { getLocalization } from 'global/global';
import * as React from 'react';
import { Button, CloseButton, Col, Form, Row } from 'react-bootstrap';
import { ILocationTreeNode } from '../../../Workspace';

interface Props {
  node: ILocationTreeNode;
  updateNode: (attr: ILocationTreeNode) => void;
}

export const PostalCodes = (props: Props) => {

  const getPostalCodes = (node: ILocationTreeNode) => {
    const lower = node.data?.LowerPostalCode?.split(';') || [];
    const upper = node.data?.UpperPostalCode?.split(';') || [];
    return [lower, upper];
  };

  const [postalCodes, setPostalCodes] = React.useState(getPostalCodes(props.node));


  const updateNode = (value: string, index: number, lowerUpper: number) => {
    const newPostalCodes = [[...postalCodes[0]], [...postalCodes[1]]];
    newPostalCodes[lowerUpper][index] = value;
    const newNode = {...props.node};
    newNode.data.LowerPostalCode = newPostalCodes[0].join(';');
    newNode.data.UpperPostalCode = newPostalCodes[1].join(';');
    props.updateNode(newNode);
    setPostalCodes(newPostalCodes);
  };

  const addNewPostalCodes = () => {
    const newPostalCodes = [[...postalCodes[0]].concat(''), [...postalCodes[1]].concat('')];
    setPostalCodes(newPostalCodes);
  };

  const removeNewPostalCodes = (index: number) => {
    const newPostalCodes = [[...postalCodes[0]], [...postalCodes[1]]];
    newPostalCodes[0].splice(index, 1);
    newPostalCodes[1].splice(index, 1);
    setPostalCodes(newPostalCodes);
  };

  return (
    <Col className="mb-2">
      {postalCodes[0].map((pc, index) => {
        return (
          <Row key={`postal-code-${index}-${props.node.key}`}>
            <Col className="row">
              <Form.Group>
                <Form.Label>{getLocalization('lowerPostalCode')}</Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  value={pc}
                  onChange={(e) => updateNode(e.target.value, index, 0)}
                />
              </Form.Group>
            </Col>
            <Col className="postal-codes">
              <Form.Group>
                <Form.Label>{getLocalization('upperPostalCode')}</Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  value={postalCodes[1][index]}
                  onChange={(e) => updateNode(e.target.value, index, 1)}
                />
              </Form.Group>
              <div className="remove-postal-code">
                <CloseButton
                  onClick={() => removeNewPostalCodes(index)}
                />
              </div>
            </Col>
          </Row>
        );
      })}
      <Button size={'sm'} onClick={addNewPostalCodes}>
        {getLocalization('addPostalCode')}
      </Button>
    </Col>
  );
};
