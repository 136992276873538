import '../styles/UserQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { ConfirmationModal } from '../../../views/Modals/ConfirmationModal';
import { SingleInstanceComponentClass } from '../SingleInstanceComponent';
import { getLocalization } from '../../../global/global';

interface Props {
  singleInstance: SingleInstanceComponentClass;
}

interface State {
  confirm: boolean;
}

export default class CloseButton extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      confirm: false
    };
  }

  @bind
  private onClose() {
    const { singleInstance } = this.props;
    if (singleInstance.formComponent.current && singleInstance.formComponent.current.getUpdated()) {
      this.setState({ confirm: true });
    } else {
      singleInstance.unrender();
    }
  }

  @bind
  private closeConfirmed() {
    this.closeAlert();
    this.props.singleInstance.unrender();
  }

  @bind
  private closeAlert() {
    this.setState({ confirm: false });
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        { this.state.confirm ? (
          <ConfirmationModal
            onConfirm={this.closeConfirmed}
            onClose={this.closeAlert}
            localizations={
              {
                cancel: getLocalization('cancel'),
                confirm:getLocalization('ok'),
                confirmStyle: 'danger',
                header: (
                  <label>{getLocalization('confirm')}</label>
                ),
                body: (
                  <div>{getLocalization('poiChangesAlert')}</div>
                )
              }
            }
          />
        )
          : null }
        <button className="close" onClick={this.onClose} id="close-single-instance">
          <i className="fa fa-times-circle" aria-hidden="true" />
        </button>
      </React.Fragment>
    );
  }
}
