import './Style.scss';
import { DocumentFilesInterface, DocumentInterface, DocumentsInterface } from 'Interfaces/DocumentInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import * as React from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getLocalization } from 'global/global';
import DocumentItem from './DocumentItem';


interface Props {
  search: string;
  activeId?: number;
  selectedFolder: number;
  setDocument: (document?: DocumentInterface) => void;
}

interface StateProps {
  documents: DocumentsInterface;
  documentFiles: DocumentFilesInterface;
}

function DocumentList(props: StateProps & Props) {
  const { selectedFolder } = props;
  const [documents, setDocuments] = React.useState(props.documents.collection);
  const [selected, setSelected] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    if (props.search === '') {
      setDocuments(selectedFolder > -1 ?
        props.documents.collection.filter(doc => doc.folderId === selectedFolder) : props.documents.collection
      );
    } else {
      const search = (doc: DocumentInterface) => doc.name.toLowerCase().search(props.search.toLocaleLowerCase()) > -1;
      const docs = props.documents.collection.filter(doc =>
        selectedFolder > -1 ? doc.folderId === selectedFolder &&
        search(doc) : search(doc)
      );
      setDocuments(docs);
    }
  }, [props.search, props.documents, props.selectedFolder]);

  React.useEffect(() => {
    if (selected) {
      const found = props.documents.collection.find(d => d.id === selected);
      if (!found) {
        props.setDocument(undefined);
        setSelected(undefined);
      }
    }
  }, [props.documents]);

  const getFilesCount = React.useCallback((id: number) => {
    return props.documentFiles.collection.filter(df => df.documentId === id).length;
  }, [documents, props.documentFiles]);

  return (
    <Container className="document-list">
      <Row className="header">
        <Col className="document-list">
          {getLocalization('title')}
        </Col>
        <Col className="document-list doc-creation-date">
          {getLocalization('docCreationDate')}
        </Col>
      </Row>
      {selectedFolder > -1 && documents.length === 0 && (
        <Alert variant="info">{getLocalization('emptyFolder')}</Alert>
      )}
      {documents.map((doc) => (
        <DocumentItem
          key={`document-${doc.id}`}
          document={doc}
          filesCount={doc.id ? getFilesCount(doc.id) : 0}
          onClick={() => {
            setSelected(doc.id);
            props.setDocument(doc);
          }}
          active={doc.id === props.activeId}
        />
      ))}
    </Container>
  );
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    documents: state.documents,
    documentFiles: state.documentFiles,
  };
};

export default connect(mapStateToProps, null)(DocumentList);
