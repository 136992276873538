// import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { StateInterface } from 'Interfaces/StateInterface';
import { Client } from '@stomp/stompjs';
import { getLocalization, globalWindow } from '../global/global';
import { addFeed, updateFeed, userUpdate } from './feed';
import { getFeedItem } from './utils/websocket';
import { showAlert } from './index';
import { setSingleInstanceMessage, updateOpenedDataPointAction } from './moduleSelectionActions';
import { updateClientPersist } from './clientPersistActions';

export const initWS = () => {
  return (dispatch, getState: () => StateInterface) => {
    // const socket = new SockJS('/json/ws');
    const client = new Client({
      brokerURL: `${globalWindow.location.origin.replace('http', 'ws')}/json/ws`,
      debug: function (str) {
        console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.webSocketFactory = () => {
      // Note that the URL is different from the WebSocket URL
      return new SockJS('/json/ws');
    };
    client.onConnect = () => {
      client.subscribe('/user/queue/automaticupdate', (message) => {
        const response = JSON.parse(message.body);
        const users = getState().users;
        const forms = getState().forms.collection;
        const userId = getState().clientPersist.user_id;
        const selectedForms = getState().filtersMenu.selectedForms.map((sf) => sf.ref);

        const setSingleInstance = () => {
          const { singleInstance } = getState().moduleSelection;
          if (singleInstance && singleInstance.dataPoint.id === response.id) {
            dispatch(updateOpenedDataPointAction(response));
          }
        };
        if (response.type) {
          if (response.type === 'NOTIFICATION') {
            dispatch(showAlert({ visible: true, message: response.message }));
          } else if (response.type === 'EDIT_NOTIFICATION') {
            const users = response.user;
            const userNameList = users
              ? users.map((u) => {
                if (u.indexOf('@') > -1) {
                  return u.substring(0, u.indexOf('@'));
                }
                return u;
              })
              : [];
            dispatch(
              setSingleInstanceMessage({
                editMessage:
                                    userNameList.length > 0
                                      ? `${userNameList.length} users (${userNameList.join(
                                        ',',
                                      )}) have opened the datapoint`
                                      : '',
              }),
            );
          } else if (response.type === 'SAME_EDIT_NOTIFICATION') {
            dispatch(setSingleInstanceMessage({ sameUserMessage: getLocalization('sameUserOpen') }));
          } else if (response.type === 'USER_CHANGES') {
            const { userData } = response;
            console.log(userData);
            const { clientPersist } = getState();
            if (clientPersist.groupId !== userData.groupId || !userData.active) {
              dispatch(updateClientPersist({ logout: true }));
            } else {
              dispatch(updateClientPersist({...userData}));
            }
          }
        } else {
          let shouldUpdateFeed = true;
          if (selectedForms.length > 0 && selectedForms.indexOf(response.questionnaire_id) === -1) {
            shouldUpdateFeed = false;
          }
          const feedItem = getFeedItem(response, users, forms);
          if (response.user_id === Number(userId)) {
            if (shouldUpdateFeed) {
              dispatch(userUpdate([feedItem]));
            }
            /* if (response.action === 'add') {
              dispatch(feedLoaded([feedItem]));
            } else if (response.action === 'edit') {
              dispatch(updateFeed(feedItem));
            }*/
            setSingleInstance();
          } else {
            if (response.action === 'add') {
              if (shouldUpdateFeed) {
                dispatch(addFeed(feedItem));
              }
            } else if (response.action === 'edit') {
              if (shouldUpdateFeed) {
                dispatch(updateFeed(feedItem));
                dispatch(addFeed(feedItem));
              }
              // dispatch(showDataPointUpdateAlert(response.id));
              setSingleInstance();
            }
          }
        }
      });
    };
    client.activate();
    /* const client = Stomp.over(() => new SockJS('/json/ws'));
    console.log('connect to client...');
    client.connect('', '', (frame) => {});*/
  };
};
