import * as React from 'react';
import bind from 'bind-decorator';
import TextareaAutosize from 'react-autosize-textarea';

interface Props {
  name: string;
  readOnly: boolean;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  extraClass?: string;
}

interface State {
  value: string;
  active: boolean;
}

export default class TextAreaComponent extends React.Component <Props, State> {
  private typingTimer: any;

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      active: false
    };
  }

  @bind
  private handleChange(e) {
    const value = e.target.value;
    const active = true;
    this.setState({ value, active });
  }

  @bind
  private handleKeyUp() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(this.doneTyping, 1000);
  }

  @bind
  private handleKeyDown() {
    clearTimeout(this.typingTimer);
    this.typingTimer = null;
  }

  @bind
  private doneTyping() {
    const { value } = this.state;
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
      // this.setState({ active: false }, () => onChange(value));
    }
  }

  /* @bind
  private onBlur() {
    this.setState({ active: false }, this.doneTyping);
  }*/

  /* This was needed when the view would be rendered before the data is loaded.
    Since now we load the data the render the view it is not needed.
    It caused BG-4386*/
  public static getDerivedStateFromProps(props: Props, state: State) {
    if (props.value !== state.value && !state.active) {
      return { value: props.value };
    }
    return null;
  }

  public render() {
    const { extraClass } = this.props;

    return (
      // @ts-ignore
      <TextareaAutosize
        name={this.props.name}
        className={`form-control ${extraClass ? extraClass : ''}`}
        rows={1}
        onKeyUp={this.handleKeyUp}
        onKeyDown={this.handleKeyDown}
        onBlur={this.doneTyping}
        readOnly={this.props.readOnly}
        value={this.state.value}
        onChange={this.handleChange}
        maxRows={10}
        placeholder={this.props.placeholder || ''}
      />
    );
  }
}
