import { AnyAction } from 'redux';
import { DocumentActivityInterface } from 'Interfaces/DocumentInterface';
import { DOCUMENTS } from '../actions/actionTypes';

const INITIAL_STATE: DocumentActivityInterface = {
  loaded: false,
  collection: []
};

const documentActivities = (state = INITIAL_STATE, action: AnyAction): DocumentActivityInterface => {
  switch (action.type) {
    case DOCUMENTS.ACTIVITY_LOADED:
      if (action.documentActivity) {
        return {
          loaded: true,
          collection: action.documentActivity
        };
      }
      return state;
    default:
      return state;
  }
};

export default documentActivities;
