import { getLocalization } from 'global/global';
import { useAppDispatch } from 'index';
import * as React from 'react';
import { Button, Toast } from 'react-bootstrap';
import { updateClientPersist } from '../../../actions/clientPersistActions';
import { logout } from '../../../actions/userActions';

interface Props {
  logout?: boolean;
}
export default function LogoutAlert(props: Props) {

  const dispatch = useAppDispatch();
  const [timer, setTimer] = React.useState(-1);
  const [intervalId, setIntervalId] = React.useState(-1);

  React.useEffect(() => {
    if (props.logout) {
      if (timer === -1) {
        setTimer(120);
        const id = setInterval(() => {
          setTimer(timer => timer - 1);
        }, 1000);
        setIntervalId(id);
        return () => clearInterval(id);
      }
    }
    return undefined;
  }, [props.logout]);

  React.useEffect(() => {
    if (timer === 1) {
      doLogout();
    }
  }, [timer]);

  const doLogout = () => {
    clearInterval(intervalId);
    dispatch(updateClientPersist({ logout: false }));
    void dispatch(logout());
  };

  return (
    <Toast
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
      }}
    >
      <Toast.Header closeButton={false}>
        <strong className="mr-auto">{getLocalization('alert')}</strong>
      </Toast.Header>
      <Toast.Body>
        <p>{getLocalization('logoutAlert').replace('{{time}}', `${timer}s`)}</p>
        <span className="pull-right mb-3">
          <Button size="sm" onClick={doLogout}>{getLocalization('logout')}</Button>
        </span>
      </Toast.Body>
    </Toast>
  );
}
