import './Schedule.scss';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateClientPersist } from 'actions/clientPersistActions';
import { clientPersistSelector } from 'reducers/clientPersistReducer';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { ScheduleInterface, SchedulesInterface } from '../../Interfaces/ScheduleInterface';
import * as scheduleApi from '../../api/schedulesApi';
import { ScheduleSelectForm } from './ScheduleSelectForm';
import { ScheduleAnswersList } from './ScheduleAnswersList';
import { ScheduleCalendar } from './ScheduleCalendar';

const className = 'Schedule';

export const Schedule: FunctionComponent<> = () => {
  const dispatch = useDispatch();
  const [selectedForm, setSelectedForm] = useState<FormInterface>();
  const [schedules, setSchedules] = useState<SchedulesInterface>([]);

  const clientPersist = useSelector(clientPersistSelector);

  useEffect(() => {
    const getSchedules = async () => {
      if (selectedForm) {
        dispatch(updateClientPersist({...clientPersist, sessionTime: Date.now() }));
        const data = await scheduleApi.loadSchedules(selectedForm);
        setSchedules(data);
      }
    };
    void getSchedules();
  }, [selectedForm?.ref]);

  const getDate = (timestamp: number | string) => {
    const pad = (v) => v < 10 ? `0${v}` : v;
    const date = new Date(timestamp);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
  };

  const addNewSchedule = async (schedule: Omit<ScheduleInterface, 'id'>, copy?: boolean) => {
    if (selectedForm) {
      dispatch(updateClientPersist({...clientPersist, sessionTime: Date.now() }));
      const savedSchedule = await scheduleApi.saveSchedule({...schedule, ...{ date: getDate(schedule.date)}}, copy);
      if (savedSchedule.id) {
        setSchedules([
          ...schedules,
          savedSchedule
        ]);
      }
    }
  };

  const deleteSchedule = async (
    id: ScheduleInterface['id']
  ) => {
    dispatch(updateClientPersist({...clientPersist, sessionTime: Date.now() }));
    await scheduleApi.deleteSchedule(id);
    setSchedules(
      schedules.filter((schedule) => {
        return schedule.id !== id;
      })
    );
  };

  return (
    <div className={className}>
      {/* <div className={`${className}-selection`}>*/}
      {/*  <ScheduleSelectForm*/}
      {/*      baseClassName={`${className}-selection__item`}*/}
      {/*      selectedForm={selectedForm}*/}
      {/*      setSelectedForm={setSelectedForm}*/}
      {/*  />*/}
      {/* </div>*/}
      <Row className={`${className}-main`}>
        <Col
          md={9}
          className={`${className}-main__calendar`}
        >
          {selectedForm && (
            <ScheduleCalendar
              baseClassName={`${className}-main`}
              selectedForm={selectedForm}
              schedules={schedules}
              addNewSchedule={addNewSchedule}
              deleteSchedule={deleteSchedule}
            />
          )}
        </Col>
        <Col md={3}>
          <ScheduleSelectForm
            baseClassName={`${className}-main__form`}
            selectedForm={selectedForm}
            setSelectedForm={setSelectedForm}
          />
          {selectedForm && (
            <ScheduleAnswersList
              baseClassName={`${className}-main`}
              selectedForm={selectedForm}
              schedules={schedules}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
