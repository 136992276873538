import { AnyAction } from 'redux';
import { DocumentFolderInterface } from 'Interfaces/DocumentInterface';
import { DOCUMENTS } from '../actions/actionTypes';

const INITIAL_STATE: DocumentFolderInterface = {
  loaded: false,
  collection: []
};

const documentFolders = (state = INITIAL_STATE, action: AnyAction): DocumentFolderInterface => {
  switch (action.type) {
    case DOCUMENTS.FOLDERS_LOADED:
      if (action.documentFolders) {
        return {
          loaded: true,
          collection: action.documentFolders
        };
      }
      return state;
    case DOCUMENTS.FOLDER_CREATED:
      if (action.folder) {
        return {...state, collection: state.collection.concat([action.folder])};
      }
      return state;
    default:
      return state;
  }
};

export default documentFolders;
