import * as React from 'react';
import './filters.scss';
import Select, { Option } from 'rc-select';
import 'rc-select/assets/index.css';
import bind from 'bind-decorator';
import { LooseObject } from '../../../Interfaces/LooseObject';

interface Props {
  column: LooseObject;
  onFilter: (value) => void;
  options: (LooseObject | string)[];
}

interface State {
  value: string[];
}

export default class SelectFilter extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: []
    };
  }

  @bind
  private onChange(value) {
    this.setState({ value: value });
    this.props.onFilter(value);
  }

  /**
   * this function ensures click events from this component do not cause sorting.
   */
  @bind
  private onClick(e) {
    e.stopPropagation();
  }

  public render(): JSX.Element {
    const { column, options } = this.props;
    return (
      <div className="select-filter" onClick={this.onClick}>
        <Select
          style={{ width: 180 }}
          onChange={this.onChange}
          value={this.state.value}
          multiple
          maxTagCount={0}
          showArrow
          allowClear
        >
          <Option value="">Select one</Option>
          {
            options.map((opt) => {
              if (typeof opt === 'string') {
                return (<Option value={`${opt}`} key={`${column.dataField}_${opt}`}>{opt}</Option>);
              }
              return (<Option value={`${opt.value}`} key={`${column.dataField}_${opt.value}`}>{opt.label}</Option>);
            })
          }
        </Select>
      </div>
    );
  }

}
