import { getLocalization } from 'global/global';
import * as React from 'react';
import { Col, Container, Form, Pagination, Row } from 'react-bootstrap';

interface Props {
  pageSize: number;
  page: number;
  total: number;
  onChangePage: (page: number) => void;
  onChangePageSize: (size: number) => void;
}

export default function TablePager(props: Props) {
  const pages = Math.ceil(props.total / props.pageSize);
  return (
    <Container fluid={true} className="mt-2">
      <Row>
        <Col md={1}>
          <Form.Control
            as="select"
            value={`${props.pageSize}`}
            onChange={(v) => {
              props.onChangePageSize(Number(v.currentTarget.value));
            }}
          >
            <option value={'5'}>5</option>
            <option value={'10'}>10</option>
            <option value={'20'}>20</option>
            <option value={'-1'}>{getLocalization('all')}</option>
          </Form.Control>
        </Col>
        {props.pageSize > 0 && (
          <Col>
            <Pagination>
              {Array(pages).fill('').map((v, index) => {
                return (
                  <Pagination.Item
                    active={index === props.page}
                    key={`page-${index + 1}`}
                    onClick={() => {
                      if (props.page !== index) {
                        props.onChangePage(index);
                      }
                    }}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              })}
            </Pagination>
          </Col>
        )}
      </Row>
    </Container>
  );
}
