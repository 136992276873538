import * as React from 'react';
import './Style.scss';
import { PaymentPlanInfo } from 'Interfaces/PaymentPlanInterface';
import { Button, Card, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { toast } from 'react-toastify';
import { getAccountUserStats } from 'actions/adminUserActions';
import TransactionBundle from '../form/TransactionBundle';
import { getTransactionCost } from '../utils';
import { getLocalization } from '../../../global/global';
import DetailsForm from '../DetailsForm';

interface Props {
  paymentPlanInfo: PaymentPlanInfo;
  openPaymentsTab: () => void;
  updateSubscription: (newValues: JSONInterface) => void;
  initPayments: () => void;
  setAdditionalUsers: (additionalUsers: JSONInterface) => Promise<boolean>;
}

export default function Plans(props: Props) {
  const { paymentPlanInfo } = props;

  const [data, setData] = React.useState<JSONInterface>({
    oneTimePayment: 0,
    totalCost: 0
  });

  const [validate, setValidate] = React.useState(false);
  const [noOfUsersInvalid, setNoUsersInvalid] = React.useState(false);
  const [userStats, setUserStats] = React.useState<JSONInterface | 'loading' | undefined>(undefined);

  const [moreTransactions, setMoreTransactions] = React.useState(false);
  const [renewOrMoreUsers, setRenewOrMoreUsers] = React.useState<'RENEW' | 'MORE_USERS' | ''>('');

  const onDataChange = (name: string, value: string | number) => {
    setData({...data, [name]: value});
  };

  React.useEffect(() => {
    if (!userStats && userStats !== 'loading') {
      setUserStats('loading');
      getAccountUserStats().then(json => {
        setUserStats(json);
      }).catch(e => {
        console.log(e);
      });
    }
  }, [userStats]);
  const objOnDataChange = (obj) => {
    setData({...data, ...obj});
  };

  React.useEffect(() => {
    props.updateSubscription(data);
    const { prepNumberOfUsers } = data;
    if (renewOrMoreUsers === 'RENEW' && userStats && prepNumberOfUsers > 0) {
      if ((
        paymentPlanInfo.pricingMethod === 'Per Transaction' && prepNumberOfUsers < userStats['noOfOfficeUsers']
      ) || (paymentPlanInfo.pricingMethod === 'Per User' && prepNumberOfUsers < userStats['activeUsers'])) {
        setNoUsersInvalid(true);
      }
    } else {
      setNoUsersInvalid(false);
    }
  }, [data]);

  React.useEffect(() => {
    const txCost = Number(getTransactionCost(data['transactions']));
    const users = Number(data.prepNumberOfUsers);
    const perUserCost = renewOrMoreUsers === 'RENEW' ?
      users * Number(paymentPlanInfo[`chargePerUser${paymentPlanInfo.plan}`]) * (users < 5 ? 12 : 10) : 0;
    setData({
      ...data,
      totalCost: (Math.round((Number(data['oneTimePayment']) + txCost + perUserCost) * 100) / 100).toFixed(2)
    });
  }, [data.oneTimePayment, data.transactions, data.prepNumberOfUsers]);

  const openPaymentsTab = () => {
    if (!data['totalCost']) {
      setValidate(true);
    } else {
      props.openPaymentsTab();
    }
  };

  const setAdditionalUsers = async () => {
    if (!data['prepNumberOfUsers'] || data['prepNumberOfUsers'] === 0) {
      setValidate(true);
    }
    const requestToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('processingPayment'), {
      toastId: requestToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false
    });
    const response = await props.setAdditionalUsers({
      additionalUsers: data['prepNumberOfUsers']
    });
    if (response) {
      toast.update(requestToastId, {
        type: toast.TYPE.SUCCESS,
        render: getLocalization('additionalUsersAdded')
      });
    } else {
      toast.update(requestToastId, {
        type: toast.TYPE.ERROR,
        render: getLocalization('additionalUsersError')
      });
    }
    setTimeout(() => toast.dismiss(requestToastId), 3000);
  };

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            {getLocalization(paymentPlanInfo.payingCustomer === 'no' ? 'annualLicense' : 'currentPlan')}
          </Card.Header>
          <Card.Body>
            {paymentPlanInfo.payingCustomer === 'no' && (<p>{getLocalization('trialAlert')}</p>)}
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td>{getLocalization('plan')}</td>
                  <td>{paymentPlanInfo.plan}</td>
                </tr>
                <tr>
                  <td>{getLocalization('pricingMethod')}</td>
                  <td>{paymentPlanInfo.pricingMethod}</td>
                </tr>
                {/* <tr>
                  <td>{getLocalization('paymentSchedule')}</td>
                  <td>{paymentPlanInfo.paymentSchedule}</td>
                </tr>*/}
                <tr>
                  <td>{getLocalization('monthSubscribed')}</td>
                  <td>{paymentPlanInfo.monthsSubscribed}</td>
                </tr>
                <tr>
                  <td>{getLocalization('licenseValidFrom')}</td>
                  <td>{moment(paymentPlanInfo.validFrom).format('L')}</td>
                </tr>
                <tr>
                  <td>{getLocalization('licenseValidTo')}</td>
                  <td>{moment(paymentPlanInfo.validTo).format('L')}</td>
                </tr>
                <tr>
                  <td>{getLocalization('active')}</td>
                  <td>{paymentPlanInfo.active}</td>
                </tr>
              </tbody>
            </Table>
            {paymentPlanInfo.payingCustomer === 'no' ? (
              <DetailsForm
                paymentPlanInfo={paymentPlanInfo}
                updateSubscription={props.updateSubscription}
                openPaymentsTab={props.openPaymentsTab}
              />
            ) : (
              <Form className="payment-form">
                <Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>{
                      getLocalization('oneTimePayments')
                    }</Form.Label>
                    <Form.Text className="text-muted">
                      {getLocalization('oneTimePaymentsAdvice')}
                    </Form.Text>
                    <Form.Control
                      type="number"
                      size="sm"
                      value={data['oneTimePayment']}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onDataChange('oneTimePayment', e.target.value)
                      }
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={6}>
                    <Col>
                      <div className="renew-add-users-div">
                        {paymentPlanInfo.pricingMethod === 'Per Transaction' && (
                          <Button
                            size="sm"
                            onClick={() => {
                              setMoreTransactions(true);
                              setRenewOrMoreUsers('');
                              objOnDataChange({ 'prepNumberOfUsers' : 0, 'validTo': null, 'transactions': null });
                            }}
                          >
                            {getLocalization('moreTransactions')}
                          </Button>
                        )}
                        <Button
                          size="sm"
                          onClick={() => {
                            setRenewOrMoreUsers('RENEW');
                            setMoreTransactions(false);
                            objOnDataChange({ 'prepNumberOfUsers' : 0, 'validTo': moment(props.paymentPlanInfo.validTo)
                              .add(12, 'months').format('YYYY-MM-DD'), 'transactions': null});
                          }}
                        >
                          {getLocalization('renew')}
                        </Button>
                        <Button
                          size="sm"
                          onClick={() => {
                            setMoreTransactions(false);
                            setRenewOrMoreUsers('MORE_USERS');
                            objOnDataChange({ 'prepNumberOfUsers' : 0, 'validTo': null, 'transactions': null });
                          }}
                        >
                          {getLocalization('addUsers')}
                        </Button>
                      </div>
                    </Col>
                    {moreTransactions && (
                      <TransactionBundle
                        value={data['transactions']}
                        onChange={(v) => onDataChange('transactions', `${v}`)}
                      />
                    )}
                    {renewOrMoreUsers && (
                      <>
                        <Form.Group as={Col}>
                          <Form.Label>{
                            getLocalization(renewOrMoreUsers === 'RENEW' ?
                              paymentPlanInfo.pricingMethod === 'Per User' ? 'prepNumberOfUsers' :
                                'estimatedNoOfficeUsers' : 'additionalUsers')
                          }</Form.Label>
                          {renewOrMoreUsers === 'RENEW' ? (
                            <>
                              <Form.Control
                                type="number"
                                size="sm"
                                value={data['prepNumberOfUsers']}
                                isInvalid={noOfUsersInvalid}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  onDataChange('prepNumberOfUsers', e.target.value)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {getLocalization('activeUsersAlert').replace(
                                  /{{current}}/g,
                                  !userStats ? '' : paymentPlanInfo.pricingMethod === 'Per User' ?
                                    `${userStats['activeUsers']}` : `${userStats['noOfOfficeUsers']}`
                                )}
                              </Form.Control.Feedback>
                              <Form.Text>
                                {getLocalization('renewAlert').replace(/{{period}}/g, new Date(data['validTo']).toLocaleDateString())}
                              </Form.Text>
                            </>
                          ) : (
                            <>
                              <Form.Control
                                type="number"
                                size="sm"
                                value={data['prepNumberOfUsers']}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  onDataChange('prepNumberOfUsers', e.target.value)
                                }
                              />
                              <Form.Text>{getLocalization('additionalUsersInfo')}</Form.Text>
                            </>
                          )}
                        </Form.Group>
                        {renewOrMoreUsers === 'MORE_USERS' && (
                          <Button
                            size="sm"
                            onClick={() =>  void setAdditionalUsers()}
                          >
                            {getLocalization('updateUsers')}
                          </Button>
                        )}
                      </>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>{getLocalization('totalCost')}</Form.Label>
                    <InputGroup className="mb-3" size="sm">
                      <InputGroup.Prepend>
                        <InputGroup.Text>$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        readOnly
                        value={data['totalCost']}
                        isInvalid={!data['totalCost'] && validate}
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <Button size="sm" onClick={openPaymentsTab}>
                      {getLocalization('proceedToPayment')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Card.Body>
        </Card>
      </Col>
      {/* <Col>
        <Card>
          <Card.Header>{getLocalization('nextPaidPlan')}</Card.Header>
          <Card.Body>
            <Table striped={true} bordered={true} hover={true}>
              <tbody>
                <tr>
                  <td>{getLocalization('plan')}</td>
                  <td>{paymentPlanInfo.futurePackage}</td>
                </tr>
                <tr>
                  <td>{getLocalization('pricingMethod')}</td>
                  <td>{paymentPlanInfo.futurePricingPlan}</td>
                </tr>
                <tr>
                  <td>{getLocalization('paymentSchedule')}</td>
                  <td>{paymentPlanInfo.futurePaymentSchedule}</td>
                </tr>
                <tr>
                  <td>{getLocalization('monthSubscribed')}</td>
                  <td>{paymentPlanInfo.futureSubscriptionMonths}</td>
                </tr>
                <tr>
                  <td>{getLocalization('licenseValidFrom')}</td>
                  <td>{paymentPlanInfo.futureLicenseValidFrom}</td>
                </tr>
                <tr>
                  <td>{getLocalization('licenseValidTo')}</td>
                  <td>{paymentPlanInfo.futureLicenseValidTo}</td>
                </tr>
                <tr>
                  <td>{getLocalization('active')}</td>
                  <td>{paymentPlanInfo.active}</td>
                </tr>
              </tbody>
            </Table>
            <Button variant="primary">
              {getLocalization('updateNextPlan')}
            </Button>
          </Card.Body>
        </Card>
      </Col>*/}
    </Row>
  );
}
