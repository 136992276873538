import './ShowVideoModal.scss';
import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BaseModalInterface } from '../../Interfaces/ModalInterface';
import { getLocalization, globalWindow } from '../../global/global';

interface IExternalProps extends BaseModalInterface {
  videoId: string;
}

const className = 'ShowVideoModal';
export class ShowVideoModal extends React.Component<IExternalProps> {
  private onEntered(videoId) {
    return () => {
      return new globalWindow.YT.Player('helpvideoid', {
        height: (800 * 360) / 640,
        width: 800,
        videoId: videoId,
        events: {
          onReady: (event) => {
            event.target.playVideo();
          }
        }
      });
    };
  }
  public render(): JSX.Element {
    return (
      <Modal
        show
        onHide={this.props.onClose}
        backdrop
        className={className}
        onEntered={this.onEntered(this.props.videoId)}
        size={'xl'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
              Help video
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${className}-body`}>
          <div id="helpvideoid" />
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="primary" onClick={this.props.onClose}>
            {getLocalization('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
