import { globalWindow } from 'global/global';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { Persistor } from 'redux-persist/es/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ProgressContainer from 'views/Progress/ProgressContainer';
import AlertContainer from 'views/Alert/AlertContainer';
import ConfirmContainer from 'views/Confirm/ConfirmContainer';
import { ReactModals } from 'views/Modals/ReactModals';
import { persistedStore } from '../../index';
import { Header } from '../../views/Header/HeaderContainer';

interface StateProps {
  clientPersist: ClientPersistInterface;
  persistedStore: Persistor;
}
class Admin extends React.Component<StateProps> {

  constructor(props: StateProps) {
    super(props);
  }

  public async componentDidUpdate(prevProps) {
    const {instance, userName, user_id, lang} = this.props.clientPersist;
    if (prevProps.clientPersist.instance !== instance) {
      if (!instance || !userName || !user_id) {
        // Force redux state to update persistance local storage
        await this.props.persistedStore.flush();
        globalWindow.open(`/login.jsp?lang=${lang}`, '_parent');
      }
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <ProgressContainer/>
        <ReactModals />
        <AlertContainer/>
        <ConfirmContainer/>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
        />
        <Header/>
      </>
    );
  }
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    clientPersist: state.clientPersist,
    persistedStore: persistedStore,
  };
};


export default connect(mapStateToProps, null)(Admin);
