import { toast } from 'react-toastify';
import { getLocalization } from 'global/global';
import { sortObjects } from 'utils/utils';
import { StateInterface } from 'Interfaces/StateInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { LocationInterface } from '../Interfaces/LocationInterface';
import { HierarchyInterface, LocationHierarchyInterface } from '../Interfaces/HierarchyInterface';
import * as locationsApi from '../api/locationsApi';
import { actionCreator } from './';
import { HIERARCHY } from './actionTypes';
import { updateClientPersist } from './clientPersistActions';

// TODO Remove, duplicate of locations
export const setLocationHierarchy = actionCreator<LocationInterface[]>(HIERARCHY.SET_LOCATION_HIERARCHY);

export const loadHierarchy = actionCreator<HierarchyInterface>(HIERARCHY.LOAD);

export const loadedHierarchies = actionCreator<LocationHierarchyInterface[]>(HIERARCHY.LOADED);

export const updateHierarchies = actionCreator<LocationHierarchyInterface[]>(HIERARCHY.UPDATE);

export const updateArchiveStatus = actionCreator<Pick<LocationHierarchyInterface, 'id' | 'active'>>
(HIERARCHY.UPDATE_ARCHIVE_STATUS);

export const loadHierarchies = () => {
  return async (dispatch) => {
    const locations = await locationsApi.getLocationHierarchies();
    dispatch(loadedHierarchies(locations.sort((a, b) => sortObjects(a, b, 'name'))));
  };
};

export const saveHierarchies = (hierarchies) => {
  return (dispatch, getState: () => StateInterface): Promise<JSONInterface> => {
    return new Promise((resolve, reject) => {
      const saveToastId = Date.now() + Math.floor(Math.random() * 100);
      toast(getLocalization('savingHierarchy'), {
        toastId: saveToastId,
        type: toast.TYPE.INFO,
        autoClose: false,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: false,
      });
      const request = locationsApi.doSaveHierarchies(hierarchies, dispatch, getState);
      request
        .then((res) => res.json())
        .then((json) => {
          resolve(json);
          if (json.status === 'OK') {
            toast.update(saveToastId, {
              type: toast.TYPE.SUCCESS,
              render: getLocalization('savedHierarchy'),
            });
            dispatch(updateHierarchies(json.data));
          } else {
            toast.update(saveToastId, {
              type: toast.TYPE.ERROR,
              render: getLocalization('errorSavingHierarchy'),
            });
          }
          setTimeout(() => toast.dismiss(saveToastId), 3000);
        })
        .catch((error) => {
          reject(error); // console.log(error);
        });
    });
  };
};

export const archiveHierarchy = (id: number, action: 'true' | 'false') => {
  return (dispatch, getState: () => StateInterface) => {
    const saveToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization(action === 'true' ? 'archiving' : 'activating'), {
      toastId: saveToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    const request = locationsApi.doArchiveHierarchy(id, action, dispatch, getState);
    request
      .then((res) => res.json())
      .then((json) => {
        if (json.status === 'OK') {
          dispatch(updateArchiveStatus({ id, active: action === 'true' ? false : true }));
          toast.update(saveToastId, {
            type: toast.TYPE.SUCCESS,
            render: getLocalization(action === 'true' ? 'lhArchived' : 'lhActivated'),
          });
        } else {
          toast.update(saveToastId, {
            type: toast.TYPE.ERROR,
            render: getLocalization('lhArchiveActivateError'),
          });
        }
        setTimeout(() => toast.dismiss(saveToastId), 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const updateGroupLocationHierarchy = (id: number) => {
  return (dispatch, getState: () => StateInterface) => {
    const saveToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('updatingGroupLocationHierarchy'), {
      toastId: saveToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    const { clientPersist } = getState();
    const groupId = getState().clientPersist.groupId;
    const request = locationsApi.doUpdateGroupHierarchy(id, groupId || -1, dispatch, getState);
    request
      .then((res) => res.json())
      .then((json) => {
        if (json.status === 'OK') {
          dispatch(updateClientPersist({ ...clientPersist, locationHierarchyId: id }));
          toast.update(saveToastId, {
            type: toast.TYPE.SUCCESS,
            render: getLocalization('groupLocationHierarchyUpdated'),
          });
        } else {
          toast.update(saveToastId, {
            type: toast.TYPE.ERROR,
            render: getLocalization('errorUpdatingGroupLocationHierarchy'),
          });
        }
        setTimeout(() => toast.dismiss(saveToastId), 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
