import { AlertInterface } from 'Interfaces/AlertInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { ShareUser } from 'Interfaces/User';
import * as React from 'react';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
import { formatDate, validEmail } from '../../../utils/utils';
import GenericModal from '../../Modals/GenericModal';
import { getLocalization, globalWindow } from '../../../global/global';

interface ShareDatePointProps {
  visible: boolean;
  cancel: () => void;
  shareDataUnregisteredUsers: boolean;
  shareDataUnregisteredUsersViewer: boolean;
  checkUser: (email: string, id: string, signal: AbortSignal) => Promise<Response>;
  showAlert: (alert: AlertInterface) => void;
  shareDataPoint: (user: ShareUser, dataPoint: DataPoint, signal: AbortSignal) => Promise<Response>;
  getSharerEmail: (id: string, signal: AbortSignal) => Promise<Response>;
  dataPoint: DataPoint;
  account: string | undefined;
  role: string;
}

interface FormFields {
  fullNames: boolean;
  emailBody: boolean;
  accessUntil: boolean;
}

let abortController: AbortController | null = new AbortController();

export const ShareDataPoint = (props: ShareDatePointProps) => {
  const [accessUntil, setAccessUntil] = React.useState('');

  const templateMessage = props.role === 'enumerator' ?
    'Dear WABTEC Auditor, \nWe have updated the self-assessment for your review.' : 'Dear Supplier, ' +
  '\nPlease find below the link to the portal for you to carry out the Wabtec Supplier Assessment,' +
  `\nAccess will be valid until {accessUntil}.` +
  '\nPlease click on the link and complete all relevant sections and attach supporting evidence,' +
  '\nShould you have any queries, please contact your Wabtec Supplier Quality representative';
  const [email, setEmail] = React.useState('');
  const [fixedEmail, setFixedEmail] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  // const [abortController, setAbortController] = React.useState<AbortController | null>(null);
  const [emailBody, setEmailBody] = React.useState(props.account !== 'wabtec' ? '' : templateMessage);

  const [fullNames, setFullNames] = React.useState('');
  const [formFields, setFormFields] = React.useState<FormFields>({
    fullNames: false, emailBody: true, accessUntil: false
  });

  const [checkStatus, setCheckStatus] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, [props]);

  React.useEffect(() => {
    if (props.dataPoint.id && globalWindow.accessSharedDataOnly === 'true') {
      if (abortController === null) {
        abortController = new AbortController();
      }
      props.getSharerEmail(props.dataPoint.id, abortController.signal).then(
        response => response.json()
      ).then(json => {
        if (json.email && validEmail(json.email)) {
          setEmail(`${json.email}`);
          setFixedEmail(true);
          setShowForm(true);
        } else if (json.userName && validEmail(json.userName)) {
          setEmail(`${json.userName}`);
          setFixedEmail(true);
          setShowForm(true);
        }
      }).catch(err => {
        console.error(err);
        setCheckStatus(false);
      });
    }
  }, [props.visible]);

  const checkUser = () => {
    if (email === '' || !validEmail(email)) {
      setHasError(true);
    } else {
      if (abortController === null) {
        abortController = new AbortController();
      }
      const checkUser = props.checkUser(email, props.dataPoint.id || '', abortController.signal);
      checkUser.then(response => response.json()).then(json => {
        const ff = {...formFields};
        if (json.errorCode) {
          if (!props.shareDataUnregisteredUsers && !props.shareDataUnregisteredUsersViewer) {
            props.cancel();
            props.showAlert({
              message: json.errorMessage,
              visible: true
            });
            return;
          } else if (
            `${json.errorCode}` === '1074' ||  `${json.errorCode}` === '1075'
          ) {
            toast(`${json.errorMessage}`, {
              type: toast.TYPE.ERROR, autoClose: 3000
            });
            return;
          } else if (
            `${json.errorCode}` === '1073' || `${json.errorCode}` === '1070' || `${json.errorCode}` === '1076'
          ) {
            toast(`${json.errorMessage}`, {
              type: toast.TYPE.WARNING, autoClose: 3000
            });
            if (`${json.errorCode}` === '1076') {
              ff.accessUntil = true;
            }
          } else if (`${json.errorCode}` === '1072') {
            ff.accessUntil = true;
            setAccessUntil(`${json.accessUntil}`);
          }
        }
        setShowForm(true);
        if (json.accessSharedDataOnly && `${json.errorCode}` !== '1072') {
          ff.accessUntil = true;
        }
        // user does not exist
        if (`${json.errorCode}` === '1071') {
          ff.fullNames = true;
          ff.accessUntil = true;
        }
        setFormFields(ff);
      }).catch(error => {
        console.log(error);
      });
    }
  };

  const shareDataPoint = () => {
    if (showForm && accessUntil === '' && formFields.accessUntil && props.shareDataUnregisteredUsers) {
      setHasError(true);
      return;
    }
    const params: ShareUser = {
      email,
      emailBody: emailBody.replace(/\n/g, '<br/>').replace('{accessUntil}', accessUntil),
      fullNames,
      accessUntil: accessUntil !== '' ? accessUntil : undefined,
      create: formFields.fullNames,
      roles: props.shareDataUnregisteredUsers ? 'enumerator' : 'viewer'
    };
    if (abortController === null) {
      abortController = new AbortController();
    }
    const toastId = Date.now() + Math.floor(Math.random() * 100);
    toast('Sharing...', {
      toastId,
      type: toast.TYPE.INFO,
      autoClose: false
    });
    const { dataPoint } = props;
    props.shareDataPoint(params, {
      id: dataPoint.id, formId: dataPoint.questionnaire_id, Name: dataPoint.Name, row_id: dataPoint.row_id,
    }, abortController.signal).then(response => response.json()).then(json => {
      if (json.status === 'OK') {
        toast.update(toastId, {
          type: toast.TYPE.SUCCESS,
          render: 'Datapoint shared successfully.'
        });
        props.cancel();
      } else {
        toast.update(toastId, {
          type: toast.TYPE.ERROR,
          render: 'Unable to send.'
        });
      }
      setTimeout(() => toast.dismiss(toastId), 3000);
    }).catch(() => {
      toast.update(toastId, {
        type: toast.TYPE.ERROR,
        render: 'Unable to send.'
      });
      setTimeout(() => toast.dismiss(toastId), 3000);
    });
  };

  const getBody = () => {
    if (checkStatus === false) {
      return (
        <Alert variant={'danger'}>
          {getLocalization('errorCheckingShareStatus')}
        </Alert>
      );
    }
    return (
      <div>
        <div className={`form-group`}>
          <label>{getLocalization('shareEmail')}</label>
          {fixedEmail ? (
            <span>{email}</span>
          ) : (
            <input
              className={
                `form-control form-control-sm ${(email === '' || !validEmail(email)) && hasError ? 'is-invalid' : ''}`
              }
              name="email"
              value={email}
              readOnly={fixedEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
        </div>
        {showForm && (
          <>
            {formFields.fullNames && (
              <div className={`form-group`}>
                <label>{getLocalization('fullNames')}</label>
                <input
                  className={`form-control form-control-sm`}
                  name="fullNames"
                  value={fullNames}
                  onChange={(e) => setFullNames(e.target.value)}
                />
              </div>
            )}
            {formFields.emailBody && (
              <div className={`form-group`}>
                <label>{getLocalization('emailBody')}</label>
                <textarea
                  className="form-control form-control-sm"
                  name="emailBody"
                  value={emailBody}
                  onChange={(e) => setEmailBody(e.target.value)}
                  rows={6}
                />
              </div>
            )}
            {formFields.accessUntil && (
              <div className={`form-group`}>
                <label>
                  {getLocalization('accessUntil')}
                  {props.shareDataUnregisteredUsers && (
                    <span className="required">*</span>
                  )}
                </label>
                <input
                  className={`form-control form-control-sm ${accessUntil === '' && hasError ? 'is-invalid' : ''}`}
                  type="date"
                  min={formatDate(new Date())}
                  name="emailBody"
                  value={accessUntil}
                  onChange={(e) => {
                    setAccessUntil(e.target.value);
                    if (props.account === 'wabtec') {
                      setEmailBody(
                        `${templateMessage.replace('{accessUntil}', new Date(e.target.value).toLocaleDateString())}`
                      );
                    }
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const cancel = () => {
    if (abortController !== null) {
      abortController.abort();
    }
    abortController = null;
    props.cancel();
  };

  return props.visible ? (
    <GenericModal
      visible={props.visible}
      title={getLocalization('share')}
      body={getBody()}
      onConfirm={checkStatus === false ? cancel : () => showForm ? shareDataPoint() :  checkUser()}
      confirmText={checkStatus === false ? getLocalization('close')
        : showForm ? getLocalization('share') : getLocalization('next')}
      cancel={checkStatus === false ? undefined : cancel}
      cancelText={getLocalization('cancel')}
    />
  ) : null;
};
