import { ThunkDispatchAction } from 'actions';
import { StateInterface } from 'Interfaces/StateInterface';
import { getRequestConfig } from './index';
import { request } from './request';

export const doGetFeed = (
  params: string[],
  signal: AbortSignal,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  return request(
    `/json/app/newsfeed?${params.join('&')}`,
    Object.assign({}, headers, { signal: signal }),
    dispatch,
    getState,
  );
};
