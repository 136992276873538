import { DocumentFileInterface } from 'Interfaces/DocumentInterface';
import { addDocumentFile } from 'actions/documentActions';
import { getLocalization, globalWindow } from 'global/global';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

interface ActionProps {
  addDocumentFile: (file: DocumentFileInterface) => void;
}

function AddFile(props: ActionProps) {

  const fileInput = React.useRef<HTMLInputElement | null>(null);

  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      const f = files[0];
      const data = new FormData();
      data.append("file", f);
      void fetch("/json/app/document/addFile", {
        method : "POST",
        credentials: 'same-origin',
        headers: {
          cache: false,
          processData: false,
          contentType: false,
          'userid': globalWindow.userName,
          'key': globalWindow.pwrd
        },
        body: data
      }).then(response => response.json()).then((json) => {
        if (json.id && json.fileId) {
          props.addDocumentFile(json);
        }
      });
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInput}
        onChange={onFileSelected}
        className="d-none"
      />
      <Button
        size="sm"
        onClick={() => fileInput?.current?.click()}
      >
        {getLocalization('addFile')}
      </Button>
    </>
  );
}

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    addDocumentFile: (file: DocumentFileInterface) => dispatch(addDocumentFile(file)),
  };
};

export default connect(null, mapDispatchToProps)(AddFile);
