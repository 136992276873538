import { ThunkDispatchAction } from 'actions';
import { ExcelData } from 'Interfaces/ImportDataInterfaces';
import { StateInterface } from 'Interfaces/StateInterface';
import { getRequestConfig } from './index';
import { request } from './request';

export const doUploadMapImport = (
  groupId,
  file,
  name,
  srs,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  const data = { ...headers };
  const formData = new FormData();
  formData.append('file', file);
  formData.append('groupId', groupId);
  formData.append('name', name);
  formData.append('s_srs', srs);
  delete data.headers['Content-Type']; //  = 'multipart/form-data';

  data['method'] = 'POST';
  data['body'] = formData;
  return request('/json/app/uploadDiagram', data, dispatch, getState);
};

export const doLoadImportMappings = (
  formIds: string[],
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  const data = { ...headers };
  data['method'] = 'POST';
  data['body'] = JSON.stringify({ poiIdentifier: formIds });
  return request('/json/app/import/mapping', data, dispatch, getState);
};

export const doUploadDataImport = (
  groupId,
  userId,
  file,
  form,
  mappings,
  geocode,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  const data = { ...headers };
  const formData = new FormData();
  formData.append('file', file);
  formData.append('mappings', JSON.stringify(mappings));
  formData.append('customTable', form.answerTable);
  delete data.headers['Content-Type']; // = 'multipart/form-data';

  data['method'] = 'POST';
  data['body'] = formData;
  return request(`/json/app/import/${form.id}/${groupId}/${userId}/${geocode}/${form.ref}`, data, dispatch, getState);
};

export const doUploadImportExcelFile = (
  file: File,
  signal: AbortSignal,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const formData = new FormData();
  formData.append('file', file);

  const headers = getRequestConfig();
  const data = { ...headers };
  delete data.headers['Content-Type']; // = 'multipart/form-data';
  data['method'] = 'POST';
  data['body'] = formData;
  data['signal'] = signal;
  return request('/json/app/import/processExcelImportFile', data, dispatch, getState);
};

export const doImportExcelData = (
  excelData: ExcelData,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  return request(
    '/json/app/import/excelDataImport',
    { ...headers, method: 'POST', body: JSON.stringify(excelData) },
    dispatch,
    getState,
  );
};
