import { getLocalization } from 'global/global';
import { LocationHierarchyInterface } from 'Interfaces/HierarchyInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import * as React from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import GenericModal from 'views/Modals/GenericModal';
import Papa from 'papaparse';
import X2JS from 'x2js';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { cleanLHJSON } from 'views/LocationHierarchy/utils';
import { saveHierarchies } from 'actions/hierarchyActions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'typescript-fsa';
import { toast } from 'react-toastify';

interface Props {
  openFile: (xml: string) => void;
}

interface StateProps {
  locationHierarchies: LocationHierarchyInterface[];
}

interface ActionProps {
  saveHierarchies: (hierarchies) => Promise<JSONInterface>;
}

type ImportPostalCodesProps = Props & StateProps & ActionProps;

const ImportPostalCodes = (props: ImportPostalCodesProps) => {

  const [importVisible, setImportVisible] = React.useState(false);
  const [fileName, setFileName] = React.useState(getLocalization('noFileSelected'));
  const [file, setFile] = React.useState('');
  const pcInput = React.useRef<HTMLInputElement>(null);
  const [lh, setLh] = React.useState('');

  const onImportPostalCodesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    let i = 0;
    if (files) {
      while (i < files?.length) {
        const f = files[i];
        if (!(f.type.match('text/csv') || f.type.match('text/plain') || f.type.match(''))) {
          return;
        }
        const reader = new FileReader();
        reader.onload = ((theFile) => {
          console.log(theFile);
          return (fe) => {
            if (fe) {
              const text = fe.target?.result;
              if (text && typeof text === 'string') {
                setFile(`${text}`);
                setFileName(f.name);
              }
            }
          };
        })(f);
        reader.readAsText(f);
        i++;
      }
    }
    e.target.value = '';
  };

  const processImport = () => {
    const selected = props.locationHierarchies.find(l => `${l.id}` === lh);
    if (selected && selected.xml) {
      const x2js = new X2JS();
      const jsonObj = x2js.xml2js(selected.xml);
      const results = Papa.parse(file.trim());
      const data = results.data;
      const missing: string[] = [];
      if (jsonObj) {
        // @ts-ignore
        const lhObject = jsonObj['Document'];
        if (lhObject && lhObject.LocationHierarchy) {
          const location1 = lhObject.LocationHierarchy.Location1Question;
          const locations = Array.isArray(location1) ? location1 : [location1];
          for (const d of data) {
            const added = setPostalCode(locations, d);
            if (!added) {
              missing.push(d[0]);
            }
          }
          console.log(jsonObj);
          cleanLHJSON(lhObject);
          props.saveHierarchies(lhObject).then(json => {
            if (json.status === 'OK') {
              setImportVisible(false);
              const data = json.data;
              if (data) {
                const keys = Object.keys(data);
                for (const key of keys) {
                  if (data[key].xml) {
                    props.openFile(data[key].xml);
                  }
                }
              }
            } else {
              toast(getLocalization('errorImportingPostalCodes'), {
                type: toast.TYPE.ERROR,
                autoClose: false,
                closeButton: true,
                hideProgressBar: true,
                closeOnClick: true
              });
            }
          }).catch(error => {
            console.log(error);
          });
        }
      }
    }
  };

  const setPostalCode = (nodes: JSONInterface[], row: string[]) => {
    for (const node of nodes) {
      if (node['__text'] === row[0].trim()) {
        const lpc = node._LowerPostalCode && node._LowerPostalCode !== '' ? node._LowerPostalCode.split(';') : [];
        const upc = node._UpperPostalCode && node._UpperPostalCode !== '' ? node._UpperPostalCode.split(';') : [];
        if (row[1]) {
          if (lpc.indexOf(row[1]) === -1) {
            lpc.push(row[1]);
          }
          if (upc.indexOf(row[2]) === -1) {
            upc.push(row[2]);
          } else {
            upc.push('');
          }
          node._LowerPostalCode = lpc.join(';');
          node._UpperPostalCode = upc.join(';');
        }
        return true;
      } else {
        const keys = Object.keys(node);
        for (const key of keys) {
          if (typeof node[key] === 'object') {
            const n = node[key];
            const ad = setPostalCode(Array.isArray(n) ? n : [n], row);
            if (ad) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const getBody = () => {
    return (
      <>
        <FormGroup>
          <Form.Text>
            {getLocalization('importPostalCodesAlert')}
          </Form.Text>
          <Form.Label>
            {getLocalization('selectLH')}
          </Form.Label>
          <Form.Control
            as={'select'}
            size={'sm'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setLh(e.target.value)}
          >
            <option value={''}>{getLocalization('selectOne')}</option>
            {props.locationHierarchies.map(lh => {
              return (
                <option key={`import-pc-${lh.id}`} value={lh.id}>{lh.name}</option>
              );
            })}
          </Form.Control>
        </FormGroup>
        <Form.Group>
          <Button
            size={'sm'}
            onClick={() => {
              if (pcInput.current) {
                pcInput.current.click();
              }
            }}
          >
            <input
              ref={pcInput}
              className={'d-none'}
              onChange={onImportPostalCodesChange}
              type={'file'}
              accept={'.csv, .txt, plain/text, text/csv'}
            />
            {getLocalization('selectFile')}
          </Button>
          <span>{fileName}</span>
        </Form.Group>
      </>
    );
  };

  return (
    <>
      {importVisible && (
        <GenericModal
          visible={importVisible}
          title={getLocalization('import')}
          body={getBody()}
          onConfirm={() => {
            processImport();
          }}
          confirmText={getLocalization('importPostalCodes')}
          cancelText={getLocalization('cancel')}
          cancel={() => setImportVisible(false)}
        />
      )}
      <Button
        size={'sm'}
        onClick={() => setImportVisible(true)}
      >
        {getLocalization('importPostalCodes')}
      </Button>
    </>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    locationHierarchies: state.locationHierarchies.locationHierarchies,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    saveHierarchies: (hierarchies) => dispatch(saveHierarchies(hierarchies))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ImportPostalCodes);
