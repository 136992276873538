import * as React from 'react';
import bind from 'bind-decorator';
import FormUtils from '../utils/FormUtils';
import { ConfirmationModal } from '../../Modals/ConfirmationModal';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { renderQuestions } from '../utils/qnrenderer';
import '../styles/TableQuestion.scss';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { getLocalization } from '../../../global/global';
import RemoveRowBtn from './RemoveRowBtn';
import OriginalFormText from './OriginalFormText';

interface Props {
  dataPoint: DataPoint; // The datamodel representing the row data.
  dynamic: boolean; // whethe this is a dynamic table or not.
  removeRow?: (index: number) => void | undefined; // call back to remove a row.
  updateAnswer: (answer: DataPoint, index: number) => void;
  index: number; // the index of the row.
  formUtils: FormUtils;  // The formutils representing the table questionnaire object.
  rowItem: LooseObject | undefined;
  question: LooseObject;
  forms: LooseObject[];
  clientPersist: ClientPersistInterface;
  parentDataPoint?: LooseObject;
  parentModel?: LooseObject;
  disabled?: boolean;
}

interface State {
  value: DataPoint;
  showConfirm: boolean;
}

export default class TableRow extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    const { dataPoint } = this.props;
    this.state = {
      value: dataPoint ? dataPoint : {} as DataPoint,
      showConfirm: false
    };
  }

  @bind
  private updateAnswer(answer: LooseObject) {
    const { updateAnswer, index } = this.props;
    this.setState((prevState) =>
      ({value: {...prevState.value, ...answer, modified: Date.now() }}),
    () => updateAnswer(this.state.value, index)
    );
  }
  /*
    This function returns the static table rows.
    If the row has no data it would initialize a new data model which would be passed to the questions.
    It also adds the row name as a column after the count column.
  */
  @bind
  private getStaticRows(): JSX.Element[] {
    const { index, formUtils, rowItem, question, forms, clientPersist, disabled, parentModel } = this.props;
    const { value } = this.state;

    const qns = renderQuestions(formUtils.getModel().question, value, true, forms, this.updateAnswer, formUtils,
      false, clientPersist, undefined, undefined, question, undefined, disabled);
    qns.splice(0, 0, <td className="count" key={`${formUtils.getModel().id}_${index}`}>{}</td>);
    qns.splice(1, 0, (
      <td key={`${formUtils.getModel().id}_${'name'}`}>
        <strong>
          {rowItem ? rowItem.value : ''}
          {rowItem?.id && (<OriginalFormText formId={parentModel?.ref} viewId={rowItem.id} />)}
        </strong>
      </td>
    )
    );
    return qns;
  }

  /*
    returns the dynamic table rows for this table question.
  */
  @bind
  private getDynamicTableRows(): JSX.Element[] {
    const { removeRow, formUtils, question, forms, clientPersist, parentDataPoint, parentModel, disabled } = this.props;
    const { value } = this.state;

    const qns = renderQuestions(formUtils.getModel().question, value, true, forms, this.updateAnswer, formUtils,
      false, clientPersist, parentModel, parentDataPoint, question, undefined, disabled);
    qns.splice(0, 0, <td className="count" key={`${formUtils.getModel().id}_${value.id}`} />);
    if (removeRow && !this.props.disabled) {
      qns.push(
        <td key={`${formUtils.getModel().id}_${value.id}_remove`} className="remove">
          <RemoveRowBtn
            removeRow={removeRow}
            index={this.props.index}
          />
        </td>
      );
    }
    return qns;
  }

  @bind
  private showConfirm(status: boolean) {
    this.setState({ showConfirm: status });
  }

  @bind
  private removeRow() {
    this.showConfirm(false);
    const { removeRow } = this.props;
    if (removeRow) {
      removeRow(this.props.index);
    }
  }

  public render(): JSX.Element {
    const confirmModal = this.state.showConfirm ? (
      <ConfirmationModal
        onConfirm={this.removeRow}
        onClose={() => this.showConfirm(false)}
        localizations={
          {
            cancel: getLocalization('cancel'),
            confirm: getLocalization('remove'),
            confirmStyle: 'danger',
            header: (
              <label>{getLocalization('confirm')}</label>
            ),
            body: (
              <div>{getLocalization('confirmRemoveRow')}</div>
            )
          }
        }
      />
    ) : null;
    return (
      <React.Fragment>
        {confirmModal}
        <tr>
          {this.props.dynamic ? this.getDynamicTableRows() : this.getStaticRows()}
        </tr>
      </React.Fragment>
    );
  }
}
