import { StateInterface } from "Interfaces/StateInterface";
import { ThunkDispatchAction } from "actions";
import { getRequestConfig } from "api";
import { request } from "./request";

export const doGetGroups =  (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  return request(`/json/app/groups`, {...headers }, dispatch, getState);
};
