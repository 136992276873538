import './Style.scss';
import { deleteDiagram } from 'actions/diagrams';
import { getLocalization } from 'global/global';
import { GroupDiagrams } from 'Interfaces/GroupDiagram';
import { StateInterface } from 'Interfaces/StateInterface';
import * as React from 'react';
import { connect } from 'react-redux';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import TextInputComponent from '../SingleInstance/elements/TextInputComponent';

interface StateProps {
  diagrams: GroupDiagrams;
}

interface ActionProps {
  deleteDiagram: (id: number, signal: AbortSignal) => void;
}

type ManageMapsProps = StateProps & ActionProps;

let abortController: AbortController | null = new AbortController();

export const ManageMaps = (props: ManageMapsProps) => {
  const [confirm, setConfirm] = React.useState(false);
  const [current, setCurrent] = React.useState(-1);
  const [diagrams, setDiagrams] = React.useState(props.diagrams);
  const [filteredDiagrams, setFilteredDiagrams] = React.useState(props.diagrams);
  const [filter, setFilter] = React.useState('');

  const filterMaps = (maps, value) => {
    return maps.filter(((diagram) => {
      if (diagram.name) {
        return diagram.name.toLowerCase().search(value.toLowerCase()) !== -1;
      } else {
        return diagram.filename.toLowerCase().search(value.toLowerCase()) !== -1;
      }
    }
    ));
  };

  React.useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, [props]);

  React.useEffect(() => {
    setDiagrams(props.diagrams);
    if (filter !== '') {
      setFilteredDiagrams(filterMaps(props.diagrams, filter));
    }
  }, [props.diagrams]);

  const deleteDiagram = () => {
    if (abortController === null) {
      abortController = new AbortController();
    }
    props.deleteDiagram(current, abortController.signal);
    setCurrent(-1);
    setConfirm(false);
  };

  const doneTyping = (value: any) => {
    const inputLength = value.length;
    const newList = inputLength === 0 ? diagrams :  filterMaps(filteredDiagrams, value);
    setFilter(value);
    setFilteredDiagrams(newList);
  };

  const confirmDeleteeModal = confirm ? (
    <ConfirmationModal
      onConfirm={deleteDiagram}
      onClose={() => setConfirm(false)}
      localizations={
        {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('remove'),
          confirmStyle: 'danger',
          header: (
            <label>{getLocalization('confirm')}</label>
          ),
          body: (
            <div>{getLocalization('confirmRemoveMap')}</div>
          )
        }
      }
    />
  ) : null;

  return (
    <div className="row container">
      <div className="container-fluid">
        <h3>{getLocalization('manageMaps')}</h3>
      </div>
      {confirmDeleteeModal}
      <div className="col-6">
        <TextInputComponent
          name={'filter-diagrams'}
          value={filter}
          onChange={doneTyping}
        />
      </div>
      <div className="container-fluid">
        <ul className="list-group manage-maps-list col-6">
          {filteredDiagrams.map((diagram) => {
            return (
              <li key={`diagram-list-${diagram.id}`} className="list-group-item">
                {diagram.name || diagram.filename}
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setConfirm(true);
                    setCurrent(diagram.id);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    diagrams: state.groupDiagrams
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteDiagram: (id: number, signal: AbortSignal) => {
      return dispatch(deleteDiagram(id, signal));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageMaps);
