import { ThunkDispatchAction } from 'actions';
import { StateInterface } from 'Interfaces/StateInterface';
import { getRequestConfig } from './index';
import { request } from './request';

export const doGetTileMapResource = (
  diagramName: string,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  return request(`/json/app/data/getTileMapResource/${diagramName}`, headers, dispatch, getState);
};

export const doGetGroupDiagrams = (groupId, dispatch: ThunkDispatchAction, getState: () => StateInterface) => {
  const headers = getRequestConfig();
  return request(`/json/app/getGroupDiagrams/${groupId}`, headers, dispatch, getState);
};

export const doDeleteDiagram = (
  id: number,
  signal: AbortSignal,
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const config = getRequestConfig();
  return request(
    `/json/app/diagrams/delete/${id}`,
    Object.assign(
      {},
      {
        method: 'GET',
      },
      config,
      signal,
    ),
    dispatch,
    getState,
  );
};
