import { questionTypes } from 'Interfaces/Forms/QuestionInterface';
import * as React from 'react';
import { QuestionInterface } from '../../Interfaces/Forms/QuestionInterface';
import { MoreTextComponent } from './MoreTextComponent';

// tests fail when you use import with moment. this will do for now.
import NameCellRenderer from './NameCellRenderer';
import TaskParentCellRenderer from './TaskParentCellRenderer';

export const cellTemplate = (props) => {
  return (
    <div className="table-cell">
      {props.value}
    </div>
  );
};

export const nameCellRenderer = (cell, row, tableQuestion: QuestionInterface) => {
  return cellTemplate({ value: <NameCellRenderer cell={cell} row={row} tableQuestion={tableQuestion} /> });
};

export const taskParentCellRenderer = (cell, row) => {
  return cellTemplate({ value: <TaskParentCellRenderer cell={cell} row={row} /> });
};

export const saleforceCellRenderer = (cell) => {
  return cellTemplate({
    value: (
      <a href={`https://emea.salesforce.com/${cell}`} target="_blank" rel="noreferrer">
        {`Sales force (${cell})`}
      </a>
    )
  });
};

export const textCellRenderer = (cell, row, question) => {
  if (cell && cell.length > 50) {
    return cellTemplate({
      value: <span>{cell.substring(0, 50)} <MoreTextComponent value={cell} row={row} question={question} /></span>
    });
  }
  return cellTemplate({
    value: cell
  });
};

export const dateCellRenderer = (cell) => {
  const value = cell ? new Date(cell).toLocaleDateString() : '';
  return cellTemplate({ value: `${value}` });
};

export const dateTimeCellRenderer = (cell) => {
  const value = cell ? new Date(cell).toUTCString() : '';
  return cellTemplate({ value: `${value}` });
};

export const timeCellRenderer = (cell) => {
  // const value = cell ? moment(cell).format('llll') : '';
  return cellTemplate({ value: `${cell || ''}` });
};

export const locationRenderer = (row, columnfield, value, ) => {
  return cellTemplate({ value: `${value}` });
};

/* export const groupNameCellRenderer = (row, columnfield, value, defaulthtml, columnProperties) => {
  return cellTemplate({ value: `${ !value ? 0 : value}s` });
};*/

export const getStringCellRenderer = (question, tableQuestion?) => {
  if (question.type === 'NameQuestion') {
    return (cell, row) => nameCellRenderer(cell, row, tableQuestion);
  }
  if (question.salesForceId && question.salesForceId !== '') {
    return saleforceCellRenderer;
  }
  return (cell, row) => textCellRenderer(cell, row, question);
};

export const numericTimeRenderer = (cell) => {
  const value = cell ? (cell / 1000) : 0;
  return cellTemplate({ value: `${value}s`});
};

export const getNumericCellRenderer = (question) => {
  if (question.id === 'phoneEditTime' || question.id === 'portalEditTime') {
    return numericTimeRenderer;
  }
  if (question.id === 'parentRowId') {
    return taskParentCellRenderer;
  }
  return undefined;
};

export const imagesCellRenderer = (question) => {
  const { id } = question;
  const isSignature = question.type === questionTypes.DIGITAL_SIGNATURE_QUESTION;
  return (cell, row) => {
    const files = row.files;
    if (files) {
      const questionFiles = files.filter(f => {
        if ((f.questionId === '' && id === 'Pictures') || id === f.questionId) {
          return true;
        }
        return false;
      });
      const value = questionFiles.map(f => {
        const url = f.urlThumbnail && !isSignature ? f.urlThumbnail : f.url;
        return (
          <img
            key={`${row.id}-${id}-images`}
            src={url}
            height={'100'}
          />
        );
      });
      return cellTemplate({ value });
    }
    return cellTemplate({ value: '' });
  };
};
