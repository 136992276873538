import * as React from 'react';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { isNumeric } from '../../SingleInstance/utils/ScriptUtils';
import { QuestionInterface, questionTypes } from '../../../Interfaces/Forms/QuestionInterface';

export const getQuestionOptions = (form, chartType) => {
  const options: JSONInterface = {};
  const allowedQns = ['countQuestion'];
  const getOptions = (questions, pageNumber) => {
    for (const q of questions) {
      if (((!q.inVisible && !q.deleted) || (allowedQns.indexOf(q.id) > -1)) &&
        q.text && q.text !== '' && filterViewQuestions(q, chartType)) {
        if (q.type === 'GPSQuestion' && !form.hasCoordinates) {
          continue;
        }
        options[q.id] = pageNumber ? `${q.text}-(Page_${pageNumber})` : `${q.text}`;
        /* if ((input === 'source' && filter.indexOf(q.id) === -1) ||
          (input === 'destination' && filter.indexOf(q.id) !== -1)) {
          options.push((
            <option key={`${q.id}-${input}`} value={q.id}>
              {}
            </option>
          ));
        }*/
      }
      if (q.triggerValues && q.triggerValues.triggerValue) {
        for (const triggerValue of q.triggerValues.triggerValue) {
          if (triggerValue.action.subQuestions) {
            getOptions(triggerValue.action.subQuestions.question, pageNumber);
          }
        }
      }
      if (q.type === questionTypes.LAYOUT_TABLE_QUESTION) {
        const column = q.table?.columns?.column;
        if (column) {
          for (const col of column) {
            if (col.question) {
              getOptions(col.question, pageNumber);
            }
          }
        }
      }
    }
  };

  for (const page of form.questionPage) {
    getOptions(page.question, page.pageNumber);
  }

  if (form.question) {
    getOptions(form.question, '');
  }
  return options;
};

export const filterViewQuestions = (question, chartType) => {
  if (chartType === '-2') {
    if (isNumeric(question)) {
      return true;
    }
    return false;
  }
  switch (question.type) {
    case 'SkipQuestion':
    case 'DigitalSignatureQuestion':
    case 'TableQuestion':
    case 'ReferenceTableQuestion':
    case 'Part_of_Question':
    case 'HeaderQuestion':
    case 'TaskQuestion':
    case 'FileQuestion':
    case 'Label':
    case 'PicturesQuestion':
    case 'ValidationRuleQuestion':
      return false;
    default:
      return true;
  }
};

/**
 * returns the option for select dropdown.
 */
export const getOption = (label, value, pageNumber, padding?) => {
  return label && label !== '' ? (
    <option value={value} key={`opt-${value}-${pageNumber}`}>
      {padding}{pageNumber ? `${label}-(Page_${pageNumber})` : `${label}`}
    </option>
  ) : null;
};

/* eslint-disable complexity */
export const getLegendQuestions = (questions: QuestionInterface[], pageNumber, chartType, subQuestion?) => {
  let options: (JSX.Element | null)[] = [];
  const invalidQuestions = ['phoneEditTime', 'portalEditTime', 'portalEditEntry'];
  const padding = subQuestion ? `\u00A0\u00A0\u00A0\u00A0` : '';
  for (const q of questions) {
    if (!q.inVisible && !q.deleted) {
      switch (q.type) {
        case questionTypes.BOOLEAN_QUESTION:
        case questionTypes.SELECT_ONE_QUESTION:
        case questionTypes.SELECT_MULTIPLE_QUESTION:
        case questionTypes.STATUS_QUESTION:
        case questionTypes.LIKERT_SCALE_QUESTION:
          if ((chartType === '-2') || chartType === '-1') {
            options.push(getOption(q.text, q.id, pageNumber, padding));
          }
          break;
        case questionTypes.NAME_QUESTION:
        case questionTypes.CALCULATED_VALUE_QUESTION:
          if (chartType === '-1') {
            options.push(getOption(q.text, q.id, pageNumber, padding));
          }
          break;
          /* if (chartType === '-2' && !q.numericOnly) {
            options.push(getOption(q.text, q.id, pageNumber, padding));
          }
          break;*/
        case questionTypes.STRING_QUESTION:
          if (q.validatefield && q.validationlist) {
            options.push(getOption(q.text, q.id, pageNumber, padding));
          }
          break;
        case questionTypes.ID_QUESTION:
        case questionTypes.PICTURES_QUESTION:
        case questionTypes.PART_OF_QUESTION:
        case questionTypes.TASK_QUESTION:
        case questionTypes.TIME_QUESTION:
        case questionTypes.TABLE_QUESTION:
        case questionTypes.REFERENCE_TABLE_QUESTION:
        case questionTypes.LABEL:
        case questionTypes.HEADER_QUESTION:
        case questionTypes.GPS_QUESTION:
        case questionTypes.VALIDATION_RULE_QUESTION:
        case questionTypes.FILE_QUESTION:
        case questionTypes.SKIP_QUESTION:
          break;
        default:
          if (invalidQuestions.indexOf(q.id) === -1) {
            if (chartType === '-1') {
              options.push(getOption(q.text, q.id, pageNumber));
            }
          }
      }
    }
    if (q.triggerValues && q.triggerValues.triggerValue) {
      for (const triggerValue of q.triggerValues.triggerValue) {
        if (triggerValue.action.subQuestions) {
          options = options.concat(
            getLegendQuestions(triggerValue.action.subQuestions.question, pageNumber, chartType, true)
          );
        }
      }
    }
  }
  return options;
};


export const getSubgroupOptions = (questions: QuestionInterface[], pageNumber, chartType) => {
  let options: (JSX.Element | null)[] = [];
  const invalidQuestions = ['phoneEditTime', 'portalEditTime', 'portalEditEntry'];
  for (const q of questions) {
    if (!q.inVisible && !q.deleted) {
      switch (q.type) {
        case 'BooleanQuestion':
        case 'SelectOneQuestion':
        case 'SelectMultipleQuestion':
        case 'StatusQuestion':
        case 'LikertScaleQuestion':
          if ((chartType === '-2') || chartType === '-1') {
            options.push(getOption(q.text, q.id, pageNumber));
          }
          break;
        case 'PicturesQuestion':
        case 'IdQuestion':
        case 'Part_of_Question':
        case 'TaskQuestion':
        case 'TimeQuestion':
        case 'TableQuestion':
        case 'Label':
        case 'HeaderQuestion':
        case 'GPSQuestion':
        case 'SkipQuestion':
        case 'ValidationRuleQuestion':
        case 'FileQuestion':
          break;
        default:
          if (invalidQuestions.indexOf(q.id) === -1) {
            if (chartType === '-1') {
              options.push(getOption(q.text, q.id, pageNumber));
            }
          }
      }
    }
    if (q.triggerValues && q.triggerValues.triggerValue) {
      for (const triggerValue of q.triggerValues.triggerValue) {
        if (triggerValue.action.subQuestions) {
          options = options.concat(
            getSubgroupOptions(triggerValue.action.subQuestions.question, pageNumber, chartType)
          );
        }
      }
    }
  }
  return options;
};
/* eslint-enable complexity */
