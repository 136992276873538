import '../styles/UserQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { Button } from 'react-bootstrap';
import { ShareDataPoint } from '../../components/share';
import { getLocalization } from '../../../global/global';
import { ShareButtonProps } from './Containers/ShareButtonContainer';
// import { globalWindow } from '../../../global/global';

interface ShareButtonState {
  modalVisible: boolean;
}

export default class ShareButton extends React.Component <ShareButtonProps, ShareButtonState> {

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false
    };
  }

  @bind
  private onClick() {
    this.setState({ modalVisible: true });
    /* if (this.props.clientPersist.shareDataUnregisteredUsers === 'simon') {
      this.setState({ modalVisible: true });
    } else {
      const { dataPoint } = this.props;
      const mainUrl = `https://portal.poimapper.com/index.jsp?`;
      const url = `${mainUrl}#${dataPoint.questionnaire_id}/${dataPoint.id}/${dataPoint.row_id}/false`;
      const target = `mailto:?subject=${dataPoint.Name}&body=${url}`;
      globalWindow.open(target, '_blank');
      if (dataPoint.id) {
        this.props.sharePOI(dataPoint.id);
      }
    }*/
  }

  public render(): JSX.Element {

    return (
      <>
        {this.state.modalVisible && (
          <ShareDataPoint
            visible={this.state.modalVisible}
            shareDataUnregisteredUsers={this.props.clientPersist.shareDataUnregisteredUsers}
            shareDataUnregisteredUsersViewer={this.props.clientPersist.shareDataUnregisteredUsersViewer}
            cancel={() => this.setState({ modalVisible: false })}
            checkUser={this.props.checkUser}
            showAlert={this.props.showAlert}
            shareDataPoint={this.props.shareDataPoint}
            getSharerEmail={this.props.getSharerEmail}
            dataPoint={this.props.dataPoint}
            account={this.props.clientPersist.instance}
            role={this.props.clientPersist.roles}
          />
        )}
        <Button size="sm" onClick={this.onClick} id="share-btn">
          <i className="fa fa-share" aria-hidden="true" />
          {` ${getLocalization('share')}`}
        </Button>
      </>
    );
  }
}
