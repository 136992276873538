import { find, forEach, isArray } from 'lodash-es';
import { questionTypes } from 'Interfaces/Forms/QuestionInterface';
import { DataPoint, FileInterface } from '../../../Interfaces/DataPoint';
import FormUtils from './FormUtils';

/*
  When a diagram is selected for use, we use this function to create file objects from the diagram
  for each file type i.e pdf, image and zip.
*/
export const getFileAnswer = (diagrams, diagramId): FileInterface[] | undefined => {
  const keys = ['pdfFileId', 'zipFileId', 'imageFileId'];
  const mimes = { pdfFileId: 'aplication/pdf', zipFileId: 'application/zip', imageFileId: 'image/jpeg' };
  const diagram = find(diagrams, (d) => {
    return d.imageFileId === diagramId;
  });
  if (diagram) {
    const files = keys.map((key) => {
      if (diagram[key]) {
        const file: FileInterface = {
          fileName: diagram['identifier'],
          mimeType: mimes[key],
          questionId: 'BackgroundDiagram',
          id: diagram[key],
          url: diagram['url'],
        };
        if (key === 'imageFileId') {
          file.fileName = file.fileName + '-image';
        }
        return file;
      }
      return undefined;
    });
    /**
     *  see, we have to it like this instead of
     *  return files.filter( f => f !== undefined)
     *  because typescript does not understand that.
     */
    const diagramFiles: FileInterface[] = [];
    for (const f of files) {
      if (f) {
        diagramFiles.push(f);
      }
    }
    return diagramFiles;
  }
  return undefined;
};

/*
  When a diagram has been selected, we need to update the data model.
  This functions removes the existing diagram, if any and adds the newly selected as the current diagram.
*/
export const updateAnswerDiagrams = (dataPoint: DataPoint, diagram: FileInterface[]): DataPoint => {
  let newAnswer = dataPoint['files'] ? [...dataPoint['files']] : [];
  if (newAnswer.length > 0) {
    newAnswer = newAnswer.filter((file) => {
      return file.questionId !== 'BackgroundDiagram';
    });
  }
  newAnswer = newAnswer.concat(diagram);
  return newAnswer;
};

export const mimeMatch = (fileMime: string, accept: string): boolean => {
  const fileSplit = fileMime.split('/');
  const acceptSplit = accept.split(',');
  for (const mime of acceptSplit) {
    if (mime.includes(fileSplit[0])) {
      return true;
    }
  }
  return false; // fileSplit[0] === acceptSplit[0];
};

/**
 * This function returns the name of the diagram being used.
 */
export const getDiagram = (files) => {
  if (files) {
    const diagram = find(files, (file) => {
      return (
        file.questionId === 'BackgroundDiagram' &&
                file.mimeType &&
                file.id &&
                (file.mimeType.indexOf('image') !== -1 || file.mimeType.indexOf('pdf') !== -1)
      );
    });
    if (diagram && diagram.fileName) {
      return diagram.fileName.substring(0, 36);
    }
  }
  return undefined;
};

/**
 *  This function returns the Diagrams used in Subforms.
 *  For each subform instance, we check if it has it's own digrams.
 *  If it has we add the subform instance to the list of subform instances for the specific diagram.
 *  For each diagram, return the Subform POI instances.
 */
export const getSubformDiagrams = (dataPoint: DataPoint, formUtils: FormUtils) => {
  const diagrams = {};
  forEach(dataPoint, (value, key) => {
    if (isArray(value)) {
      const question = formUtils.getQuestion(key);
      if (
        key !== 'files' &&
        question &&
        (question.type === questionTypes.PART_OF_QUESTION || question.type === questionTypes.TASK_QUESTION)
      ) {
        for (const val of value) {
          const diagram = getDiagram(val['files']);
          if (diagram) {
            if (!diagrams[diagram]) {
              diagrams[diagram] = [];
            }
            diagrams[diagram].push(val);
          }
        }
      }
    }
  });
  return diagrams;
};
