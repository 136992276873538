import { Action } from 'typescript-fsa';
import { GALLERY } from '../actions/actionTypes';
import { GalleryMenuInterface } from '../Interfaces/GalleryMenuInterface';
import { FormInterface } from '../Interfaces/Forms/FormsInterface';
import { StateInterface } from '../Interfaces/StateInterface';
import ReducerBuilder from './ReducerBuilder';

const INITIAL_STATE: GalleryMenuInterface = {
  selectedForm: undefined,
};
export type PayloadType = FormInterface['ref'];

const selectForm = (state: GalleryMenuInterface, { payload }): GalleryMenuInterface => {
  return { ...state, selectedForm: payload };
};

const galleryMenuReducer = new ReducerBuilder<GalleryMenuInterface, Action<PayloadType>>()
  .setInitialState(INITIAL_STATE)
  .addReducer(GALLERY.SELECT_FORM, selectForm)
  .build();

export default galleryMenuReducer;

// Selectors
export const galleryMenuSelector = (state: StateInterface): GalleryMenuInterface => state.galleryMenu;
