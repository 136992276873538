import { PowerBiUrl } from 'Interfaces/PowerBiUrl';
import { POWERBI_URLS } from '../actions/actionTypes';

const powerBiUrls = (state: PowerBiUrl[] = [], action) => {
  switch (action.type) {
    case POWERBI_URLS.LOADED:
      if (action.powerBiUrls) {
        return Object.assign([], action.powerBiUrls);
      }
      return state;
    case POWERBI_URLS.NEW:
      if (action.powerBiUrl) {
        return state.concat(action.powerBiUrl);
      }
      return state;
    case POWERBI_URLS.DELETED:
      if (action.ids) {
        return state.filter((du) => action.ids.indexOf(du.id) === -1);
      }
      return state;
    default:
      return state; //
  }
};

export default powerBiUrls;
