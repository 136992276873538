import './LocationsContainer.scss';
import 'rc-tree-select/assets/index.css';
import * as React from 'react';
import { StateInterface } from 'Interfaces/StateInterface';
import { connect } from 'react-redux';
import TreeSelect, { SHOW_PARENT } from 'rc-tree-select';
import { Form } from 'react-bootstrap';
import { Parameters } from 'enzyme';
import bind from 'bind-decorator';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { ThunkDispatchAction } from '../../../actions';
import { LocationInterface, undefinedLocationKey } from '../../../Interfaces/LocationInterface';
import { locationsSelector } from '../../../reducers/locationsReducer';
import { getLocalization } from '../../../global/global';
import * as filtersMenuActions from '../../../actions/filtersMenuActions';
import { FiltersMenuInterface, filtersSelectedLocationType } from '../../../Interfaces/FiltersMenuInterface';
import {
  filtersMenuSelectedLocationsSelector,
  filtersMenuSettingsSelector
} from '../../../reducers/filtersMenuReducer';
import { selectedFormsSelector } from '../../../reducers/formsReducer';

interface IStateProps {
  areLocationsLoaded: boolean;
  locations: LocationInterface[];
  selectedLocations: FiltersMenuInterface['selectedLocations'];
  selectedForms: FormInterface[];
  panelHeight: number;
  clientPersist: ClientPersistInterface;
}

interface IActionProps {
  actions: {
    selectUnSelectLocations: (locations: filtersSelectedLocationType[]) => void;
    selectUnselectAllLocations: (...args: Parameters<typeof filtersMenuActions.selectUnselectAllLocations>) => void;
  };
}

interface IOwnState {
  locationsHierarchyTree: ILocationTreeNode[];
  searchValue: string;
  selectionOpen: boolean;
  treeExpandAll: boolean;
  treeExpandedKeys: string[];
  parentKeys: string[];
  areAllFormsSelected: boolean;
  locationPOICounts: { [key in string]: number; };
}

interface ILocationTreeNode {
  key: string;
  value: string;
  title: string | JSX.Element;
  parent: string;
  searchValue: string;
  children: ILocationTreeNode[];
}

const className = 'LocationsContainer';
export type LocationsContainerPropsInterface = IStateProps & IActionProps;

class LocationsContainerClass extends React.Component<LocationsContainerPropsInterface, IOwnState> {
  constructor(props) {
    super(props);
    this.state = {
      locationsHierarchyTree: [],
      parentKeys: [],
      locationPOICounts: {},
      searchValue: '',
      selectionOpen: true,
      treeExpandAll: false,
      treeExpandedKeys: [],
      areAllFormsSelected: false
    };
  }

  public componentDidMount(): void {
    const {locationsHierarchyTree, parentKeys, locationPOICounts} = this.generateTreeFromLocationsList();
    this.setState({locationsHierarchyTree, parentKeys, locationPOICounts});
  }

  public componentDidUpdate(prevProps: Readonly<LocationsContainerPropsInterface>): void {
    if (prevProps.locations !== this.props.locations
        || prevProps.selectedForms !== this.props.selectedForms
    ) {
      const {locationsHierarchyTree, parentKeys, locationPOICounts} = this.generateTreeFromLocationsList();
      this.setState({locationsHierarchyTree, parentKeys, locationPOICounts});
    }
  }

  public componentDidCatch(error, info) {
    console.error('ERROR in LocationsContainer =>', error, info);
  }

  private getChildren(parent, locationPOICounts) {
    const { locations } = this.props;
    const children: ILocationTreeNode[] = [];
    const titleClassNames = [`${className}-tree__title`];
    for (const node of locations) {
      if (node.parent === parent) {
        let classNames = [...titleClassNames];
        if (locationPOICounts
          && locationPOICounts[node.key]
          && locationPOICounts[node.key] > 0
        ) {
          classNames = [...classNames, `${className}-tree__title--hasPOIs`];
        }
        children.push({
          key: node.key,
          value: node.key,
          title: <span className={classNames.join(' ')}>{node.title}</span>,
          searchValue: node.title,
          parent: node.parent,
          children: this.getChildren(`${node.key}`, locationPOICounts)
        });
      }
    }
    return children;
  }

  private getAssignedLocationsTree(locationPOICounts) {
    const { clientPersist, locations } = this.props;
    const assigned = clientPersist.userLocations.split(',');

    const newList: ILocationTreeNode[] = [];
    const titleClassNames = [`${className}-tree__title`];
    for (const node of locations) {
      let classNames = [...titleClassNames];
      if (locationPOICounts
        && locationPOICounts[node.key]
        && locationPOICounts[node.key] > 0
      ) {
        classNames = [...classNames, `${className}-tree__title--hasPOIs`];
      }
      if (assigned.indexOf(`${node.key}`) > -1) {
        newList.push({
          key: node.key,
          value: node.key,
          title: <span className={classNames.join(' ')}>{node.title}</span>,
          searchValue: node.title,
          parent: node.parent,
          children: this.getChildren(`${node.key}`, locationPOICounts)
        });
      }
    }
    return newList;
  }

  private getParentKeys(nodes: ILocationTreeNode[]) {
    let parentKeys: IOwnState['parentKeys'] = [];
    for (const node of nodes) {
      if (node.parent !== '-1') {
        if (!parentKeys.includes(node.parent + '')) {
          parentKeys = [...parentKeys, node.parent + ''];
        }
        parentKeys = this.getParentKeys(node.children);
      }
    }
    return parentKeys;
  }

  private generateTreeFromLocationsList(): Pick<IOwnState,
  'locationsHierarchyTree' | 'parentKeys' | 'locationPOICounts'> {
    const { clientPersist } = this.props;
    const list = this.props.locations;
    const map = {};
    let newList: ILocationTreeNode[] = [];
    // parentKeys include all locations that are parents of some other location
    let parentKeys: IOwnState['parentKeys'] = [];
    const locationPOICounts = this.extractPOICounts();
    if (clientPersist.assignedLocationOnly) {
      const locationsHierarchyTree = this.getAssignedLocationsTree(locationPOICounts);
      parentKeys = this.getParentKeys(locationsHierarchyTree);
      return {parentKeys, locationsHierarchyTree, locationPOICounts};
    }

    for (let i = 0; i < list.length; i++) {
      let titleClassNames = [`${className}-tree__title`];
      map[list[i].key] = i; // initialize the map
      const node = list[i];
      // TODO This will work after answers are loaded for forms and
      //  'hierarchyCounts' attributes are set. Then locations will get bolder
      if (locationPOICounts
        && locationPOICounts[node.key]
        && locationPOICounts[node.key] > 0
      ) {
        titleClassNames = [...titleClassNames, `${className}-tree__title--hasPOIs`];
      }
      newList = [...newList, {
        key: node.key,
        value: node.key,
        title: <span className={titleClassNames.join(' ')}>{node.title}</span>,
        searchValue: node.title,
        parent: node.parent,
        children: []
      }];
    }

    for (const node of newList) {
      if (node.parent !== '-1') {
        // if you have dangling branches check that map[node.parentId] exists
        try {
          newList[map[node.parent]].children.push(node);
          if (!parentKeys.includes(node.parent + '')) {
            parentKeys = [...parentKeys, node.parent + ''];
          }
        } catch (e) {
          console.log(node.key, node.parent);
        }
      } else {
        if (!parentKeys.includes(node.key + '')) {
          parentKeys = [...parentKeys, node.key + ''];
        }
      }
    }
    const locationsHierarchyTree: IOwnState['locationsHierarchyTree'] =
    newList.filter((node) => node.parent === '-1');
    return {parentKeys, locationsHierarchyTree, locationPOICounts};
  }

  private extractPOICounts(): { [key in string]: number } {
    let locationPOICounts = {};
    if (this.props.selectedForms && this.props.selectedForms.length > 0) {
      const formHierarchyCounts = this.props.selectedForms
        .filter(form => form.hierarchyCounts && form.hierarchyCounts !== {})
        .map(form => form.hierarchyCounts) as { [key in string]: number }[];
      formHierarchyCounts.forEach(formHierarchy => {
        Object.keys(formHierarchy).forEach(locationKey => {
          if (locationPOICounts[locationKey]) {
            locationPOICounts = {
              ...locationPOICounts,
              [locationKey]: (formHierarchy[locationKey] + locationPOICounts[locationKey])
            };
          } else {
            locationPOICounts = {
              ...locationPOICounts,
              [locationKey]: formHierarchy[locationKey]
            };
          }
        });
      });
      if (locationPOICounts['-1']) {
        locationPOICounts = {
          ...locationPOICounts,
          [undefinedLocationKey]: locationPOICounts['-1']
        };
        delete locationPOICounts['-1'];
      }
    }
    return locationPOICounts;
  }

  @bind
  private onMultipleChange(multipleValues) {
    const selectedLocations = this.props.locations
      .filter((location) => multipleValues.includes(location.key))
      .map(location => ({key: location.key, title: location.title, level: location.level}));
    this.props.actions.selectUnSelectLocations(selectedLocations);
  }

  @bind
  private onSearch(value) {
    this.setState({searchValue: value});
  }

  @bind
  private onShowFullLocationHierarchy(treeExpandAll: boolean) {
    if (treeExpandAll) {
      this.setState({treeExpandAll, treeExpandedKeys: this.state.parentKeys});
    } else {
      this.setState({treeExpandAll, treeExpandedKeys: []});
    }
  }

  @bind
  private onTreeExpand(treeExpandedKeys) {
    if (this.state.treeExpandAll) {
      this.setState({
        treeExpandAll: false,
        treeExpandedKeys
      });
    } else {
      this.setState({treeExpandedKeys});
    }
  }

  @bind
  private onSelectUnselectAll(areAllFormsSelected: boolean) {
    this.setState({
      areAllFormsSelected
    });
    const task = areAllFormsSelected ? 'selectAll' : 'unSelectAll';
    this.props.actions.selectUnselectAllLocations(task);
  }

  private renderHeader(): JSX.Element {
    const selectedLocationsLength = this.props.selectedLocations.length;
    return (
      <div className={`${className}__header`}>
        <div className={`${className}__header__row`}>
          <Form.Check
            label={getLocalization('filterSelectLocations')}
            className={`${className}__header__checkbox`}
            checked={this.state.areAllFormsSelected}
            onChange={(event) => {
              this.onSelectUnselectAll(event.target['checked']);
            }}
          />
          {selectedLocationsLength > 0 && (
            <div
              className={`${className}__header__unSelectAll`}
              onClick={() => this.onSelectUnselectAll(false)}
            >
              {getLocalization('none')}
            </div>
          )}
        </div>
        <div className={`${className}__header__row`}>
          <Form.Check
            label={getLocalization('filterAllLocations')}
            className={`${className}__header__checkbox ${className}__header__expandAll`}
            checked={this.state.treeExpandAll}
            onChange={(event) => {
              this.onShowFullLocationHierarchy(event.target['checked']);
            }}
          />
        </div>
      </div>
    );
  }

  private renderBody(): JSX.Element {
    const {
      selectionOpen,
      locationsHierarchyTree,
      searchValue,
      treeExpandedKeys
    } = this.state;
    const {selectedLocations} = this.props;
    return (
      <div className={`${className}__body`}>
        <TreeSelect
          className={`${className}-tree`}
          dropdownClassName={`${className}-tree__dropdown`}
          dropdownStyle={{
            // panelHeight - __header ht - selection--multiple ht - buffer
            maxHeight: `${this.props.panelHeight - 40 - 25 - 40}px`
          }}
          open={selectionOpen}
          multiple
          allowClear
          treeCheckable
          showCheckedStrategy={SHOW_PARENT}
          // placeholder={<i>Search Locations</i>}
          // searchPlaceholder={'search'}
          showSearch={false}
          searchValue={searchValue}
          onSearch={this.onSearch}
          treeData={locationsHierarchyTree}
          onChange={this.onMultipleChange}
          value={selectedLocations.map(location => location.key)}
          treeNodeFilterProp={'searchValue'}
          onTreeExpand={this.onTreeExpand}
          treeExpandedKeys={treeExpandedKeys}
          maxTagCount={2}
          maxTagTextLength={10}
        />
      </div>
    );
  }

  public render(): JSX.Element | null {
    const { locations, clientPersist } = this.props;
    if (
      locations.length === 1 ||
      (clientPersist.readOwn && clientPersist.roles.indexOf('enumerator') !== -1) ||
      clientPersist.accessSharedDataOnly
    ) {
      return null;
    }
    return (
      <div className={`${className} col`}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  const locations = locationsSelector(state);
  return {
    areLocationsLoaded: locations.loaded,
    locations: locations.collection,
    selectedLocations: filtersMenuSelectedLocationsSelector(state),
    selectedForms: selectedFormsSelector(state),
    panelHeight: filtersMenuSettingsSelector(state).height,
    clientPersist: state.clientPersist
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchAction): IActionProps => {
  return {
    actions: {
      selectUnSelectLocations: (locations) => dispatch(filtersMenuActions.selectUnSelectLocations(locations)),
      selectUnselectAllLocations: (...args) => dispatch(filtersMenuActions.selectUnselectAllLocations(args[0]))
    }
  };
};

export const LocationsContainer = connect<
IStateProps,
IActionProps,
Record<string, never>,
StateInterface
>(mapStateToProps, mapDispatchToProps)(LocationsContainerClass);
