import * as React from 'react';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import { Dropdown, Form } from 'react-bootstrap';
import { ChartModel } from 'Interfaces/ChartInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { FormsInterface } from '../../Interfaces/Forms/FormsInterface';
import { ChartsMenu } from '../../Interfaces/ChartsMenu';
import { MODULES } from '../../Interfaces/ModuleSelectionInterface';
import { addNewChart, selectChart, unSelectChart } from '../../actions/chartsActions';
import NewChartButton from './NewChartButton';

interface StateProps {
  chartsMenu: ChartsMenu;
  forms: FormsInterface;
}

interface ActionProps {
  addNewChart: (chart: ChartModel) => void;
  selectChart: (chartId: string) => void;
  unSelectChart: (chartId: string) => void;
}

interface Props {
  button: JSX.Element;
  buttonInnerJSX: JSX.Element;
  onClick: () => void;
  classNames: string;
  selectedModule: string;
}

export type ChartButtonProps = ActionProps & StateProps & Props;

class ChartsButtonContainer extends React.Component<ChartButtonProps> {

  @bind
  private getCharts() {
    const { chartsMenu, forms } = this.props;
    const visibleCharts = forms.loaded ? chartsMenu.charts.map((chart) => {
      const form = forms.collection.find(f => f.ref === chart.formId || chart.formId.startsWith(f.ref));
      if (form) {
        return chart;
      }
      return undefined;
    }).filter(chart => chart !== undefined) : chartsMenu.charts;

    const items = visibleCharts.map((chart, index) => {
      return chart && (
        <Dropdown.Item
          as="div"
          eventKey={`${index}`}
          key={`chart-dropdown-item-${chart.id}`}
        >
          <Form.Check
            id={`chart-drop-down-checkbox-${chart.id}`}
            type={'checkbox'}
            label={chart.name}
            value={chart.id}
            checked={this.props.chartsMenu.selectedCharts.indexOf(`${chart.id}`) !== -1}
            onChange={this.onChartSelectChange}
          />
        </Dropdown.Item>
      );
    });
    return items;
  }

  @bind
  private onClick(e) {
    e.stopPropagation();
  }

  @bind
  private onChartSelectChange(e) {
    if (e.target.checked) {
      this.props.selectChart(e.target.value);
    } else {
      this.props.unSelectChart(e.target.value);
    }
  }

  public render(): JSX.Element {
    const { forms, addNewChart, selectChart } = this.props;
    return (
      <React.Fragment>
        <Dropdown
          id="chart-btn"
          onToggle={(isOpen, event, metadata) => {
            if (metadata.source === 'click' && this.props.selectedModule !== `${MODULES.CHARTS}`) {
              this.props.onClick();
            }
          }}
        >
          <Dropdown.Toggle id="chart-btn-toggle" variant="primary" size={'sm'} className={this.props.classNames}>
            {this.props.buttonInnerJSX}
          </Dropdown.Toggle>
          <Dropdown.Menu rootCloseEvent="click" onClick={this.onClick}>
            {this.getCharts()}
          </Dropdown.Menu>
        </Dropdown>
        <NewChartButton
          forms={forms.collection}
          addNewChart={addNewChart}
          selectChart={selectChart}
          selectedModule={this.props.selectedModule}
          onModuleSelectClick={this.props.onClick}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    chartsMenu: state.chartsMenu,
    forms: state.forms
  };
};

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    addNewChart: (chart) => dispatch(addNewChart(chart)),
    selectChart: (chartId) => dispatch(selectChart(chartId)),
    unSelectChart: (chartId) => dispatch(unSelectChart(chartId))
  };
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(ChartsButtonContainer);
