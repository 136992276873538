import { GalleryItemsInterface } from 'Interfaces/Answers/AnswersInterface';
import { GalleryFileInterface } from 'Interfaces/DataPoint';
import { GalleryMenuInterface } from 'Interfaces/GalleryMenuInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { createSelector } from 'reselect';
import { GALLERY } from '../actions/actionTypes';
import { galleryMenuSelector } from './galleryMenuReducer';

const INITIAL_STATE: GalleryItemsInterface = {};

export const galleryItemsSelector = (state: StateInterface): GalleryItemsInterface => state.galleryItems;

export const selectAnswersForGallerySelectedForm = createSelector<
StateInterface,
GalleryItemsInterface,
GalleryMenuInterface,
GalleryFileInterface[]
>([galleryItemsSelector, galleryMenuSelector], (galleryItems, { selectedForm }) => {
  if (!selectedForm || !galleryItems[selectedForm]) {
    return [];
  }
  return galleryItems[selectedForm];
});

const galleryItems = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GALLERY.LOADED:
      if (action.formId) {
        const formGallery = state[action.formId] || [];
        return { ...state, [action.formId]: formGallery.concat(action.galleryItems) };
      }
      return state;
    default:
      return state;
  }
};

export default galleryItems;
