import { ThunkDispatchAction } from 'actions';
import { StateInterface } from 'Interfaces/StateInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { DocumentVersionInterface } from 'Interfaces/DocumentInterface';
import { DocumentComment } from 'Interfaces/Documents/DocumentComments';
import { AnyAction } from 'redux';
import { getRequestConfig } from './index';
import { request } from './request';

export const doGetDataPointDocumentVersions = (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  id: string,
  format: string,
  signal: AbortSignal
) => {
  const headers = getRequestConfig();
  return request(`/json/app/document/datapoint/${id}/${format}`, {...headers, signal }, dispatch, getState);
};

export const doApproveVersion = (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  id: number,
  signal: AbortSignal
) => {
  const headers = getRequestConfig();
  return request(`/json/app/document/version/approve/${id}`, {...headers, signal }, dispatch, getState);
};

export const doGetDocuments = (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  signal: AbortSignal
) => {
  const headers = getRequestConfig();
  return request(`/json/app/document`, {...headers, signal }, dispatch, getState);
};

export const doGetDocumentFolders = (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  signal: AbortSignal
) => {
  const headers = getRequestConfig();
  return request(`/json/app/document/folders`, {...headers, signal }, dispatch, getState);
};

export const doSaveDocument = (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  config: JSONInterface,
  signal: AbortSignal
) => {
  const headers = getRequestConfig();
  return request(`/json/app/document`, {...headers, ...config, signal }, dispatch, getState);
};

export const doGetDocumentFiles = (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  signal: AbortSignal
) => {
  const headers = getRequestConfig();
  return request(`/json/app/document/files`, {...headers, signal }, dispatch, getState);
};

export const doGetDocumentActivity = (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  signal: AbortSignal
) => {
  const headers = getRequestConfig();
  return request(`/json/app/document/activity`, {...headers, signal }, dispatch, getState);
};

export const doGetDocumentVersions = (
  id: number,
  signal: AbortSignal
): Promise<DocumentVersionInterface[]> => {
  const headers = getRequestConfig();
  return fetch(`/json/app/document/versions/${id}`, {...headers, signal })
    .then(res => res.json()).then(json => json);
};

export const doDeleteDocument = (
  dispatch: ThunkDispatchAction | AnyAction,
  getState: () => StateInterface,
  config: JSONInterface,
  id: number
) => {
  const headers = getRequestConfig();
  return request(`/json/app/document/delete/${id}`, {...headers, ...config }, dispatch, getState);
};

export const doDeleteDocumentFile = (
  dispatch: ThunkDispatchAction | AnyAction,
  getState: () => StateInterface,
  config: JSONInterface,
  id: number
) => {
  const headers = getRequestConfig();
  return request(`/json/app/document/file/${id}`, {...headers, ...config }, dispatch, getState);
};

export const doCreateFolder = (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
  config: JSONInterface,
  signal: AbortSignal
) => {
  const headers = getRequestConfig();
  return request(`/json/app/document/folder`, {...headers, ...config, signal }, dispatch, getState);
};

export const doGetDocumentFolderPermissions = (
  dispatch: ThunkDispatchAction,
  getState: () => StateInterface,
) => {
  const headers = getRequestConfig();
  return request(`/json/app/document/folder/permission`, {...headers }, dispatch, getState);
};

export const doGetComments = (id: number) => {
  const config = getRequestConfig();
  return fetch(`/json/app/document/comments/${id}`, {
    ...config
  });
};

export const doSaveComment = (comment: DocumentComment) => {
  const config = getRequestConfig();
  return fetch(`/json/app/document/comment`,
    {...config, method: 'POST', body: JSON.stringify(comment)}
  ).then(res => res.json());
};
