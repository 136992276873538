import '../styles/UserQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import uuid from 'uuid/v4';
import { forEach, isArray } from 'lodash-es';
import { Button } from 'react-bootstrap';
import { getLocalization } from '../../../global/global';
import { DataPoint, FileInterface } from '../../../Interfaces/DataPoint';
import { SingleInstanceComponentClass } from '../SingleInstanceComponent';

interface Props {
  dataPoint: DataPoint;
  singleInstance: SingleInstanceComponentClass;
}

export default class CopyButton extends React.Component <Props> {

  constructor(props) {
    super(props);
  }

  @bind
  private clearChildDataPoints(dataPoint: DataPoint | FileInterface) {
    forEach(dataPoint, (value) => {
      if (isArray(value)) {
        for (const val of value) {
          if (val['id'] && !val['fileName']) { // delete id but if this is a file, do not delete id.
            val['id'] = uuid();
          }
          if (val['row_id']) {
            delete val['row_id'];
          }
          if (val['rowId']) {
            delete val['rowId'];
          }
          this.clearChildDataPoints(val);
        }
      }
    });
  }

  @bind
  private onClick() {
    const newDataPoint = JSON.parse(JSON.stringify(this.props.dataPoint));
    delete newDataPoint['id'];
    delete newDataPoint['row_id'];
    delete newDataPoint['_id'];
    delete newDataPoint['scheduleStatus'];
    delete newDataPoint['scheduleDate'];
    newDataPoint['Name'] = `${newDataPoint['Name']} copy`;
    this.clearChildDataPoints(newDataPoint);
    this.props.singleInstance.copyPoi(newDataPoint);
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        <Button size="sm" onClick={this.onClick} id="copy-btn">
          <i className="fa fa-files-o" aria-hidden="true" />
          {` ${getLocalization('copy')}`}
        </Button>
      </React.Fragment>
    );
  }
}
