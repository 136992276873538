import { toast } from 'react-toastify';
import { DataPoint } from 'Interfaces/DataPoint';
import { MODULES, SingleInstanceInterface, SingleInstanceMessages } from '../Interfaces/ModuleSelectionInterface';
import { StateInterface } from '../Interfaces/StateInterface';
import { getLocalization } from '../global/global';
import { MODULE_SELECTION } from './actionTypes';
import { actionCreator } from './index';
import { toggleFilterMenu } from './filtersMenuActions';

export const toggleSelectedModule = actionCreator<MODULES>(MODULE_SELECTION.TOGGLE);

export const setSingleInstanceState = actionCreator<SingleInstanceInterface>(MODULE_SELECTION.SET_SINGLE_INSTANCE);

export const unsetSingleInstanceState = actionCreator(MODULE_SELECTION.UNSET_SINGLE_INSTANCE);

export const updateOpenedDataPointAction = actionCreator<DataPoint | null>(MODULE_SELECTION.UPDATE_OPENED_DATAPOINT);

export const setSingleInstance = (singleInstance: SingleInstanceInterface) => {
  return (dispatch, getState: () => StateInterface) => {
    dispatch(setSingleInstanceState(singleInstance));
    if (getState().filtersMenu.open) {
      dispatch(toggleFilterMenu(false));
    }
  };
};

export const setSingleInstanceMessage = (message: SingleInstanceMessages) => {
  return (dispatch, getState: () => StateInterface) => {
    const singleInstance = getState().moduleSelection.singleInstance;
    if (singleInstance) {
      const msg = singleInstance.message || {};
      const newSingleInstanceMessages: SingleInstanceMessages = {
        editMessage: msg.editMessage || message.editMessage,
        sameUserMessage: msg.sameUserMessage || message.sameUserMessage,
      };
      dispatch(setSingleInstance({ ...singleInstance, message: newSingleInstanceMessages }));
    }
  };
};

export const setSingleInstanceWithHistory = (singleInstance: SingleInstanceInterface) => {
  return (dispatch, getState: () => StateInterface) => {
    // const {model: {ref: formRef}, dataPoint, newAnswer} = singleInstance;
    dispatch(setSingleInstanceState(singleInstance));
    // history.push(`#${formRef}/${dataPoint.id}/${dataPoint.row_id}/${newAnswer}`);
    if (getState().filtersMenu.open) {
      dispatch(toggleFilterMenu(false));
    }
  };
};

export const showDataPointUpdateAlert = (id: string) => {
  return (dispatch, getState: () => StateInterface) => {
    const singleInstance = getState().moduleSelection.singleInstance;
    if (singleInstance && singleInstance.dataPoint.id === id) {
      toast(getLocalization('updatedDatapoint'), {
        type: toast.TYPE.WARNING,
        autoClose: false,
        closeButton: true,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
  };
};
