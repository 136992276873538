export const getTransactionCost = (transactions: string) => {
  switch (transactions) {
    case '500':
      return 500;
    case '1000':
      return 800;
    case '2500':
      return 1750;
    case '5000':
      return 3000;
    case '10000':
      return 4500;
    default:
      return 0;
  }
};
