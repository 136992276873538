import * as React from 'react';
import bind from 'bind-decorator';
import { Button, Modal } from 'react-bootstrap';

interface IExternalProps {
  visible: boolean;
  cancel?: () => void;
  body: JSX.Element;
  title?: string;
  onConfirm: () => void;
  cancelText?: string;
  confirmText: string;
  dialogClassName?: string;
  extraButtons?: JSX.Element[];
}

interface State {
  isOpened: boolean;
  showBody: boolean;
}

export default class GenericModal extends React.Component<IExternalProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpened : props.visible,
      showBody: false
    };
  }

  @bind
  private onClose() {
    this.setState({
      isOpened: false,
    });
    if (this.props.cancel) {
      this.props.cancel();
    }
  }

  @bind
  private onConfirm() {
    this.setState({
      isOpened: false,
    });
    this.props.onConfirm();
  }

  public static getDerivedStateFromProps(props, state) {
    if (props.visible === true && !state.isOpened) {
      return { isOpened : props.visible };
    } else if (!props.visible) {
      return { isOpened : props.visible };
    }
    return null;
  }

  @bind
  private onExit() {
    this.setState({ showBody: false });
  }

  @bind
  private onEntered() {
    this.setState({ showBody: true });
  }

  public render() {
    const dialogClassName = this.props.dialogClassName ? this.props.dialogClassName : '';
    const cancelButton =  this.props.cancel ?
      (
        <Button size="sm" variant="primary" onClick={this.onClose} id={'generic-modal-negative-btn'}>
          {this.props.cancelText}
        </Button>
      ) : null;
    return (
      <Modal
        size={dialogClassName !== '' ? 'lg' : undefined}
        show={this.state.isOpened}
        onHide={this.onClose}
        backdrop="static"
        backdropClassName={`backdrop-op-1`}
        dialogClassName={dialogClassName}
        onExit={this.onExit}
        onEntered={this.onEntered}
      >
        {this.props.title ? (
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
        ) : null}
        <Modal.Body>
          {this.state.showBody ? this.props.body : null}
        </Modal.Body>
        <Modal.Footer>
          {cancelButton}
          {this.props.extraButtons}
          <Button
            size="sm"
            onClick={this.onConfirm}
            id={'generic-modal-positive-btn'}
          >
            {this.props.confirmText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
