import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { StateInterface } from '../../Interfaces/StateInterface';
import { ChartModel } from '../../Interfaces/ChartInterface';
import { loadBarChart } from '../../actions/chartsActions';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { SingleInstanceChart } from './SingleInstanceChart';

interface Props {
  formId: string;
  rowId: number;
}

interface StateProps {
  charts: ChartModel[];
  forms: FormInterface[];
}

interface ActionProps {
  loadBarChart: (chartModel: ChartModel)  => Promise<Response>;
}

type SingleInstanceChartComponentProps = StateProps & ActionProps & Props;

export const SingleInstanceChartComponent = (props: SingleInstanceChartComponentProps) => {

  return (
    <div>
      {props.charts.map((chart) => {
        const form = props.forms.find((f) => f.ref === chart.formId || chart.formId.startsWith(f.ref));
        return (
          <SingleInstanceChart
            key={`single-instance-chart-${chart.id}`}
            chartModel={chart}
            forms={props.forms}
            form={form || props.forms[0]}
            loadBarChart={props.loadBarChart}
            rowId={props.rowId}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: StateInterface, props: Props): StateProps => {
  return {
    charts: state.chartsMenu.charts.filter(chart => {
      return chart.formId === props.formId &&
        state.chartsMenu.selectedSingleInstanceCharts.indexOf(`${chart.id}`) !== -1;
    }),
    forms: state.forms.collection,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps =>  {
  return {
    loadBarChart: (chartModel) => {
      return dispatch(loadBarChart(chartModel));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleInstanceChartComponent);
