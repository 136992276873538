import * as React from 'react';
import { connect } from 'react-redux';
import Select from 'react-dropdown-select';
import { DocumentVersionInterface } from 'Interfaces/DocumentInterface';
import { getLocalization } from 'global/global';
import { Button, Form, Table } from 'react-bootstrap';
import { Users } from 'Interfaces/User';
import { FormsInterface } from 'Interfaces/Forms/FormsInterface';
import { locationsSelector } from 'reducers/locationsReducer';
import { StateInterface } from 'Interfaces/StateInterface';
import { activeUsersSelector } from 'reducers/users';
import { formsSelector } from 'reducers/formsReducer';
import { LocationsInterface } from 'Interfaces/LocationInterface';
import { formatDisplayDate } from 'utils/utils';
import { approveVersion } from 'actions/documentActions';
import { JSONInterface } from 'Interfaces/JsonInterface';


interface Props {
  versions: DocumentVersionInterface[];
  version?: DocumentVersionInterface;
  validate?: boolean;
  newVersion?: () => void;
  updateVersion: (version: DocumentVersionInterface) => void;
  reloadVersions: () => void;
}

interface StateProps {
  users: Users;
  forms: FormsInterface;
  locations: LocationsInterface;
}

interface ActionProps {
  approveVersion: (id: number, signal: AbortSignal) => Promise<JSONInterface>;
}

function DocumentDetails(props: Props & StateProps & ActionProps) {
  const { versions, version } = props;

  const abortController = React.useRef<AbortController>(new AbortController());
  const getUser = React.useCallback((id) => {
    return props.users.find(u => u.id === id);
  }, []);

  const selectedUser = React.useCallback((id) => {
    return props.users.filter(u => u.id === id);
  }, [document]);

  const approveVersion = async (id: number) => {
    const approved = await props.approveVersion(id, abortController.current.signal);
    console.log(approved);
    props.reloadVersions();
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>{getLocalization('version')}</th>
            <th>{getLocalization('editedBy')}</th>
            <th>{getLocalization('editDate')}</th>
            <th>{getLocalization('changeLog')}</th>
            <th>{getLocalization('approvedby')}</th>
            <th>{getLocalization('approveddate')}</th>
          </tr>
        </thead>
        <tbody>
          {versions.map((version) => {
            return (
              <tr key={`doc-$${version.id}`}>
                <td>{version?.version}</td>
                <td>{getUser(version?.editor)?.name}</td>
                <td>{formatDisplayDate(new Date(version?.editDate))}</td>
                <td>{version?.changeLog}</td>
                <td>{version.approvedBy === 0 ? (
                  <Button
                    size="sm"
                    onClick={() => version.id ? void approveVersion(version.id) : null}
                  >
                    {'Approve'}
                  </Button>
                ) : getUser(version?.approvedBy)?.name}</td>
                <td>{version?.approvalDate ? formatDisplayDate(new Date(version.approvalDate)) : ''}</td>
              </tr>
            );
          })}
          {version && (
            <tr>
              <td>
                <Form.Control
                  type="text"
                  size="sm"
                  value={version.version}
                  isInvalid={props.validate && !version.version}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    props.updateVersion({...version, 'version': e.target.value})}
                />
              </td>
              <td>
                <Select
                  multi={false}
                  options={props.users}
                  onChange={(u) => props.updateVersion({...version, editor: u.length === 1 ? u[0].id : ''})}
                  values={selectedUser(version?.editor)}
                  labelField='name'
                  valueField='id'
                  searchBy='name'
                  searchable
                  clearable
                  placeholder={''}
                  create={false}
                />
              </td>
              <td>
                <Form.Control
                  type="date"
                  size="sm"
                  value={version?.editDate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    props.updateVersion({...version, 'editDate': e.target.value})}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  size="sm"
                  value={version?.changeLog}
                  isInvalid={props.validate && !version.changeLog}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    props.updateVersion({...version, 'changeLog': e.target.value})}
                />
              </td>
              <td />
              <td />
            </tr>
          )}
        </tbody>
      </Table>
      {props.newVersion && !version && (
        <Button
          size="sm"
          onClick={props.newVersion}
        >
          <i className="fa fa-plus" />
          <span>{getLocalization('newVersion')}</span>
        </Button>
      )}
    </>
  );
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    users: activeUsersSelector(state),
    forms: formsSelector(state),
    locations: locationsSelector(state)
  };
};

const mapActionsToProps = (dispatch): ActionProps => {
  return {
    approveVersion: (id: number, signal: AbortSignal) => {
      return dispatch(approveVersion(id, signal));
    }
  };
};

export default connect(mapStateToProps, mapActionsToProps)(DocumentDetails);
