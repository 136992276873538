import * as React from 'react';
import { connect } from 'react-redux';
import LocationHierarchyComponent from '../elements/LocationHierarchyComponent';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { LocationInterface, Locations } from '../../../Interfaces/LocationInterface';
import FormUtils from '../utils/FormUtils';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { addNewLocation } from '../../../actions/locationsActions';

interface Props {
  dataPoint: DataPoint;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  labels?: string[];
  edit: boolean;
}

export interface StateProps {
  locationHierarchy: Locations;
  clientPersist: ClientPersistInterface;
}

export interface ActionProps {
  addNewLocation: (locations: Pick<LocationInterface, 'title' | 'level' | 'parent' | 'identifier'>[]) => void;
}

export type LHPropsInterface = Props & StateProps & ActionProps;

const LocationHierarchyContainer = (props: LHPropsInterface) => {
  return (
    <LocationHierarchyComponent {...props} />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    locationHierarchy: state.locations.collection,
    clientPersist: state.clientPersist
  };
};

const mapActionToProps = (dispatch) => {
  return {
    addNewLocation: (locations: Pick<LocationInterface, 'title' | 'level' | 'parent' | 'identifier'>[]) => {
      dispatch(addNewLocation(locations));
    }
  };
};

export default connect(mapStateToProps, mapActionToProps)(LocationHierarchyContainer);
