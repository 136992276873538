import { globalWindow } from "global/global";
import { formatDate } from "utils/utils";

export interface DocumentInterface {
  id?: number;
  name: string;
  version: string;
  comments: string;
  description: string;
  creationDate: number | Date;
  language: string;
  files: DocumentFileInterface[];
  subject: string;
  createdBy: string | number;
  editedBy: string | number;
  format: string;
  form: string;
  locations: string;
  dataPointId: string;
  groupId: number;
  folderId?: number;
  documentIdentifier?: string;
}

export interface DocumentFileInterface {
  name: string;
  fileId: number;
  documentId?: number;
  documentIdentifier?: string;
  id?: number | string;
}

export interface DocumentVersionInterface {
  version: string;
  editor: string | number;
  editDate: string;
  changeLog: string;
  id?: number;
  documentId?: number;
  approvedBy?: number;
  approvalDate?: string;
}

export interface DocumentsInterface {
  loaded: boolean;
  collection: DocumentInterface[];
}

export interface DocumentFilesInterface {
  loaded: boolean;
  collection: DocumentFileInterface[];
}

export interface DocumentFolder {
  id?: number;
  name: string;
  groupId: number;
  parentId?: number;
}

export type DocumentFolders = DocumentFolder[];

export interface DocumentFolderInterface {
  loaded: boolean;
  collection: DocumentFolders;
}


export interface DocumentActivity {
  id: number;
  documentId: number;
  documentIdentifier: string;
  userId: number;
  fileId?: number;
  folderId?: number;
  action: string;
  date: number;
  version: string;
}

export type DocumentActivities = DocumentActivity[];

export interface DocumentActivityInterface {
  loaded: boolean;
  collection: DocumentActivities;
}

export const INITIAL_DOCUMENT: DocumentInterface = {
  name: '',
  version: '',
  comments: '',
  description: '',
  creationDate: new Date(),
  language: globalWindow.lang,
  files: [],
  subject: '',
  createdBy: globalWindow.userID,
  editedBy: globalWindow.userID,
  format: '',
  form: '',
  locations: '',
  dataPointId: '',
  groupId: Number(globalWindow.groupID)
};

export const INITIAL_VERSION: DocumentVersionInterface = {
  version: '',
  editor: globalWindow.userID,
  editDate: formatDate(new Date()),
  changeLog: ''
};
