import './ModuleSelectionContainer.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import bind from 'bind-decorator';
import ReactGA from 'react-ga';
import { AlertInterface } from 'Interfaces/AlertInterface';
import { toggleFilterMenu } from '../../actions/filtersMenuActions';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { filtersMenuOpenSelector, filtersMenuSelector } from '../../reducers/filtersMenuReducer';
import { showAlert, ThunkDispatchAction } from '../../actions';
import { StateInterface } from '../../Interfaces/StateInterface';
import { MODULES, ModuleSelectionInterface } from '../../Interfaces/ModuleSelectionInterface';
import { moduleSelectionSelector } from '../../reducers/moduleSelectionReducer';
import { getLocalization, globalWindow } from '../../global/global';
import { toggleSelectedModule } from '../../actions/moduleSelectionActions';
import {
  formsForFiltersSelector, formsWithGPSSelector, formsWithScheduleStatusSelector, taskFormsSelector
} from '../../reducers/formsReducer';
import ActionMenusContainer from '../ActionMenus/ActionMenusContainer';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import GenericModal from '../Modals/GenericModal';
import GalleryButton from './GalleryButton';
import ChartsButtonContainer from './ChartsButtonContainer';
import TableButtonContainer from './TableButtonContainer';
import DashBoardButton from './DashBoardButton';

interface IStateProps {
  filtersMenu: FiltersMenuInterface;
  filtersMenuOpen: FiltersMenuInterface['open'];
  moduleSelection: ModuleSelectionInterface;
  gpsForms: FormInterface[];
  taskForms: FormInterface[];
  singleInstanceVisible: boolean;
  clientPersist: ClientPersistInterface;
  feedCount: number;
  forms: FormInterface[];
  hasMySchedule: boolean;
  hasFormsWithScheduleStatus: boolean;
}

interface IActionProps {
  actions: {
    toggleFilterMenu: (open: boolean) => void;
    toggleSelectedModule: (module: MODULES) => void;
    showAlert: (alert: AlertInterface) => void;
  };
}

interface IState {
  showToast: boolean;
  showSessionAlert: boolean;
}

export type ModuleSelectionPropsInterface = IStateProps & IActionProps;

const className = 'ModuleSelectionContainer';

export class ModuleSelectionContainerClass extends React.Component<ModuleSelectionPropsInterface, IState> {

  public constructor(props: ModuleSelectionPropsInterface) {
    super(props);
    this.state = {
      showToast: false,
      showSessionAlert: false
    };
  }

  @bind
  private toggleFilter() {
    this.props.actions.toggleFilterMenu(!this.props.filtersMenuOpen);
  }

  @bind
  private toggleSelectedModule(module: MODULES) {
    const { clientPersist, hasFormsWithScheduleStatus } = this.props;
    if (clientPersist.instance === 'poimapper' && (module === MODULES.SHARED_DATA || module === MODULES.SCHEDULE)) {
      return () => {
        this.props.actions.showAlert({
          visible: true,
          message: getLocalization('upgradeAlert')
        });
      };
    }
    if (module === MODULES.SCHEDULE && !hasFormsWithScheduleStatus) {
      return () => {
        this.props.actions.showAlert({
          visible: true,
          message: getLocalization('noFormsWithScheduleStatusAlert')
        });
      };
    }
    if (module === MODULES.SHARED_DATA && !clientPersist.shareDataUnregisteredUsers) {
      return () => this.setState({ showToast: true });
    } else {
      return () => {
        if (clientPersist.payingCustomer === 'yes') {
          ReactGA.event({
            category: 'module-selection',
            action: `${module}`,
            label: `${clientPersist.instance}`
          });
        }
        this.props.actions.toggleSelectedModule(module);
      };
    }
  }

  private renderModuleButton(module: MODULES): JSX.Element {
    const classNames = [`${className}__btn ${className}__modules`];
    let buttonInnerJSX: JSX.Element;
    const selectedModule = this.props.moduleSelection.selected;
    if (selectedModule === module) {
      classNames.push(`${className}__btn--active`);
    }
    switch (module) {
      case MODULES.MAP: {
        if (selectedModule === MODULES.MAP_AND_TABLE) {
          classNames.push(`${className}__btn--active`);
        }
        buttonInnerJSX = (
          <React.Fragment>
            <i className="fa fa-map-o"/>
            <span>{getLocalization('mapModuleBtn')}</span>
          </React.Fragment>
        );
        break;
      }
      case MODULES.TABLE: {
        if (selectedModule === MODULES.MAP_AND_TABLE) {
          classNames.push(`${className}__btn--active`);
        }
        buttonInnerJSX = (
          <React.Fragment>
            <i className="fa fa-table"/>
            <span>{getLocalization('tableModuleBtn')}</span>
          </React.Fragment>
        );
        break;
      }
      case MODULES.SCHEDULE: {
        if (selectedModule === MODULES.SCHEDULE) {
          classNames.push(`${className}__btn--active`);
        }
        buttonInnerJSX = (
          <React.Fragment>
            <i className="fa fa-calendar"/>
            <span>{getLocalization('schedule')}</span>
          </React.Fragment>
        );
        break;
      }
      case MODULES.GALLERY: {
        buttonInnerJSX = (
          <React.Fragment>
            <i className="fa fa-picture-o"/>
            <span>{getLocalization('galleryModuleBtn')}</span>
          </React.Fragment>
        );
        break;
      }
      case MODULES.CHARTS: {
        buttonInnerJSX = (
          <React.Fragment>
            <i className="fa fa-pie-chart"/>
            <span>{getLocalization('chartModuleBtn')}</span>
          </React.Fragment>
        );
        break;
      }
      case MODULES.TASKS: {
        buttonInnerJSX = (
          <React.Fragment>
            <i className="fa fa-tasks"/>
            <span>{getLocalization('tasksModuleBtn')}</span>
          </React.Fragment>
        );
        break;
      }
      case MODULES.SHARED_DATA: {
        buttonInnerJSX = (
          <React.Fragment>
            <i className="fa fa-share-alt" aria-hidden="true" />
            <span>{getLocalization('sharedData')}</span>
          </React.Fragment>
        );
        break;
      }
      case MODULES.MY_SCHEDULE: {
        buttonInnerJSX = (
          <React.Fragment>
            <i className="fa fa-calendar-minus-o" aria-hidden="true" />
            <span>{getLocalization('mySchedule')}</span>
          </React.Fragment>
        );
        break;
      }
      case MODULES.DASHBOARD: {
        buttonInnerJSX = (
          <React.Fragment>
            <i className="fa fa-tachometer" aria-hidden="true" />
            <span>{getLocalization('dashboard')}</span>
          </React.Fragment>
        );
        break;
      }
      default: {
        buttonInnerJSX = (
          <React.Fragment>
            <i className="fa fa-list"/>
            <span>{getLocalization('feedModuleBtn')}</span>
          </React.Fragment>
        );
        break;
      }
    }
    if (module === MODULES.GALLERY) {
      return (
        <GalleryButton
          button={(
            <Button
              className={classNames.join(' ')}
              onClick={this.toggleSelectedModule(module)}
              size={'sm'}
              id={'gallery-module-btn'}
            >
              {buttonInnerJSX}
            </Button>
          )}
        />
      );
    } else if (module === MODULES.CHARTS) {
      return (
        <ChartsButtonContainer
          selectedModule={this.props.moduleSelection.selected}
          onClick={this.toggleSelectedModule(module)}
          classNames={classNames.join(' ')}
          buttonInnerJSX={buttonInnerJSX}
          button={(
            <Button
              className={classNames.join(' ')}
              onClick={this.toggleSelectedModule(module)}
              size={'sm'}
              id={'charts-module-btn'}
            >
              {buttonInnerJSX}
            </Button>
          )}
        />
      );
    } else if (module === MODULES.TABLE) {
      return (
        <TableButtonContainer
          selectedModule={this.props.moduleSelection.selected}
          onClick={this.toggleSelectedModule(module)}
          classNames={classNames.join(' ')}
          buttonInnerJSX={buttonInnerJSX}
        />
      );
    } else if (module === MODULES.DASHBOARD) {
      return (
        <DashBoardButton
          buttonInnerJSX={buttonInnerJSX}
          selectedModule={selectedModule}
          classNames={classNames.join(' ')}
          onClick={this.toggleSelectedModule(module)}
        />
      );
    }
    return (
      <Button
        className={classNames.join(' ')}
        onClick={this.toggleSelectedModule(module)}
        size={'sm'}
        id={`${module}-module-btn`}
      >
        {buttonInnerJSX}
      </Button>
    );
  }

  private renderFilterToggleBtn(): JSX.Element {
    const { filtersMenu } = this.props;
    let classNames = [`${className}__btn`, `${className}__filter`];
    if (this.props.filtersMenuOpen) {
      classNames = [...classNames, `${className}__btn--active`];
    }
    const filtersSelected = filtersMenu.selectedForms.length > 0 || filtersMenu.selectedLocations.length > 0 ||
      filtersMenu.selectedUsers.length > 0 || filtersMenu.selectedDates.from || filtersMenu.selectedDates.to;
    return (
      <Button
        className={classNames.join(' ')}
        onClick={this.toggleFilter}
        size={'sm'}
        id={'filter-toggle-btn'}
      >
        <i className="fa fa-filter"/>
        {filtersSelected && (
          <span title={getLocalization('activeFilters')}>
            <i className="fa fa-circle filters-indicator" />
          </span>
        )}
      </Button>
    );
  }

  @bind
  private getToast() {
    const { clientPersist } = this.props;
    const msgKey = clientPersist.roles.includes('admin') ? 'sharedDataAdminInfo' : 'sharedDataUserInfo';
    return (
      <GenericModal
        visible={this.state.showToast}
        title={getLocalization('info')}
        body={<p>{getLocalization(msgKey)}</p>}
        confirmText={getLocalization('ok')}
        onConfirm={() => this.setState({ showToast: false })}
      />
    );
  }

  public render(): JSX.Element | null {
    if (this.props.singleInstanceVisible) {
      return null;
    }
    const { clientPersist } = this.props;
    const {roles} = clientPersist;
    const showBtns = roles.indexOf('enumerator') === -1 || (!globalWindow.mobile && roles.indexOf('enumerator') !== -1);
    const hideFilters = !showBtns && this.props.forms.length === 1;
    const showScheduleView =
    Boolean(roles) &&
        !(
          roles.includes('enumerator') ||
            roles.includes('viewer')
        );
    return (
      <div
        className={`${className} row`}
      >
        {this.getToast()}
        {!hideFilters && (
          <ButtonGroup>
            {this.renderFilterToggleBtn()}
          </ButtonGroup>
        )}
        {showBtns && (
          <React.Fragment>
            <ButtonGroup>
              {this.renderModuleButton(MODULES.FEED)}
            </ButtonGroup>
            {this.props.gpsForms.length > 0 && (
              <ButtonGroup>
                {this.renderModuleButton(MODULES.MAP)}
              </ButtonGroup>
            )}
            {!clientPersist.accessSharedDataOnly && (
              <ButtonGroup>
                {this.renderModuleButton(MODULES.TABLE)}
              </ButtonGroup>
            )}
            {showScheduleView && (
              <ButtonGroup>
                {this.renderModuleButton(MODULES.SCHEDULE)}
              </ButtonGroup>
            )}
            {this.renderModuleButton(MODULES.GALLERY)}
            {roles.indexOf('enumerator') === -1 && (
              <>
                <ButtonGroup>
                  {this.renderModuleButton(MODULES.CHARTS)}
                </ButtonGroup>
                <ButtonGroup>
                  {this.renderModuleButton(MODULES.DASHBOARD)}
                </ButtonGroup>
              </>
            )}
          </React.Fragment>
        )}
        {this.props.taskForms.length > 0 && !this.props.clientPersist.accessSharedDataOnly && (
          <ButtonGroup>
            {this.renderModuleButton(MODULES.TASKS)}
          </ButtonGroup>
        )}
        {(roles.includes('admin') || roles.includes('modeler') || roles.includes('ordinary')) && (
          <ButtonGroup>
            {this.renderModuleButton(MODULES.SHARED_DATA)}
          </ButtonGroup>
        )}
        {this.props.hasMySchedule && this.props.hasFormsWithScheduleStatus && (
          <ButtonGroup>
            {this.renderModuleButton(MODULES.MY_SCHEDULE)}
          </ButtonGroup>
        )}
        <ActionMenusContainer />
      </div>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    filtersMenu: filtersMenuSelector(state),
    filtersMenuOpen: filtersMenuOpenSelector(state),
    moduleSelection: moduleSelectionSelector(state),
    gpsForms: formsWithGPSSelector(state),
    taskForms: taskFormsSelector(state),
    forms: formsForFiltersSelector(state),
    singleInstanceVisible: !!state.moduleSelection.singleInstance,
    clientPersist: state.clientPersist,
    feedCount: state.feed ? state.feed.feed.length : 0,
    hasMySchedule: state.mySchedule.schedules.length > 0,
    hasFormsWithScheduleStatus: formsWithScheduleStatusSelector(state).length > 0
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatchAction): IActionProps => {
  return {
    actions: {
      toggleFilterMenu: (open: boolean) => {
        dispatch(toggleFilterMenu(open));
      },
      toggleSelectedModule: (module) => dispatch(toggleSelectedModule(module)),
      showAlert: (alert) => dispatch(showAlert(alert))
    }
  };
};

export const ModuleSelectionContainer = connect<IStateProps, IActionProps, StateInterface>(
  mapStateToProps, mapDispatchToProps
)(ModuleSelectionContainerClass);
