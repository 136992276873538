import * as React from 'react';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import ReactGA from 'react-ga';
import { SingleInstance } from '../../Interfaces/ModuleSelectionInterface';
import { FeedItem } from '../../Interfaces/FeedItem';

interface Props {
  feedItem: FeedItem;
  readOwn: boolean | undefined;
  setSingleInstance: (singleInstance: SingleInstance) => void;
  forms: FormInterface[];
  clientPersist: ClientPersistInterface;
}

const getSingleInstance = (dataPoint: DataPoint, forms) => {
  const form = forms.find(f => f.ref === dataPoint.questionnaire_id);
  if (form) {
    return {
      model: form,
      dataPoint: {...dataPoint, formId: form.id },
      newAnswer: false
    };
  }
  return undefined;
};

const FeedItemComponent = (props: Props) => {
  const { feedItem } = props;
  const icon = feedItem.iconUrl ? (<img src={feedItem.iconUrl} alt="icon" />) : null;
  const showSingleInstance = () => {
    const dataPoint: DataPoint = { questionnaire_id: feedItem.formid, };
    if (feedItem.id) {
      dataPoint.id = feedItem.id;
    } else if (feedItem.rowId) {
      dataPoint.row_id = feedItem.rowId;
    }
    const singleInstance = getSingleInstance(dataPoint, props.forms);
    if (singleInstance) {
      const { clientPersist } = props;
      if (clientPersist.payingCustomer === 'yes') {
        ReactGA.event({
          category: 'single-instance',
          action: `open-feed`,
          label: `${clientPersist.instance}`
        });
      }
      props.setSingleInstance(singleInstance);
    }
  };

  return (
    <tr>
      <td scope="col-4" >
        <span className="feed-icon" title={feedItem.formName}>
          {icon}
        </span>
        <a onClick={showSingleInstance} href={`#${feedItem.formid}/${feedItem.id}/${feedItem.rowId}/false`}>
          <strong>{feedItem.name}</strong>
        </a>
        <i> {feedItem.status}</i>
      </td>
      {!props.readOwn && (<td scope="col">{feedItem.editor}</td>)}
      <td scope="col">{feedItem.modified}</td>
      {!props.readOwn && (<td scope="col">{feedItem.createdOn}</td>)}
      {!props.readOwn && (<td scope="col">{feedItem.createdBy}</td>)}
    </tr>
  );
};
export default FeedItemComponent;
