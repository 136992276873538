import './ScheduleDayInfoModal.scss';
import React, { FunctionComponent } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BaseModalInterface } from '../../Interfaces/ModalInterface';
import { getLocalization } from '../../global/global';
import { ScheduleInterface } from '../../Interfaces/ScheduleInterface';
import { User } from '../../Interfaces/User';
import { ScheduleSelectUser } from '../Schedule/ScheduleSelectUser';

export interface DayScheduleInterface extends Omit<ScheduleInterface, 'formId' | 'initialized'> {
  title: string;
}

export interface IScheduleUserSelectionModalProps extends BaseModalInterface {
  addNewSchedule: (user: User) => void;
}

const className = 'ScheduleUserSelectionModal';

export const ScheduleUserSelectionModal: FunctionComponent<IScheduleUserSelectionModalProps> = (props) => {
  const addSchedule = (user: User) => {
    props.addNewSchedule(user);
    props.onClose();
  };
  return (
    <Modal
      show
      backdrop={'static'}
      className={className}
      size={'sm'}
    >
      <Modal.Header closeButton>
        {getLocalization('scheduleSelectUser')}
      </Modal.Header>
      <Modal.Body className={`${className}-body`}>
        <ScheduleSelectUser
          baseClassName={''}
          setSelectedUser={(user) => {
            addSchedule(user);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => props.onClose()}>
          {getLocalization('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
