import * as React from 'react';
import './Syle.scss';
import { getLocalization, globalWindow } from '../../global/global';

export default function Footer() {

  return (
    <div className="logfoot">
      {`${getLocalization('copyright_1')} `} &#169;
      {` ${new Date().getFullYear()}`}
      {` ${getLocalization('copyright_2')} `}
      {globalWindow.version}
      {` | `}
      <strong>
        <a href="cookiepolicy.jsp" className="cookiePolicy" target="_blank">
          {getLocalization('cookiepolicy')}
        </a>
      </strong>
    </div>
  );
}
