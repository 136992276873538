import { HIERARCHY } from '../actions/actionTypes';
import { Locations } from '../Interfaces/LocationInterface';

// TODO remove
const locationHierarchy = (state: Locations = [], action) => {
  switch (action.type) {
    case HIERARCHY.SET_LOCATION_HIERARCHY:
      if (action.payload) {
        return Object.assign([], action.payload);
      }
      return state;
    default:
      return state; //
  }
};

export default locationHierarchy;
