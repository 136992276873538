import { combineReducers } from 'redux';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { StateInterface } from '../Interfaces/StateInterface';
import { persistDataKey } from '../global/constants';
import { FormsInterface } from '../Interfaces/Forms/FormsInterface';
import hierarchy from './hierarchyReducer';
import navigation from './navigationReducer';
import progress from './progress';
import alert from './alert';
import confirm from './confirm';
import groupDiagrams from './groupDiagrams';
import filtersMenu from './filtersMenuReducer';
import forms from './formsReducer';
import totalPOICount from './totalPOICountReducer';
import clientPersist from './clientPersistReducer';
import system from './systemReducer';
import moduleSelection from './moduleSelectionReducer';
import singleInstanceAnswer from './SingleInstanceAnswer';
import locationHierarchy from './locationHierarchy';
import users from './users';
import answers from './answers';
import scheduleAnswers from './scheduleAnswersReducer';
import locations from './locationsReducer';
import feed from './feed';
import autoUpdateFeed from './autoUpdatedFeed';
import reports from './reports';
import importMappings from './importMappings';
import taskUsers from './taskUsers';
import galleryMenu from './galleryMenuReducer';
import chartsMenu from './chartsMenu';
import tableMenu from './tableMenu';
import sharedData from './sharedData';
import powerBiUrls from './powerBIUrls';
import galleryItems from './galleryItems';
import locationHierarchies from './locationHierarchies';
import paymentPlanInfo from './paymentPlanInfo';
import transactions from './transactions';
import mySchedule from './mySchedule';
import dashboard from './dashboard';
import dashboardMenu from './dashboardMenu';
import documents from './documents';
import documentFolders from './documentFolders';
import documentFiles from './documentFiles';
import documentActivities from './documentActivities';
import groups from './groups';
import documentFolderPermissions from './documentFolderPermissions';
import transcribing from './transcribing';

const routerTransform = createTransform<RouterState, RouterState>(
  // transform state on its way to being serialized and persisted.
  (inboundState) => {
    return { ...inboundState };
  },
  // transform state being rehydrated
  (outboundState) => {
    return {
      location: outboundState.location,
      action: outboundState.action || 'POP',
    };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['router'] },
);

const collectionTransform = createTransform<FormsInterface, FormsInterface>(
  // transform state on its way to being serialized and persisted.
  (inboundState) => {
    return { ...inboundState };
  },
  // transform state being rehydrated
  (outboundState) => {
    // set loaded to false for CollectionInterfaces so that values are fetched from server atleast once.
    return {
      collection: outboundState.collection,
      loaded: false,
    };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['forms'] },
);

const rootPersistConfig = {
  key: persistDataKey,
  storage,
  // specify store states that should not be stored in localStorage
  blacklist: [
    'clientPersist',
    'progress',
    'confirm',
    'alert',
    'answers',
    'scheduleAnswers',
    'feed',
    'galleryMenu',
    'chartsMenu',
    'navigation',
    'moduleSelection',
    'locationHierarchy',
    'locationHierarchies',
    'locations',
    'autoUpdateFeed',
    'forms',
    'sharedData',
    'galleryItems',
    'paymentPlanInfo',
    'mySchedule',
    'dashboard',
    'dashboardMenu',
    'documents',
    'documentFolders',
    'documentFiles',
    'documentActivities',
    'documentFolderPermissions',
    'groups',
    'transcribing'
  ],
  transforms: [collectionTransform, routerTransform],
  // These transforms can be used to logout the user if token does not exist
  // transforms: [
  //   createTransform(
  //       (state, key) => (sessionStorage.PoimapperSession ? state : null),
  //       (state, key) => {
  //         return state;
  //       }
  //   )
  // ]
};

export const createRootReducer = (history: History) =>
  persistReducer(
    rootPersistConfig,
    combineReducers<StateInterface>({
      clientPersist,
      progress,
      alert,
      confirm,
      navigation,
      router: connectRouter(history),
      filtersMenu,
      forms,
      totalPOICount,
      hierarchy,
      system,
      moduleSelection,
      singleInstanceAnswer,
      groupDiagrams,
      locationHierarchy,
      users,
      answers,
      scheduleAnswers,
      feed,
      autoUpdateFeed,
      taskUsers,
      locations,
      reports,
      importMappings,
      galleryMenu,
      chartsMenu,
      tableMenu,
      sharedData,
      powerBiUrls,
      galleryItems,
      locationHierarchies,
      paymentPlanInfo,
      transactions,
      mySchedule,
      dashboard,
      dashboardMenu,
      documents,
      documentFolders,
      documentFiles,
      documentActivities,
      groups,
      documentFolderPermissions,
      transcribing
    }),
  );
