import { UPDATE_ALERT } from '../actions/actionTypes';
import { AlertInterface } from '../Interfaces/AlertInterface';

const alert = (state = { visible: false }, action): AlertInterface => {
  switch (action.type) {
    case UPDATE_ALERT:
      if (action.alert) {
        return Object.assign({}, state, action.alert);
      }
      return state;
    default:
      return state;
  }
};

export default alert;
