import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import GPSQuestion from '../elements/GPSQuestion';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import FormUtils from '../utils/FormUtils';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { reverseGeoCode } from '../../../actions/reverseGeoCode';

export interface Props {
  question: LooseObject;
  dataPoint: DataPoint;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  parentDataPoint: LooseObject | undefined;
  forms: LooseObject;
  edit?: boolean;
}

export interface ActionProps {
  reverseGeoCode: (lat: number, lng: number, callBack: (geoCodedData: LooseObject) => void) => void;
}
export interface StateProps {
  groupDiagrams: LooseObject[];
}

export type GPSQuestionPropsInterface = Props & StateProps & ActionProps;

const GPSQuestionContainer = (props: GPSQuestionPropsInterface) => {
  return (
    <GPSQuestion {...props} />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    groupDiagrams: state.groupDiagrams // read state from parent if there?
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    reverseGeoCode: (lat: number, lng: number, callBack: (geoCodedData: LooseObject) => void) => {
      dispatch(reverseGeoCode(lat, lng, callBack));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GPSQuestionContainer);
