import * as React from 'react';
import bind from 'bind-decorator';
import { Dropdown, Form } from 'react-bootstrap';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { getLocalization } from '../../../global/global';

interface Props {
  value: string;
  question: LooseObject;
  options: LooseObject[];
  onUpdate: (value: string) => void;
}

interface State {
  value: string[];
  showDropdown: boolean;
  renderValue: string;
}

export default class MultipleSelectEditor extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    const value: string[] = props.value ?
      props.value.split(',').map( (v: string) => v.trim()).filter(v => v !== '') : [];
    this.state = {
      value: value,
      showDropdown: true,
      renderValue: this.getRenderValue(value)
    };
  }

  @bind
  private getRenderValue(value) {
    const { options } = this.props;
    const str: string[] = [];
    options.forEach(el => {
      if (value.indexOf(el.value) !== -1) {
        str.push(el.label);
      }
    });
    return str.join(',');
  }

  @bind
  private handleChange(e) {
    const value = [...this.state.value];
    if (e.target.checked) {
      value.push(e.target.value);
      this.setState({value});
    } else {
      this.setState({ value: value.filter( id => id !== e.target.value) });
    }
  }

  @bind
  private onToggle(isOpen, event, metadata) {
    console.log(metadata.source);
    if (metadata.source === 'select') {
      this.setState({ showDropdown: true });
    } else {
      this.setState({ showDropdown: isOpen });
      if (!isOpen) {
        this.props.onUpdate(this.state.value.join(','));
      }
    }
  }

  public render(): JSX.Element {
    const { options, question } = this.props;
    return (
      <div>
        <Dropdown className="select-question-editor" onToggle={this.onToggle} show={this.state.showDropdown}>
          <Dropdown.Toggle variant="primary" id="chart-settings-dropdown" size="sm">
            <span>{getLocalization('clickToSelect')}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu rootCloseEvent="click">
            {options.map((opt) => {
              return (
                <Dropdown.Item as="div" key={`option-${opt.value}-${question.id}`}>
                  <Form.Check
                    id={`${opt.value}`}
                    type={'checkbox'}
                    label={opt.label}
                    value={opt.value}
                    onChange={this.handleChange}
                    name={opt.value}
                    checked={this.state.value.indexOf(opt.value) !== -1}
                  />
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
