import { DocumentInterface } from 'Interfaces/DocumentInterface';
import './Style.scss';
import { getLocalization } from 'global/global';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Container, Form, Table } from 'react-bootstrap';
import { getRequestConfig } from 'api';
import { RolePermissions } from 'Interfaces/Documents/PermissionInterface';

interface Props {
  document: DocumentInterface;
  documentPermissions?: RolePermissions;
  setDocumentPermissions: (permissions: RolePermissions) => void;
}
export const roles =  ['admin', 'modeler', 'ordinary', 'enumerator'];

function DocumentPermission(props: Props) {
  const { document } = props;

  const [documentPermissions, setDocumentPermissions] =
    React.useState<RolePermissions | undefined>(props.documentPermissions);

  React.useEffect(() => {
    setDocumentPermissions(props.documentPermissions);
  }, [props.document.id]);

  const updatePermission = (permission, role) => {
    if (documentPermissions) {
      setDocumentPermissions({...documentPermissions, [role]: permission });
    }
  };

  const savePermissions = async () => {
    console.log('save');
    if (documentPermissions) {
      const data = Object.values(documentPermissions);
      const config = getRequestConfig();
      const permissions = await fetch(`/json/app/document/permission`, {
        ...config,
        method: 'POST',
        body: JSON.stringify(data)
      }).then(res => res.json());
      if (Array.isArray(permissions)) {
        const docPerm = {...documentPermissions};
        permissions.forEach((permission) => {
          docPerm[permission.role] = permission;
        });
        props.setDocumentPermissions(docPerm);
      }
    }
  };

  return (
    <Container className="document-permission">
      <Table className="mt-5">
        {documentPermissions && roles.map((role) => {
          const permission = documentPermissions[role];
          return (
            <tr key={`role-${document.id}`}>
              <td>{permission.role}</td>
              <td>
                <Form.Check
                  label={'Read'}
                  type="checkbox"
                  id={`${role}-${document.id}-read`}
                  checked={permission.read}
                  onChange={(e) => updatePermission({
                    ...permission, read: e.target.checked,
                    write: !e.target.checked ? false : permission.write
                  }, role)}
                />
              </td>
              <td>
                <Form.Check
                  label={'Write'}
                  id={`${role}-${document.id}-write`}
                  checked={permission.write}
                  onChange={(e) => updatePermission({
                    ...permission, write: e.target.checked,
                    read: e.target.checked ? true : permission.read
                  }, role)}
                />
              </td>
              <td>
                <Form.Check
                  label={'Delete'}
                  id={`${role}-${document.id}-delete`}
                  checked={permission.delete}
                  onChange={(e) => updatePermission({...permission, delete: e.target.checked}, role)}
                />
              </td>
            </tr>
          );
        })}
      </Table>
      <Button
        size="sm"
        onClick={() => void savePermissions()}
      >
        <i className="fa fa-save" aria-hidden="true" />
        <span>{getLocalization('save')}</span>
      </Button>
    </Container>
  );
}

export default connect(null, null)(DocumentPermission);
